import { CSS } from '@stitches/react';
import * as React from 'react';
import { CommonEnvVars } from '../../envVars';
import { Box } from './box';
import { Flex } from './flex';
import { I } from './i';
import { ILinkProps, Link } from './link';

interface ILinkTextProps extends ILinkProps {
    css?: CSS;
    children: React.ReactNode | string;
    hideIcon?: boolean;
}

export const LinkText = ({ css, children, hideIcon, to, ...props }: ILinkTextProps) => {
    // The `base` param is overridden if the `url` param is a full href (e.g., when we link
    // from the main app to the public app)
    const url = new URL(to.toString(), CommonEnvVars.HEAVENT_APP_URL);

    const icon = !hideIcon
        ? ['http:', 'https:'].includes(url.protocol) && url.origin !== location.origin
            ? 'arrow-up-right-from-square'
            : props.download
              ? 'arrow-down-to-bracket'
              : undefined
        : undefined;

    return (
        <Flex
            align="center"
            css={{
                cursor: 'pointer',
                fontWeight: '$medium',
                '&:hover': {
                    textDecoration: 'underline'
                },
                ...css
            }}
            gap="2"
        >
            <Link to={url} {...props}>
                {children}

                {icon && (
                    <Box>
                        <I icon={icon} />
                    </Box>
                )}
            </Link>
        </Flex>
    );
};
LinkText.displayName = 'LinkText';
