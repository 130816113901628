import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';

const _FullScreenPopup = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    zIndex: '10',
    '& .fsp-header': {
        px: '$7'
    },
    '& .fsp-category': {
        fontSize: '11px',
        fontWeight: '$semiBold',
        textTransform: 'uppercase'
    },
    '& .fsp-title': {
        fontSize: '$textMd',
        fontWeight: '$semiBold'
    },
    variants: {
        color: {
            white: {
                '& .fsp-header': {
                    background: 'white',
                    borderBottom: '1px solid $gray200'
                },
                '& .fsp-category': {
                    color: '$gray600'
                },
                '& .fsp-title': {
                    color: '$gray900'
                }
            },
            dark: {
                '& .fsp-header': {
                    background: '$gray900'
                },
                '& .fsp-category': {
                    color: 'white'
                },
                '& .fsp-title': {
                    color: 'white'
                }
            }
        }
    },
    defaultVariants: {
        color: 'white'
    }
});

type CloseFnProps = {
    fallbackClosePath?: null;
    onClose: () => void;
};

type ClosePathProps = {
    fallbackClosePath: string;
    onClose?: null;
};

type CloseProps = CloseFnProps | ClosePathProps;

type IFullScreenPopupProps = CloseProps & {
    button?: React.ReactNode;
    category?: string;
    children: React.ReactNode;
    color?: 'white' | 'dark';
    title: string;
};

export const FullScreenPopup = (props: IFullScreenPopupProps) => {
    const history = useHistory();
    const onClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        } else {
            history.goBack(props.fallbackClosePath || HeaventPaths.HOME);
        }
    };

    return (
        <_FullScreenPopup color={props.color}>
            <Flex align="center" className="fsp-header" gap="4" height={65} width={1}>
                <Box width={300} />

                <Flex align="center" css={{ flex: '1' }} direction="column">
                    {props.category && <Box className="fsp-category">{props.category}</Box>}

                    <Box className="fsp-title">{props.title}</Box>
                </Flex>

                <Flex gap="4" justify="end" width={300}>
                    {props.button}

                    <Button
                        color={props.color === 'dark' ? 'gray' : 'white'}
                        leftIcon="xmark"
                        onClick={onClose}
                    />
                </Flex>
            </Flex>

            <Box css={{ height: 'calc(100% - 65px)' }} width={1}>
                {props.children}
            </Box>
        </_FullScreenPopup>
    );
};
