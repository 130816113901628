import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Grid } from 'common/src/designSystem/components/grid';
import * as React from 'react';
import { FormMenu } from './formMenu';

interface IFormContainerProps {
    children: React.ReactNode;
    button?: React.ReactNode;
    title: string;

    onClose(): void;
}

export const FormContainer = (props: IFormContainerProps) => {
    const { translate } = useHeavent();

    return (
        <FullScreenPopup
            button={props.button}
            category={translate('cr_ation_d_un_f_67278')}
            color="dark"
            title={props.title}
            onClose={props.onClose}
        >
            <Grid gridtemplatecolumns="1fr" gridtemplaterows="55px 1fr" height={1} width={1}>
                <FormMenu />

                <Box css={{ overflowY: 'auto' }}>{props.children}</Box>
            </Grid>
        </FullScreenPopup>
    );
};
