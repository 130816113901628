import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FORM_FORMAT } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';
import { IDatetimeInputProps } from './datetimeInput';

export const DateInput = ({
    value: _value,
    css,
    label,
    description,
    hint,
    icon,
    min,
    onChange,
    max,
    state,
    rightIcon,
    onRightIconClick,
    ...rest
}: IDatetimeInputProps) => {
    const dateTimeValue = _value
        ? isNonEmptyString(_value)
            ? DateTime.fromISO(_value)
            : _value
        : null;
    const value = dateTimeValue?.isValid ? dateTimeValue.toFormat(FORM_FORMAT) : '';
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={css} direction="column" width={1}>
            <Label htmlFor={inputId}>{label}</Label>

            <Description id={descId}>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <StyledInputContainer
                cursor="default"
                icon={icon}
                rightIcon={rightIcon}
                state={state}
                onRightIconClick={onRightIconClick}
            >
                <Box
                    css={{
                        flex: '1',
                        height: '100%',
                        '& input': {
                            background: 'transparent',
                            border: 'none',
                            color: '$gray500',
                            fontSize: '$textSm',
                            height: '100%',
                            lineHeight: '20px',
                            outline: 'none',
                            width: '100%'
                        }
                    }}
                >
                    <input
                        aria-describedby={description ? descId : undefined}
                        aria-errormessage={state === 'error' ? errorId : undefined}
                        aria-invalid={state === 'error'}
                        disabled={state === 'disabled'}
                        id={inputId}
                        max={max?.toFormat(FORM_FORMAT)}
                        min={min?.toFormat(FORM_FORMAT)}
                        type="date"
                        value={value}
                        onChange={(e) => {
                            if (isNonEmptyString(e.target.value)) {
                                onChange(
                                    DateTime.fromFormat(e.target.value, FORM_FORMAT, {
                                        zone: 'UTC'
                                    })
                                );
                            } else {
                                onChange(DateTime.invalid('Invalid'));
                            }
                        }}
                        {...rest}
                    />
                </Box>
            </StyledInputContainer>

            <Hint id={errorId} state={state}>
                {hint}
            </Hint>
        </Flex>
    );
};
