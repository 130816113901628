import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { FullScreenPopup } from './fullScreenPopup/fullScreenPopup';

interface IAssignPopupProps {
    button: React.ReactNode;
    children: React.ReactNode;
    closePath: string;
    popup: {
        title: string;
        category: string;
    };
}

export const AssignPopup = (props: IAssignPopupProps) => (
    <FullScreenPopup
        category={props.popup.category}
        fallbackClosePath={props.closePath}
        title={props.popup.title}
    >
        <Flex css={{ overflow: 'hidden' }} direction="column" height={1} width={1}>
            <Flex
                css={{ background: '$gray50', flex: '1', overflow: 'auto', py: '$9' }}
                justify="center"
            >
                <Flex
                    css={{
                        height: 'fit-content',
                        overflow: 'hidden'
                    }}
                    direction="column"
                    width={1170}
                >
                    {props.children}
                </Flex>
            </Flex>

            <Flex
                align="center"
                css={{
                    boxShadow:
                        '0px -4px 8px -2px rgba(16, 24, 40, 0.1), 0px -2px 4px -2px rgba(16, 24, 40, 0.06)'
                }}
                height={72}
                justify="center"
                width={1}
            >
                {props.button}
            </Flex>
        </Flex>
    </FullScreenPopup>
);
