// /!\ THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT /!\
/* tslint:disable */
/* @public */
import { DateTime, Interval } from 'luxon';
import { NominalType } from '../util/nominalType';
export type UserEmail = NominalType<string, 'UserEmail'>;
export type CustomFieldSlug = NominalType<string, 'CustomFieldSlug'>;
export type MessageId = NominalType<string, 'MessageId'>;
export type SmsId = NominalType<string, 'SmsId'>;
export type TeamCode = NominalType<string, 'TeamCode'>;
export type UTID = NominalType<string, 'UTID'>;
export type FormsElementId = NominalType<string, 'FormsElementId'>;
export type AccreditationId = NominalType<number, 'AccreditationId'>;
export type AccreditationsCategoryId = NominalType<number, 'AccreditationsCategoryId'>;
export type AccreditationsSlotId = NominalType<number, 'AccreditationsSlotId'>;
export type AccreditationsUsersInfoId = NominalType<number, 'AccreditationsUsersInfoId'>;
export type CampaignId = NominalType<number, 'CampaignId'>;
export type CommentId = NominalType<number, 'CommentId'>;
export type CustomDocumentId = NominalType<number, 'CustomDocumentId'>;
export type CustomFieldId = NominalType<number, 'CustomFieldId'>;
export type CustomFieldsCategoryId = NominalType<number, 'CustomFieldsCategoryId'>;
export type CustomFieldsValueId = NominalType<number, 'CustomFieldsValueId'>;
export type CustomSlotId = NominalType<number, 'CustomSlotId'>;
export type CustomSlotsPeriodId = NominalType<number, 'CustomSlotsPeriodId'>;
export type DelegationId = NominalType<number, 'DelegationId'>;
export type DelegationsCategoryId = NominalType<number, 'DelegationsCategoryId'>;
export type DesignId = NominalType<number, 'DesignId'>;
export type DocumentId = NominalType<number, 'DocumentId'>;
export type EmailId = NominalType<number, 'EmailId'>;
export type EmailsSenderId = NominalType<number, 'EmailsSenderId'>;
export type EmailsTemplateId = NominalType<number, 'EmailsTemplateId'>;
export type EventId = NominalType<number, 'EventId'>;
export type EventsDocumentId = NominalType<number, 'EventsDocumentId'>;
export type FormId = NominalType<number, 'FormId'>;
export type FormsUsersInfoId = NominalType<number, 'FormsUsersInfoId'>;
export type FormsUsersInfosSlotId = NominalType<number, 'FormsUsersInfosSlotId'>;
export type InvoiceId = NominalType<number, 'InvoiceId'>;
export type OrganizationId = NominalType<number, 'OrganizationId'>;
export type PhoneId = NominalType<number, 'PhoneId'>;
export type PositionId = NominalType<number, 'PositionId'>;
export type PositionsCategoryId = NominalType<number, 'PositionsCategoryId'>;
export type PositionsSlotId = NominalType<number, 'PositionsSlotId'>;
export type PositionsSlotsUsersInfoId = NominalType<number, 'PositionsSlotsUsersInfoId'>;
export type RoleId = NominalType<number, 'RoleId'>;
export type SegmentId = NominalType<number, 'SegmentId'>;
export type SegmentsFolderId = NominalType<number, 'SegmentsFolderId'>;
export type TagId = NominalType<number, 'TagId'>;
export type TextMessageId = NominalType<number, 'TextMessageId'>;
export type TextMessageReplyId = NominalType<number, 'TextMessageReplyId'>;
export type TraceId = NominalType<number, 'TraceId'>;
export type TranslationId = NominalType<number, 'TranslationId'>;
export type UserId = NominalType<number, 'UserId'>;
export type UsersInfoId = NominalType<number, 'UsersInfoId'>;
export type VolunteersRegistrationId = NominalType<number, 'VolunteersRegistrationId'>;
export type VolunteersRegistrationsSlotId = NominalType<number, 'VolunteersRegistrationsSlotId'>;
export type WeezeventRuleId = NominalType<number, 'WeezeventRuleId'>;
export type WeezeventWebhookId = NominalType<number, 'WeezeventWebhookId'>;
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    OrganizationId: OrganizationId;
    EventId: EventId;
    TranslationId: TranslationId;
    Date: DateTime;
    DocumentId: DocumentId;
    Datetime: DateTime;
    JSON: any;
    CustomSlotId: CustomSlotId;
    FormId: FormId;
    CustomSlotsPeriodId: CustomSlotsPeriodId;
    Time: any;
    PositionsCategoryId: PositionsCategoryId;
    PositionId: PositionId;
    TraceId: TraceId;
    PositionsSlotId: PositionsSlotId;
    DatetimeRange: Interval;
    CustomFieldId: CustomFieldId;
    UserId: UserId;
    UsersInfoId: UsersInfoId;
    CustomFieldSlug: any;
    UserEmail: any;
    CampaignId: CampaignId;
    EmailsSenderId: EmailsSenderId;
    AccreditationsCategoryId: AccreditationsCategoryId;
    AccreditationId: AccreditationId;
    AccreditationsSlotId: AccreditationsSlotId;
    VolunteersRegistrationId: VolunteersRegistrationId;
    BigInt: number;
    RoleId: RoleId;
    BitString: string;
    Duration: any;
    TagId: TagId;
    AccreditationsUsersInfoId: AccreditationsUsersInfoId;
    DelegationId: DelegationId;
    DelegationsCategoryId: DelegationsCategoryId;
    DesignId: DesignId;
    Timezone: string;
    CustomDocumentId: CustomDocumentId;
    CustomFieldsCategoryId: CustomFieldsCategoryId;
    EmailsTemplateId: EmailsTemplateId;
    InvoiceId: InvoiceId;
    SegmentId: SegmentId;
    SegmentsFolderId: SegmentsFolderId;
    TextMessageId: TextMessageId;
    SmsId: any;
    FormsElementId: any;
    PositionsSlotsUsersInfoId: PositionsSlotsUsersInfoId;
    TeamCode: any;
    UTID: any;
    EmailId: EmailId;
    MessageId: any;
    CommentId: CommentId;
    FormsUsersInfoId: FormsUsersInfoId;
    FormsUsersInfosSlotId: FormsUsersInfosSlotId;
    CustomFieldsValueId: CustomFieldsValueId;
    EventsDocumentId: EventsDocumentId;
    WeezeventRuleId: WeezeventRuleId;
    UUID: any;
    PhoneId: PhoneId;
    TextMessageReplyId: TextMessageReplyId;
    VolunteersRegistrationsSlotId: VolunteersRegistrationsSlotId;
    WeezeventWebhookId: WeezeventWebhookId;
};

export type Accreditation = IAccreditation & {
    id: Scalars['AccreditationId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationCategory: AccreditationsCategory;
    accreditationCategoryName: Scalars['String'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    privateNoteId: Scalars['TranslationId'];
    privateNote: Scalars['String'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom: Scalars['Int'];
    layer: MapLayer;
    accreditationDisplay: AccreditationDisplay;
    conditionsOperator: ConditionsOperator;
    willAutoAccredit: Scalars['Boolean'];
    weezeventIsSynchronized: Scalars['Boolean'];
    fields: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    accreditationsCustomFields: Array<AccreditationsCustomField>;
    assignedResources: Scalars['Int'];
    availableVolunteersRegistrations: Array<VolunteersRegistration>;
    fillingRate: Scalars['Int'];
    hasSlots: Scalars['Boolean'];
    hiddenSlotId: Scalars['AccreditationsSlotId'];
    hiddenSlot: AccreditationsSlot;
    maxResources?: Maybe<Scalars['Int']>;
    slots: Array<AccreditationsSlot>;
    numberOfSlots: Scalars['Int'];
    tags: Array<Tag>;
    tagsIds: Array<Scalars['TagId']>;
};

export type AccreditationFieldsArgs = {
    expand?: Maybe<Scalars['Boolean']>;
};

export type AccreditationSlotsArgs = {
    includeHidden?: Maybe<Scalars['Boolean']>;
};

export enum AccreditationDisplay {
    Calendar = 'Calendar',
    List = 'List',
    Radio = 'Radio'
}

export type AccreditationInput = {
    accreditationCategoryId: Scalars['BigInt'];
    name: Scalars['String'];
    description: Scalars['String'];
    privateNote: Scalars['String'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    weezeventIsSynchronized: Scalars['Boolean'];
    willAutoAccredit: Scalars['Boolean'];
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom?: Maybe<Scalars['Int']>;
    layer?: Maybe<MapLayer>;
    slots: Array<AccreditationsSlotInput>;
    accreditationDisplay: AccreditationDisplay;
    conditionsOperator: ConditionsOperator;
    accreditationsCustomFields: Array<AccreditationsCustomFieldInput>;
    fields?: Maybe<Scalars['JSON']>;
    tagsIds: Array<Scalars['TagId']>;
};

export enum AccreditationProperty {
    Acronym = 'Acronym',
    Address = 'Address',
    AutoAccredit = 'AutoAccredit',
    Category = 'Category',
    Color = 'Color',
    Description = 'Description',
    Icon = 'Icon',
    Latitude = 'Latitude',
    Longitude = 'Longitude',
    Name = 'Name',
    Tag = 'Tag',
    WeezeventIsSynchronized = 'WeezeventIsSynchronized'
}

export type AccreditationPublic = IAccreditation & {
    id: Scalars['AccreditationId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationCategory: AccreditationsCategory;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom: Scalars['Int'];
    layer: MapLayer;
    accreditationDisplay: AccreditationDisplay;
    accreditationsCustomFields: Array<AccreditationsCustomField>;
    hasSlots: Scalars['Boolean'];
    hiddenSlotId: Scalars['AccreditationsSlotId'];
    hiddenSlot: AccreditationsSlot;
    slots: Array<AccreditationsSlotPublic>;
    conditionsOperator: ConditionsOperator;
};

export type AccreditationsCategoriesConnection = {
    nodes: Array<AccreditationsCategory>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type AccreditationsCategory = IAccreditationsCategory & {
    id: Scalars['AccreditationsCategoryId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    accreditations: Array<Accreditation>;
    assignedResources: Scalars['Int'];
    numberOfAccreditations: Scalars['Int'];
};

export type AccreditationsCategoryInput = {
    name: Scalars['String'];
};

export type AccreditationsCategoryPublic = IAccreditationsCategory & {
    id: Scalars['AccreditationsCategoryId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    accreditations: Array<AccreditationPublic>;
};

export type AccreditationsConnection = {
    nodes: Array<Accreditation>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type AccreditationsCustomField = {
    accreditationId: Scalars['AccreditationId'];
    customFieldId: Scalars['CustomFieldId'];
    customField: CustomField;
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    conditionValue: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type AccreditationsCustomFieldInput = {
    customFieldId: Scalars['CustomFieldId'];
    conditionValue: Scalars['JSON'];
};

export enum AccreditationsEmailsRecipients {
    Accredited = 'Accredited',
    Everyone = 'Everyone',
    Members = 'Members',
    Refused = 'Refused',
    Segments = 'Segments'
}

export type AccreditationsSlot = IAccreditationsSlot & {
    id: Scalars['AccreditationsSlotId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationCategory: AccreditationsCategory;
    accreditationId: Scalars['AccreditationId'];
    accreditation: Accreditation;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    isHidden: Scalars['Boolean'];
    date?: Maybe<Scalars['Date']>;
    maxResources?: Maybe<Scalars['Int']>;
    weezeventChecklistId?: Maybe<Scalars['BigInt']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    assignedResources: Scalars['Int'];
    fillingRate: Scalars['Int'];
    isFull: Scalars['Boolean'];
    scannedResources: Scalars['Int'];
    wishedResources: Scalars['Int'];
};

export type AccreditationsSlotInput = {
    id?: Maybe<Scalars['AccreditationsSlotId']>;
    name: Scalars['String'];
    date?: Maybe<Scalars['Date']>;
    maxResources?: Maybe<Scalars['Int']>;
    assignedResources?: Maybe<Scalars['Int']>;
};

export type AccreditationsSlotPublic = IAccreditationsSlot & {
    id: Scalars['AccreditationsSlotId'];
    accreditationId: Scalars['AccreditationId'];
    accreditation: AccreditationPublic;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    date?: Maybe<Scalars['Date']>;
    maxResources?: Maybe<Scalars['Int']>;
    isFull: Scalars['Boolean'];
};

export type AccreditationsSlotsConnection = {
    nodes: Array<AccreditationsSlot>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type AccreditationsSlotsSort = {
    attribute: Scalars['String'];
    direction: SortDirection;
};

export enum AccreditationsSlotsSortAttributes {
    Accreditation = 'Accreditation',
    Acronym = 'Acronym',
    Category = 'Category',
    CreatedAt = 'CreatedAt',
    Date = 'Date',
    Id = 'Id',
    Name = 'Name'
}

export type AccreditationsSort = {
    attribute: Scalars['String'];
    direction: SortDirection;
};

export enum AccreditationsSortAttributes {
    Acronym = 'Acronym',
    Category = 'Category',
    CreatedAt = 'CreatedAt',
    Id = 'Id',
    Name = 'Name'
}

export type AccreditationsStats = {
    numberOfAccredited: Scalars['Int'];
    numberOfNotApplicable: Scalars['Int'];
    numberOfWaitingAccreditation: Scalars['Int'];
};

export enum AccreditationState {
    Accredited = 'Accredited',
    AutoAccredited = 'AutoAccredited',
    NotApplicable = 'NotApplicable',
    Refused = 'Refused',
    WaitingAccreditation = 'WaitingAccreditation'
}

export type AccreditationsUsersInfo = {
    id: Scalars['AccreditationsUsersInfoId'];
    accreditationId: Scalars['AccreditationId'];
    accreditation: Accreditation;
    userInfoId: Scalars['UsersInfoId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationCategory: AccreditationsCategory;
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    accreditationSlot: AccreditationsSlot;
    isAutoAccredit: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    scannedAt?: Maybe<Scalars['Datetime']>;
    isConfirmationEmailSent: Scalars['Boolean'];
};

export type ActivitiesConnection = {
    nodes: Array<Activity>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type Activity = {
    date: Scalars['Datetime'];
    icon: Scalars['String'];
    text: Scalars['String'];
};

export type AsoassignmentDocumentOptions = {
    habits: Scalars['String'];
};

export type AssignmentInput = {
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
};

export enum AssignmentsEmailsRecipients {
    Assigned = 'Assigned',
    Everyone = 'Everyone',
    Members = 'Members',
    Refused = 'Refused',
    Segments = 'Segments'
}

export type AssignmentsStats = {
    numberOfAssigned: Scalars['Int'];
    numberOfNotApplicable: Scalars['Int'];
    numberOfPreAssigned: Scalars['Int'];
    numberOfRefused: Scalars['Int'];
    numberOfWaitingAssignment: Scalars['Int'];
};

export type AwsS3PostCredentials = {
    endpointUrl: Scalars['String'];
    policy: Scalars['String'];
    amzSignature: Scalars['String'];
    amzCredential: Scalars['String'];
    amzDate: Scalars['String'];
};

export type Backoffice = {
    events: EventsConnection;
    organization: Organization;
    organizations: OrganizationsConnection;
    invoices: InvoicesConnection;
    qontoTransactions: Array<QontoTransaction>;
};

export type BackofficeEventsArgs = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    states?: Maybe<Array<EventState>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<EventsSort>;
};

export type BackofficeOrganizationArgs = {
    id: Scalars['OrganizationId'];
};

export type BackofficeOrganizationsArgs = {
    name?: Maybe<Scalars['String']>;
    features?: Maybe<Array<Feature>>;
    isAdmin?: Maybe<Scalars['Boolean']>;
    isActive?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<OrganizationSort>;
};

export type BackofficeInvoicesArgs = {
    name?: Maybe<Scalars['String']>;
    states?: Maybe<Array<InvoiceState>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type BackofficeOrganizationInput = {
    name: Scalars['String'];
    features: Array<Feature>;
    customBadges: Array<CustomBadge>;
    periodEndAt: Scalars['Date'];
    usersIds: Array<Scalars['UserId']>;
    isBlocked: Scalars['Boolean'];
    weezeventOrganizationId?: Maybe<Scalars['BigInt']>;
    membersLimit?: Maybe<Scalars['BigInt']>;
};

export type BadgeDocumentOptions = {
    format: PdfFormat;
    logo?: Maybe<Scalars['JSON']>;
    color: Scalars['String'];
    name?: Maybe<Scalars['String']>;
    infos?: Maybe<Scalars['String']>;
    showAssignments?: Maybe<Scalars['Boolean']>;
};

export type BruleursDocumentOptions = {
    logo: Scalars['JSON'];
};

export type Campaign = {
    id: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    event?: Maybe<Event>;
    name: Scalars['String'];
    campaignType: CampaignType;
    state: CampaignState;
    sender?: Maybe<Scalars['String']>;
    text: Scalars['JSON'];
    subject?: Maybe<Scalars['String']>;
    html?: Maybe<Scalars['String']>;
    replyTo?: Maybe<Scalars['String']>;
    sendToEveryone: Scalars['Boolean'];
    numberOfRecipients: Scalars['Int'];
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    emailSender?: Maybe<EmailsSender>;
    sentAt?: Maybe<Scalars['Datetime']>;
    scheduledAt?: Maybe<Scalars['Datetime']>;
    attachments: Array<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    deletedAt?: Maybe<Scalars['Datetime']>;
    accreditationsCategoriesIds: Array<Scalars['AccreditationsCategoryId']>;
    accreditationsCategories: Array<AccreditationsCategory>;
    accreditationsIds: Array<Scalars['AccreditationId']>;
    accreditations: Array<Accreditation>;
    accreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    accreditationsSlots: Array<AccreditationsSlot>;
    cost: Scalars['Float'];
    documents: Array<Document>;
    emails: EmailsConnection;
    numberOfClick?: Maybe<Scalars['Int']>;
    numberOfDelivery: Scalars['Int'];
    numberOfOpen?: Maybe<Scalars['Int']>;
    positions: Array<Position>;
    positionsCategories: Array<PositionsCategory>;
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    positionsIds: Array<Scalars['PositionId']>;
    positionsSlots: Array<PositionsSlot>;
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    preview: Scalars['String'];
    segments: Array<Segment>;
    segmentsIds: Array<Scalars['SegmentId']>;
    states: Array<VolunteerRegistrationState>;
    tags: Array<Tag>;
    tagsIds: Array<Scalars['TagId']>;
    textMessages: TextMessagesConnection;
    usersInfos: Array<UsersInfo>;
    usersInfosIds: Array<Scalars['UsersInfoId']>;
};

export type CampaignEmailsArgs = {
    name?: Maybe<Scalars['String']>;
    state?: Maybe<EmailState>;
    userInfoId?: Maybe<Scalars['UsersInfoId']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type CampaignPreviewArgs = {
    userInfoId?: Maybe<Scalars['UsersInfoId']>;
};

export type CampaignTextMessagesArgs = {
    name?: Maybe<Scalars['String']>;
    state?: Maybe<TextMessageState>;
    replies?: Maybe<TextMessageReplies>;
    userInfoId?: Maybe<Scalars['UsersInfoId']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type CampaignEstimate = {
    price: Scalars['Float'];
    numberOfRecipients: Scalars['Int'];
};

export type CampaignInput = {
    name: Scalars['String'];
    campaignType: CampaignType;
    sender?: Maybe<Scalars['String']>;
    text: Scalars['JSON'];
    subject?: Maybe<Scalars['String']>;
    html?: Maybe<Scalars['String']>;
    replyTo?: Maybe<Scalars['String']>;
    states: Array<VolunteerRegistrationState>;
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    positionsIds: Array<Scalars['PositionId']>;
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    tagsIds: Array<Scalars['TagId']>;
    accreditationsCategoriesIds: Array<Scalars['AccreditationsCategoryId']>;
    accreditationsIds: Array<Scalars['AccreditationId']>;
    accreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    usersInfosIds: Array<Scalars['UsersInfoId']>;
    segmentsIds: Array<Scalars['SegmentId']>;
    state: CampaignState;
    sendToEveryone: Scalars['Boolean'];
    scheduledAtDate?: Maybe<Scalars['Date']>;
    scheduledAtTime?: Maybe<Scalars['Time']>;
    documents: Array<DocumentInput>;
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    attachments: Array<Scalars['String']>;
};

export type CampaignsAccreditation = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsAccreditationsCategory = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsAccreditationsSlot = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsConnection = {
    nodes: Array<Campaign>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type CampaignsDocument = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    documentId: Scalars['DocumentId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsPosition = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsPositionsCategory = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsPositionsSlot = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionSlotId: Scalars['PositionsSlotId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsSegment = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
    insertedAt: Scalars['Datetime'];
};

export type CampaignsSort = {
    attribute: CampaignsSortAttributes;
    direction: SortDirection;
};

export enum CampaignsSortAttributes {
    Name = 'Name',
    Type = 'Type',
    State = 'State'
}

export type CampaignsTag = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    tagId: Scalars['TagId'];
    insertedAt: Scalars['Datetime'];
};

export enum CampaignState {
    Done = 'Done',
    Draft = 'Draft',
    InProgress = 'InProgress',
    Scheduled = 'Scheduled'
}

export type CampaignsUsersInfo = {
    campaignId: Scalars['CampaignId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    insertedAt: Scalars['Datetime'];
};

export enum CampaignType {
    Email = 'Email',
    Sms = 'Sms'
}

export type CertificateDocumentOptions = {
    address: Scalars['String'];
    header: Scalars['JSON'];
    infos: Scalars['String'];
    name: Scalars['String'];
    place: Scalars['String'];
    signature: Scalars['JSON'];
    title: Scalars['String'];
};

export type CheckInInput = {
    dateTime?: Maybe<Scalars['Datetime']>;
    positionsSlotsIds?: Maybe<Array<Scalars['PositionsSlotId']>>;
    positionsSlotsUsersInfosIds?: Maybe<Array<Scalars['PositionsSlotsUsersInfoId']>>;
    strategy: CheckInStrategy;
};

export enum CheckInState {
    Waiting = 'Waiting',
    CheckedIn = 'CheckedIn',
    CheckedOut = 'CheckedOut'
}

export enum CheckInStrategy {
    CheckIn = 'CheckIn',
    CheckOut = 'CheckOut',
    Reset = 'Reset'
}

export type Comment = {
    id: Scalars['CommentId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    event?: Maybe<Event>;
    userInfoId: Scalars['UsersInfoId'];
    writtenById: Scalars['UserId'];
    writtenBy: User;
    content: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type CommentInput = {
    content: Scalars['String'];
};

export enum ConditionsOperator {
    And = 'And',
    Or = 'Or'
}

export type CsvDocumentInput = {
    acl: Scalars['String'];
    delimiter?: Maybe<Scalars['String']>;
    key: Scalars['String'];
    name: Scalars['String'];
    newline?: Maybe<Scalars['String']>;
    quoteChar?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['BigInt']>;
    formats?: Maybe<Formats>;
};

export enum CustomBadge {
    Alldayin = 'Alldayin',
    Animajeconvention = 'Animajeconvention',
    Asoassignment = 'Asoassignment',
    Auborddeleau = 'Auborddeleau',
    Bobital = 'Bobital',
    Bruleurs = 'Bruleurs',
    Cerclelivraison = 'Cerclelivraison',
    Cercleopcom = 'Cercleopcom',
    Cerclepressandpartenaire = 'Cerclepressandpartenaire',
    Cerclestaff = 'Cerclestaff',
    Delta = 'Delta',
    Dfcoa4 = 'Dfcoa4',
    Dfcosmall = 'Dfcosmall',
    Elephants = 'Elephants',
    Eurockeennes = 'Eurockeennes',
    Fcg = 'Fcg',
    Francofoliesesch = 'Francofoliesesch',
    Garorockbadge = 'Garorockbadge',
    Garorockplanning = 'Garorockplanning',
    Goldencoast = 'Goldencoast',
    Hbcldc = 'Hbcldc',
    Hbclnh = 'Hbclnh',
    Kermesse = 'Kermesse',
    Lnh = 'Lnh',
    Moorea = 'Moorea',
    Nuitschampagne = 'Nuitschampagne',
    Nuitssecretes = 'Nuitssecretes',
    Openbrest = 'Openbrest',
    P2nassignment = 'P2nassignment',
    P2nassignmentmontagedemontage = 'P2nassignmentmontagedemontage',
    P2nbadge = 'P2nbadge',
    Paris20km = 'Paris20km',
    Paris20kmassignment = 'Paris20kmassignment',
    Paris20kmcontremarque = 'Paris20kmcontremarque',
    Pastourelle = 'Pastourelle',
    Pastourelleexposant = 'Pastourelleexposant',
    Pec = 'Pec',
    Playground = 'Playground',
    Res2024contremarque = 'Res2024contremarque',
    Res2024parking = 'Res2024parking',
    Roiarthur = 'Roiarthur',
    Rose = 'Rose',
    Roseparking = 'Roseparking',
    Salamandre = 'Salamandre',
    Seattle = 'Seattle',
    T24assignment = 'T24assignment',
    Utopialesa4 = 'Utopialesa4',
    Utopialesimprimeur = 'Utopialesimprimeur',
    Vancouver = 'Vancouver',
    Vandbcontremarque = 'Vandbcontremarque',
    Vannes = 'Vannes',
    Vannesassignment = 'Vannesassignment',
    Vendanges = 'Vendanges',
    Vendeeglobebadge = 'Vendeeglobebadge',
    Vendeeglobecontremarque = 'Vendeeglobecontremarque',
    Wfa24 = 'Wfa24',
    Wfa24planning = 'Wfa24planning'
}

export type CustomDocument = {
    id: Scalars['CustomDocumentId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    event: Event;
    createdById: Scalars['UserId'];
    createdBy: UsersInfo;
    name: Scalars['String'];
    slug: Scalars['String'];
    populationsIds: Array<Scalars['BigInt']>;
    populations: Array<Scalars['String']>;
    configuration: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type CustomDocumentInput = {
    name: Scalars['String'];
    slug: Scalars['String'];
    populationsIds: Array<Scalars['BigInt']>;
    configuration: Scalars['JSON'];
};

export type CustomField = {
    id: Scalars['CustomFieldId'];
    organizationId: Scalars['OrganizationId'];
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    customFieldCategoryId: Scalars['CustomFieldsCategoryId'];
    category: CustomFieldsCategory;
    customFieldCategoryName: Scalars['String'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    label?: Maybe<Scalars['String']>;
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    variety: CustomFieldVariety;
    fieldType: FieldType;
    isPrivate: Scalars['Boolean'];
    valuesId: Scalars['TranslationId'];
    hasCondition: Scalars['Boolean'];
    conditionCustomFieldId?: Maybe<Scalars['CustomFieldId']>;
    conditionCustomField?: Maybe<CustomField>;
    conditionValue: Scalars['JSON'];
    dependencies: Array<Maybe<Scalars['BigInt']>>;
    nestedOrder: Array<Maybe<Scalars['BigInt']>>;
    canSelectMultiple: Scalars['Boolean'];
    documentId?: Maybe<Scalars['DocumentId']>;
    document?: Maybe<Document>;
    fieldProperty: FieldProperty;
    canDelete: Scalars['Boolean'];
    slug: Scalars['CustomFieldSlug'];
    numberOfTimesUsed: Scalars['Int'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** List of forms in which this custom field is used */
    forms: Array<Form>;
    /** List of positions in which this custom field is used as a condition */
    positions: Array<Position>;
    values: Array<CustomFieldsValue>;
    isSelectV2: Scalars['Boolean'];
};

export type CustomFieldInput = {
    name: Scalars['String'];
    label: Scalars['String'];
    customFieldCategoryId: Scalars['CustomFieldsCategoryId'];
    description: Scalars['String'];
    variety: CustomFieldVariety;
    fieldType: FieldType;
    isPrivate?: Maybe<Scalars['Boolean']>;
    values: Array<CustomFieldsValueInput>;
    hasCondition?: Maybe<Scalars['Boolean']>;
    conditionCustomFieldId?: Maybe<Scalars['BigInt']>;
    conditionValue: Scalars['JSON'];
    canSelectMultiple?: Maybe<Scalars['Boolean']>;
    document?: Maybe<DocumentInput>;
    isSelectV2?: Maybe<Scalars['Boolean']>;
};

export enum CustomFieldPrivacy {
    All = 'All',
    Public = 'Public',
    Private = 'Private'
}

export enum CustomFieldProperty {
    Category = 'Category',
    Label = 'Label',
    Name = 'Name',
    Privacy = 'Privacy'
}

export type CustomFieldsCategoriesConnection = {
    nodes: Array<CustomFieldsCategory>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type CustomFieldsCategory = {
    id: Scalars['CustomFieldsCategoryId'];
    organizationId: Scalars['OrganizationId'];
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    numberOfCustomFields: Scalars['Int'];
};

export type CustomFieldsCategoryInput = {
    name: Scalars['String'];
};

export type CustomFieldsConnection = {
    nodes: Array<CustomField>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type CustomFieldsSort = {
    attribute?: Maybe<CustomFieldsSortAttributes>;
    direction?: Maybe<SortDirection>;
};

export enum CustomFieldsSortAttributes {
    Category = 'Category',
    CreatedAt = 'CreatedAt',
    Id = 'Id',
    Label = 'Label',
    Name = 'Name',
    Status = 'Status',
    Type = 'Type'
}

export type CustomFieldsValue = {
    id: Scalars['CustomFieldsValueId'];
    organizationId: Scalars['OrganizationId'];
    customFieldId: Scalars['CustomFieldId'];
    translationId: Scalars['TranslationId'];
    index: Scalars['Int'];
    value: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type CustomFieldsValueInput = {
    id?: Maybe<Scalars['CustomFieldsValueId']>;
    value: Scalars['String'];
};

export enum CustomFieldVariety {
    Accreditation = 'Accreditation',
    Delegation = 'Delegation',
    Event = 'Event',
    Position = 'Position',
    UserInfo = 'UserInfo'
}

export type CustomSlot = {
    id: Scalars['CustomSlotId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId?: Maybe<Scalars['FormId']>;
    customSlotPeriodId?: Maybe<Scalars['CustomSlotsPeriodId']>;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    startTime: Scalars['Time'];
    endTime: Scalars['Time'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type CustomSlotInput = {
    name: Scalars['String'];
    startTime: Scalars['Time'];
    endTime: Scalars['Time'];
};

export type CustomSlotsPeriod = {
    id: Scalars['CustomSlotsPeriodId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
    hideNameDates: Scalars['Boolean'];
    displayAsCalendar: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    customSlots: Array<CustomSlot>;
};

export type CustomSlotsPeriodInput = {
    name: Scalars['String'];
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
    customSlots: Array<CustomSlotInput>;
    hideNameDates: Scalars['Boolean'];
    displayAsCalendar: Scalars['Boolean'];
};

export type Delegation = {
    id: Scalars['DelegationId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    event?: Maybe<Event>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
    delegationCategory: DelegationsCategory;
    parentId?: Maybe<Scalars['DelegationId']>;
    parent?: Maybe<Delegation>;
    createdById: Scalars['UserId'];
    createdBy: UsersInfo;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId?: Maybe<Scalars['TranslationId']>;
    description: Scalars['String'];
    formId: Scalars['FormId'];
    form: Form;
    deadlineDate?: Maybe<Scalars['Date']>;
    leadersIds: Array<Scalars['UsersInfoId']>;
    leaders: Array<UsersInfo>;
    maxResources?: Maybe<Scalars['Int']>;
    privateNoteId: Scalars['TranslationId'];
    privateNote: Scalars['String'];
    columns: Array<Scalars['String']>;
    lockedAt?: Maybe<Scalars['Datetime']>;
    addDefaultAccreditationsByAvailability: Scalars['Boolean'];
    showInDelegationSpace: Scalars['Boolean'];
    showFormLinkInDelegationSpace: Scalars['Boolean'];
    fields: Scalars['JSON'];
    canLeadersAddMembers: Scalars['Boolean'];
    canLeadersEditMembers: Scalars['Boolean'];
    canLeadersEditCustomFields: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    customFields: Array<DelegationsCustomField>;
    fillingRate: Scalars['Int'];
    minAccreditationSlotDate?: Maybe<Scalars['Date']>;
    numberOfDelegationsAccreditationsSlots: Scalars['Int'];
    numberOfResources: Scalars['Int'];
    numberOfMembersAccredited: Scalars['Int'];
    numberOfMembersWaitingAccreditation: Scalars['Int'];
    numberOfMembersRefusedAccreditation: Scalars['Int'];
    state: DelegationState;
    accreditations: Array<DelegationAccreditation>;
    accreditationSlot: DelegationAccreditationSlot;
    accreditationsSlots: Array<DelegationAccreditationSlot>;
    parentUsersInfos: Array<UsersInfo>;
    tags: Array<Tag>;
    tagsIds: Array<Scalars['TagId']>;
    userInfo: UsersInfo;
    usersInfos: Array<UsersInfo>;
};

export type DelegationFieldsArgs = {
    expand?: Maybe<Scalars['Boolean']>;
};

export type DelegationAccreditationSlotArgs = {
    id: Scalars['AccreditationsSlotId'];
};

export type DelegationAccreditationsSlotsArgs = {
    accreditationId?: Maybe<Scalars['AccreditationId']>;
    onlyDefault?: Maybe<Scalars['Boolean']>;
    onlyVisible?: Maybe<Scalars['Boolean']>;
};

export type DelegationParentUsersInfosArgs = {
    name?: Maybe<Scalars['String']>;
};

export type DelegationUserInfoArgs = {
    id: Scalars['UsersInfoId'];
};

export type DelegationAccreditation = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationId: Scalars['AccreditationId'];
    accreditation: Accreditation;
    maxResources?: Maybe<Scalars['Int']>;
    willAutoAccredit: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
};

export type DelegationAccreditationInput = {
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationId: Scalars['AccreditationId'];
    maxResources?: Maybe<Scalars['JSON']>;
    willAutoAccredit: Scalars['Boolean'];
};

export type DelegationAccreditationSlot = {
    accreditationCategory: AccreditationsCategory;
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationId: Scalars['AccreditationId'];
    accreditation: Accreditation;
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    accreditationSlot: AccreditationsSlot;
    delegationId: Scalars['DelegationId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    isDefault: Scalars['Boolean'];
    maxResources?: Maybe<Scalars['Int']>;
    isVisible: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    assignedResources: Scalars['Int'];
    scannedResources: Scalars['Int'];
    volunteersRegistrations: Array<VolunteersRegistration>;
    wishedResources: Scalars['Int'];
};

export type DelegationAccreditationSlotInput = {
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationId: Scalars['AccreditationId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    isDefault: Scalars['Boolean'];
    maxResources?: Maybe<Scalars['JSON']>;
    isVisible: Scalars['Boolean'];
};

export type DelegationInput = {
    delegationCategoryId: Scalars['DelegationsCategoryId'];
    name: Scalars['String'];
    description: Scalars['String'];
    leadersIds: Array<Scalars['UsersInfoId']>;
    formId: Scalars['FormId'];
    deadlineDate?: Maybe<Scalars['Date']>;
    maxResources?: Maybe<Scalars['Int']>;
    columns: Array<Scalars['String']>;
    privateNote: Scalars['String'];
    accreditations: Array<DelegationAccreditationInput>;
    accreditationsSlots: Array<DelegationAccreditationSlotInput>;
    tagsIds: Array<Scalars['TagId']>;
    parentId?: Maybe<Scalars['BigInt']>;
    addDefaultAccreditationsByAvailability: Scalars['Boolean'];
    showInDelegationSpace: Scalars['Boolean'];
    showFormLinkInDelegationSpace: Scalars['Boolean'];
    customFields: Array<DelegationsCustomFieldInput>;
    fields?: Maybe<Scalars['JSON']>;
    canLeadersAddMembers: Scalars['Boolean'];
    canLeadersEditMembers: Scalars['Boolean'];
    canLeadersEditCustomFields: Scalars['Boolean'];
};

export enum DelegationProperty {
    CanLeadersAddMembers = 'CanLeadersAddMembers',
    CanLeadersEditCustomFields = 'CanLeadersEditCustomFields',
    CanLeadersEditMembers = 'CanLeadersEditMembers',
    Category = 'Category',
    DeadlineDate = 'DeadlineDate',
    Form = 'Form',
    IsLocked = 'IsLocked',
    MaxResources = 'MaxResources',
    Name = 'Name',
    ShowFormLinkInDelegationSpace = 'ShowFormLinkInDelegationSpace',
    ShowInDelegationSpace = 'ShowInDelegationSpace',
    Tag = 'Tag'
}

export type DelegationsCategoriesConnection = {
    nodes: Array<DelegationsCategory>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type DelegationsCategory = {
    id: Scalars['DelegationsCategoryId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    delegations: Array<Delegation>;
    numberOfDelegations: Scalars['Int'];
    numberOfResources: Scalars['Int'];
};

export type DelegationsCategoryInput = {
    name: Scalars['String'];
};

export type DelegationsConnection = {
    nodes: Array<Delegation>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type DelegationsCustomField = {
    delegationId: Scalars['DelegationId'];
    customFieldId: Scalars['CustomFieldId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    value: Scalars['JSON'];
    addValues: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
};

export type DelegationsCustomFieldInput = {
    customFieldId: Scalars['CustomFieldId'];
    value: Scalars['JSON'];
    addValues: Scalars['Boolean'];
};

export type DelegationsImportEvent = {
    name: Scalars['String'];
};

export type DelegationsImportInput = {
    csv: CsvDocumentInput;
};

export type DelegationsImportResult = {
    state: DelegationsImportState;
    event?: Maybe<DelegationsImportEvent>;
};

export enum DelegationsImportState {
    Done = 'Done',
    Waiting = 'Waiting'
}

export type DelegationSpaceSettingsInput = {
    allowDelegationSpaceDownload: Scalars['Boolean'];
    delegationsSpaceCustomBadges: Array<Scalars['String']>;
};

export type DelegationsSort = {
    attribute: Scalars['String'];
    direction: SortDirection;
};

export enum DelegationsSortAttributes {
    Category = 'Category',
    CreatedAt = 'CreatedAt',
    Id = 'Id',
    Name = 'Name'
}

export enum DelegationState {
    Expired = 'Expired',
    Full = 'Full',
    Locked = 'Locked',
    Ongoing = 'Ongoing'
}

export type Design = {
    id: Scalars['DesignId'];
    organizationId: Scalars['OrganizationId'];
    logoId?: Maybe<Scalars['DocumentId']>;
    logo?: Maybe<Document>;
    bannerId?: Maybe<Scalars['DocumentId']>;
    banner?: Maybe<Document>;
    colors: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type DesignInput = {
    logo?: Maybe<DocumentInput>;
    banner?: Maybe<DocumentInput>;
    colors?: Maybe<Scalars['JSON']>;
};

export type Dkim = {
    domain: Scalars['String'];
    status: DkimStatus;
    dnsEntries: Array<DnsEntry>;
};

export enum DkimStatus {
    Failed = 'FAILED',
    Pending = 'PENDING',
    Success = 'SUCCESS'
}

export type DnsEntry = {
    name: Scalars['String'];
    type: Scalars['String'];
    value: Scalars['String'];
};

export type Document = {
    id: Scalars['DocumentId'];
    key: Scalars['String'];
    name: Scalars['String'];
    acl: Scalars['String'];
    size?: Maybe<Scalars['Int']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    url: Scalars['String'];
};

export type DocumentInput = {
    acl: Scalars['String'];
    key: Scalars['String'];
    name: Scalars['String'];
    size?: Maybe<Scalars['BigInt']>;
};

export type DocumentsConnection = {
    nodes: Array<Document>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type DocumentsExportInput = {
    badgeType?: Maybe<CustomBadge>;
    customDocumentSlug?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['Date']>;
    documentType: DocumentType;
    language: SupportedLanguage;
    segmentsIds: Array<Scalars['SegmentId']>;
    strategy?: Maybe<DocumentsExportStrategy>;
};

export enum DocumentsExportStrategy {
    OnePerPage = 'OnePerPage',
    GroupedA4 = 'GroupedA4'
}

export enum DocumentType {
    Assignments = 'Assignments',
    Badge = 'Badge',
    Certificate = 'Certificate',
    CustomBadge = 'CustomBadge',
    Planning = 'Planning',
    Position = 'Position',
    PositionPlanning = 'PositionPlanning',
    PositionsVolunteersPlanning = 'PositionsVolunteersPlanning',
    UserCustomDocument = 'UserCustomDocument',
    UserPlanning = 'UserPlanning',
    UserPlanningDays = 'UserPlanningDays',
    UserPlanningList = 'UserPlanningList',
    VolunteersListing = 'VolunteersListing'
}

export type Domain = {
    name: Scalars['String'];
    status: DkimStatus;
    dnsEntries: Array<DnsEntry>;
};

export type Email = {
    id: Scalars['EmailId'];
    emailType: EmailType;
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId?: Maybe<Scalars['FormId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfo: UsersInfo;
    campaignId?: Maybe<Scalars['CampaignId']>;
    messageId?: Maybe<Scalars['MessageId']>;
    infos: Scalars['JSON'];
    state: EmailState;
    sentAt?: Maybe<Scalars['Datetime']>;
    bouncedAt?: Maybe<Scalars['Datetime']>;
    complaintAt?: Maybe<Scalars['Datetime']>;
    deliveredAt?: Maybe<Scalars['Datetime']>;
    rejectedAt?: Maybe<Scalars['Datetime']>;
    openedAt?: Maybe<Scalars['Datetime']>;
    clickedAt?: Maybe<Scalars['Datetime']>;
    queuedAt?: Maybe<Scalars['Datetime']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type EmailsConnection = {
    nodes: Array<Email>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type EmailsSender = {
    id: Scalars['EmailsSenderId'];
    organizationId: Scalars['OrganizationId'];
    name: Scalars['String'];
    headerId?: Maybe<Scalars['DocumentId']>;
    header?: Maybe<Document>;
    pictureId?: Maybe<Scalars['DocumentId']>;
    picture?: Maybe<Document>;
    footerId?: Maybe<Scalars['DocumentId']>;
    footer?: Maybe<Document>;
    signature?: Maybe<Scalars['String']>;
    fromEmail: Scalars['String'];
    fromName?: Maybe<Scalars['String']>;
    domain: Scalars['String'];
    replyTo?: Maybe<Scalars['String']>;
    domainStatus: DkimStatus;
    emailStatus: EmailStatus;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    eventsIds: Array<Scalars['EventId']>;
    events: Array<Event>;
    from: Scalars['String'];
    isOrganizationDefault: Scalars['Boolean'];
    isValidToSendEmail: Scalars['Boolean'];
    preview: Scalars['String'];
};

export type EmailsSenderInput = {
    header?: Maybe<DocumentInput>;
    picture?: Maybe<DocumentInput>;
    footer?: Maybe<DocumentInput>;
    signature?: Maybe<Scalars['String']>;
    fromEmail: Scalars['String'];
    fromName?: Maybe<Scalars['String']>;
    replyTo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    eventsIds: Array<Scalars['EventId']>;
    isOrganizationDefault: Scalars['Boolean'];
};

export type EmailsSenderSkeleton = {
    organizationId: Scalars['OrganizationId'];
    fromName?: Maybe<Scalars['String']>;
    fromEmail: Scalars['String'];
    signature?: Maybe<Scalars['String']>;
    headerId?: Maybe<Scalars['DocumentId']>;
    pictureId?: Maybe<Scalars['DocumentId']>;
};

export type EmailsSenderUnion = EmailsSender | EmailsSenderSkeleton;

export type EmailsStats = {
    numberOfClick: Scalars['Int'];
    numberOfOpen: Scalars['Int'];
    numberOfSent: Scalars['Int'];
    numberOfWaiting: Scalars['Int'];
};

export enum EmailState {
    Bounced = 'Bounced',
    Delivered = 'Delivered',
    Opened = 'Opened',
    Queued = 'Queued',
    Sent = 'Sent',
    Waiting = 'Waiting'
}

export enum EmailStatus {
    DoesNotExist = 'DoesNotExist',
    NotVerified = 'NotVerified',
    Verified = 'Verified'
}

export type EmailsTemplate = {
    id: Scalars['EmailsTemplateId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId?: Maybe<Scalars['FormId']>;
    useDefault: Scalars['Boolean'];
    emailType: EmailType;
    subjectId: Scalars['TranslationId'];
    subject: Scalars['String'];
    contentId: Scalars['TranslationId'];
    content: Scalars['String'];
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    emailSender?: Maybe<EmailsSender>;
    source?: Maybe<LoginCodeSource>;
    attachments: Array<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type EmailsTemplateInput = {
    useDefault: Scalars['Boolean'];
    emailType: EmailType;
    subject: Scalars['String'];
    content: Scalars['String'];
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    attachments: Array<Scalars['String']>;
};

export enum EmailType {
    Accreditations = 'Accreditations',
    AccreditationsRefused = 'AccreditationsRefused',
    Assignments = 'Assignments',
    Campaign = 'Campaign',
    Code = 'Code',
    DelegationLeaderAssign = 'DelegationLeaderAssign',
    DelegationsImport = 'DelegationsImport',
    Documents = 'Documents',
    EventInvite = 'EventInvite',
    Export = 'Export',
    FilesImport = 'FilesImport',
    FormInvite = 'FormInvite',
    FormSubmit = 'FormSubmit',
    MembersImport = 'MembersImport',
    NewMember = 'NewMember',
    NewVolunteer = 'NewVolunteer',
    NotifyLeaders = 'NotifyLeaders',
    OrganizationInvite = 'OrganizationInvite',
    PositionCategoryLeaderAssign = 'PositionCategoryLeaderAssign',
    PositionLeaderAssign = 'PositionLeaderAssign',
    Refused = 'Refused',
    VolunteerRegistration = 'VolunteerRegistration',
    PositionsImport = 'PositionsImport'
}

export type Event = IEvent & {
    id: Scalars['EventId'];
    organizationId: Scalars['OrganizationId'];
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    state: EventState;
    startAt: Scalars['Date'];
    endAt: Scalars['Date'];
    languages: Array<SupportedLanguage>;
    positionDisplay: RegisterPositionDisplay;
    slotDisplay: RegisterSlotDisplay;
    positionFilter: RegisterPositionFilter;
    daysDisplay: RegisterDaysDisplay;
    registerSuccessMessageId: Scalars['TranslationId'];
    registerSuccessMessage: Scalars['String'];
    backgroundId?: Maybe<Scalars['DocumentId']>;
    background?: Maybe<Document>;
    parameters: Scalars['JSON'];
    volunteerSpaceLink?: Maybe<Scalars['String']>;
    timeZone: Scalars['Timezone'];
    country: Scalars['String'];
    key: Scalars['String'];
    layer: MapLayer;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    formState: FormState;
    isFormOpen: Scalars['Boolean'];
    formClosedMessageId?: Maybe<Scalars['TranslationId']>;
    formClosedMessage: Scalars['String'];
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    emailSender?: Maybe<EmailsSenderUnion>;
    dayStartTime: Scalars['Time'];
    profilePrivateFields: Array<Scalars['CustomFieldSlug']>;
    weezevent: Scalars['JSON'];
    fields: Scalars['JSON'];
    deletedAt?: Maybe<Scalars['Datetime']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    accreditation: Accreditation;
    accreditations: AccreditationsConnection;
    accreditationSlot: AccreditationsSlot;
    accreditationsSlots: AccreditationsSlotsConnection;
    accreditationsCategories: Array<AccreditationsCategory>;
    accreditationCategory: AccreditationsCategory;
    adminsLeaders: EventAdminsLeadersConnection;
    assignedResources: Scalars['Int'];
    accreditationsStats: AccreditationsStats;
    assignmentsStats: AssignmentsStats;
    campaign: Campaign;
    campaigns: CampaignsConnection;
    campaignsCost: Scalars['Float'];
    collaborators: Array<User>;
    customDocument?: Maybe<CustomDocument>;
    customDocuments: Array<CustomDocument>;
    customSlots: Array<CustomSlot>;
    customSlotsPeriods: Array<CustomSlotsPeriod>;
    delegation: Delegation;
    delegations: DelegationsConnection;
    document: EventsDocument;
    emails: EmailsConnection;
    emailsStats: EmailsStats;
    forms: Array<Form>;
    formsCustomsFields: Array<CustomField>;
    hasDelegations: Scalars['Boolean'];
    hasForms: Scalars['Boolean'];
    hasFormWithAccreditationDisplay: Scalars['Boolean'];
    hasFormWithPositionDisplay: Scalars['Boolean'];
    hasFormWithSlotDisplay: Scalars['Boolean'];
    hasFormWithTeamsAllowed: Scalars['Boolean'];
    isWeezeventConnected: Scalars['Boolean'];
    leaders: Array<Leader>;
    numberOfAccreditations: Scalars['Int'];
    numberOfDelegations: Scalars['Int'];
    numberOfPositions: Scalars['Int'];
    onboarding: EventOnboarding;
    position: Position;
    positionCategory: PositionsCategory;
    positionSlot: PositionsSlot;
    positions: PositionsConnection;
    positionsCategories: Array<PositionsCategory>;
    positionsSlots: PositionsSlotsConnection;
    recentPositionsRegistrationsSearches: Array<Scalars['String']>;
    recentVolunteersRegistrationsSearches: Array<Scalars['String']>;
    range?: Maybe<Scalars['DatetimeRange']>;
    ranges: Array<Scalars['DatetimeRange']>;
    resources: Scalars['Int'];
    segment: Segment;
    segments: Array<Segment>;
    segmentsFolders: Array<SegmentsFolder>;
    tags: Array<Tag>;
    tagsIds: Array<Scalars['TagId']>;
    team?: Maybe<Team>;
    teams: TeamsConnection;
    traces: Array<Trace>;
    users: Array<User>;
    volunteerMinutesAssigned: Scalars['BigInt'];
    volunteerMinutesNeeded: Scalars['BigInt'];
    volunteerRegistration: VolunteersRegistration;
    volunteerRegistrationMaybe?: Maybe<VolunteersRegistration>;
    volunteerRegistrationByUserInfoId: VolunteersRegistration;
    volunteersRegistrations: VolunteersRegistrationsConnection;
    weezeventConnectionInfos?: Maybe<WeezeventConnectionInfo>;
    weezeventRule: WeezeventRule;
    weezeventRules: WeezeventRulesConnection;
};

export type EventFieldsArgs = {
    expand?: Maybe<Scalars['Boolean']>;
};

export type EventAccreditationArgs = {
    id: Scalars['AccreditationId'];
};

export type EventAccreditationsArgs = {
    name?: Maybe<Scalars['String']>;
    accreditationCategoryId?: Maybe<Scalars['AccreditationsCategoryId']>;
    predicates?: Maybe<Scalars['JSON']>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<AccreditationsSort>;
};

export type EventAccreditationSlotArgs = {
    id: Scalars['AccreditationsSlotId'];
};

export type EventAccreditationsSlotsArgs = {
    name?: Maybe<Scalars['String']>;
    accreditationsIds?: Maybe<Array<Scalars['AccreditationId']>>;
    accreditationName?: Maybe<Scalars['String']>;
    accreditationsCategoriesIds?: Maybe<Array<Scalars['AccreditationsCategoryId']>>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    date?: Maybe<Scalars['Date']>;
    tagsIds?: Maybe<Array<Scalars['TagId']>>;
    predicates?: Maybe<Scalars['JSON']>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    includeHidden?: Maybe<Scalars['Boolean']>;
    excludeFull?: Maybe<Scalars['Boolean']>;
    excludeEmpty?: Maybe<Scalars['Boolean']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<AccreditationsSlotsSort>;
};

export type EventAccreditationCategoryArgs = {
    id: Scalars['AccreditationsCategoryId'];
};

export type EventAdminsLeadersArgs = {
    name?: Maybe<Scalars['String']>;
    onlyAdmin?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type EventCampaignArgs = {
    id: Scalars['CampaignId'];
};

export type EventCampaignsArgs = {
    name?: Maybe<Scalars['String']>;
    state?: Maybe<CampaignState>;
    campaignType?: Maybe<CampaignType>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CampaignsSort>;
};

export type EventCollaboratorsArgs = {
    name?: Maybe<Scalars['String']>;
};

export type EventCustomDocumentArgs = {
    customDocumentId: Scalars['CustomDocumentId'];
};

export type EventCustomDocumentsArgs = {
    slug?: Maybe<Scalars['String']>;
};

export type EventDelegationArgs = {
    id: Scalars['DelegationId'];
};

export type EventDelegationsArgs = {
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    delegationsCategoriesIds?: Maybe<Array<Scalars['DelegationsCategoryId']>>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    onlyVisibleInDelegation?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<DelegationsSort>;
};

export type EventDocumentArgs = {
    documentType: DocumentType;
    customBadge?: Maybe<CustomBadge>;
};

export type EventEmailsArgs = {
    emailTypes: Array<EmailType>;
    name?: Maybe<Scalars['String']>;
    state?: Maybe<EmailState>;
    userInfoId?: Maybe<Scalars['UsersInfoId']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type EventEmailsStatsArgs = {
    emailTypes: Array<EmailType>;
};

export type EventPositionArgs = {
    id: Scalars['PositionId'];
};

export type EventPositionCategoryArgs = {
    id: Scalars['PositionsCategoryId'];
};

export type EventPositionSlotArgs = {
    id: Scalars['PositionsSlotId'];
};

export type EventPositionsArgs = {
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    positionCategoriesIds?: Maybe<Array<Scalars['PositionsCategoryId']>>;
    date?: Maybe<Scalars['Date']>;
    tagsIds?: Maybe<Array<Scalars['TagId']>>;
    hasCoordinates?: Maybe<Scalars['Boolean']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<PositionsSort>;
};

export type EventPositionsCategoriesArgs = {
    scoped?: Maybe<Scalars['Boolean']>;
};

export type EventPositionsSlotsArgs = {
    name?: Maybe<Scalars['String']>;
    positionsIds?: Maybe<Array<Scalars['PositionId']>>;
    positionName?: Maybe<Scalars['String']>;
    positionsCategoriesIds?: Maybe<Array<Scalars['PositionsCategoryId']>>;
    date?: Maybe<Scalars['Date']>;
    tagsIds?: Maybe<Array<Scalars['TagId']>>;
    predicates?: Maybe<Scalars['JSON']>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    excludeFull?: Maybe<Scalars['Boolean']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    selecteds?: Maybe<MassActionSelectedsInput>;
    filterAvailabilities?: Maybe<Scalars['Boolean']>;
    filterWished?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<PositionsSlotsSort>;
};

export type EventRangesArgs = {
    formId?: Maybe<Scalars['FormId']>;
};

export type EventSegmentArgs = {
    id?: Maybe<Scalars['SegmentId']>;
    segmentType?: Maybe<SegmentType>;
};

export type EventSegmentsArgs = {
    segmentType: SegmentType;
};

export type EventSegmentsFoldersArgs = {
    segmentType: SegmentType;
};

export type EventTeamArgs = {
    teamCode?: Maybe<Scalars['TeamCode']>;
};

export type EventTeamsArgs = {
    state?: Maybe<VolunteerRegistrationState>;
    accreditationState?: Maybe<AccreditationState>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type EventVolunteerRegistrationArgs = {
    id: Scalars['VolunteersRegistrationId'];
};

export type EventVolunteerRegistrationMaybeArgs = {
    id: Scalars['VolunteersRegistrationId'];
};

export type EventVolunteerRegistrationByUserInfoIdArgs = {
    userInfoId: Scalars['UsersInfoId'];
};

export type EventVolunteersRegistrationsArgs = {
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    states?: Maybe<Array<VolunteerRegistrationState>>;
    assignmentState?: Maybe<VolunteerRegistrationState>;
    accreditationStates?: Maybe<Array<AccreditationState>>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    positionCategoryId?: Maybe<Scalars['PositionsCategoryId']>;
    positionId?: Maybe<Scalars['PositionId']>;
    positionSlotId?: Maybe<Scalars['PositionsSlotId']>;
    preassignedPositionCategoryId?: Maybe<Scalars['PositionsCategoryId']>;
    preassignedPositionId?: Maybe<Scalars['PositionId']>;
    accreditationCategoryId?: Maybe<Scalars['AccreditationsCategoryId']>;
    accreditationId?: Maybe<Scalars['AccreditationId']>;
    accreditationSlotId?: Maybe<Scalars['AccreditationsSlotId']>;
    userInfoIds?: Maybe<Array<Scalars['UsersInfoId']>>;
    date?: Maybe<Scalars['Date']>;
    wishedDate?: Maybe<Scalars['Date']>;
    delegationCategoryId?: Maybe<Scalars['DelegationsCategoryId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    excludeDelegationMembers?: Maybe<Scalars['Boolean']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<VolunteersRegistrationsSort>;
};

export type EventWeezeventRuleArgs = {
    id: Scalars['WeezeventRuleId'];
};

export type EventWeezeventRulesArgs = {
    name?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type EventAdminLeader = {
    id: Scalars['String'];
    userId: Scalars['UserId'];
    user: User;
    userInfoId?: Maybe<Scalars['UsersInfoId']>;
    userInfo: UsersInfo;
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionCategoryId?: Maybe<Scalars['PositionsCategoryId']>;
    positionCategory?: Maybe<PositionsCategory>;
    positionId?: Maybe<Scalars['PositionId']>;
    position?: Maybe<Position>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    delegation?: Maybe<Delegation>;
    canEdit?: Maybe<Scalars['Boolean']>;
    roleId?: Maybe<Scalars['RoleId']>;
    isAdmin: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
};

export type EventAdminsLeadersConnection = {
    nodes: Array<EventAdminLeader>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type EventDesignInput = {
    background?: Maybe<DocumentInput>;
    parameters?: Maybe<Scalars['JSON']>;
};

export type EventDuplicateInput = {
    endAt: Scalars['Date'];
    eventId?: Maybe<Scalars['EventId']>;
    includeAccreditations: Scalars['Boolean'];
    includeCustomDocuments: Scalars['Boolean'];
    includeDelegations: Scalars['Boolean'];
    includeForms: Scalars['Boolean'];
    includePositions: Scalars['Boolean'];
    includeSegments: Scalars['Boolean'];
    name: Scalars['String'];
    startAt: Scalars['Date'];
};

export type EventInput = {
    name: Scalars['String'];
    description: Scalars['String'];
    startAt: Scalars['Date'];
    endAt: Scalars['Date'];
    languages: Array<SupportedLanguage>;
    timeZone: Scalars['Timezone'];
    country?: Maybe<Scalars['String']>;
    positionFilter?: Maybe<RegisterPositionFilter>;
    daysDisplay?: Maybe<RegisterDaysDisplay>;
    layer?: Maybe<MapLayer>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    dayStartTime: Scalars['Time'];
    tagsIds: Array<Scalars['TagId']>;
    fields: Scalars['JSON'];
};

export type EventLinksInput = {
    volunteerSpaceLink?: Maybe<Scalars['String']>;
};

export type EventOnboarding = {
    isFirstCampaignCreated: Scalars['Boolean'];
    isFirstFormCreated: Scalars['Boolean'];
    isFirstPositionCreated: Scalars['Boolean'];
};

export type EventOrganizationInput = {
    event: EventInput;
    organization: OrganizationInput;
};

export type EventParametersInput = {
    positionDisplay: RegisterPositionDisplay;
    slotDisplay: RegisterSlotDisplay;
    positionFilter: RegisterPositionFilter;
    daysDisplay: RegisterDaysDisplay;
    description?: Maybe<Scalars['String']>;
    registerSuccessMessage?: Maybe<Scalars['String']>;
    customSlots: Array<CustomSlotInput>;
    formState: FormState;
    formClosedMessage?: Maybe<Scalars['String']>;
};

export enum EventProperty {
    EndAt = 'EndAt',
    Name = 'Name',
    StartAt = 'StartAt',
    Tag = 'Tag'
}

export type EventPublic = IEvent & {
    id: Scalars['EventId'];
    organizationId: Scalars['OrganizationId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    startAt: Scalars['Date'];
    endAt: Scalars['Date'];
    languages: Array<SupportedLanguage>;
    positionDisplay: RegisterPositionDisplay;
    slotDisplay: RegisterSlotDisplay;
    positionFilter: RegisterPositionFilter;
    daysDisplay: RegisterDaysDisplay;
    registerSuccessMessageId: Scalars['TranslationId'];
    registerSuccessMessage: Scalars['String'];
    backgroundId?: Maybe<Scalars['DocumentId']>;
    background?: Maybe<Document>;
    parameters: Scalars['JSON'];
    country: Scalars['String'];
    accreditationsCategories: Array<AccreditationsCategoryPublic>;
    accreditation: AccreditationPublic;
    customSlots: Array<CustomSlot>;
    position: PositionPublic;
    positionsCategories: Array<PositionsCategoryPublic>;
    range?: Maybe<Scalars['DatetimeRange']>;
    ranges: Array<Scalars['DatetimeRange']>;
    team?: Maybe<Team>;
    traces: Array<Trace>;
    formState: FormState;
    isFormOpen: Scalars['Boolean'];
    formClosedMessageId?: Maybe<Scalars['TranslationId']>;
    formClosedMessage: Scalars['String'];
};

export type EventPublicAccreditationArgs = {
    id: Scalars['AccreditationId'];
};

export type EventPublicPositionArgs = {
    id: Scalars['PositionId'];
};

export type EventPublicRangesArgs = {
    formId?: Maybe<Scalars['FormId']>;
};

export type EventPublicTeamArgs = {
    teamCode?: Maybe<Scalars['TeamCode']>;
};

export type EventsConnection = {
    nodes: Array<Event>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type EventsDocument = {
    id: Scalars['EventsDocumentId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    documentType: DocumentType;
    customBadge?: Maybe<CustomBadge>;
    options: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type EventsSort = {
    attribute: EventsSortAttributes;
    direction: SortDirection;
};

export enum EventsSortAttributes {
    EndAt = 'EndAt',
    Id = 'Id',
    Name = 'Name',
    StartAt = 'StartAt'
}

export enum EventState {
    Archived = 'Archived',
    Done = 'Done',
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming'
}

export type EventWeezeventInput = {
    customFieldsSlugs: Array<Scalars['CustomFieldId']>;
    segmentsIds: Array<Scalars['SegmentId']>;
};

export enum ExportFormat {
    Excel = 'Excel',
    Csv = 'CSV'
}

export type ExportResult = {
    state: ExportState;
    url: Scalars['String'];
};

export enum ExportState {
    Done = 'Done',
    Waiting = 'Waiting'
}

export type FailedJob = {
    queueName: Scalars['String'];
    jobName: Scalars['String'];
    errorMessage: Scalars['String'];
    env: Scalars['String'];
    insertedAt: Scalars['Datetime'];
};

export enum Feature {
    Accreditation = 'Accreditation',
    Api = 'Api',
    Community = 'Community',
    CustomDocument = 'CustomDocument',
    Delegation = 'Delegation',
    Documents = 'Documents',
    Eid = 'Eid',
    KmlImport = 'KmlImport',
    Languages = 'Languages',
    Multiform = 'Multiform',
    Skidata = 'Skidata',
    Sms = 'Sms',
    Weezevent = 'Weezevent',
    Zapier = 'Zapier'
}

export enum FieldProperty {
    City = 'city',
    Country = 'country',
    Custom = 'custom',
    DateOfBirth = 'dateOfBirth',
    Eid = 'eid',
    FirstName = 'firstName',
    Language = 'language',
    LastName = 'lastName',
    Nationality = 'nationality',
    Phone = 'phone',
    Picture = 'picture',
    Population = 'population',
    PostalCode = 'postalCode',
    Sex = 'sex',
    Street = 'street'
}

export enum FieldType {
    Address = 'Address',
    Checkbox = 'Checkbox',
    Country = 'Country',
    Date = 'Date',
    Datetime = 'Datetime',
    File = 'File',
    Language = 'Language',
    Nationality = 'Nationality',
    Number = 'Number',
    Phone = 'Phone',
    Select = 'Select',
    Sex = 'Sex',
    Text = 'Text',
    Textarea = 'Textarea',
    Time = 'Time',
    Validation = 'Validation'
}

export type FileInput = {
    name: Scalars['String'];
    content: Scalars['String'];
};

export type FilesImportEvent = {
    name: Scalars['String'];
};

export type FilesImportInput = {
    archive: DocumentInput;
    fieldId: Scalars['CustomFieldId'];
};

export type FilesImportResult = {
    state: FilesImportState;
    event?: Maybe<FilesImportEvent>;
};

export enum FilesImportState {
    Done = 'Done',
    Waiting = 'Waiting'
}

export enum FilterType {
    Campaign = 'Campaign',
    Checkbox = 'Checkbox',
    Country = 'Country',
    Date = 'Date',
    DateTimeRange = 'DateTimeRange',
    Datetime = 'Datetime',
    File = 'File',
    Language = 'Language',
    Nationality = 'Nationality',
    Number = 'Number',
    Phone = 'Phone',
    Select = 'Select',
    Sex = 'Sex',
    Text = 'Text',
    Textarea = 'Textarea',
    Time = 'Time',
    Validation = 'Validation'
}

export type Form = {
    id: Scalars['FormId'];
    organizationId: Scalars['OrganizationId'];
    organization: Organization;
    eventId?: Maybe<Scalars['EventId']>;
    event?: Maybe<Event>;
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    delegationsIds: Array<Scalars['DelegationId']>;
    name: Scalars['String'];
    allowMultiProfiles: Scalars['Boolean'];
    setAssignmentStateToWaiting: Scalars['Boolean'];
    setAccreditationStateToWaiting: Scalars['Boolean'];
    state: FormState;
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    successMessageId: Scalars['TranslationId'];
    successMessage: Scalars['String'];
    closedTitleId?: Maybe<Scalars['TranslationId']>;
    closedTitle: Scalars['String'];
    closedMessageId: Scalars['TranslationId'];
    closedMessage: Scalars['String'];
    noAccessTitleId?: Maybe<Scalars['TranslationId']>;
    noAccessTitle: Scalars['String'];
    noAccessMessageId: Scalars['TranslationId'];
    noAccessMessage: Scalars['String'];
    slotDisplay: RegisterSlotDisplay;
    daysDisplay: RegisterDaysDisplay;
    hiddenPositionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    hiddenPositionsIds: Array<Scalars['PositionId']>;
    hiddenPositionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    displayedPositionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    displayedPositionsIds: Array<Scalars['PositionId']>;
    displayedPositionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    positionDisplay: RegisterPositionDisplay;
    positionFilter: RegisterPositionFilter;
    positionRanking: FormPositionRanking;
    positionLimit: Scalars['Int'];
    showFullPosition: Scalars['Boolean'];
    showPositionCategoryDescription: Scalars['Boolean'];
    showPositionDescription: Scalars['Boolean'];
    positionTitleId?: Maybe<Scalars['TranslationId']>;
    positionTitle: Scalars['String'];
    positionSubtitleId?: Maybe<Scalars['TranslationId']>;
    positionSubtitle: Scalars['String'];
    slotTitleId?: Maybe<Scalars['TranslationId']>;
    slotTitle: Scalars['String'];
    slotSubtitleId?: Maybe<Scalars['TranslationId']>;
    slotSubtitle: Scalars['String'];
    accreditationDisplay: RegisterAccreditationDisplay;
    accreditationFilter: RegisterPositionFilter;
    hiddenAccreditationsCategoriesIds: Array<Scalars['AccreditationsCategoryId']>;
    hiddenAccreditationsIds: Array<Scalars['AccreditationId']>;
    hiddenAccreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    displayedAccreditationsCategoriesIds: Array<Scalars['AccreditationsCategoryId']>;
    displayedAccreditationsIds: Array<Scalars['AccreditationId']>;
    displayedAccreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    showFullAccreditation: Scalars['Boolean'];
    showAccreditationDescription: Scalars['Boolean'];
    accreditationTitleId?: Maybe<Scalars['TranslationId']>;
    accreditationTitle: Scalars['String'];
    accreditationSubtitleId?: Maybe<Scalars['TranslationId']>;
    accreditationSubtitle: Scalars['String'];
    designId?: Maybe<Scalars['DesignId']>;
    design?: Maybe<Design>;
    areTeamsAllowed: Scalars['Boolean'];
    maxTeamMembers?: Maybe<Scalars['Int']>;
    teamMemberFillFullForm: Scalars['Boolean'];
    registerButtonTextId?: Maybe<Scalars['TranslationId']>;
    registerButtonText: Scalars['String'];
    willAutoAssign: Scalars['Boolean'];
    informationTitleId?: Maybe<Scalars['TranslationId']>;
    informationTitle: Scalars['String'];
    emailTitleId?: Maybe<Scalars['TranslationId']>;
    emailTitle: Scalars['String'];
    closedAt?: Maybe<Scalars['Datetime']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    deletedAt?: Maybe<Scalars['Datetime']>;
    customFields: Array<FormsCustomField>;
    customSlotsPeriods: Array<CustomSlotsPeriod>;
    elements: Array<FormsElement>;
    isOpen: Scalars['Boolean'];
    numberOfCustomFields: Scalars['Int'];
    segments: Array<FormsSegment>;
    isEditableInMemberSpace: Scalars['Boolean'];
};

export type Formats = {
    timeFormat?: Maybe<Scalars['String']>;
    dateFormat?: Maybe<Scalars['String']>;
    datetimeFormat?: Maybe<Scalars['String']>;
};

export enum FormElementType {
    Field = 'Field',
    Section = 'Section',
    Text = 'Text'
}

export type FormInput = {
    name: Scalars['String'];
};

export type FormParametersAccreditationsInput = {
    accreditationDisplay: RegisterAccreditationDisplay;
    accreditationFilter: RegisterPositionFilter;
    hiddenAccreditationsCategoriesIds: Array<Scalars['AccreditationsCategoryId']>;
    hiddenAccreditationsIds: Array<Scalars['AccreditationId']>;
    hiddenAccreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    displayedAccreditationsCategoriesIds: Array<Scalars['AccreditationsCategoryId']>;
    displayedAccreditationsIds: Array<Scalars['AccreditationId']>;
    displayedAccreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    showFullAccreditation?: Maybe<Scalars['Boolean']>;
    showAccreditationDescription?: Maybe<Scalars['Boolean']>;
    accreditationTitle?: Maybe<Scalars['String']>;
    accreditationSubtitle?: Maybe<Scalars['String']>;
};

export type FormParametersAvailabilitiesInput = {
    slotDisplay: RegisterSlotDisplay;
    daysDisplay: RegisterDaysDisplay;
    customSlotsPeriods: Array<CustomSlotsPeriodInput>;
    slotTitle?: Maybe<Scalars['String']>;
    slotSubtitle?: Maybe<Scalars['String']>;
};

export type FormParametersBroadcastInput = {
    segmentsIds: Array<Scalars['SegmentId']>;
    noAccessMessage: Scalars['String'];
    noAccessTitle: Scalars['String'];
};

export type FormParametersGeneralInput = {
    name: Scalars['String'];
    allowMultiProfiles: Scalars['Boolean'];
    setAssignmentStateToWaiting: Scalars['Boolean'];
    setAccreditationStateToWaiting: Scalars['Boolean'];
    description: Scalars['String'];
    successMessage: Scalars['String'];
    customFields: Array<FormsCustomFieldInput>;
    areTeamsAllowed: Scalars['Boolean'];
    maxTeamMembers?: Maybe<Scalars['Int']>;
    teamMemberFillFullForm: Scalars['Boolean'];
    registerButtonText: Scalars['String'];
    emailTitle: Scalars['String'];
    informationTitle: Scalars['String'];
    isEditableInMemberSpace: Scalars['Boolean'];
};

export type FormParametersMissionsInput = {
    positionDisplay: RegisterPositionDisplay;
    positionFilter: RegisterPositionFilter;
    positionRanking: FormPositionRanking;
    positionLimit: Scalars['Int'];
    hiddenPositionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    hiddenPositionsIds: Array<Scalars['PositionId']>;
    hiddenPositionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    displayedPositionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    displayedPositionsIds: Array<Scalars['PositionId']>;
    displayedPositionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    showFullPosition: Scalars['Boolean'];
    showPositionCategoryDescription: Scalars['Boolean'];
    showPositionDescription: Scalars['Boolean'];
    positionTitle?: Maybe<Scalars['String']>;
    positionSubtitle?: Maybe<Scalars['String']>;
    willAutoAssign: Scalars['Boolean'];
};

export type FormParametersStateInput = {
    state: FormState;
    closedTitle: Scalars['String'];
    closedMessage: Scalars['String'];
    closedAt?: Maybe<Scalars['Datetime']>;
};

export enum FormPositionRanking {
    Ranked = 'Ranked',
    Unranked = 'Unranked'
}

export type FormRegisterInput = {
    forOther: Scalars['Boolean'];
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    positionsIds: Array<Scalars['PositionId']>;
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    accreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    userInfo: UsersInfoInput;
    sendNotificationEmail: Scalars['Boolean'];
    slots: Array<VolunteersRegistrationsSlotInput>;
    teamCode?: Maybe<Scalars['TeamCode']>;
};

export type FormRegisterResult = {
    teamCode?: Maybe<Scalars['TeamCode']>;
    volunteerRegistration?: Maybe<VolunteersRegistration>;
};

export type FormsCustomField = {
    formId: Scalars['FormId'];
    customFieldId: Scalars['CustomFieldId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    value: Scalars['JSON'];
    addValues: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
};

export type FormsCustomFieldInput = {
    customFieldId: Scalars['CustomFieldId'];
    value: Scalars['JSON'];
    addValues: Scalars['Boolean'];
};

export type FormsElement = {
    id: Scalars['FormsElementId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    elementType: FormElementType;
    index: Scalars['Int'];
    customFieldId?: Maybe<Scalars['CustomFieldId']>;
    customField?: Maybe<CustomField>;
    sectionId?: Maybe<Scalars['TranslationId']>;
    section?: Maybe<Scalars['String']>;
    textId?: Maybe<Scalars['TranslationId']>;
    text?: Maybe<Scalars['String']>;
    isMandatory?: Maybe<Scalars['Boolean']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type FormsElementInput = {
    id: Scalars['FormsElementId'];
    elementType: FormElementType;
    index: Scalars['Int'];
    customFieldId?: Maybe<Scalars['CustomFieldId']>;
    section?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    isMandatory?: Maybe<Scalars['Boolean']>;
};

export type FormsSegment = {
    formId: Scalars['FormId'];
    segmentId: Scalars['SegmentId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    insertedAt: Scalars['Datetime'];
};

export enum FormState {
    CloseWhenEventDone = 'CloseWhenEventDone',
    Closed = 'Closed',
    ClosedAt = 'ClosedAt',
    Open = 'Open'
}

export type FormsUsersInfo = {
    id: Scalars['FormsUsersInfoId'];
    formId: Scalars['FormId'];
    form: Form;
    userInfoId: Scalars['UsersInfoId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    event?: Maybe<Event>;
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    positionsCategories: Array<PositionsCategory>;
    positionsIds: Array<Scalars['PositionId']>;
    positions: Array<Position>;
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    positionsSlots: Array<PositionsSlot>;
    accreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    accreditationsSlots: Array<AccreditationsSlot>;
    teamCode?: Maybe<Scalars['TeamCode']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    slots: Array<FormsUsersInfosSlot>;
    teamMembers: Array<UsersInfo>;
};

export type FormsUsersInfosSlot = ISlot & {
    id: Scalars['FormsUsersInfosSlotId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    formUserInfoId: Scalars['FormsUsersInfoId'];
    formId: Scalars['FormId'];
    userInfoId: Scalars['UsersInfoId'];
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
    range: Scalars['DatetimeRange'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type FormsUsersInfosSlotInput = {
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
};

export type HeaventTranslation = {
    key: Scalars['String'];
    fr: Scalars['String'];
    en: Scalars['String'];
    es: Scalars['String'];
    it: Scalars['String'];
    nl: Scalars['String'];
    de: Scalars['String'];
};

export type IAccreditation = {
    id: Scalars['AccreditationId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationCategory: AccreditationsCategory;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom: Scalars['Int'];
    layer: MapLayer;
    accreditationDisplay: AccreditationDisplay;
    accreditationsCustomFields: Array<AccreditationsCustomField>;
    hasSlots: Scalars['Boolean'];
    hiddenSlotId: Scalars['AccreditationsSlotId'];
    hiddenSlot: AccreditationsSlot;
    slots: Array<IAccreditationsSlot>;
    conditionsOperator: ConditionsOperator;
};

export type IAccreditationsCategory = {
    id: Scalars['AccreditationsCategoryId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    accreditations: Array<IAccreditation>;
};

export type IAccreditationsSlot = {
    id: Scalars['AccreditationsSlotId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    date?: Maybe<Scalars['Date']>;
    maxResources?: Maybe<Scalars['Int']>;
    isFull: Scalars['Boolean'];
};

export enum Icon {
    Backpack = 'Backpack',
    BadgeCheck = 'BadgeCheck',
    BagShopping = 'BagShopping',
    Bed = 'Bed',
    BriefcaseMedical = 'BriefcaseMedical',
    BurgerSoda = 'BurgerSoda',
    CakeCandles = 'CakeCandles',
    Camera = 'Camera',
    Car = 'Car',
    CircleParking = 'CircleParking',
    CircleQuestion = 'CircleQuestion',
    Droplet = 'Droplet',
    ForkKnife = 'ForkKnife',
    HatCowboy = 'HatCowboy',
    House = 'House',
    LocationDot = 'LocationDot',
    MartiniGlass = 'MartiniGlass',
    Megaphone = 'Megaphone',
    PersonBiking = 'PersonBiking',
    PersonHiking = 'PersonHiking',
    ScrewdriverWrench = 'ScrewdriverWrench',
    Shirt = 'Shirt',
    SignsPost = 'SignsPost',
    Star = 'Star',
    Ticket = 'Ticket',
    UserPolice = 'UserPolice'
}

export type IEvent = {
    id: Scalars['EventId'];
    organizationId: Scalars['OrganizationId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    startAt: Scalars['Date'];
    endAt: Scalars['Date'];
    languages: Array<SupportedLanguage>;
    positionDisplay: RegisterPositionDisplay;
    slotDisplay: RegisterSlotDisplay;
    positionFilter: RegisterPositionFilter;
    daysDisplay: RegisterDaysDisplay;
    registerSuccessMessageId: Scalars['TranslationId'];
    registerSuccessMessage: Scalars['String'];
    backgroundId?: Maybe<Scalars['DocumentId']>;
    background?: Maybe<Document>;
    parameters: Scalars['JSON'];
    country: Scalars['String'];
    customSlots: Array<CustomSlot>;
    positionsCategories: Array<IPositionsCategory>;
    range?: Maybe<Scalars['DatetimeRange']>;
    ranges: Array<Scalars['DatetimeRange']>;
    team?: Maybe<Team>;
    traces: Array<Trace>;
    formState: FormState;
    isFormOpen: Scalars['Boolean'];
    formClosedMessageId?: Maybe<Scalars['TranslationId']>;
    formClosedMessage: Scalars['String'];
};

export type IEventRangesArgs = {
    formId?: Maybe<Scalars['FormId']>;
};

export type IEventTeamArgs = {
    teamCode?: Maybe<Scalars['TeamCode']>;
};

export type Invoice = {
    id: Scalars['InvoiceId'];
    invoiceNumber: Scalars['BigInt'];
    amountCents: Scalars['BigInt'];
    smsAmountCents: Scalars['BigInt'];
    totalAmountCents?: Maybe<Scalars['BigInt']>;
    paidAt?: Maybe<Scalars['Date']>;
    dealId?: Maybe<Scalars['String']>;
    infos: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    organizationsNames: Scalars['String'];
    url: Scalars['String'];
    isRefunded: Scalars['Boolean'];
};

export type InvoicesConnection = {
    nodes: Array<Invoice>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type InvoicesOrganization = {
    invoiceId: Scalars['UUID'];
    organizationId: Scalars['OrganizationId'];
    insertedAt: Scalars['Datetime'];
};

export enum InvoiceState {
    Overdue = 'Overdue',
    Paid = 'Paid',
    Refunded = 'Refunded',
    Waiting = 'Waiting'
}

export type IOrganization = {
    id: Scalars['OrganizationId'];
    name: Scalars['String'];
    country?: Maybe<Scalars['String']>;
    membersSpaceDesignId?: Maybe<Scalars['DesignId']>;
    membersSpaceDesign?: Maybe<Design>;
    delegationsSpaceDesignId?: Maybe<Scalars['DesignId']>;
    delegationsSpaceDesign?: Maybe<Design>;
    allowDelegationSpaceDownload: Scalars['Boolean'];
    delegationsSpaceCustomBadges: Array<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    customFieldBySlug: CustomField;
    form: Form;
};

export type IOrganizationCustomFieldBySlugArgs = {
    slug: Scalars['CustomFieldSlug'];
};

export type IOrganizationFormArgs = {
    id: Scalars['FormId'];
};

export type IPosition = {
    id: Scalars['PositionId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    category: IPositionsCategory;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom: Scalars['Int'];
    layer: MapLayer;
    isVisible: Scalars['Boolean'];
    fields: Scalars['JSON'];
    tracesIds: Array<Scalars['TraceId']>;
    traces: Array<Trace>;
    slots: Array<IPositionsSlot>;
    positionsCustomFields: Array<PositionsCustomField>;
    range?: Maybe<Scalars['DatetimeRange']>;
    leaders: Array<Leader>;
    conditionsOperator: ConditionsOperator;
    tags: Array<Tag>;
    tagsIds: Array<Scalars['TagId']>;
};

export type IPositionFieldsArgs = {
    expand?: Maybe<Scalars['Boolean']>;
};

export type IPositionSlotsArgs = {
    date?: Maybe<Scalars['Date']>;
};

export type IPositionsCategory = {
    id: Scalars['PositionsCategoryId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    description: Scalars['String'];
    positions: Array<IPosition>;
    leaders: Array<Leader>;
};

export type IPositionsSlot = {
    id: Scalars['PositionsSlotId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
    range: Scalars['DatetimeRange'];
    resources: Scalars['Int'];
    isFull: Scalars['Boolean'];
    nameOrRange: Scalars['String'];
};

export type ISlot = {
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
    range: Scalars['DatetimeRange'];
};

export enum Language {
    Mandarin = 'Mandarin',
    Spanish = 'Spanish',
    English = 'English',
    Hindi = 'Hindi',
    Bengali = 'Bengali',
    Portuguese = 'Portuguese',
    Russian = 'Russian',
    Japanese = 'Japanese',
    Punjabi = 'Punjabi',
    Turkish = 'Turkish',
    Korean = 'Korean',
    French = 'French',
    German = 'German',
    Vietnamese = 'Vietnamese',
    Tamil = 'Tamil',
    Urdu = 'Urdu',
    Javanese = 'Javanese',
    Italian = 'Italian',
    Persian = 'Persian',
    Polish = 'Polish',
    Ukrainian = 'Ukrainian',
    Romanian = 'Romanian',
    Dutch = 'Dutch'
}

export type Leader = {
    userInfo: UsersInfo;
    positionCategoryId?: Maybe<Scalars['PositionsCategoryId']>;
    positionCategory?: Maybe<PositionsCategory>;
    positionId?: Maybe<Scalars['PositionId']>;
    position?: Maybe<Position>;
    canEdit: Scalars['Boolean'];
    role: Role;
};

export type LeaderInput = {
    userId: Scalars['UserId'];
    userInfoId: Scalars['UsersInfoId'];
    roleId: Scalars['RoleId'];
};

export type LoginCodesEmail = {
    email: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    source: LoginCodeSource;
    isEmbed: Scalars['Boolean'];
    language: SupportedLanguage;
    eventId?: Maybe<Scalars['EventId']>;
    organizationId?: Maybe<Scalars['OrganizationId']>;
    formId?: Maybe<Scalars['FormId']>;
};

export type LoginCodesEmailInput = {
    email: Scalars['String'];
    source: LoginCodeSource;
    isEmbed?: Maybe<Scalars['Boolean']>;
    eventId?: Maybe<Scalars['EventId']>;
    organizationId?: Maybe<Scalars['OrganizationId']>;
    formId?: Maybe<Scalars['FormId']>;
};

export enum LoginCodeSource {
    App = 'App',
    DelegationsSpace = 'DelegationsSpace',
    MembersSpace = 'MembersSpace',
    VolunteerAssignments = 'VolunteerAssignments',
    VolunteerRegister = 'VolunteerRegister'
}

export enum MapLayer {
    Google = 'Google',
    GoogleSatellite = 'GoogleSatellite',
    IgnPlan = 'IgnPlan'
}

export type MassAccreditationStateUpdateInput = {
    state: AccreditationState;
    selecteds: MassActionSelectedsInput;
};

export type MassAccreditInput = {
    accreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    selecteds: MassActionSelectedsInput;
    strategy: MassAssignStrategy;
};

export type MassActionSelectedsInput = {
    ids?: Maybe<Array<Scalars['BigInt']>>;
    predicates?: Maybe<Scalars['JSON']>;
    segmentId?: Maybe<Scalars['SegmentId']>;
};

export type MassAddEventInput = {
    eventId: Scalars['EventId'];
    selecteds: MassActionSelectedsInput;
};

export type MassAssignInput = {
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    selecteds: MassActionSelectedsInput;
    strategy: MassAssignStrategy;
};

export enum MassAssignStrategy {
    Add = 'Add',
    Delete = 'Delete',
    Replace = 'Replace'
}

export type MassConditionsInput = {
    customFieldId: Scalars['CustomFieldId'];
    selecteds: MassActionSelectedsInput;
    strategy: MassAssignStrategy;
    value: Scalars['JSON'];
};

export type MassDelegationsMove = {
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type MassEditInput = {
    reset: Scalars['Boolean'];
    selecteds: MassActionSelectedsInput;
    slug: Scalars['String'];
    strategy: MassAssignStrategy;
    value?: Maybe<Scalars['JSON']>;
};

export type MassPreAssignInput = {
    level: MassPreAssignLevel;
    positionsIds: Array<Scalars['PositionId']>;
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    selecteds: MassActionSelectedsInput;
    strategy: MassAssignStrategy;
};

export enum MassPreAssignLevel {
    Categories = 'Categories',
    Positions = 'Positions'
}

export type MassStateUpdateInput = {
    state: VolunteerRegistrationState;
    selecteds: MassActionSelectedsInput;
};

export type MembersSpaceSettingsInput = {
    hideAssignmentsUntilConfirmationEmailSent: Scalars['Boolean'];
    hideAccreditationsUntilConfirmationEmailSent: Scalars['Boolean'];
};

export type Mutation = {
    accreditationCreate: Accreditation;
    accreditationDuplicate: Accreditation;
    accreditationUpdate: Accreditation;
    accreditationCategoryCreate: AccreditationsCategory;
    accreditationCategoryDelete: AccreditationsCategory;
    accreditationCategoryDuplicate: AccreditationsCategory;
    accreditationCategoryUpdate: AccreditationsCategory;
    accreditationsMassDelete: Scalars['Boolean'];
    accreditationsMassEdit: Scalars['Boolean'];
    accreditationSlotDelete: AccreditationsSlot;
    accreditationSlotUpdate: AccreditationsSlot;
    backofficeEventUnarchive: Scalars['Boolean'];
    backofficeInvoiceMarkAsPaid: Scalars['Boolean'];
    backofficeOrganizationUpdate: Organization;
    backofficeOrganizationUserAdd: Scalars['Boolean'];
    backofficeOrganizationUserRemove: Scalars['Boolean'];
    campaignCreate: Campaign;
    campaignDelete: Campaign;
    campaignDuplicate: Campaign;
    campaignEstimate: CampaignEstimate;
    campaignSend: Campaign;
    campaignUpdate: Campaign;
    commentCreate: Comment;
    commentDelete: Comment;
    customDocumentCreate: CustomDocument;
    customDocumentDelete: CustomDocument;
    customDocumentDuplicate: CustomDocument;
    customDocumentUpdate: CustomDocument;
    customFieldCreate: CustomField;
    customFieldDuplicate: CustomField;
    customFieldUpdate: CustomField;
    customFieldCategoryCreate: CustomFieldsCategory;
    customFieldCategoryDelete: CustomFieldsCategory;
    customFieldCategoryUpdate: CustomFieldsCategory;
    customFieldsMassEdit: Scalars['Boolean'];
    customFieldsMassDelete: Scalars['Boolean'];
    delegationCreate: Delegation;
    delegationDuplicate: Delegation;
    delegationUpdate: Delegation;
    delegationUpdateFields: Scalars['Boolean'];
    delegationCategoryCreate: DelegationsCategory;
    delegationCategoryDelete: DelegationsCategory;
    delegationCategoryDuplicate: DelegationsCategory;
    delegationCategoryUpdate: DelegationsCategory;
    delegationsImport: Scalars['UUID'];
    delegationsImportResult: DelegationsImportResult;
    delegationsMassDelete: Scalars['Boolean'];
    delegationsMassEdit: Scalars['Boolean'];
    delegationsMassMove: Scalars['Boolean'];
    domainDkimVerificationRetry: Scalars['Boolean'];
    emailResend: Email;
    emailFormInviteSend: Scalars['Boolean'];
    emailSenderCreate: EmailsSender;
    emailSenderDelete: EmailsSender;
    emailSenderDuplicate: EmailsSender;
    emailSenderUpdate: EmailsSender;
    emailSenderVerificationResend: Scalars['Boolean'];
    emailTemplateUpdate: EmailsTemplate;
    emailUpdate: Scalars['Boolean'];
    eventAccreditationsEmailsEstimate: Scalars['Int'];
    eventAccreditationsEmailsSend: Scalars['Boolean'];
    eventAssignmentsEmailsEstimate: Scalars['Int'];
    eventAssignmentsEmailsSend: Scalars['Boolean'];
    eventCreate: Event;
    eventDocumentUpdate: EventsDocument;
    eventDocumentsExport: Scalars['UUID'];
    eventDocumentsExportResult: ExportResult;
    eventDuplicate: Event;
    eventInvite: User;
    eventLayerUpdate: Event;
    eventOrganizationCreate: Event;
    eventProfilePrivateFieldsUpdate: Event;
    eventUpdate: Event;
    eventWeezeventUpdate: Event;
    eventsMassArchive: Scalars['Boolean'];
    eventsMassEdit: Scalars['Boolean'];
    exportResult: ExportResult;
    filesImport: Scalars['UUID'];
    filesImportResult: FilesImportResult;
    formCreate: Form;
    formDelete: Form;
    formDuplicate: Form;
    formRegister: FormRegisterResult;
    formElementsUpdate: Form;
    formParametersAccreditationsUpdate: Form;
    formParametersAvailabilitiesUpdate: Form;
    formParametersBroadcastUpdate: Form;
    formParametersDesignUpdate: Form;
    formParametersGeneralUpdate: Form;
    formParametersMissionsUpdate: Form;
    formParametersStateUpdate: Form;
    loginCodeEmailCreate: LoginCodesEmail;
    organizationCreate: Organization;
    organizationInvite: User;
    organizationDelegationsSpaceDesignUpdate: Organization;
    organizationDelegationSpaceSettingsUpdate: Organization;
    organizationMembersSpaceDesignUpdate: Organization;
    organizationMembersSpaceSettingsUpdate: Organization;
    organizationProfilePrivateFieldsUpdate: Organization;
    organizationUpdateInfos: Organization;
    positionCategoryCreate: PositionsCategory;
    positionCategoryDelete: PositionsCategory;
    positionCategoryDuplicate: PositionsCategory;
    positionsCategoryLeadersAssign: PositionsCategory;
    positionCategoryLeaderDelete: PositionsCategory;
    positionCategoryUpdate: PositionsCategory;
    positionCategoryDescriptionUpdate: PositionsCategory;
    positionCreate: Position;
    positionsCreate: Array<Position>;
    positionDuplicate: Position;
    positionsImport: Scalars['UUID'];
    positionsImportResult: PositionsImportResult;
    positionLeadersAssign: Position;
    positionLeaderDelete: Position;
    positionUpdate: Position;
    positionsMassConditions: Array<Scalars['PositionId']>;
    positionsMassDelete: Scalars['Boolean'];
    positionsMassEdit: Scalars['Boolean'];
    positionSlotCreate: PositionsSlot;
    positionSlotDelete: PositionsSlot;
    positionSlotUpdate: PositionsSlot;
    positionsSlotsUsersInfosCheckIn: Scalars['Boolean'];
    segmentCreate: Segment;
    segmentDelete: Segment;
    segmentMoveFolder: Segment;
    segmentMoveDown: Segment;
    segmentMoveUp: Segment;
    segmentUpdate: Segment;
    segmentUpdateName: Segment;
    segmentFolderCreate: SegmentsFolder;
    segmentFolderDelete: SegmentsFolder;
    segmentFolderUpdateName: SegmentsFolder;
    segmentFolderMoveDown: SegmentsFolder;
    segmentFolderMoveUp: SegmentsFolder;
    segmentsFoldersReorder: Scalars['Boolean'];
    skidataConnectionInfosDelete: SkidataConnectionInfo;
    skidataConnectionInfosUpsert: SkidataConnectionInfo;
    skidataConnectionInfoSeaonEventAdd: SkidataConnectionInfo;
    skidataConnectionInfoSeaonEventDelete: SkidataConnectionInfo;
    skidataConnectionInfoSynchronizedEventAdd: SkidataConnectionInfo;
    skidataConnectionInfoSynchronizedEventDelete: SkidataConnectionInfo;
    skidataSynchronize: Scalars['Boolean'];
    tagCreate: Tag;
    tagDelete: Tag;
    tagUpdateName: Tag;
    teamAssign: Scalars['Boolean'];
    teamAssignmentRefuse: Scalars['Boolean'];
    textMessageRepliesRead: TextMessage;
    textMessageSend: TextMessage;
    traceCreate: Trace;
    traceDelete: Trace;
    traceUpdate: Trace;
    translationUpdate: Translation;
    userCreate: User;
    userDelete: User;
    userEventDelete: Scalars['Boolean'];
    userEventUpdate: Scalars['Boolean'];
    userInfoAndTermsUpdate: User;
    userLeaderDelete: Event;
    userOrganizationDelete: Organization;
    userOrganizationUpdate: Organization;
    userPositionCategoryUpdate?: Maybe<UsersPositionsCategory>;
    userPositionUpdate?: Maybe<UsersPosition>;
    userProfileUpdate: User;
    userInfoAdminCreate: UsersInfo;
    userInfoAdminDelete: UsersInfo;
    userInfoUpdate: UsersInfo;
    userInfoUpdateField: UsersInfo;
    userInfoDelegationDelete: Scalars['Boolean'];
    userInfoDelegationUpdate: Scalars['Boolean'];
    userInfoAccreditationsUpdate: Scalars['Boolean'];
    userInfoPositionsUpdate: Scalars['Boolean'];
    userInfoSlotsUpdate: Scalars['Boolean'];
    usersInfosExport: Scalars['UUID'];
    usersInfosDelegationAdd?: Maybe<Scalars['Boolean']>;
    usersInfosMassAddEvent: Array<VolunteersRegistration>;
    usersInfosMassCampaignSend: Scalars['Boolean'];
    usersInfosMassDelegationAdd: Scalars['Boolean'];
    usersInfosMassFormAdd: Scalars['Boolean'];
    usersInfosMassFormRemove: Scalars['Boolean'];
    usersInfosMassDelete: Scalars['Boolean'];
    usersInfosMassEdit: Array<UsersInfo>;
    usersInfosMerge: UsersInfo;
    volunteersRegistrationsAdminAdd: Array<VolunteersRegistration>;
    volunteerRegistrationAssignmentRefuse: VolunteersRegistration;
    volunteerRegistrationUpdate: VolunteersRegistration;
    volunteerRegistrationUpdateField: VolunteersRegistration;
    volunteersExport: Scalars['UUID'];
    volunteersImport: Scalars['UUID'];
    volunteersImportResult: VolunteersImportResult;
    volunteersRegistrationsMassAssign: Scalars['Boolean'];
    volunteersRegistrationsMassPreAssign: Scalars['Boolean'];
    volunteersRegistrationsMassCampaignSend: Scalars['Boolean'];
    volunteersRegistrationsMassDelegationAdd: Scalars['Boolean'];
    volunteersRegistrationsMassFormAdd: Scalars['Boolean'];
    volunteersRegistrationsMassFormRemove: Scalars['Boolean'];
    volunteersRegistrationsMassDelete: Scalars['Boolean'];
    volunteersRegistrationsMassStateUpdate: Array<VolunteersRegistration>;
    volunteersRegistrationsMassAccreditationStateUpdate: Array<VolunteersRegistration>;
    volunteersRegistrationsAccreditationsValidate: Scalars['Boolean'];
    volunteersRegistrationsMassAccredit: Scalars['Boolean'];
    volunteersRegistrationsMassWishedAccreditationsUpdate: Scalars['Boolean'];
};

export type MutationAccreditationCreateArgs = {
    eventId: Scalars['EventId'];
    accreditation: AccreditationInput;
};

export type MutationAccreditationDuplicateArgs = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
};

export type MutationAccreditationUpdateArgs = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
    accreditation: AccreditationInput;
};

export type MutationAccreditationCategoryCreateArgs = {
    eventId: Scalars['EventId'];
    accreditationCategory: AccreditationsCategoryInput;
};

export type MutationAccreditationCategoryDeleteArgs = {
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
};

export type MutationAccreditationCategoryDuplicateArgs = {
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
};

export type MutationAccreditationCategoryUpdateArgs = {
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationCategory: AccreditationsCategoryInput;
};

export type MutationAccreditationsMassDeleteArgs = {
    eventId: Scalars['EventId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationAccreditationsMassEditArgs = {
    eventId: Scalars['EventId'];
    massEdit: MassEditInput;
};

export type MutationAccreditationSlotDeleteArgs = {
    eventId: Scalars['EventId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
};

export type MutationAccreditationSlotUpdateArgs = {
    eventId: Scalars['EventId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    accrediationSlot: AccreditationsSlotInput;
};

export type MutationBackofficeEventUnarchiveArgs = {
    eventId: Scalars['EventId'];
};

export type MutationBackofficeInvoiceMarkAsPaidArgs = {
    invoiceId: Scalars['InvoiceId'];
    transactionId: Scalars['String'];
};

export type MutationBackofficeOrganizationUpdateArgs = {
    id: Scalars['OrganizationId'];
    organization: BackofficeOrganizationInput;
};

export type MutationBackofficeOrganizationUserAddArgs = {
    organizationId: Scalars['OrganizationId'];
};

export type MutationBackofficeOrganizationUserRemoveArgs = {
    organizationId: Scalars['OrganizationId'];
};

export type MutationCampaignCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaign: CampaignInput;
};

export type MutationCampaignDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
};

export type MutationCampaignDuplicateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
};

export type MutationCampaignEstimateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaign: CampaignInput;
};

export type MutationCampaignSendArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
};

export type MutationCampaignUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
    campaign: CampaignInput;
};

export type MutationCommentCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    comment: CommentInput;
};

export type MutationCommentDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    commentId: Scalars['CommentId'];
};

export type MutationCustomDocumentCreateArgs = {
    eventId: Scalars['EventId'];
    customDocument: CustomDocumentInput;
};

export type MutationCustomDocumentDeleteArgs = {
    eventId: Scalars['EventId'];
    customDocumentId: Scalars['CustomDocumentId'];
};

export type MutationCustomDocumentDuplicateArgs = {
    eventId: Scalars['EventId'];
    customDocumentId: Scalars['CustomDocumentId'];
};

export type MutationCustomDocumentUpdateArgs = {
    eventId: Scalars['EventId'];
    customDocumentId: Scalars['CustomDocumentId'];
    customDocument: CustomDocumentInput;
};

export type MutationCustomFieldCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    customField: CustomFieldInput;
};

export type MutationCustomFieldDuplicateArgs = {
    organizationId: Scalars['OrganizationId'];
    customFieldId: Scalars['CustomFieldId'];
};

export type MutationCustomFieldUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    customFieldId: Scalars['CustomFieldId'];
    customField: CustomFieldInput;
};

export type MutationCustomFieldCategoryCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    customFieldCategory: CustomFieldsCategoryInput;
};

export type MutationCustomFieldCategoryDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    customFieldCategoryId: Scalars['CustomFieldsCategoryId'];
};

export type MutationCustomFieldCategoryUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    customFieldCategoryId: Scalars['CustomFieldsCategoryId'];
    customFieldCategory: CustomFieldsCategoryInput;
};

export type MutationCustomFieldsMassEditArgs = {
    organizationId: Scalars['OrganizationId'];
    massEdit: MassEditInput;
};

export type MutationCustomFieldsMassDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationDelegationCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegation: DelegationInput;
};

export type MutationDelegationDuplicateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId: Scalars['DelegationId'];
};

export type MutationDelegationUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId: Scalars['DelegationId'];
    delegation: DelegationInput;
};

export type MutationDelegationUpdateFieldsArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId: Scalars['DelegationId'];
    fields: Scalars['JSON'];
};

export type MutationDelegationCategoryCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategory: DelegationsCategoryInput;
};

export type MutationDelegationCategoryDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
};

export type MutationDelegationCategoryDuplicateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
};

export type MutationDelegationCategoryUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
    delegationCategory: DelegationsCategoryInput;
};

export type MutationDelegationsImportArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    input: DelegationsImportInput;
};

export type MutationDelegationsImportResultArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    jobId: Scalars['UUID'];
};

export type MutationDelegationsMassDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    selecteds: MassActionSelectedsInput;
};

export type MutationDelegationsMassEditArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    massEdit: MassEditInput;
};

export type MutationDelegationsMassMoveArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    massMove: MassDelegationsMove;
};

export type MutationDomainDkimVerificationRetryArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    domain: Scalars['String'];
};

export type MutationEmailResendArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    emailType: EmailType;
};

export type MutationEmailFormInviteSendArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
    teamCode: Scalars['TeamCode'];
    emails: Array<Scalars['UserEmail']>;
};

export type MutationEmailSenderCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    emailSender: EmailsSenderInput;
};

export type MutationEmailSenderDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type MutationEmailSenderDuplicateArgs = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type MutationEmailSenderUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
    emailSender: EmailsSenderInput;
};

export type MutationEmailSenderVerificationResendArgs = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type MutationEmailTemplateUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId?: Maybe<Scalars['FormId']>;
    source?: Maybe<LoginCodeSource>;
    emailTemplate: EmailsTemplateInput;
};

export type MutationEmailUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    email: Scalars['UserEmail'];
};

export type MutationEventAccreditationsEmailsEstimateArgs = {
    eventId: Scalars['EventId'];
    sendAccreditationsEmails: SendAccreditationsEmailsInput;
};

export type MutationEventAccreditationsEmailsSendArgs = {
    eventId: Scalars['EventId'];
    sendAccreditationsEmails: SendAccreditationsEmailsInput;
};

export type MutationEventAssignmentsEmailsEstimateArgs = {
    eventId: Scalars['EventId'];
    sendAssignmentsEmails: SendAssignmentsEmailsInput;
};

export type MutationEventAssignmentsEmailsSendArgs = {
    eventId: Scalars['EventId'];
    sendAssignmentsEmails: SendAssignmentsEmailsInput;
};

export type MutationEventCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    event: EventInput;
};

export type MutationEventDocumentUpdateArgs = {
    eventId: Scalars['EventId'];
    id: Scalars['EventsDocumentId'];
    options: Scalars['JSON'];
};

export type MutationEventDocumentsExportArgs = {
    eventId: Scalars['EventId'];
    delegationId?: Maybe<Scalars['DelegationId']>;
    documentsExport: DocumentsExportInput;
};

export type MutationEventDocumentsExportResultArgs = {
    eventId: Scalars['EventId'];
    jobId: Scalars['UUID'];
};

export type MutationEventDuplicateArgs = {
    eventId: Scalars['EventId'];
    options: EventDuplicateInput;
};

export type MutationEventInviteArgs = {
    eventId: Scalars['EventId'];
    email: Scalars['UserEmail'];
};

export type MutationEventLayerUpdateArgs = {
    eventId: Scalars['EventId'];
    layer: MapLayer;
};

export type MutationEventOrganizationCreateArgs = {
    eventOrganization: EventOrganizationInput;
};

export type MutationEventProfilePrivateFieldsUpdateArgs = {
    eventId: Scalars['EventId'];
    profilePrivateFields: Array<Scalars['CustomFieldSlug']>;
};

export type MutationEventUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    event: EventInput;
};

export type MutationEventWeezeventUpdateArgs = {
    eventId: Scalars['EventId'];
    weezevent: EventWeezeventInput;
};

export type MutationEventsMassArchiveArgs = {
    organizationId: Scalars['OrganizationId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationEventsMassEditArgs = {
    organizationId: Scalars['OrganizationId'];
    massEdit: MassEditInput;
};

export type MutationExportResultArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    jobId: Scalars['UUID'];
};

export type MutationFilesImportArgs = {
    eventId?: Maybe<Scalars['EventId']>;
    organizationId: Scalars['OrganizationId'];
    input: FilesImportInput;
};

export type MutationFilesImportResultArgs = {
    organizationId: Scalars['OrganizationId'];
    jobId: Scalars['UUID'];
};

export type MutationFormCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    form: FormInput;
};

export type MutationFormDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
};

export type MutationFormDuplicateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    newEventId?: Maybe<Scalars['EventId']>;
};

export type MutationFormRegisterArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    formId: Scalars['FormId'];
    email: Scalars['UserEmail'];
    formRegister: FormRegisterInput;
};

export type MutationFormElementsUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    elements: Array<FormsElementInput>;
};

export type MutationFormParametersAccreditationsUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersAccreditationsInput;
};

export type MutationFormParametersAvailabilitiesUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersAvailabilitiesInput;
};

export type MutationFormParametersBroadcastUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersBroadcastInput;
};

export type MutationFormParametersDesignUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    design: DesignInput;
};

export type MutationFormParametersGeneralUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersGeneralInput;
};

export type MutationFormParametersMissionsUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersMissionsInput;
};

export type MutationFormParametersStateUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersStateInput;
};

export type MutationLoginCodeEmailCreateArgs = {
    loginCodeEmail: LoginCodesEmailInput;
};

export type MutationOrganizationCreateArgs = {
    organization: OrganizationInput;
};

export type MutationOrganizationInviteArgs = {
    organizationId: Scalars['OrganizationId'];
    email: Scalars['UserEmail'];
};

export type MutationOrganizationDelegationsSpaceDesignUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    delegationsSpaceDesign: DesignInput;
};

export type MutationOrganizationDelegationSpaceSettingsUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    delegationSpaceSettings: DelegationSpaceSettingsInput;
};

export type MutationOrganizationMembersSpaceDesignUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    membersSpaceDesign: DesignInput;
};

export type MutationOrganizationMembersSpaceSettingsUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    membersSpaceSettings: MembersSpaceSettingsInput;
};

export type MutationOrganizationProfilePrivateFieldsUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    profilePrivateFields: Array<Scalars['CustomFieldSlug']>;
};

export type MutationOrganizationUpdateInfosArgs = {
    organizationId: Scalars['OrganizationId'];
    organization: OrganizationInfosInput;
};

export type MutationPositionCategoryCreateArgs = {
    eventId: Scalars['EventId'];
    positionCategory: PositionsCategoryInput;
};

export type MutationPositionCategoryDeleteArgs = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
};

export type MutationPositionCategoryDuplicateArgs = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
};

export type MutationPositionsCategoryLeadersAssignArgs = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    leaders: Array<LeaderInput>;
};

export type MutationPositionCategoryLeaderDeleteArgs = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type MutationPositionCategoryUpdateArgs = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    positionCategory: PositionsCategoryInput;
};

export type MutationPositionCategoryDescriptionUpdateArgs = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    description: Scalars['String'];
};

export type MutationPositionCreateArgs = {
    eventId: Scalars['EventId'];
    position: PositionInput;
};

export type MutationPositionsCreateArgs = {
    eventId: Scalars['EventId'];
    positions: Array<PositionInput>;
};

export type MutationPositionDuplicateArgs = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type MutationPositionsImportArgs = {
    eventId: Scalars['EventId'];
    organizationId: Scalars['OrganizationId'];
    input: PositionsImportInput;
};

export type MutationPositionsImportResultArgs = {
    organizationId: Scalars['OrganizationId'];
    jobId: Scalars['UUID'];
};

export type MutationPositionLeadersAssignArgs = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    leaders: Array<LeaderInput>;
};

export type MutationPositionLeaderDeleteArgs = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type MutationPositionUpdateArgs = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    position: PositionInput;
};

export type MutationPositionsMassConditionsArgs = {
    eventId: Scalars['EventId'];
    massConditions: MassConditionsInput;
};

export type MutationPositionsMassDeleteArgs = {
    eventId: Scalars['EventId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationPositionsMassEditArgs = {
    eventId: Scalars['EventId'];
    massEdit: MassEditInput;
};

export type MutationPositionSlotCreateArgs = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    positionSlot: PositionsSlotInput;
};

export type MutationPositionSlotDeleteArgs = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    positionSlotId: Scalars['PositionsSlotId'];
};

export type MutationPositionSlotUpdateArgs = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    positionSlotId: Scalars['PositionsSlotId'];
    positionSlot: PositionsSlotInput;
};

export type MutationPositionsSlotsUsersInfosCheckInArgs = {
    eventId: Scalars['EventId'];
    checkIn: CheckInInput;
};

export type MutationSegmentCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
    segment: SegmentInput;
};

export type MutationSegmentDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
};

export type MutationSegmentMoveFolderArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type MutationSegmentMoveDownArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
};

export type MutationSegmentMoveUpArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
};

export type MutationSegmentUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
    segment: SegmentInput;
};

export type MutationSegmentUpdateNameArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
    name: Scalars['String'];
};

export type MutationSegmentFolderCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolder: SegmentsFolderInput;
};

export type MutationSegmentFolderDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type MutationSegmentFolderUpdateNameArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
    name: Scalars['String'];
};

export type MutationSegmentFolderMoveDownArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type MutationSegmentFolderMoveUpArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type MutationSegmentsFoldersReorderArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentType: SegmentType;
    segmentsFolders: Array<SegmentFolderReorderInput>;
};

export type MutationSkidataConnectionInfosDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
};

export type MutationSkidataConnectionInfosUpsertArgs = {
    organizationId: Scalars['OrganizationId'];
    connectionInfos: SkidataConnectionInfoInput;
};

export type MutationSkidataConnectionInfoSeaonEventAddArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type MutationSkidataConnectionInfoSeaonEventDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type MutationSkidataConnectionInfoSynchronizedEventAddArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type MutationSkidataConnectionInfoSynchronizedEventDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type MutationSkidataSynchronizeArgs = {
    organizationId: Scalars['OrganizationId'];
};

export type MutationTagCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    tag: TagInput;
};

export type MutationTagDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    tagId: Scalars['TagId'];
};

export type MutationTagUpdateNameArgs = {
    organizationId: Scalars['OrganizationId'];
    tagId: Scalars['TagId'];
    name: Scalars['String'];
};

export type MutationTeamAssignArgs = {
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
    assignment: AssignmentInput;
};

export type MutationTeamAssignmentRefuseArgs = {
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
};

export type MutationTextMessageRepliesReadArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    textMessageId: Scalars['TextMessageId'];
};

export type MutationTextMessageSendArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    parentId: Scalars['TextMessageId'];
    content: Scalars['String'];
};

export type MutationTraceCreateArgs = {
    eventId: Scalars['EventId'];
    trace: TraceInput;
};

export type MutationTraceDeleteArgs = {
    eventId: Scalars['EventId'];
    traceId: Scalars['TraceId'];
};

export type MutationTraceUpdateArgs = {
    eventId: Scalars['EventId'];
    traceId: Scalars['TraceId'];
    trace: TraceInput;
};

export type MutationTranslationUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    translationId: Scalars['TranslationId'];
    translation: TranslationInput;
};

export type MutationUserEventDeleteArgs = {
    eventId: Scalars['EventId'];
    userId: Scalars['UserId'];
};

export type MutationUserEventUpdateArgs = {
    eventId: Scalars['EventId'];
    userId: Scalars['UserId'];
    roleId: Scalars['RoleId'];
};

export type MutationUserInfoAndTermsUpdateArgs = {
    userInfoId: Scalars['UsersInfoId'];
    userInfoAndTermsInput: UserInfoAndTermsInput;
};

export type MutationUserLeaderDeleteArgs = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type MutationUserOrganizationDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    userId: Scalars['UserId'];
};

export type MutationUserOrganizationUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    userId: Scalars['UserId'];
    sendNotifications: Scalars['Boolean'];
};

export type MutationUserPositionCategoryUpdateArgs = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    roleId: Scalars['RoleId'];
};

export type MutationUserPositionUpdateArgs = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    positionId: Scalars['PositionId'];
    roleId: Scalars['RoleId'];
};

export type MutationUserProfileUpdateArgs = {
    userId: Scalars['UserId'];
    userInfoId: Scalars['UsersInfoId'];
    userInfo: UsersInfoProfileInput;
};

export type MutationUserInfoAdminCreateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    email: Scalars['UserEmail'];
    sendVolunteerRegistrationEmail: Scalars['Boolean'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type MutationUserInfoAdminDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type MutationUserInfoUpdateArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userId: Scalars['UserId'];
    userInfo: UsersInfoInput;
    filledFormsIds?: Maybe<Array<Scalars['FormId']>>;
    formKeyToTeamCode?: Maybe<Scalars['JSON']>;
};

export type MutationUserInfoUpdateFieldArgs = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
    slug: Scalars['String'];
    value: Scalars['JSON'];
};

export type MutationUserInfoDelegationDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    delegationId: Scalars['DelegationId'];
    deleteFromEvent?: Maybe<Scalars['Boolean']>;
};

export type MutationUserInfoDelegationUpdateArgs = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    delegationId?: Maybe<Scalars['DelegationId']>;
};

export type MutationUserInfoAccreditationsUpdateArgs = {
    eventId: Scalars['EventId'];
    userId?: Maybe<Scalars['UserId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfoAccreditations: Array<UserInfoAccreditationsInput>;
};

export type MutationUserInfoPositionsUpdateArgs = {
    eventId: Scalars['EventId'];
    userId?: Maybe<Scalars['UserId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfoPositions: Array<UserInfoPositionsInput>;
};

export type MutationUserInfoSlotsUpdateArgs = {
    eventId: Scalars['EventId'];
    userId?: Maybe<Scalars['UserId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfoSlots: Array<UserInfoSlotsInput>;
};

export type MutationUsersInfosExportArgs = {
    organizationId: Scalars['OrganizationId'];
    segmentId: Scalars['SegmentId'];
    format: ExportFormat;
    includeFiles: Scalars['Boolean'];
    columns: Array<Scalars['String']>;
};

export type MutationUsersInfosDelegationAddArgs = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
};

export type MutationUsersInfosMassAddEventArgs = {
    organizationId: Scalars['OrganizationId'];
    massAddEvent: MassAddEventInput;
};

export type MutationUsersInfosMassCampaignSendArgs = {
    organizationId: Scalars['OrganizationId'];
    campaignId: Scalars['CampaignId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationUsersInfosMassDelegationAddArgs = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationUsersInfosMassFormAddArgs = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationUsersInfosMassFormRemoveArgs = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationUsersInfosMassDeleteArgs = {
    organizationId: Scalars['OrganizationId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationUsersInfosMassEditArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    massEdit: MassEditInput;
};

export type MutationUsersInfosMergeArgs = {
    organizationId: Scalars['OrganizationId'];
    mainUserInfoId: Scalars['UsersInfoId'];
    toMergeUsersInfosIds: Array<Scalars['UsersInfoId']>;
};

export type MutationVolunteersRegistrationsAdminAddArgs = {
    eventId: Scalars['EventId'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
    sendVolunteerRegistrationEmail: Scalars['Boolean'];
};

export type MutationVolunteerRegistrationAssignmentRefuseArgs = {
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
};

export type MutationVolunteerRegistrationUpdateArgs = {
    eventId: Scalars['EventId'];
    email: Scalars['UserEmail'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    volunteerRegistration: VolunteersRegistrationInput;
};

export type MutationVolunteerRegistrationUpdateFieldArgs = {
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    slug: Scalars['String'];
    value: Scalars['JSON'];
};

export type MutationVolunteersExportArgs = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
    format: ExportFormat;
    includeFiles: Scalars['Boolean'];
    columns: Array<Scalars['String']>;
};

export type MutationVolunteersImportArgs = {
    eventId?: Maybe<Scalars['EventId']>;
    organizationId: Scalars['OrganizationId'];
    input: VolunteersImportInput;
};

export type MutationVolunteersImportResultArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    jobId: Scalars['UUID'];
};

export type MutationVolunteersRegistrationsMassAssignArgs = {
    eventId: Scalars['EventId'];
    massAssign: MassAssignInput;
};

export type MutationVolunteersRegistrationsMassPreAssignArgs = {
    eventId: Scalars['EventId'];
    massPreAssign: MassPreAssignInput;
};

export type MutationVolunteersRegistrationsMassCampaignSendArgs = {
    eventId: Scalars['EventId'];
    campaignId: Scalars['CampaignId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationVolunteersRegistrationsMassDelegationAddArgs = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationVolunteersRegistrationsMassFormAddArgs = {
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationVolunteersRegistrationsMassFormRemoveArgs = {
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationVolunteersRegistrationsMassDeleteArgs = {
    eventId: Scalars['EventId'];
    selecteds: MassActionSelectedsInput;
};

export type MutationVolunteersRegistrationsMassStateUpdateArgs = {
    eventId: Scalars['EventId'];
    massStateUpdate: MassStateUpdateInput;
};

export type MutationVolunteersRegistrationsMassAccreditationStateUpdateArgs = {
    eventId: Scalars['EventId'];
    massStateUpdate: MassAccreditationStateUpdateInput;
};

export type MutationVolunteersRegistrationsAccreditationsValidateArgs = {
    eventId: Scalars['EventId'];
    delegationId?: Maybe<Scalars['DelegationId']>;
    formId: Scalars['FormId'];
    volunteerRegistrationsIds: Array<Scalars['VolunteersRegistrationId']>;
};

export type MutationVolunteersRegistrationsMassAccreditArgs = {
    eventId: Scalars['EventId'];
    massAccredit: MassAccreditInput;
};

export type MutationVolunteersRegistrationsMassWishedAccreditationsUpdateArgs = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    massAccredit: MassAccreditInput;
};

export type Organization = IOrganization & {
    id: Scalars['OrganizationId'];
    name: Scalars['String'];
    adminEmail?: Maybe<Scalars['String']>;
    adminName?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    features: Array<Feature>;
    periodEndAt: Scalars['Datetime'];
    customBadges: Array<CustomBadge>;
    timeZone: Scalars['Timezone'];
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    emailSender?: Maybe<EmailsSender>;
    isBlocked: Scalars['Boolean'];
    membersSpaceDesignId?: Maybe<Scalars['DesignId']>;
    membersSpaceDesign?: Maybe<Design>;
    delegationsSpaceDesignId?: Maybe<Scalars['DesignId']>;
    delegationsSpaceDesign?: Maybe<Design>;
    allowDelegationSpaceDownload: Scalars['Boolean'];
    delegationsSpaceCustomBadges: Array<Scalars['String']>;
    profilePrivateFields: Array<Scalars['CustomFieldSlug']>;
    exportDateFormat: Scalars['String'];
    exportDatetimeFormat: Scalars['String'];
    exportTimeFormat: Scalars['String'];
    key: Scalars['String'];
    weezeventOrganizationId?: Maybe<Scalars['BigInt']>;
    membersLimit?: Maybe<Scalars['BigInt']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    accreditationsCategories: AccreditationsCategoriesConnection;
    activities: ActivitiesConnection;
    campaign: Campaign;
    campaigns: CampaignsConnection;
    campaignsCreators: Array<UsersInfo>;
    customDocuments: Array<CustomDocument>;
    customField: CustomField;
    customFieldBySlug: CustomField;
    customFields: CustomFieldsConnection;
    customFieldsCategories: CustomFieldsCategoriesConnection;
    delegation: Delegation;
    delegations: DelegationsConnection;
    delegationCategory: DelegationsCategory;
    delegationsCategories: DelegationsCategoriesConnection;
    domains: Array<Domain>;
    emailTemplate?: Maybe<EmailsTemplate>;
    emailsSenders: Array<EmailsSender>;
    event: Event;
    eventMaybe?: Maybe<Event>;
    events: EventsConnection;
    form: Form;
    forms: Array<Form>;
    hasDelegations: Scalars['Boolean'];
    hasEvents: Scalars['Boolean'];
    hasForms: Scalars['Boolean'];
    hideAssignmentsUntilConfirmationEmailSent: Scalars['Boolean'];
    hideAccreditationsUntilConfirmationEmailSent: Scalars['Boolean'];
    invoices: Array<Invoice>;
    languages: Array<SupportedLanguage>;
    numberOfDelegations: Scalars['Int'];
    numberOfEvents: Scalars['Int'];
    numberOfUsersInfos: Scalars['Int'];
    positionsCategories: PositionsCategoriesConnection;
    possibleDuplicates: Array<PossibleDuplicate>;
    roles: Array<Role>;
    segment: Segment;
    segments: Array<Segment>;
    segmentsFolders: Array<SegmentsFolder>;
    skidataConnectionInfos?: Maybe<SkidataConnectionInfo>;
    tags: TagsConnection;
    textMessage: TextMessage;
    translations: TranslationsConnection;
    userInfo: UsersInfo;
    users: Array<User>;
    usersInfos: UsersInfoConnection;
    usersOrganizations: Array<UsersOrganization>;
    volunteersRegistrations: VolunteersRegistrationsConnection;
};

export type OrganizationEmailSenderArgs = {
    id?: Maybe<Scalars['EmailsSenderId']>;
};

export type OrganizationAccreditationsCategoriesArgs = {
    name?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['EventId']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationActivitiesArgs = {
    operations?: Maybe<Array<Scalars['String']>>;
    eventsIds?: Maybe<Array<Scalars['EventId']>>;
    date?: Maybe<Scalars['Date']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationCampaignArgs = {
    id: Scalars['CampaignId'];
};

export type OrganizationCampaignsArgs = {
    name?: Maybe<Scalars['String']>;
    state?: Maybe<CampaignState>;
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CampaignsSort>;
};

export type OrganizationCustomFieldArgs = {
    id: Scalars['CustomFieldId'];
};

export type OrganizationCustomFieldBySlugArgs = {
    slug: Scalars['CustomFieldSlug'];
};

export type OrganizationCustomFieldsArgs = {
    variety?: Maybe<CustomFieldVariety>;
    privacy?: Maybe<CustomFieldPrivacy>;
    name?: Maybe<Scalars['String']>;
    fieldTypes?: Maybe<Array<FieldType>>;
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CustomFieldsSort>;
};

export type OrganizationCustomFieldsCategoriesArgs = {
    name?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationDelegationArgs = {
    id: Scalars['DelegationId'];
};

export type OrganizationDelegationsArgs = {
    level: OrganizationEventLevel;
    eventId?: Maybe<Scalars['EventId']>;
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    delegationsCategoriesIds?: Maybe<Array<Scalars['DelegationsCategoryId']>>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    onlyVisibleInDelegation?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<DelegationsSort>;
};

export type OrganizationDelegationCategoryArgs = {
    id: Scalars['DelegationsCategoryId'];
};

export type OrganizationDelegationsCategoriesArgs = {
    level: OrganizationEventLevel;
    eventId?: Maybe<Scalars['EventId']>;
    name?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationEmailTemplateArgs = {
    emailType?: Maybe<EmailType>;
    eventId?: Maybe<Scalars['EventId']>;
    formId?: Maybe<Scalars['FormId']>;
    source?: Maybe<LoginCodeSource>;
};

export type OrganizationEventArgs = {
    id: Scalars['EventId'];
};

export type OrganizationEventMaybeArgs = {
    id?: Maybe<Scalars['EventId']>;
};

export type OrganizationEventsArgs = {
    name?: Maybe<Scalars['String']>;
    states?: Maybe<Array<EventState>>;
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<EventsSort>;
};

export type OrganizationFormArgs = {
    id: Scalars['FormId'];
};

export type OrganizationFormsArgs = {
    level: OrganizationEventLevel;
    eventId?: Maybe<Scalars['EventId']>;
};

export type OrganizationInvoicesArgs = {
    state?: Maybe<InvoiceState>;
};

export type OrganizationPositionsCategoriesArgs = {
    name?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['EventId']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationSegmentArgs = {
    id: Scalars['SegmentId'];
};

export type OrganizationSegmentsArgs = {
    segmentType: SegmentType;
};

export type OrganizationSegmentsFoldersArgs = {
    segmentType: SegmentType;
};

export type OrganizationTagsArgs = {
    name?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationTextMessageArgs = {
    id: Scalars['TextMessageId'];
};

export type OrganizationTranslationsArgs = {
    content?: Maybe<Scalars['String']>;
    status?: Maybe<TranslationStatus>;
    eventsIds?: Maybe<Array<Scalars['EventId']>>;
    modules?: Maybe<Array<TranslationModule>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationUserInfoArgs = {
    id: Scalars['UsersInfoId'];
};

export type OrganizationUsersInfosArgs = {
    name?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['EventId']>;
    excludedEventId?: Maybe<Scalars['EventId']>;
    excludedDelegationId?: Maybe<Scalars['DelegationId']>;
    predicates?: Maybe<Scalars['JSON']>;
    ids?: Maybe<Array<Scalars['UsersInfoId']>>;
    delegationCategoryId?: Maybe<Scalars['DelegationsCategoryId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    positionId?: Maybe<Scalars['PositionId']>;
    assignmentState?: Maybe<VolunteerRegistrationState>;
    accreditationState?: Maybe<AccreditationState>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<UsersInfosSort>;
};

export type OrganizationVolunteersRegistrationsArgs = {
    ids?: Maybe<Array<Scalars['VolunteersRegistrationId']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<VolunteersRegistrationsSort>;
};

export enum OrganizationEventLevel {
    Both = 'Both',
    Event = 'Event',
    Organization = 'Organization'
}

export type OrganizationInfosInput = {
    name: Scalars['String'];
    adminName: Scalars['String'];
    adminEmail: Scalars['String'];
    street: Scalars['String'];
    postalCode: Scalars['String'];
    city: Scalars['String'];
    country: Scalars['String'];
    timeZone: Scalars['Timezone'];
    emailSenderId?: Maybe<Scalars['EmailsSenderId']>;
    exportDateFormat: Scalars['String'];
    exportDatetimeFormat: Scalars['String'];
    exportTimeFormat: Scalars['String'];
};

export type OrganizationInput = {
    name: Scalars['String'];
    adminFirstName: Scalars['String'];
    adminLastName: Scalars['String'];
    adminPhone: PhoneInput;
    termsAccepted?: Maybe<Scalars['Boolean']>;
    membersSpaceDesignId?: Maybe<Scalars['BigInt']>;
    delegationsSpaceDesignId?: Maybe<Scalars['BigInt']>;
    hideAssignmentsUntilConfirmationEmailSent?: Maybe<Scalars['Boolean']>;
    hideAccreditationsUntilConfirmationEmailSent?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPublic = IOrganization & {
    id: Scalars['OrganizationId'];
    name: Scalars['String'];
    country?: Maybe<Scalars['String']>;
    delegation: Delegation;
    delegations: DelegationsConnection;
    membersSpaceDesignId?: Maybe<Scalars['DesignId']>;
    membersSpaceDesign?: Maybe<Design>;
    delegationsSpaceDesignId?: Maybe<Scalars['DesignId']>;
    delegationsSpaceDesign?: Maybe<Design>;
    allowDelegationSpaceDownload: Scalars['Boolean'];
    delegationsSpaceCustomBadges: Array<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    event?: Maybe<EventPublic>;
    form: Form;
    customFieldBySlug: CustomField;
    customFields: CustomFieldsConnection;
};

export type OrganizationPublicDelegationArgs = {
    id: Scalars['DelegationId'];
};

export type OrganizationPublicDelegationsArgs = {
    level: OrganizationEventLevel;
    eventId?: Maybe<Scalars['EventId']>;
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    onlyVisibleInDelegation?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<DelegationsSort>;
};

export type OrganizationPublicEventArgs = {
    id?: Maybe<Scalars['EventId']>;
};

export type OrganizationPublicFormArgs = {
    id: Scalars['FormId'];
};

export type OrganizationPublicCustomFieldBySlugArgs = {
    slug: Scalars['CustomFieldSlug'];
};

export type OrganizationPublicCustomFieldsArgs = {
    variety?: Maybe<CustomFieldVariety>;
    privacy?: Maybe<CustomFieldPrivacy>;
    name?: Maybe<Scalars['String']>;
    fieldTypes?: Maybe<Array<FieldType>>;
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CustomFieldsSort>;
};

export type OrganizationsConnection = {
    nodes: Array<Organization>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type OrganizationSort = {
    attribute: OrganizationsSortAttributes;
    direction: SortDirection;
};

export enum OrganizationsSortAttributes {
    InsertedAt = 'InsertedAt',
    PeriodEndAt = 'PeriodEndAt'
}

export type PageInfo = {
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export enum PdfFormat {
    A4Portrait = 'A4Portrait',
    A4Landscape = 'A4Landscape',
    A5Portrait = 'A5Portrait',
    A6Portrait = 'A6Portrait'
}

export type PecbadgeDocumentOptions = {
    header: Scalars['JSON'];
};

export type PermissionCheck = {
    name: Scalars['String'];
    args: Scalars['JSON'];
};

export type Phone = {
    id: Scalars['PhoneId'];
    country: Scalars['String'];
    code: Scalars['String'];
    number: Scalars['String'];
    internationalFormat: Scalars['String'];
    isValid: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type PhoneInput = {
    country: Scalars['String'];
    code: Scalars['String'];
    number: Scalars['String'];
};

export type Position = IPosition & {
    id: Scalars['PositionId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    event: Event;
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    positionCategoryId: Scalars['PositionsCategoryId'];
    category: PositionsCategory;
    positionCategoryName: Scalars['String'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    privateNoteId: Scalars['TranslationId'];
    privateNote: Scalars['String'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom: Scalars['Int'];
    layer: MapLayer;
    tracesIds: Array<Scalars['TraceId']>;
    traces: Array<Trace>;
    isVisible: Scalars['Boolean'];
    fields: Scalars['JSON'];
    conditionsOperator: ConditionsOperator;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    assignedDuration: Scalars['Duration'];
    assignedResources: Scalars['Int'];
    checkedInRate: Scalars['Int'];
    duration: Scalars['Duration'];
    fillingRate: Scalars['Int'];
    leaders: Array<Leader>;
    links: PositionDocumentLink;
    numberOfSlots: Scalars['Int'];
    positionSlot: PositionsSlot;
    positionsCustomFields: Array<PositionsCustomField>;
    preassignedResources: Scalars['Int'];
    range?: Maybe<Scalars['DatetimeRange']>;
    resources: Scalars['Int'];
    slots: Array<PositionsSlot>;
    state: PositionState;
    tags: Array<Tag>;
    tagsIds: Array<Scalars['TagId']>;
};

export type PositionFieldsArgs = {
    expand?: Maybe<Scalars['Boolean']>;
};

export type PositionPositionSlotArgs = {
    id: Scalars['PositionsSlotId'];
};

export type PositionSlotsArgs = {
    date?: Maybe<Scalars['Date']>;
};

export type PositionDocumentLink = {
    positionPlanningLink: Scalars['String'];
    positionSheetLink: Scalars['String'];
};

export type PositionDocumentOptions = {
    format: PdfFormat;
    showPositionData: Scalars['Boolean'];
    showVolunteersLists: Scalars['Boolean'];
    slugsToDisplay: Array<Scalars['String']>;
};

export type PositionInput = {
    name: Scalars['String'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    description?: Maybe<Scalars['JSON']>;
    privateNote?: Maybe<Scalars['JSON']>;
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom?: Maybe<Scalars['Int']>;
    leadersIds: Array<Scalars['UsersInfoId']>;
    layer?: Maybe<MapLayer>;
    tracesIds: Array<Scalars['TraceId']>;
    slots: Array<PositionsSlotInput>;
    conditionsOperator: ConditionsOperator;
    positionsCustomFields: Array<PositionsCustomFieldInput>;
    fields: Scalars['JSON'];
    tagsIds: Array<Scalars['TagId']>;
};

export enum PositionProperty {
    Acronym = 'Acronym',
    Address = 'Address',
    Category = 'Category',
    Color = 'Color',
    Description = 'Description',
    Icon = 'Icon',
    Latitude = 'Latitude',
    Longitude = 'Longitude',
    Name = 'Name',
    Tag = 'Tag',
    Traces = 'Traces'
}

export type PositionPublic = IPosition & {
    id: Scalars['PositionId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    category: PositionsCategoryPublic;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    acronym?: Maybe<Scalars['String']>;
    color: Scalars['String'];
    icon: Icon;
    address?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    zoom: Scalars['Int'];
    layer: MapLayer;
    tracesIds: Array<Scalars['TraceId']>;
    traces: Array<Trace>;
    isVisible: Scalars['Boolean'];
    fields: Scalars['JSON'];
    slots: Array<PositionsSlotPublic>;
    positionsCustomFields: Array<PositionsCustomField>;
    range?: Maybe<Scalars['DatetimeRange']>;
    leaders: Array<Leader>;
    conditionsOperator: ConditionsOperator;
    tags: Array<Tag>;
    tagsIds: Array<Scalars['TagId']>;
};

export type PositionPublicFieldsArgs = {
    expand?: Maybe<Scalars['Boolean']>;
};

export type PositionPublicSlotsArgs = {
    date?: Maybe<Scalars['Date']>;
};

export type PositionsCategoriesConnection = {
    nodes: Array<PositionsCategory>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type PositionsCategory = IPositionsCategory & {
    id: Scalars['PositionsCategoryId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    assignedResources: Scalars['Int'];
    leaders: Array<Leader>;
    numberOfPositions: Scalars['Int'];
    positions: Array<Position>;
    preassignedResources: Scalars['Int'];
    range?: Maybe<Scalars['DatetimeRange']>;
    resources: Scalars['Int'];
};

export type PositionsCategoryInput = {
    name: Scalars['String'];
};

export type PositionsCategoryPublic = IPositionsCategory & {
    id: Scalars['PositionsCategoryId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
    positions: Array<PositionPublic>;
    leaders: Array<Leader>;
};

export type PositionsConnection = {
    nodes: Array<Position>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type PositionsCustomField = {
    positionId: Scalars['PositionId'];
    customFieldId: Scalars['CustomFieldId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    conditionValue: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    customField: CustomField;
};

export type PositionsCustomFieldInput = {
    customFieldId: Scalars['CustomFieldId'];
    conditionValue: Scalars['JSON'];
};

export type PositionsImportEvent = {
    name: Scalars['String'];
};

export type PositionsImportInput = {
    csv: CsvDocumentInput;
    mappings: Array<Maybe<Scalars['String']>>;
    presets: Array<FormsCustomFieldInput>;
};

export type PositionsImportResult = {
    state: PositionsImportState;
    event?: Maybe<PositionsImportEvent>;
};

export enum PositionsImportState {
    Done = 'Done',
    Waiting = 'Waiting'
}

export type PositionsSegmentStats = {
    assignedResources: Scalars['Int'];
    resources: Scalars['Int'];
};

export type PositionsSlot = IPositionsSlot & {
    id: Scalars['PositionsSlotId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    position: Position;
    positionName: Scalars['String'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    positionCategory: PositionsCategory;
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
    range: Scalars['DatetimeRange'];
    resources: Scalars['Int'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    assignedResources: Scalars['Int'];
    availableVolunteersRegistrations: Array<VolunteersRegistration>;
    checkedInRate: Scalars['Int'];
    fillingRate: Scalars['Int'];
    isFull: Scalars['Boolean'];
    leaders: Array<Leader>;
    nameOrRange: Scalars['String'];
    state: PositionState;
};

export type PositionsSlotAvailableVolunteersRegistrationsArgs = {
    filterAvailabilities: Scalars['Boolean'];
    filterPositions: Scalars['Boolean'];
    applyConditions: Scalars['Boolean'];
    sort?: Maybe<VolunteersRegistrationsSort>;
};

export type PositionsSlotInput = {
    id?: Maybe<Scalars['PositionsSlotId']>;
    name?: Maybe<Scalars['String']>;
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
    resources: Scalars['Int'];
    assignedResources?: Maybe<Scalars['Int']>;
};

export type PositionsSlotPublic = IPositionsSlot & {
    id: Scalars['PositionsSlotId'];
    nameId: Scalars['TranslationId'];
    name: Scalars['String'];
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
    range: Scalars['DatetimeRange'];
    resources: Scalars['Int'];
    isFull: Scalars['Boolean'];
    nameOrRange: Scalars['String'];
};

export type PositionsSlotsConnection = {
    nodes: Array<PositionsSlot>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type PositionsSlotsSort = {
    attribute: Scalars['String'];
    direction: SortDirection;
};

export enum PositionsSlotsSortAttributes {
    Acronym = 'Acronym',
    Category = 'Category',
    CreatedAt = 'CreatedAt',
    Id = 'Id',
    Name = 'Name',
    Position = 'Position',
    Range = 'Range'
}

export type PositionsSlotsUsersInfo = {
    id: Scalars['PositionsSlotsUsersInfoId'];
    positionSlotId: Scalars['PositionsSlotId'];
    positionSlot: PositionsSlotPublic;
    positionCategoryId: Scalars['PositionsCategoryId'];
    positionCategory: PositionsCategory;
    positionId: Scalars['PositionId'];
    position: PositionPublic;
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    userInfo: UsersInfo;
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    checkInAt?: Maybe<Scalars['Datetime']>;
    checkOutAt?: Maybe<Scalars['Datetime']>;
    state: CheckInState;
    timeDifferenceMinutes: Scalars['Int'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    isConfirmationEmailSent: Scalars['Boolean'];
};

export type PositionsSort = {
    attribute: Scalars['String'];
    direction: SortDirection;
};

export enum PositionsSortAttributes {
    Acronym = 'Acronym',
    Category = 'Category',
    CreatedAt = 'CreatedAt',
    Id = 'Id',
    Name = 'Name',
    Slot = 'Slot'
}

export enum PositionState {
    Empty = 'Empty',
    Partial = 'Partial',
    Full = 'Full'
}

export type PossibleDuplicate = {
    id: Scalars['String'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
    usersInfos: Array<UsersInfo>;
};

export type QontoTransaction = {
    id: Scalars['String'];
    label: Scalars['String'];
};

export type Query = {
    awsS3PostCredentials: AwsS3PostCredentials;
    backoffice: Backoffice;
    event: Event;
    eventPublic: EventPublic;
    organization: Organization;
    organizationPublic: OrganizationPublic;
    organizations: Array<Organization>;
    timeZones: Array<Scalars['Timezone']>;
    user: User;
    userExists: Scalars['Boolean'];
    userInfo: UsersInfo;
    userTokens: Tokens;
};

export type QueryAwsS3PostCredentialsArgs = {
    organizationId: Scalars['OrganizationId'];
    acl: Scalars['String'];
};

export type QueryEventArgs = {
    id: Scalars['EventId'];
};

export type QueryEventPublicArgs = {
    id: Scalars['EventId'];
};

export type QueryOrganizationArgs = {
    id: Scalars['OrganizationId'];
};

export type QueryOrganizationPublicArgs = {
    id: Scalars['OrganizationId'];
};

export type QueryUserExistsArgs = {
    email: Scalars['UserEmail'];
};

export type QueryUserInfoArgs = {
    id: Scalars['UsersInfoId'];
};

export type QueryUserTokensArgs = {
    code: Scalars['String'];
};

export enum RegisterAccreditationDisplay {
    Accreditation = 'Accreditation',
    None = 'None'
}

export enum RegisterDaysDisplay {
    AllDays = 'AllDays',
    DaysWithSlots = 'DaysWithSlots'
}

export enum RegisterPositionDisplay {
    Category = 'Category',
    Position = 'Position',
    Slot = 'Slot',
    None = 'None'
}

export enum RegisterPositionFilter {
    FilterWithSlots = 'FilterWithSlots',
    None = 'None'
}

export enum RegisterSlotDisplay {
    Calendar = 'Calendar',
    Custom = 'Custom',
    Display = 'Display',
    DisplayDays = 'DisplayDays',
    Hide = 'Hide'
}

export type Role = {
    id: Scalars['RoleId'];
    organizationId: Scalars['OrganizationId'];
    name: Scalars['String'];
    positionPermissions: Scalars['BitString'];
    type: RoleType;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    editPosition: Scalars['Boolean'];
    descriptionId: Scalars['TranslationId'];
    description: Scalars['String'];
};

export type RoleInput = {
    name: Scalars['String'];
    positionPermissions: Scalars['BitString'];
    descriptionId: Scalars['BigInt'];
};

export enum RoleType {
    Custom = 'Custom',
    EventAdmin = 'EventAdmin',
    EventReader = 'EventReader',
    OrganizationAdmin = 'OrganizationAdmin',
    OrganizationReader = 'OrganizationReader',
    PositionEditor = 'PositionEditor',
    PositionReader = 'PositionReader'
}

export type ScanWebhook = {
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    ticketId: Scalars['String'];
    checklistId: Scalars['BigInt'];
    validatedAt?: Maybe<Scalars['Datetime']>;
    insertedAt: Scalars['Datetime'];
};

export type Segment = {
    id: Scalars['SegmentId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    segmentFolderId: Scalars['SegmentsFolderId'];
    segmentType: SegmentType;
    name: Scalars['String'];
    predicates: Scalars['JSON'];
    columns: Array<Scalars['String']>;
    columnsExport: Array<Scalars['String']>;
    usersInfosIds: Array<Scalars['UsersInfoId']>;
    positionsIds: Array<Scalars['PositionId']>;
    sort?: Maybe<Scalars['JSON']>;
    index: Scalars['Int'];
    count: Scalars['Int'];
    links: SegmentDocumentLink;
    predicatesSlugs: Array<Maybe<Scalars['String']>>;
    needToRecompute: Scalars['Boolean'];
    rendering: Scalars['String'];
    columnsSearch: Array<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    stats?: Maybe<SegmentStats>;
};

export type SegmentDocumentLink = {
    positionsPlanningLink: Scalars['String'];
    positionsVolunteersPlanningLink: Scalars['String'];
    usersListingLink: Scalars['String'];
};

export type SegmentFolderReorderInput = {
    id?: Maybe<Scalars['SegmentsFolderId']>;
    name: Scalars['String'];
    segments: Array<SegmentReorderInput>;
};

export type SegmentInput = {
    columns: Array<Scalars['String']>;
    columnsExport: Array<Scalars['String']>;
    columnsSearch: Array<Scalars['String']>;
    name: Scalars['String'];
    predicates: Scalars['JSON'];
    segmentType: SegmentType;
    sort?: Maybe<Scalars['JSON']>;
    rendering: Scalars['String'];
};

export type SegmentReorderInput = {
    id: Scalars['SegmentId'];
    name: Scalars['String'];
};

export type SegmentsFolder = {
    id: Scalars['SegmentsFolderId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    createdById?: Maybe<Scalars['UserId']>;
    createdBy?: Maybe<UsersInfo>;
    segmentType: SegmentType;
    name: Scalars['String'];
    index: Scalars['Int'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    segments: Array<Segment>;
};

export type SegmentsFolderInput = {
    segmentType: SegmentType;
    name: Scalars['String'];
};

export type SegmentStats = PositionsSegmentStats;

export enum SegmentType {
    Accreditations = 'Accreditations',
    Campaigns = 'Campaigns',
    CustomFields = 'CustomFields',
    Delegations = 'Delegations',
    Events = 'Events',
    Positions = 'Positions',
    Volunteers = 'Volunteers'
}

export type SendAccreditationsEmailsInput = {
    recipients: AccreditationsEmailsRecipients;
    segmentsIds: Array<Scalars['SegmentId']>;
    usersInfosIds: Array<Scalars['UsersInfoId']>;
};

export type SendAssignmentsEmailsInput = {
    recipients: AssignmentsEmailsRecipients;
    segmentsIds: Array<Scalars['SegmentId']>;
    usersInfosIds: Array<Scalars['UsersInfoId']>;
};

export enum Sex {
    Male = 'Male',
    Female = 'Female'
}

export type SkidataConnectionInfo = {
    organizationId: Scalars['OrganizationId'];
    url: Scalars['String'];
    seasonEventsIds: Array<Scalars['EventId']>;
    synchronizedEventsIds: Array<Scalars['EventId']>;
    issuer: Scalars['String'];
    receiver: Scalars['String'];
    place?: Maybe<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type SkidataConnectionInfoInput = {
    url: Scalars['String'];
    seasonEventsIds: Array<Scalars['EventId']>;
    synchronizedEventsIds: Array<Scalars['EventId']>;
    issuer: Scalars['String'];
    receiver: Scalars['String'];
    place?: Maybe<Scalars['String']>;
};

export type SkidataEventInfo = {
    eventId: Scalars['EventId'];
    skidataEventId: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type SkidataEventsTicket = {
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    eventId: Scalars['EventId'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type SkidataTicketInfo = {
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    utid: Scalars['UTID'];
    iv: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type SlotMerged = {
    range: Scalars['DatetimeRange'];
    userInfoId: Scalars['UsersInfoId'];
};

export enum SortDirection {
    Asc = 'Asc',
    Desc = 'Desc'
}

export enum SupportedLanguage {
    De = 'De',
    En = 'En',
    Es = 'Es',
    Fr = 'Fr',
    Keys = 'Keys',
    Nl = 'Nl'
}

export enum SynchronizationState {
    Synchronizing = 'Synchronizing',
    Synchronized = 'Synchronized'
}

export type T24assignmentDocumentOptions = {
    logo: Scalars['JSON'];
};

export type Tag = {
    id: Scalars['TagId'];
    organizationId: Scalars['OrganizationId'];
    name: Scalars['String'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    numberOfTimesUsed: Scalars['Int'];
};

export type TagInput = {
    name: Scalars['String'];
};

export type TagsAccreditation = {
    tagId: Scalars['TagId'];
    accreditationId: Scalars['AccreditationId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    insertedAt: Scalars['Datetime'];
};

export type TagsConnection = {
    nodes: Array<Tag>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type TagsDelegation = {
    tagId: Scalars['TagId'];
    delegationId: Scalars['DelegationId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    insertedAt: Scalars['Datetime'];
};

export type TagsEvent = {
    tagId: Scalars['TagId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    insertedAt: Scalars['Datetime'];
};

export type TagsPosition = {
    tagId: Scalars['TagId'];
    positionId: Scalars['PositionId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    insertedAt: Scalars['Datetime'];
};

export type Team = {
    leaderFormUserInfoId: Scalars['FormsUsersInfoId'];
    leaderUserInfoId: Scalars['UsersInfoId'];
    leaderUserInfo: UsersInfo;
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
    formId: Scalars['FormId'];
    accreditationState?: Maybe<AccreditationState>;
    state?: Maybe<VolunteerRegistrationState>;
    numberOfMembers: Scalars['Int'];
    insertedAt: Scalars['Datetime'];
    accreditationsSlots: Array<AccreditationsSlotPublic>;
    accreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
    members: Array<VolunteersRegistration>;
    positions: Array<PositionPublic>;
    positionsCategories: Array<PositionsCategoryPublic>;
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    positionsIds: Array<Scalars['PositionId']>;
    positionsSlots: Array<PositionsSlotPublic>;
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    slots: Array<ISlot>;
    slotsMerged: Array<SlotMerged>;
};

export type TeamsConnection = {
    nodes: Array<Team>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type TextMessage = {
    id: Scalars['TextMessageId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId?: Maybe<Scalars['CampaignId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfo: UsersInfo;
    state: TextMessageState;
    smsId?: Maybe<Scalars['SmsId']>;
    /** Cost in millims (1/1000 of €) */
    cost: Scalars['Int'];
    replies: Array<Scalars['String']>;
    hasUnreadReplies: Scalars['Boolean'];
    parentId?: Maybe<Scalars['TextMessageId']>;
    content?: Maybe<Scalars['String']>;
    createdById?: Maybe<Scalars['UsersInfoId']>;
    createdBy?: Maybe<UsersInfo>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    children: Array<TextMessage>;
    numberOfReplies: Scalars['Int'];
};

export enum TextMessageReplies {
    WithReplies = 'WithReplies',
    WithUnreadReplies = 'WithUnreadReplies',
    WithoutReplies = 'WithoutReplies'
}

export type TextMessageReply = {
    id: Scalars['TextMessageReplyId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId?: Maybe<Scalars['CampaignId']>;
    userInfoId: Scalars['UsersInfoId'];
    smsId: Scalars['SmsId'];
    receptionDate: Scalars['Datetime'];
    message: Scalars['String'];
    insertedAt: Scalars['Datetime'];
};

export type TextMessagesConnection = {
    nodes: Array<TextMessage>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export enum TextMessageState {
    Delivered = 'Delivered',
    InvalidNumber = 'InvalidNumber',
    Other = 'Other',
    Refused = 'Refused',
    Sent = 'Sent',
    Undelivered = 'Undelivered',
    Waiting = 'Waiting'
}

export type Tokens = {
    idToken: Scalars['String'];
    accessToken: Scalars['String'];
    refreshToken: Scalars['String'];
    email: Scalars['UserEmail'];
};

export type Trace = {
    id: Scalars['TraceId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    name: Scalars['String'];
    color: Scalars['String'];
    points: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type TraceInput = {
    name: Scalars['String'];
    color: Scalars['String'];
    points: Scalars['JSON'];
};

export type Translation = {
    id: Scalars['TranslationId'];
    organizationId: Scalars['OrganizationId'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    eventId?: Maybe<Scalars['EventId']>;
    translationType: TranslationType;
    module: TranslationModule;
    fr?: Maybe<Scalars['JSON']>;
    en?: Maybe<Scalars['JSON']>;
    es?: Maybe<Scalars['JSON']>;
    de?: Maybe<Scalars['JSON']>;
    nl?: Maybe<Scalars['JSON']>;
    isFrTranslated: Scalars['Boolean'];
    isEnTranslated: Scalars['Boolean'];
    isEsTranslated: Scalars['Boolean'];
    isDeTranslated: Scalars['Boolean'];
    isNlTranslated: Scalars['Boolean'];
    frString: Scalars['String'];
    enString: Scalars['String'];
    esString: Scalars['String'];
    deString: Scalars['String'];
    nlString: Scalars['String'];
    allString: Scalars['String'];
    cleanAllString: Scalars['String'];
};

export type TranslationInput = {
    fr?: Maybe<Scalars['JSON']>;
    en?: Maybe<Scalars['JSON']>;
    es?: Maybe<Scalars['JSON']>;
    de?: Maybe<Scalars['JSON']>;
    nl?: Maybe<Scalars['JSON']>;
};

export enum TranslationModule {
    Accreditation = 'Accreditation',
    CustomField = 'CustomField',
    CustomSlot = 'CustomSlot',
    Delegation = 'Delegation',
    Email = 'Email',
    Event = 'Event',
    Form = 'Form',
    Other = 'Other',
    Position = 'Position'
}

export type TranslationsConnection = {
    nodes: Array<Translation>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export enum TranslationStatus {
    NotTranslated = 'NotTranslated',
    Translated = 'Translated',
    TranslatedAndNotTranslated = 'TranslatedAndNotTranslated'
}

export enum TranslationType {
    String = 'String',
    StringArray = 'StringArray',
    RichText = 'RichText'
}

export type User = {
    id: Scalars['UserId'];
    email: Scalars['UserEmail'];
    language: SupportedLanguage;
    emailsBlacklistEventsIds: Array<Maybe<Scalars['BigInt']>>;
    emailsBlacklistOrganizationsIds: Array<Maybe<Scalars['BigInt']>>;
    termsAcceptedAt?: Maybe<Scalars['Datetime']>;
    lastSeenAt?: Maybe<Scalars['Datetime']>;
    deletedAt?: Maybe<Scalars['Datetime']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    hubspotToken?: Maybe<Scalars['String']>;
    permission: Array<Scalars['Boolean']>;
    profile: UserProfile;
    roles: Array<Role>;
    userInfo: UsersInfo;
    usersEvents: Array<UsersEvent>;
    usersInfos: Array<UsersInfo>;
    usersOrganizations: Array<UsersOrganization>;
    usersPositions: Array<UsersPosition>;
    usersPositionsCategories: Array<UsersPositionsCategory>;
    volunteersRegistrations: Array<VolunteersRegistration>;
};

export type UserPermissionArgs = {
    checks: Array<PermissionCheck>;
};

export type UserRolesArgs = {
    organizationId?: Maybe<Scalars['OrganizationId']>;
    eventId?: Maybe<Scalars['EventId']>;
};

export type UserUserInfoArgs = {
    organizationId: Scalars['OrganizationId'];
};

export type UserUsersEventsArgs = {
    organizationId?: Maybe<Scalars['OrganizationId']>;
    eventId?: Maybe<Scalars['EventId']>;
};

export type UserUsersInfosArgs = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
};

export type UserUsersOrganizationsArgs = {
    organizationId?: Maybe<Scalars['OrganizationId']>;
};

export type UserUsersPositionsArgs = {
    organizationId?: Maybe<Scalars['OrganizationId']>;
    eventId?: Maybe<Scalars['EventId']>;
};

export type UserUsersPositionsCategoriesArgs = {
    organizationId?: Maybe<Scalars['OrganizationId']>;
    eventId?: Maybe<Scalars['EventId']>;
};

export type UserVolunteersRegistrationsArgs = {
    eventId: Scalars['EventId'];
};

export type UserAssignmentsDocumentOptions = {
    background: Scalars['JSON'];
    fontFamily: Scalars['String'];
    headerColor: Scalars['String'];
    informationsContent: Scalars['String'];
    informationsTitle: Scalars['String'];
    informationsContent2: Scalars['String'];
    informationsTitle2: Scalars['String'];
    leadersData: Scalars['String'];
    positionCustomFields: Array<Scalars['CustomFieldId']>;
    showInformations: Scalars['Boolean'];
    showInformations2: Scalars['Boolean'];
    showLeaders: Scalars['Boolean'];
    showQrCode: Scalars['Boolean'];
    signature: Scalars['String'];
    subtitleColor: Scalars['String'];
    textColor: Scalars['String'];
    title: Scalars['String'];
    titleColor: Scalars['String'];
    titleFontFamily: Scalars['String'];
};

export type UserInfoAccreditationsInput = {
    formId: Scalars['FormId'];
    accreditationsSlotsIds: Array<Scalars['AccreditationsSlotId']>;
};

export type UserInfoAndTermsInput = {
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['JSON']>;
    termsAccepted: Scalars['Boolean'];
};

export type UserInfoDocumentLink = {
    userAssignmentsLink: Scalars['String'];
    userBadgeLink: Scalars['String'];
    userCertificateLink: Scalars['String'];
    userCustomBadgeLinks: Scalars['JSON'];
    userCustomDocumentLinks: Scalars['JSON'];
    userPlanningLink: Scalars['String'];
    userPlanningDaysLink: Scalars['String'];
    userPlanningListLink: Scalars['String'];
};

export type UserInfoPositionsInput = {
    formId: Scalars['FormId'];
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    positionsIds: Array<Scalars['PositionId']>;
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
};

export type UserInfoSlotsInput = {
    formId: Scalars['FormId'];
    slots: Array<VolunteersRegistrationsSlotInput>;
};

export type UserInput = {
    email: Scalars['String'];
    language: SupportedLanguage;
    termsAcceptedAt?: Maybe<Scalars['Datetime']>;
    emailsBlacklistEventsIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
    emailsBlacklistOrganizationsIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type UserPlanningDaysOptions = {
    logo?: Maybe<Scalars['JSON']>;
};

export type UserPlanningListOptions = {
    showHours: Scalars['Boolean'];
};

export enum UserProfile {
    Admin = 'Admin',
    Leader = 'Leader'
}

export type UsersDelegation = {
    userId: Scalars['UserId'];
    userInfoId: Scalars['UsersInfoId'];
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
    delegationId: Scalars['DelegationId'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type UsersEvent = {
    userId: Scalars['UserId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    roleId: Scalars['RoleId'];
    role: Role;
    insertedAt: Scalars['Datetime'];
};

export type UsersInfo = {
    id: Scalars['UsersInfoId'];
    userId: Scalars['UserId'];
    organizationId: Scalars['OrganizationId'];
    fields: Scalars['JSON'];
    calculatedFields: Scalars['JSON'];
    fieldValue?: Maybe<Scalars['JSON']>;
    fieldRawValue?: Maybe<Scalars['JSON']>;
    name: Scalars['String'];
    email: Scalars['UserEmail'];
    nameOrEmail: Scalars['String'];
    isEditable: Scalars['Boolean'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    deletedAt?: Maybe<Scalars['Datetime']>;
    campaigns: CampaignsConnection;
    canAccess: Scalars['Boolean'];
    comments: Array<Comment>;
    delegations: Array<Delegation>;
    documents: DocumentsConnection;
    emails: EmailsConnection;
    eventsNames: Scalars['String'];
    formUserInfo: FormsUsersInfo;
    formsUsersInfos: Array<FormsUsersInfo>;
    numberOfComments: Scalars['Int'];
    numberOfEvents: Scalars['Int'];
    links?: Maybe<UserInfoDocumentLink>;
    picture?: Maybe<Document>;
    registrationDate: Scalars['Datetime'];
    user: User;
    volunteerRegistration?: Maybe<VolunteersRegistration>;
    volunteersRegistrations: Array<VolunteersRegistration>;
};

export type UsersInfoFieldsArgs = {
    expand?: Maybe<Scalars['Boolean']>;
};

export type UsersInfoFieldValueArgs = {
    slug: Scalars['CustomFieldSlug'];
};

export type UsersInfoFieldRawValueArgs = {
    slug: Scalars['CustomFieldSlug'];
};

export type UsersInfoCampaignsArgs = {
    name?: Maybe<Scalars['String']>;
    campaignType?: Maybe<CampaignType>;
    eventId?: Maybe<Scalars['EventId']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CampaignsSort>;
};

export type UsersInfoCanAccessArgs = {
    formId?: Maybe<Scalars['FormId']>;
};

export type UsersInfoDelegationsArgs = {
    level?: Maybe<OrganizationEventLevel>;
};

export type UsersInfoDocumentsArgs = {
    name?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type UsersInfoEmailsArgs = {
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type UsersInfoFormUserInfoArgs = {
    formId: Scalars['FormId'];
};

export type UsersInfoFormsUsersInfosArgs = {
    level?: Maybe<OrganizationEventLevel>;
    eventId?: Maybe<Scalars['EventId']>;
};

export type UsersInfoLinksArgs = {
    eventId?: Maybe<Scalars['EventId']>;
};

export type UsersInfoRegistrationDateArgs = {
    eventId?: Maybe<Scalars['EventId']>;
};

export type UsersInfoVolunteerRegistrationArgs = {
    eventId?: Maybe<Scalars['EventId']>;
};

export type UsersInfoConnection = {
    nodes: Array<UsersInfo>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type UsersInfoInput = {
    id?: Maybe<Scalars['UsersInfoId']>;
    fields: Scalars['JSON'];
};

export type UsersInfoProfileInput = {
    email: Scalars['String'];
    fields: Scalars['JSON'];
};

export type UsersInfosDelegation = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
    delegationId: Scalars['DelegationId'];
    userInfoId: Scalars['UsersInfoId'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type UsersInfosSort = {
    attribute: Scalars['String'];
    direction: SortDirection;
};

export enum UsersInfosSortAttributes {
    FirstName = 'FirstName',
    Id = 'Id',
    InsertedAt = 'InsertedAt',
    LastName = 'LastName',
    Name = 'Name',
    UpdatedAt = 'UpdatedAt'
}

export type UsersOrganization = {
    userId: Scalars['UserId'];
    organizationId: Scalars['OrganizationId'];
    sendNotifications: Scalars['Boolean'];
    roleId: Scalars['RoleId'];
    role: Role;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    user: User;
};

export type UsersPosition = {
    userId: Scalars['UserId'];
    userInfoId: Scalars['UsersInfoId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    canEdit: Scalars['Boolean'];
    roleId: Scalars['RoleId'];
    role: Role;
    insertedAt: Scalars['Datetime'];
};

export type UsersPositionsCategory = {
    userId: Scalars['UserId'];
    userInfoId: Scalars['UsersInfoId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    canEdit: Scalars['Boolean'];
    roleId: Scalars['RoleId'];
    role: Role;
    insertedAt: Scalars['Datetime'];
};

export enum VolunteerRegistrationState {
    Assigned = 'Assigned',
    NotApplicable = 'NotApplicable',
    PreAssigned = 'PreAssigned',
    Refused = 'Refused',
    WaitingAssignment = 'WaitingAssignment'
}

export type VolunteersImportEvent = {
    name: Scalars['String'];
};

export type VolunteersImportInput = {
    csv: CsvDocumentInput;
    mappings: Array<Maybe<Scalars['String']>>;
    presets: Array<FormsCustomFieldInput>;
    mode: VolunteersImportMode;
};

export enum VolunteersImportMode {
    Create = 'Create',
    CreateUpdateFromEmail = 'CreateUpdateFromEmail',
    UpdateFromId = 'UpdateFromId'
}

export type VolunteersImportResult = {
    state: VolunteersImportState;
    event?: Maybe<VolunteersImportEvent>;
};

export enum VolunteersImportState {
    Done = 'Done',
    Waiting = 'Waiting'
}

export type VolunteersListingDocumentOptions = {
    header: Scalars['JSON'];
    slugsToDisplay: Array<Scalars['String']>;
};

export type VolunteersRegistration = {
    id: Scalars['VolunteersRegistrationId'];
    localId: Scalars['BigInt'];
    ticketId: Scalars['String'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    event: Event;
    state: VolunteerRegistrationState;
    positionsSlotsIds: Array<Scalars['PositionsSlotId']>;
    positionsSlots: Array<PositionsSlot>;
    positionsIds: Array<Scalars['PositionId']>;
    positions: Array<Position>;
    positionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    positionsCategories: Array<PositionsCategory>;
    userInfoId: Scalars['UsersInfoId'];
    userInfo: UsersInfo;
    userId: Scalars['UserId'];
    preassignPositionsIds: Array<Scalars['PositionId']>;
    preassignPositions: Array<Position>;
    preassignPositionsCategoriesIds: Array<Scalars['PositionsCategoryId']>;
    preassignPositionsCategories: Array<PositionsCategory>;
    accreditationState: AccreditationState;
    weezevent: Scalars['JSON'];
    shortTag?: Maybe<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    accreditationsSlots: Array<AccreditationsSlot>;
    accreditationsUsersInfos: Array<AccreditationsUsersInfo>;
    availablePositionsSlots: Array<PositionsSlot>;
    canRegistrationStateUpdate: Scalars['Boolean'];
    delegation?: Maybe<Delegation>;
    isAssignedTo: Scalars['Boolean'];
    isAccreditedTo: Scalars['Boolean'];
    isLeader: Scalars['Boolean'];
    numberOfAssignments: Scalars['Int'];
    numberOfAssignmentsDays: Scalars['Int'];
    numberOfAccreditations: Scalars['Int'];
    numberOfAvailableDays: Scalars['Int'];
    positionSlotUserInfo?: Maybe<PositionsSlotsUsersInfo>;
    positionsSlotsUsersInfos: Array<PositionsSlotsUsersInfo>;
    slots: Array<ISlot>;
    slotsMerged: Array<SlotMerged>;
    teamCode: Scalars['TeamCode'];
    utid?: Maybe<Scalars['UTID']>;
    volunteerMinutes: Scalars['Int'];
    weezeventParticipantId?: Maybe<Scalars['Int']>;
    weezeventParticipantInfo?: Maybe<WeezeventParticipantInfo>;
};

export type VolunteersRegistrationAccreditationsUsersInfosArgs = {
    accreditationId?: Maybe<Scalars['AccreditationId']>;
};

export type VolunteersRegistrationIsAssignedToArgs = {
    positionSlotId: Scalars['PositionsSlotId'];
};

export type VolunteersRegistrationIsAccreditedToArgs = {
    accreditationId: Scalars['AccreditationId'];
};

export type VolunteersRegistrationPositionSlotUserInfoArgs = {
    positionSlotId: Scalars['PositionsSlotId'];
};

export type VolunteersRegistrationPositionsSlotsUsersInfosArgs = {
    positionId?: Maybe<Scalars['PositionId']>;
};

export type VolunteersRegistrationInput = {
    positionsCategoriesIds: Array<Maybe<Scalars['PositionsCategoryId']>>;
    positionsIds: Array<Maybe<Scalars['PositionId']>>;
    positionsSlotsIds: Array<Maybe<Scalars['PositionsSlotId']>>;
    userInfo: UsersInfoInput;
    slots: Array<VolunteersRegistrationsSlotInput>;
    delegationId?: Maybe<Scalars['DelegationId']>;
};

export type VolunteersRegistrationsConnection = {
    nodes: Array<VolunteersRegistration>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type VolunteersRegistrationsSlot = ISlot & {
    id: Scalars['VolunteersRegistrationsSlotId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
    range: Scalars['DatetimeRange'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type VolunteersRegistrationsSlotInput = {
    startDate: Scalars['Date'];
    startTime: Scalars['Time'];
    endDate: Scalars['Date'];
    endTime: Scalars['Time'];
};

export type VolunteersRegistrationsSort = {
    attribute: Scalars['String'];
    direction: SortDirection;
};

export enum VolunteersRegistrationsSortAttributes {
    AccreditationState = 'AccreditationState',
    FirstName = 'FirstName',
    Id = 'Id',
    InsertedAt = 'InsertedAt',
    LastName = 'LastName',
    Name = 'Name',
    State = 'State',
    TeamCode = 'TeamCode',
    UpdatedAt = 'UpdatedAt'
}

export type WeezeventConnectionInfo = {
    eventId: Scalars['EventId'];
    organizationId: Scalars['OrganizationId'];
    apiKey: Scalars['String'];
    email: Scalars['String'];
    password: Scalars['String'];
    accessToken: Scalars['String'];
    idEvent: Scalars['String'];
    event: Scalars['String'];
    idTicket: Scalars['String'];
    ticket: Scalars['String'];
    synchronizationState: SynchronizationState;
    synchronizationDirection: WeezeventSynchronizationDirection;
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type WeezeventConnectionInfoInput = {
    apiKey: Scalars['String'];
    email: Scalars['String'];
    password: Scalars['String'];
    idEvent: Scalars['String'];
    idTicket: Scalars['String'];
};

export type WeezeventEvent = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type WeezeventField = {
    id: Scalars['String'];
    name: Scalars['String'];
    weezeventFieldType: Scalars['String'];
    choices?: Maybe<Array<WeezeventSelectChoice>>;
};

export type WeezeventParticipantInfo = {
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    idBarcode: Scalars['String'];
    idParticipant: Scalars['String'];
    identificationNumber?: Maybe<Scalars['String']>;
    idTicket: Scalars['String'];
    ticket: Scalars['String'];
    form: Scalars['JSON'];
    synchronizationState: SynchronizationState;
    participant: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type WeezeventRule = {
    id: Scalars['WeezeventRuleId'];
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    name: Scalars['String'];
    segmentId: Scalars['SegmentId'];
    segment: Segment;
    idQuestion: Scalars['String'];
    field?: Maybe<WeezeventField>;
    value: Scalars['JSON'];
    insertedAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type WeezeventRuleInput = {
    name: Scalars['String'];
    segmentId: Scalars['SegmentId'];
    idQuestion: Scalars['String'];
    value: Scalars['JSON'];
};

export type WeezeventRulesConnection = {
    nodes: Array<WeezeventRule>;
    pageInfo: PageInfo;
    numberOfPages: Scalars['Int'];
    totalCount: Scalars['Int'];
};

export type WeezeventSelectChoice = {
    id: Scalars['String'];
    label: Scalars['String'];
};

export enum WeezeventSynchronizationDirection {
    Both = 'Both',
    Pull = 'Pull',
    Push = 'Push'
}

export type WeezeventTicket = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type WeezeventWebhook = {
    id: Scalars['WeezeventWebhookId'];
    data: Scalars['JSON'];
    type?: Maybe<Scalars['String']>;
    ticketId?: Maybe<Scalars['String']>;
    checklistId?: Maybe<Scalars['BigInt']>;
    shortTag?: Maybe<Scalars['String']>;
    insertedAt: Scalars['Datetime'];
};

export type AccreditationCreateMutationVariables = {
    eventId: Scalars['EventId'];
    accreditation: AccreditationInput;
};

export type AccreditationCreateMutation = { accreditationCreate: Pick<Accreditation, 'id'> };

export type AccreditationEventInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type AccreditationEventInfosQuery = {
    organization: {
        accreditationCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
    } & CreatePositionCustomFieldsFragment &
        CreatePositionTagsFragment;
    event: CreateAccreditationEventFragment;
};

export type AccreditationToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
};

export type AccreditationToEditQuery = {
    organization: {
        accreditationCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
    } & CreatePositionCustomFieldsFragment &
        CreatePositionTagsFragment;
    event: {
        accreditation: Pick<
            Accreditation,
            | 'name'
            | 'accreditationCategoryId'
            | 'description'
            | 'privateNote'
            | 'acronym'
            | 'color'
            | 'icon'
            | 'weezeventIsSynchronized'
            | 'willAutoAccredit'
            | 'address'
            | 'latitude'
            | 'longitude'
            | 'zoom'
            | 'layer'
            | 'tagsIds'
            | 'accreditationDisplay'
            | 'conditionsOperator'
            | 'fields'
        > & {
            accreditationsCustomFields: Array<
                Pick<AccreditationsCustomField, 'customFieldId' | 'conditionValue'>
            >;
            slots: Array<
                Pick<
                    AccreditationsSlot,
                    'id' | 'name' | 'date' | 'maxResources' | 'assignedResources'
                >
            >;
        };
    } & CreateAccreditationEventFragment;
};

export type AccreditationUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
    accreditation: AccreditationInput;
};

export type AccreditationUpdateMutation = { accreditationUpdate: Pick<Accreditation, 'id'> };

export type AccreditationsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<AccreditationsSort>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
};

export type AccreditationsQuery = {
    data: {
        rows: Pick<AccreditationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<AccreditationsAccreditationFragment>;
        };
    };
};

export type AccreditationsAccreditationQueryVariables = {
    eventId: Scalars['EventId'];
    id: Scalars['AccreditationId'];
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
};

export type AccreditationsAccreditationQuery = {
    data: { row: AccreditationsAccreditationFragment };
};

export type AccreditationsCategoriesQueryVariables = {
    eventId: Scalars['EventId'];
};

export type AccreditationsCategoriesQuery = {
    event: { accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>> };
};

export type AccreditationsMassDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    selecteds: MassActionSelectedsInput;
};

export type AccreditationsMassDeleteMutation = Pick<Mutation, 'accreditationsMassDelete'>;

export type AccreditationsSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type AccreditationsSegmentsQuery = {
    organization: Pick<Organization, 'weezeventOrganizationId'> & {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
    };
    event: Pick<Event, 'organizationId' | 'numberOfAccreditations' | 'isWeezeventConnected'> & {
        accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>>;
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type AccreditationMapQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
};

export type AccreditationMapQuery = {
    eventPublic: {
        accreditation: Pick<AccreditationPublic, 'layer' | 'latitude' | 'longitude' | 'zoom'>;
    };
};

export type AccreditationQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
};

export type AccreditationQuery = {
    event: {
        accreditation: Pick<
            Accreditation,
            'id' | 'name' | 'assignedResources' | 'hasSlots' | 'acronym'
        >;
    };
};

export type AccreditationChartQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
};

export type AccreditationChartQuery = {
    event: Pick<Event, 'weezevent'> & { accreditation: AccreditationChartFragment };
};

export type AccreditationSlotDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
};

export type AccreditationSlotDeleteMutation = {
    accreditationSlotDelete: Pick<AccreditationsSlot, 'id'>;
};

export type AccreditationSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
    name?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['Date']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<AccreditationsSlotsSort>;
};

export type AccreditationSlotsQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> & {
        accreditationsSlots: Pick<AccreditationsSlotsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<
                Pick<
                    AccreditationsSlot,
                    | 'id'
                    | 'name'
                    | 'isHidden'
                    | 'date'
                    | 'maxResources'
                    | 'assignedResources'
                    | 'wishedResources'
                >
            >;
        };
    };
};

export type AccreditationDashboardQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
};

export type AccreditationDashboardQuery = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: {
        accreditation: Pick<
            Accreditation,
            | 'id'
            | 'name'
            | 'description'
            | 'privateNote'
            | 'acronym'
            | 'icon'
            | 'color'
            | 'address'
            | 'latitude'
            | 'longitude'
            | 'layer'
            | 'zoom'
            | 'assignedResources'
            | 'maxResources'
            | 'hasSlots'
            | 'hiddenSlotId'
            | 'fields'
        > & {
            accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
            accreditationsCustomFields: Array<
                Pick<AccreditationsCustomField, 'conditionValue'> & {
                    customField: Pick<CustomField, 'name' | 'fieldType'> & {
                        values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
                    };
                }
            >;
            tags: Array<Pick<Tag, 'id' | 'name'>>;
        };
    };
};

export type AccreditInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type AccreditInfosQuery = {
    organization: {
        tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> };
        userInfo: FormsUsersInfosWishedFragment;
    };
    event: Pick<Event, 'startAt' | 'endAt'> & {
        formsCustomsFields: Array<CustomFieldWithConditionFragment>;
        accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>>;
        accreditations: { nodes: Array<Pick<Accreditation, 'id' | 'name'>> };
        segments: Array<Pick<Segment, 'id' | 'name'>>;
        volunteerRegistration: Pick<
            VolunteersRegistration,
            'id' | 'userInfoId' | 'accreditationState' | 'insertedAt'
        > & {
            accreditationsSlots: Array<
                Pick<AccreditationsSlot, 'id' | 'maxResources' | 'assignedResources'>
            >;
            accreditationsUsersInfos: Array<Pick<AccreditationsUsersInfo, 'accreditationSlotId'>>;
            delegation?: Maybe<DelegationAccreditationsSlotsFragment>;
            userInfo: Pick<UsersInfo, 'id' | 'name' | 'email' | 'fields'> & {
                picture?: Maybe<Pick<Document, 'url'>>;
            };
        };
    };
};

export type AccreditSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    accreditationCategoriesIds?: Maybe<Array<Scalars['AccreditationsCategoryId']>>;
    accreditationsIds?: Maybe<Array<Scalars['AccreditationId']>>;
    date?: Maybe<Scalars['Date']>;
    tagsIds?: Maybe<Array<Scalars['TagId']>>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    excludeFull?: Maybe<Scalars['Boolean']>;
};

export type AccreditSlotsQuery = {
    event: { accreditationsSlots: { nodes: Array<AccreditationsSlotFragment> } };
};

export type AccreditationsAssignmentsEmailsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    state?: Maybe<EmailState>;
    offset?: Maybe<Scalars['Int']>;
};

export type AccreditationsAssignmentsEmailsQuery = { event: { emails: EmailsRecipientsFragment } };

export type AccreditationsAssignmentsKpisQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    direction: SortDirection;
};

export type AccreditationsAssignmentsKpisQuery = {
    organization: {
        emailTemplate?: Maybe<{ emailSender?: Maybe<Pick<EmailsSender, 'isValidToSendEmail'>> }>;
    };
    event: {
        emailsStats: Pick<
            EmailsStats,
            'numberOfOpen' | 'numberOfClick' | 'numberOfSent' | 'numberOfWaiting'
        >;
        waitingAccreditation: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<Pick<VolunteersRegistration, 'userInfoId'>>;
        };
    };
};

export type AccreditationsEmailsEstimateMutationVariables = {
    eventId: Scalars['EventId'];
    sendAccreditationsEmails: SendAccreditationsEmailsInput;
};

export type AccreditationsEmailsEstimateMutation = Pick<
    Mutation,
    'eventAccreditationsEmailsEstimate'
>;

export type SendAccreditationsEmailsMutationVariables = {
    eventId: Scalars['EventId'];
    sendAccreditationsEmails: SendAccreditationsEmailsInput;
};

export type SendAccreditationsEmailsMutation = Pick<Mutation, 'eventAccreditationsEmailsSend'>;

export type AccreditationCategoryCreateMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationCategory: AccreditationsCategoryInput;
};

export type AccreditationCategoryCreateMutation = {
    accreditationCategoryCreate: Pick<AccreditationsCategory, 'id' | 'name'>;
};

export type AccreditationCategoryUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
    accreditationCategory: AccreditationsCategoryInput;
};

export type AccreditationCategoryUpdateMutation = {
    accreditationCategoryUpdate: Pick<AccreditationsCategory, 'id' | 'name'>;
};

export type AccreditationCategoryDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
};

export type AccreditationCategoryDeleteMutation = {
    accreditationCategoryDelete: Pick<AccreditationsCategory, 'id'>;
};

export type AccreditationCategoryDuplicateMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationCategoryId: Scalars['AccreditationsCategoryId'];
};

export type AccreditationCategoryDuplicateMutation = {
    accreditationCategoryDuplicate: Pick<AccreditationsCategory, 'id'>;
};

export type AccreditationsCategoryQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationsCategoryId: Scalars['AccreditationsCategoryId'];
};

export type AccreditationsCategoryQuery = {
    event: {
        accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name' | 'assignedResources'>;
    };
};

export type AccreditationsCategoryAccreditationsQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationsCategoryId?: Maybe<Scalars['AccreditationsCategoryId']>;
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<AccreditationsSort>;
};

export type AccreditationsCategoryAccreditationsQuery = {
    event: {
        accreditations: Pick<AccreditationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<
                Pick<Accreditation, 'id' | 'name' | 'accreditationDisplay' | 'numberOfSlots'>
            >;
        };
    };
};

export type AccreditationsCategoryChartQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationsCategoryId: Scalars['AccreditationsCategoryId'];
};

export type AccreditationsCategoryChartQuery = {
    event: Pick<Event, 'weezevent'> & {
        accreditations: Pick<AccreditationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<AccreditationChartFragment>;
        };
    };
};

export type AccreditationSlotUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    accreditationSlot: AccreditationsSlotInput;
};

export type AccreditationSlotUpdateMutation = {
    accreditationSlotUpdate: Pick<AccreditationsSlot, 'id'>;
};

export type AccreditationsAccreditationSlotQueryVariables = {
    eventId: Scalars['EventId'];
    id: Scalars['AccreditationsSlotId'];
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
};

export type AccreditationsAccreditationSlotQuery = {
    event: { accreditationSlot: AccreditationsAccreditationSlotFragment };
};

export type AccreditationsSlotsListQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<AccreditationsSlotsSort>;
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
};

export type AccreditationsSlotsListQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> & {
        accreditationsSlots: Pick<AccreditationsSlotsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<AccreditationsAccreditationSlotFragment>;
        };
    };
};

export type AccreditationsSlotQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    accreditationsSlotId: Scalars['AccreditationsSlotId'];
};

export type AccreditationsSlotQuery = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: {
        accreditationSlot: Pick<
            AccreditationsSlot,
            'id' | 'name' | 'assignedResources' | 'maxResources'
        > & {
            accreditation: Pick<
                Accreditation,
                | 'name'
                | 'description'
                | 'privateNote'
                | 'acronym'
                | 'icon'
                | 'color'
                | 'address'
                | 'latitude'
                | 'longitude'
                | 'layer'
                | 'zoom'
                | 'accreditationCategoryId'
                | 'accreditationCategoryName'
                | 'fields'
            > & {
                accreditationsCustomFields: Array<
                    Pick<AccreditationsCustomField, 'conditionValue'> & {
                        customField: Pick<CustomField, 'name' | 'fieldType'> & {
                            values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
                        };
                    }
                >;
                tags: Array<Pick<Tag, 'id' | 'name'>>;
            };
        };
    };
};

export type AccreditationsSlotMembersQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationsSlotId?: Maybe<Scalars['AccreditationsSlotId']>;
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<VolunteersRegistrationsSort>;
};

export type AccreditationsSlotMembersQuery = {
    event: {
        volunteersRegistrations: Pick<
            VolunteersRegistrationsConnection,
            'numberOfPages' | 'totalCount'
        > & { nodes: Array<VolunteerInfosFragment> };
    };
};

export type AssignmentInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type AssignmentInfosQuery = {
    organization: {
        tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> };
        userInfo: FormsUsersInfosWishedFragment;
    };
    event: Pick<Event, 'startAt' | 'endAt'> & {
        formsCustomsFields: Array<CustomFieldWithConditionFragment>;
        positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
        positions: { nodes: Array<Pick<Position, 'id' | 'name'>> };
        segments: Array<Pick<Segment, 'id' | 'name'>>;
        volunteerRegistration: Pick<
            VolunteersRegistration,
            | 'id'
            | 'userInfoId'
            | 'state'
            | 'preassignPositionsIds'
            | 'preassignPositionsCategoriesIds'
            | 'insertedAt'
        > & {
            userInfo: Pick<UsersInfo, 'id' | 'name' | 'email' | 'fields'> & {
                picture?: Maybe<Pick<Document, 'url'>>;
            };
            positionsCategories: Array<Pick<PositionsCategory, 'id'>>;
            positions: Array<Pick<Position, 'id'>>;
            positionsSlots: Array<Pick<PositionsSlot, 'id'>>;
            slots: Array<Pick<SlotMerged, 'range'>>;
            positionsSlotsUsersInfos: Array<Pick<PositionsSlotsUsersInfo, 'positionSlotId'>>;
        };
    };
};

export type AssignmentSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    positionsCategoriesIds?: Maybe<Array<Scalars['PositionsCategoryId']>>;
    positionsIds?: Maybe<Array<Scalars['PositionId']>>;
    date?: Maybe<Scalars['Date']>;
    tagsIds?: Maybe<Array<Scalars['TagId']>>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    excludeFull?: Maybe<Scalars['Boolean']>;
};

export type AssignmentSlotsQuery = {
    event: {
        positionsSlots: {
            nodes: Array<
                Pick<PositionsSlot, 'id' | 'name' | 'range' | 'assignedResources' | 'resources'> & {
                    positionCategory: Pick<PositionsCategory, 'id' | 'name' | 'resources'>;
                    position: Pick<
                        Position,
                        | 'id'
                        | 'name'
                        | 'color'
                        | 'icon'
                        | 'assignedResources'
                        | 'resources'
                        | 'range'
                    > &
                        PositionPositionsCustomFieldsFragment;
                }
            >;
        };
    };
};

export type VolunteerRegistrationAssignmentRefuseMutationVariables = {
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
};

export type VolunteerRegistrationAssignmentRefuseMutation = {
    volunteerRegistrationAssignmentRefuse: Pick<VolunteersRegistration, 'id'>;
};

export type AssignmentsEmailsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    state?: Maybe<EmailState>;
    offset?: Maybe<Scalars['Int']>;
};

export type AssignmentsEmailsQuery = { event: { emails: EmailsRecipientsFragment } };

export type AssignmentsEmailsEstimateMutationVariables = {
    eventId: Scalars['EventId'];
    sendAssignmentsEmails: SendAssignmentsEmailsInput;
};

export type AssignmentsEmailsEstimateMutation = Pick<Mutation, 'eventAssignmentsEmailsEstimate'>;

export type AssignmentsKpisQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    direction: SortDirection;
};

export type AssignmentsKpisQuery = {
    organization: {
        emailTemplate?: Maybe<{ emailSender?: Maybe<Pick<EmailsSender, 'isValidToSendEmail'>> }>;
    };
    event: Pick<Event, 'hasFormWithTeamsAllowed'> & {
        emailsStats: Pick<
            EmailsStats,
            'numberOfClick' | 'numberOfOpen' | 'numberOfSent' | 'numberOfWaiting'
        >;
        waitingAssignment: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<Pick<VolunteersRegistration, 'userInfoId'>>;
        };
        assigned: Pick<VolunteersRegistrationsConnection, 'totalCount'>;
        waitingTeams: Pick<TeamsConnection, 'totalCount'> & {
            nodes: Array<Pick<Team, 'teamCode'>>;
        };
    };
};

export type PreAssignmentsKpisQueryVariables = {
    eventId: Scalars['EventId'];
    direction: SortDirection;
};

export type PreAssignmentsKpisQuery = {
    event: {
        preAssigneds: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<Pick<VolunteersRegistration, 'userInfoId'>>;
        };
    };
};

export type SendAssignmentsEmailsMutationVariables = {
    eventId: Scalars['EventId'];
    sendAssignmentsEmails: SendAssignmentsEmailsInput;
};

export type SendAssignmentsEmailsMutation = Pick<Mutation, 'eventAssignmentsEmailsSend'>;

export type SendEmailInfosQueryVariables = {
    eventId: Scalars['EventId'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
    name?: Maybe<Scalars['String']>;
};

export type SendEmailInfosQuery = {
    event: {
        segments: Array<Pick<Segment, 'id' | 'name'>>;
        volunteersRegistrations: {
            nodes: Array<
                Pick<VolunteersRegistration, 'id'> & {
                    userInfo: Pick<UsersInfo, 'id' | 'nameOrEmail'>;
                }
            >;
        };
        selectedVolunteersRegistrations: {
            nodes: Array<
                Pick<VolunteersRegistration, 'id'> & {
                    userInfo: Pick<UsersInfo, 'id' | 'nameOrEmail'>;
                }
            >;
        };
    };
};

export type TeamAssignMutationVariables = {
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
    assignment: AssignmentInput;
};

export type TeamAssignMutation = Pick<Mutation, 'teamAssign'>;

export type TeamAssignmentInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
};

export type TeamAssignmentInfosQuery = {
    organization: { tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> } };
    event: Pick<Event, 'startAt' | 'endAt'> & {
        formsCustomsFields: Array<CustomFieldWithConditionFragment>;
        positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
        positions: { nodes: Array<Pick<Position, 'id' | 'name'>> };
        segments: Array<Pick<Segment, 'id' | 'name'>>;
        team?: Maybe<
            Pick<
                Team,
                | 'teamCode'
                | 'formId'
                | 'positionsCategoriesIds'
                | 'positionsIds'
                | 'positionsSlotsIds'
            > & {
                leaderUserInfo: Pick<UsersInfo, 'name' | 'fields'> & FormsUsersInfosWishedFragment;
                members: Array<
                    Pick<VolunteersRegistration, 'id'> & {
                        userInfo: Pick<UsersInfo, 'email' | 'nameOrEmail' | 'fields'>;
                    }
                >;
                slots: Array<Pick<SlotMerged, 'range'>>;
            }
        >;
    };
};

export type TeamAssignmentRefuseMutationVariables = {
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
};

export type TeamAssignmentRefuseMutation = Pick<Mutation, 'teamAssignmentRefuse'>;

export type TeamAssignmentWaitingTeamsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type TeamAssignmentWaitingTeamsQuery = {
    event: {
        teams: Pick<TeamsConnection, 'totalCount'> & {
            nodes: Array<
                Pick<Team, 'teamCode'> & {
                    members: Array<VolunteerRegistrationWaitingAssignmentFragment>;
                }
            >;
        };
    };
};

export type TokensQueryVariables = {
    code: Scalars['String'];
};

export type TokensQuery = {
    userTokens: Pick<Tokens, 'accessToken' | 'idToken' | 'refreshToken' | 'email'>;
};

export type BackofficeInvoiceMarkAsPaidMutationVariables = {
    invoiceId: Scalars['InvoiceId'];
    transactionId: Scalars['String'];
};

export type BackofficeInvoiceMarkAsPaidMutation = Pick<Mutation, 'backofficeInvoiceMarkAsPaid'>;

export type BackofficeInvoicesQueryVariables = {
    name?: Maybe<Scalars['String']>;
    states?: Maybe<Array<InvoiceState>>;
    offset?: Maybe<Scalars['Int']>;
};

export type BackofficeInvoicesQuery = {
    backoffice: {
        invoices: Pick<InvoicesConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<
                Pick<
                    Invoice,
                    | 'id'
                    | 'invoiceNumber'
                    | 'amountCents'
                    | 'smsAmountCents'
                    | 'paidAt'
                    | 'url'
                    | 'organizationsNames'
                    | 'dealId'
                    | 'isRefunded'
                    | 'insertedAt'
                    | 'infos'
                >
            >;
        };
    };
};

export type QontoTransactionsQueryVariables = {};

export type QontoTransactionsQuery = {
    backoffice: { qontoTransactions: Array<Pick<QontoTransaction, 'id' | 'label'>> };
};

export type BackofficeOrganizationUserAddMutationVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type BackofficeOrganizationUserAddMutation = Pick<Mutation, 'backofficeOrganizationUserAdd'>;

export type BackofficeOrganizationUserRemoveMutationVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type BackofficeOrganizationUserRemoveMutation = Pick<
    Mutation,
    'backofficeOrganizationUserRemove'
>;

export type BackofficeOrganizationsQueryVariables = {
    name?: Maybe<Scalars['String']>;
    features?: Maybe<Array<Feature>>;
    isAdmin?: Maybe<Scalars['Boolean']>;
    isActive?: Maybe<Scalars['Boolean']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<OrganizationSort>;
};

export type BackofficeOrganizationsQuery = {
    backoffice: {
        organizations: Pick<OrganizationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<
                Pick<
                    Organization,
                    'id' | 'name' | 'periodEndAt' | 'features' | 'numberOfEvents' | 'insertedAt'
                > & { users: Array<Pick<User, 'id'>> }
            >;
        };
    };
};

export type BackofficeEventUnarchiveMutationVariables = {
    eventId: Scalars['EventId'];
};

export type BackofficeEventUnarchiveMutation = Pick<Mutation, 'backofficeEventUnarchive'>;

export type BackofficeEventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    states?: Maybe<Array<EventState>>;
    sort?: Maybe<EventsSort>;
    offset?: Maybe<Scalars['Int']>;
};

export type BackofficeEventQuery = {
    backoffice: {
        events: Pick<EventsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<Pick<Event, 'id' | 'name' | 'state'>>;
        };
    };
};

export type BackofficeOrganizationQueryVariables = {
    id: Scalars['OrganizationId'];
};

export type BackofficeOrganizationQuery = {
    backoffice: {
        organization: Pick<
            Organization,
            | 'id'
            | 'name'
            | 'periodEndAt'
            | 'features'
            | 'customBadges'
            | 'isBlocked'
            | 'weezeventOrganizationId'
            | 'membersLimit'
        > & { users: Array<Pick<User, 'id' | 'email'>> };
    };
};

export type BackofficeOrganizationUpdateMutationVariables = {
    id: Scalars['OrganizationId'];
    organization: BackofficeOrganizationInput;
};

export type BackofficeOrganizationUpdateMutation = {
    backofficeOrganizationUpdate: Pick<Organization, 'id'>;
};

export type CampaignCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaign: CampaignInput;
};

export type CampaignCreateMutation = { campaignCreate: Pick<Campaign, 'id'> };

export type CampaignEstimateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaign: CampaignInput;
};

export type CampaignEstimateMutation = {
    campaignEstimate: Pick<CampaignEstimate, 'numberOfRecipients' | 'price'>;
};

export type CampaignOrganizationToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    campaignId: Scalars['CampaignId'];
};

export type CampaignOrganizationToEditQuery = {
    organization: Pick<Organization, 'adminEmail' | 'emailSenderId' | 'timeZone'> & {
        campaign: CampaignEditFragment;
        emailsSenders: Array<CreateCampaignEmailSenderFragment>;
    };
};

export type CampaignToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    campaignId: Scalars['CampaignId'];
};

export type CampaignToEditQuery = {
    organization: { emailsSenders: Array<CreateCampaignEmailSenderFragment> };
    event: Pick<Event, 'timeZone'> & {
        campaign: CampaignEditFragment;
        emailSender?: Maybe<Pick<EmailsSender, 'id'>>;
    };
};

export type CampaignUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
    campaign: CampaignInput;
};

export type CampaignUpdateMutation = { campaignUpdate: Pick<Campaign, 'id'> };

export type CreateCampaignInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type CreateCampaignInfosQuery = {
    organization: { emailsSenders: Array<CreateCampaignEmailSenderFragment> };
    event: Pick<Event, 'timeZone'> & { emailSender?: Maybe<Pick<EmailsSender, 'id'>> };
};

export type CreateOrganizationCampaignInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type CreateOrganizationCampaignInfosQuery = {
    organization: Pick<Organization, 'adminEmail' | 'emailSenderId' | 'timeZone'> & {
        emailsSenders: Array<CreateCampaignEmailSenderFragment>;
    };
};

export type CampaignEventAccreditationsRecipientsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type CampaignEventAccreditationsRecipientsQuery = {
    event: {
        accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>>;
        accreditations: {
            nodes: Array<
                Pick<Accreditation, 'id' | 'name' | 'accreditationCategoryName'> & {
                    slots: Array<Pick<AccreditationsSlot, 'id' | 'name' | 'date'>>;
                }
            >;
        };
    };
};

export type CampaignEventPositionsRecipientsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type CampaignEventPositionsRecipientsQuery = {
    organization: { tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> } };
    event: {
        positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
        positions: {
            nodes: Array<
                Pick<Position, 'id' | 'name' | 'positionCategoryName'> & {
                    slots: Array<Pick<PositionsSlot, 'id' | 'name' | 'range'>>;
                }
            >;
        };
    };
};

export type CampaignEventSegmentsRecipientsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type CampaignEventSegmentsRecipientsQuery = {
    event: {
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name'> & { segments: Array<Pick<Segment, 'id' | 'name'>> }
        >;
    };
};

export type CampaignEventUsersRecipientsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type CampaignEventUsersRecipientsQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<{ userInfo: Pick<UsersInfo, 'id' | 'nameOrEmail'> }>;
        };
    };
};

export type CampaignOrganizationSegmentsRecipientsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type CampaignOrganizationSegmentsRecipientsQuery = {
    organization: {
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name'> & { segments: Array<Pick<Segment, 'id' | 'name'>> }
        >;
    };
};

export type CampaignOrganizationUsersRecipientsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type CampaignOrganizationUsersRecipientsQuery = {
    organization: { usersInfos: { nodes: Array<Pick<UsersInfo, 'id' | 'nameOrEmail'>> } };
};

export type CampaignDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
};

export type CampaignDeleteMutation = { campaignDelete: Pick<Campaign, 'id'> };

export type CampaignDuplicateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
};

export type CampaignDuplicateMutation = { campaignDuplicate: Pick<Campaign, 'id'> };

export type CampaignSendMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    campaignId: Scalars['CampaignId'];
};

export type CampaignSendMutation = { campaignSend: Pick<Campaign, 'id'> };

export type CampaignsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    state?: Maybe<CampaignState>;
    campaignType?: Maybe<CampaignType>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CampaignsSort>;
};

export type CampaignsQuery = {
    event: Pick<Event, 'campaignsCost'> & {
        campaigns: Pick<CampaignsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<CampaignListFragment>;
        };
        doneCampaigns: Pick<CampaignsConnection, 'totalCount'>;
    };
};

export type CampaignQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    campaignId: Scalars['CampaignId'];
};

export type CampaignQuery = {
    organization: {
        campaign: Pick<
            Campaign,
            | 'name'
            | 'state'
            | 'campaignType'
            | 'text'
            | 'subject'
            | 'numberOfRecipients'
            | 'numberOfDelivery'
            | 'numberOfOpen'
            | 'numberOfClick'
            | 'cost'
            | 'sentAt'
            | 'preview'
            | 'sendToEveryone'
            | 'states'
            | 'attachments'
        > & {
            createdBy?: Maybe<
                Pick<UsersInfo, 'name' | 'email'> & { picture?: Maybe<Pick<Document, 'url'>> }
            >;
            documents: Array<DocumentEmailPreviewFragment>;
            emailSender?: Maybe<Pick<EmailsSender, 'fromEmail' | 'fromName'>>;
            usersInfos: Array<Pick<UsersInfo, 'id' | 'nameOrEmail'>>;
            segments: Array<Pick<Segment, 'id' | 'name'>>;
            positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
            positions: Array<Pick<Position, 'id' | 'name'>>;
            positionsSlots: Array<
                Pick<PositionsSlot, 'id' | 'nameOrRange'> & {
                    position: Pick<Position, 'id' | 'name'>;
                }
            >;
            tags: Array<Pick<Tag, 'id' | 'name'>>;
            accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>>;
            accreditations: Array<Pick<Accreditation, 'id' | 'name'>>;
            accreditationsSlots: Array<
                Pick<AccreditationsSlot, 'id' | 'name'> & {
                    accreditation: Pick<Accreditation, 'id' | 'name'>;
                }
            >;
        };
    };
};

export type CampaignEmailsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    campaignId: Scalars['CampaignId'];
    name?: Maybe<Scalars['String']>;
    state?: Maybe<EmailState>;
    offset?: Maybe<Scalars['Int']>;
};

export type CampaignEmailsQuery = {
    organization: { campaign: { emails: EmailsRecipientsFragment } };
};

export type CampaignTextMessagesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    campaignId: Scalars['CampaignId'];
    name?: Maybe<Scalars['String']>;
    state?: Maybe<TextMessageState>;
    replies?: Maybe<TextMessageReplies>;
    offset?: Maybe<Scalars['Int']>;
};

export type CampaignTextMessagesQuery = {
    organization: {
        campaign: {
            createdBy?: Maybe<CreatedByFragment>;
            textMessages: Pick<TextMessagesConnection, 'numberOfPages' | 'totalCount'> & {
                nodes: Array<
                    Pick<TextMessage, 'id' | 'state' | 'numberOfReplies'> & {
                        sentAt: TextMessage['insertedAt'];
                    } & { userInfo: CampaignUserInfoFragment }
                >;
            };
        };
    };
};

export type TextMessageRepliesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    textMessageId: Scalars['TextMessageId'];
};

export type TextMessageRepliesQuery = {
    organization: {
        textMessage: Pick<TextMessage, 'replies'> & {
            children: Array<
                Pick<TextMessage, 'replies'> & {
                    text: TextMessage['content'];
                    sentAt: TextMessage['insertedAt'];
                } & { createdBy?: Maybe<CreatedByFragment> }
            >;
        };
    };
};

export type TextMessageSendMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    parentId: Scalars['TextMessageId'];
    content: Scalars['String'];
};

export type TextMessageSendMutation = { textMessageSend: Pick<TextMessage, 'id'> };

export type TextMessageRepliesReadMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    textMessageId: Scalars['TextMessageId'];
};

export type TextMessageRepliesReadMutation = { textMessageRepliesRead: Pick<TextMessage, 'id'> };

export type CampaignsSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type CampaignsSegmentsQuery = {
    organization: {
        campaignsCreators: Array<Pick<UsersInfo, 'userId' | 'nameOrEmail'>>;
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type CommunityCampaignsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    predicates: Scalars['JSON'];
    name?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CampaignsSort>;
};

export type CommunityCampaignsQuery = {
    organization: {
        rows: Pick<CampaignsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<CampaignListFragment>;
        };
    };
};

export type CommunityDashboardQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type CommunityDashboardQuery = {
    organization: Pick<Organization, 'numberOfUsersInfos'> & {
        events: {
            nodes: Array<
                Pick<
                    Event,
                    | 'id'
                    | 'name'
                    | 'range'
                    | 'assignedResources'
                    | 'resources'
                    | 'numberOfPositions'
                >
            >;
        };
    };
};

export type CommunityDelegationsSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type CommunityDelegationsSegmentsQuery = {
    organization: Pick<Organization, 'numberOfDelegations'> & {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type UsersInfosMassAddEventMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    massAddEvent: MassAddEventInput;
};

export type UsersInfosMassAddEventMutation = {
    usersInfosMassAddEvent: Array<Pick<VolunteersRegistration, 'id'>>;
};

export type MergeMembersQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
};

export type MergeMembersQuery = {
    organization: {
        usersInfos: {
            nodes: Array<
                Pick<UsersInfo, 'id' | 'name' | 'email'> & {
                    picture?: Maybe<Pick<Document, 'url'>>;
                }
            >;
        };
    };
};

export type UsersInfosMergeMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    mainUserInfoId: Scalars['UsersInfoId'];
    toMergeUsersInfosIds: Array<Scalars['UsersInfoId']>;
};

export type UsersInfosMergeMutation = { usersInfosMerge: Pick<UsersInfo, 'id'> };

export type UsersActionsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type UsersActionsQuery = {
    organization: Pick<Organization, 'hasDelegations' | 'hasEvents' | 'hasForms'>;
};

export type UsersInfosMassDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    selecteds: MassActionSelectedsInput;
};

export type UsersInfosMassDeleteMutation = Pick<Mutation, 'usersInfosMassDelete'>;

export type PossibleDuplicatesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type PossibleDuplicatesQuery = {
    organization: {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        possibleDuplicates: Array<
            Pick<PossibleDuplicate, 'id'> & {
                usersInfos: Array<
                    Pick<UsersInfo, 'id' | 'email' | 'name' | 'fields'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                        delegations: Array<
                            Pick<Delegation, 'id' | 'name'> & { event?: Maybe<Pick<Event, 'name'>> }
                        >;
                    }
                >;
            }
        >;
    };
};

export type CustomFieldCategoryCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    category: CustomFieldsCategoryInput;
};

export type CustomFieldCategoryCreateMutation = {
    customFieldCategoryCreate: Pick<CustomFieldsCategory, 'id' | 'name'>;
};

export type CustomFieldCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    customField: CustomFieldInput;
};

export type CustomFieldCreateMutation = {
    customField: Pick<CustomField, 'id' | 'name' | 'fieldType' | 'customFieldCategoryName'> & {
        conditionCustomField?: Maybe<Pick<CustomField, 'id' | 'name'>>;
    };
};

export type CustomFieldToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    customFieldId: Scalars['CustomFieldId'];
};

export type CustomFieldToEditQuery = {
    organization: {
        customField: Pick<
            CustomField,
            | 'name'
            | 'label'
            | 'customFieldCategoryId'
            | 'description'
            | 'variety'
            | 'fieldType'
            | 'isPrivate'
            | 'hasCondition'
            | 'conditionCustomFieldId'
            | 'canSelectMultiple'
            | 'conditionValue'
            | 'isSelectV2'
        > & {
            document?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
            values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
        };
    } & CreateCustomFieldInfosFragment;
};

export type CustomFieldUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    customFieldId: Scalars['CustomFieldId'];
    customField: CustomFieldInput;
};

export type CustomFieldUpdateMutation = {
    customField: Pick<CustomField, 'id' | 'name' | 'fieldType' | 'customFieldCategoryName'> & {
        conditionCustomField?: Maybe<Pick<CustomField, 'id' | 'name'>>;
    };
};

export type OrganizationInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationInfosQuery = { organization: CreateCustomFieldInfosFragment };

export type CustomFieldQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    id: Scalars['CustomFieldId'];
};

export type CustomFieldQuery = { data: { row: CustomFieldFragment } };

export type CustomFieldCategoryDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    customFieldCategoryId: Scalars['CustomFieldsCategoryId'];
};

export type CustomFieldCategoryDeleteMutation = {
    customFieldCategoryDelete: Pick<CustomFieldsCategory, 'id'>;
};

export type CustomFieldDuplicateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    customFieldId: Scalars['CustomFieldId'];
};

export type CustomFieldDuplicateMutation = { customFieldDuplicate: Pick<CustomField, 'id'> };

export type CustomFieldUsageQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    customFieldId: Scalars['CustomFieldId'];
};

export type CustomFieldUsageQuery = {
    organization: {
        customField: Pick<CustomField, 'name' | 'fieldProperty' | 'fieldType'> & {
            positions: Array<Pick<Position, 'id' | 'name'> & { event: Pick<Event, 'id' | 'name'> }>;
            forms: Array<
                Pick<Form, 'id' | 'name'> & {
                    event?: Maybe<Pick<Event, 'id' | 'name'>>;
                    organization: Pick<Organization, 'id' | 'name'>;
                }
            >;
        };
    };
};

export type CustomFieldsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    predicates: Scalars['JSON'];
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<CustomFieldsSort>;
};

export type CustomFieldsQuery = {
    data: {
        rows: Pick<CustomFieldsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<CustomFieldFragment>;
        };
    };
};

export type CustomFieldsMassDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    selecteds: MassActionSelectedsInput;
};

export type CustomFieldsMassDeleteMutation = Pick<Mutation, 'customFieldsMassDelete'>;

export type CustomFieldsSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    predicates: Scalars['JSON'];
};

export type CustomFieldsSegmentsQuery = {
    organization: {
        customFields: { nodes: Array<Pick<CustomField, 'id' | 'name'>> };
        customFieldsCategories: { nodes: Array<Pick<CustomFieldsCategory, 'id' | 'name'>> };
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type CustomFieldCategoryUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    customFieldCategoryId: Scalars['CustomFieldsCategoryId'];
    customFieldCategory: CustomFieldsCategoryInput;
};

export type CustomFieldCategoryUpdateMutation = {
    customFieldCategoryUpdate: Pick<CustomFieldsCategory, 'id' | 'name'>;
};

export type DashboardQueryVariables = {
    eventId: Scalars['EventId'];
};

export type DashboardQuery = {
    event: Pick<
        Event,
        | 'assignedResources'
        | 'numberOfPositions'
        | 'resources'
        | 'volunteerMinutesAssigned'
        | 'volunteerMinutesNeeded'
    > & {
        assignmentsStats: Pick<AssignmentsStats, 'numberOfAssigned' | 'numberOfWaitingAssignment'>;
        onboarding: Pick<
            EventOnboarding,
            'isFirstCampaignCreated' | 'isFirstFormCreated' | 'isFirstPositionCreated'
        >;
        positionsCategories: Array<
            Pick<PositionsCategory, 'id' | 'name' | 'resources' | 'assignedResources'>
        >;
    };
};

export type DashboardMobileQueryVariables = {
    eventId: Scalars['EventId'];
};

export type DashboardMobileQuery = {
    event: Pick<
        Event,
        | 'assignedResources'
        | 'name'
        | 'resources'
        | 'volunteerMinutesAssigned'
        | 'volunteerMinutesNeeded'
    > & {
        positions: Pick<PositionsConnection, 'totalCount'> & {
            nodes: Array<Pick<Position, 'id' | 'name' | 'resources' | 'assignedResources'>>;
        };
        waitingAssignment: Pick<VolunteersRegistrationsConnection, 'totalCount'>;
        assigned: Pick<VolunteersRegistrationsConnection, 'totalCount'>;
        positionsCategories: Array<
            Pick<PositionsCategory, 'id' | 'name' | 'resources' | 'assignedResources'>
        >;
    };
};

export type CreateEventDelegationLeadersQueryVariables = {
    eventId: Scalars['EventId'];
    hasSelected: Scalars['Boolean'];
    userInfoIds: Array<Scalars['UsersInfoId']>;
    name?: Maybe<Scalars['String']>;
};

export type CreateEventDelegationLeadersQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<{ userInfo: Pick<UsersInfo, 'id' | 'nameOrEmail'> }>;
        };
        selectedVolunteersRegistrations: {
            nodes: Array<{ userInfo: Pick<UsersInfo, 'id' | 'nameOrEmail'> }>;
        };
    };
};

export type CreateOrganizationDelegationLeadersQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    hasSelected: Scalars['Boolean'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
    name?: Maybe<Scalars['String']>;
};

export type CreateOrganizationDelegationLeadersQuery = {
    organization: {
        usersInfos: { nodes: Array<Pick<UsersInfo, 'id' | 'nameOrEmail'>> };
        selectedUsersInfos: { nodes: Array<Pick<UsersInfo, 'id' | 'nameOrEmail'>> };
    };
};

export type DelegationCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegation: DelegationInput;
};

export type DelegationCreateMutation = { delegationCreate: Pick<Delegation, 'id'> };

export type DelegationEventInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type DelegationEventInfosQuery = {
    organization: {
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
        delegationCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
        delegations: {
            nodes: Array<
                Pick<
                    Delegation,
                    'id' | 'name' | 'privateNote' | 'leadersIds' | 'tagsIds' | 'columns'
                > & {
                    delegationCategory: Pick<DelegationsCategory, 'name'>;
                    form: Pick<Form, 'name'>;
                }
            >;
        };
    } & CreatePositionTagsFragment &
        OrganizationCustomFieldsFragment;
    event: CreateDelegationEventFragment;
};

export type DelegationEventToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
};

export type DelegationEventToEditQuery = {
    organization: {
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
        delegationCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
        delegations: { nodes: Array<Pick<Delegation, 'id' | 'name'>> };
    } & CreatePositionTagsFragment &
        OrganizationCustomFieldsFragment;
    event: {
        delegation: Pick<
            Delegation,
            | 'name'
            | 'description'
            | 'delegationCategoryId'
            | 'parentId'
            | 'leadersIds'
            | 'formId'
            | 'deadlineDate'
            | 'maxResources'
            | 'columns'
            | 'privateNote'
            | 'tagsIds'
            | 'addDefaultAccreditationsByAvailability'
            | 'showInDelegationSpace'
            | 'showFormLinkInDelegationSpace'
            | 'fields'
            | 'canLeadersAddMembers'
            | 'canLeadersEditMembers'
            | 'canLeadersEditCustomFields'
        > & {
            customFields: Array<
                Pick<DelegationsCustomField, 'addValues' | 'customFieldId' | 'value'>
            >;
            accreditations: Array<
                Pick<
                    DelegationAccreditation,
                    | 'accreditationCategoryId'
                    | 'accreditationId'
                    | 'maxResources'
                    | 'willAutoAccredit'
                >
            >;
            accreditationsSlots: Array<
                Pick<
                    DelegationAccreditationSlot,
                    | 'accreditationCategoryId'
                    | 'accreditationId'
                    | 'accreditationSlotId'
                    | 'isDefault'
                    | 'maxResources'
                    | 'isVisible'
                >
            >;
        };
    } & CreateDelegationEventFragment;
};

export type DelegationOrganizationInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type DelegationOrganizationInfosQuery = {
    organization: {
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
        delegationCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
        forms: Array<CreateDelegationFormFragment>;
    } & CreatePositionTagsFragment &
        OrganizationCustomFieldsFragment;
};

export type DelegationOrganizationToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
};

export type DelegationOrganizationToEditQuery = {
    organization: {
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
        delegationCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
        forms: Array<CreateDelegationFormFragment>;
        delegation: Pick<
            Delegation,
            | 'name'
            | 'description'
            | 'delegationCategoryId'
            | 'leadersIds'
            | 'formId'
            | 'deadlineDate'
            | 'maxResources'
            | 'columns'
            | 'privateNote'
            | 'tagsIds'
            | 'addDefaultAccreditationsByAvailability'
            | 'showInDelegationSpace'
            | 'showFormLinkInDelegationSpace'
            | 'fields'
            | 'canLeadersAddMembers'
            | 'canLeadersEditMembers'
            | 'canLeadersEditCustomFields'
        > & {
            customFields: Array<
                Pick<DelegationsCustomField, 'addValues' | 'customFieldId' | 'value'>
            >;
        };
    } & CreatePositionTagsFragment &
        OrganizationCustomFieldsFragment;
};

export type DelegationUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId: Scalars['DelegationId'];
    delegation: DelegationInput;
};

export type DelegationUpdateMutation = { delegationUpdate: Pick<Delegation, 'id'> };

export type EventUsersInfosQueryVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    name?: Maybe<Scalars['String']>;
};

export type EventUsersInfosQuery = {
    event: {
        delegation: { parentUsersInfos: Array<ExistingVolunteerFragment> };
        volunteersRegistrations: { nodes: Array<{ userInfo: ExistingVolunteerFragment }> };
    };
};

export type DelegationsImportMutationVariables = {
    eventId: Scalars['EventId'];
    organizationId: Scalars['OrganizationId'];
    input: DelegationsImportInput;
};

export type DelegationsImportMutation = { jobId: Mutation['delegationsImport'] };

export type DelegationDuplicateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId: Scalars['DelegationId'];
};

export type DelegationDuplicateMutation = { delegationDuplicate: Pick<Delegation, 'id'> };

export type DelegationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    level: OrganizationEventLevel;
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<DelegationsSort>;
};

export type DelegationsQuery = {
    data: {
        rows: Pick<DelegationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<DelegationsDelegationFragment>;
        };
    };
};

export type DelegationsCategoriesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    level: OrganizationEventLevel;
};

export type DelegationsCategoriesQuery = {
    organization: {
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
    };
};

export type DelegationsDelegationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    id: Scalars['DelegationId'];
};

export type DelegationsDelegationQuery = { data: { row: DelegationsDelegationFragment } };

export type DelegationsMassDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    selecteds: MassActionSelectedsInput;
};

export type DelegationsMassDeleteMutation = Pick<Mutation, 'delegationsMassDelete'>;

export type DelegationsSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type DelegationsSegmentsQuery = {
    organization: {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
    };
    event: Pick<Event, 'organizationId' | 'numberOfDelegations'> & {
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type ParentDelegationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type ParentDelegationsQuery = {
    organization: { delegations: { nodes: Array<Pick<Delegation, 'id' | 'name' | 'leadersIds'>> } };
};

export type DelegationsMassMoveMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    massMove: MassDelegationsMove;
};

export type DelegationsMassMoveMutation = Pick<Mutation, 'delegationsMassMove'>;

export type DelegationsMassMoveInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    level: OrganizationEventLevel;
    eventId?: Maybe<Scalars['EventId']>;
};

export type DelegationsMassMoveInfosQuery = {
    organization: {
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
        forms: Array<Pick<Form, 'id' | 'name'>>;
    };
};

export type DelegationDashboardQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
};

export type DelegationDashboardQuery = {
    organization: {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        delegation: Pick<
            Delegation,
            | 'id'
            | 'name'
            | 'description'
            | 'deadlineDate'
            | 'privateNote'
            | 'state'
            | 'numberOfResources'
            | 'maxResources'
            | 'numberOfMembersAccredited'
            | 'numberOfMembersWaitingAccreditation'
            | 'numberOfMembersRefusedAccreditation'
            | 'fields'
        > & {
            parent?: Maybe<Pick<Delegation, 'id' | 'name'>>;
            form: Pick<Form, 'id' | 'name'>;
            leaders: Array<
                Pick<UsersInfo, 'id' | 'email' | 'name'> & {
                    picture?: Maybe<Pick<Document, 'url'>>;
                }
            >;
            tags: Array<Pick<Tag, 'id' | 'name'>>;
        };
    };
};

export type DelegationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
};

export type DelegationQuery = {
    organization: Pick<
        Organization,
        'allowDelegationSpaceDownload' | 'delegationsSpaceCustomBadges'
    > & {
        delegation: Pick<
            Delegation,
            | 'id'
            | 'name'
            | 'formId'
            | 'numberOfResources'
            | 'maxResources'
            | 'columns'
            | 'parentId'
            | 'lockedAt'
        > & { event?: Maybe<{ customDocuments: Array<Pick<CustomDocument, 'slug' | 'name'>> }> };
    } & OrganizationCustomFieldsFragment;
};

export type DelegationReportingQueryVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
};

export type DelegationReportingQuery = {
    event: Pick<Event, 'weezevent'> & {
        delegation: {
            accreditationsSlots: Array<
                Pick<
                    DelegationAccreditationSlot,
                    'maxResources' | 'assignedResources' | 'scannedResources' | 'wishedResources'
                > & {
                    accreditationSlot: Pick<AccreditationsSlot, 'name' | 'date'>;
                    accreditation: Pick<Accreditation, 'id' | 'name' | 'hasSlots'>;
                }
            >;
        };
    };
};

export type DelegationCategoryCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategory: DelegationsCategoryInput;
};

export type DelegationCategoryCreateMutation = {
    delegationCategoryCreate: Pick<DelegationsCategory, 'id' | 'name'>;
};

export type DelegationCategoryUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
    delegationCategory: DelegationsCategoryInput;
};

export type DelegationCategoryUpdateMutation = {
    delegationCategoryUpdate: Pick<DelegationsCategory, 'id' | 'name'>;
};

export type DelegationCategoryDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
};

export type DelegationCategoryDeleteMutation = {
    delegationCategoryDelete: Pick<DelegationsCategory, 'id'>;
};

export type DelegationCategoryDelegationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    level: OrganizationEventLevel;
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
};

export type DelegationCategoryDelegationsQuery = {
    organization: {
        delegations: Pick<DelegationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<Pick<Delegation, 'id' | 'name' | 'state'>>;
        };
    };
};

export type DelegationCategoryQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationCategoryId: Scalars['DelegationsCategoryId'];
};

export type DelegationCategoryQuery = {
    organization: Pick<Organization, 'delegationsSpaceCustomBadges'> & {
        delegationCategory: Pick<
            DelegationsCategory,
            'id' | 'name' | 'numberOfDelegations' | 'numberOfResources'
        >;
    };
};

export type DelegationCategoryDuplicateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationCategoryId: Scalars['DelegationsCategoryId'];
};

export type DelegationCategoryDuplicateMutation = {
    delegationCategoryDuplicate: Pick<DelegationsCategory, 'id'>;
};

export type DelegationCategoryMembersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
};

export type DelegationCategoryMembersInfosQuery = {
    organization: {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
        event?: Maybe<Pick<Event, 'startAt' | 'endAt'>>;
    };
};

export type CustomDocumentQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    customDocumentId: Scalars['CustomDocumentId'];
};

export type CustomDocumentQuery = {
    organization: {
        population: { values: Array<Pick<CustomFieldsValue, 'id' | 'value'>> };
        customFields: { nodes: Array<PositionConditionCustomFieldFragment> };
    };
    event: {
        customDocument?: Maybe<
            Pick<CustomDocument, 'name' | 'slug' | 'populationsIds' | 'configuration'>
        >;
    };
};

export type CustomDocumentConditionsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type CustomDocumentConditionsQuery = {
    event: {
        accreditationsSlots: {
            nodes: Array<
                Pick<AccreditationsSlot, 'id' | 'name' | 'date'> & {
                    accreditation: Pick<Accreditation, 'name'>;
                }
            >;
        };
        positionsSlots: {
            nodes: Array<
                Pick<PositionsSlot, 'id' | 'name' | 'range'> & { position: Pick<Position, 'name'> }
            >;
        };
    };
};

export type CustomDocumentUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    customDocumentId: Scalars['CustomDocumentId'];
    customDocument: CustomDocumentInput;
};

export type CustomDocumentUpdateMutation = { customDocumentUpdate: Pick<CustomDocument, 'id'> };

export type CustomDocumentCreateMutationVariables = {
    eventId: Scalars['EventId'];
    customDocument: CustomDocumentInput;
};

export type CustomDocumentCreateMutation = { customDocumentCreate: Pick<CustomDocument, 'id'> };

export type CustomDocumentDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    customDocumentId: Scalars['CustomDocumentId'];
};

export type CustomDocumentDeleteMutation = { customDocumentDelete: Pick<CustomDocument, 'id'> };

export type CustomDocumentDuplicateMutationVariables = {
    eventId: Scalars['EventId'];
    customDocumentId: Scalars['CustomDocumentId'];
};

export type CustomDocumentDuplicateMutation = {
    customDocumentDuplicate: Pick<CustomDocument, 'id'>;
};

export type DocumentsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type DocumentsQuery = {
    event: {
        customDocuments: Array<
            Pick<CustomDocument, 'id' | 'name' | 'slug' | 'configuration' | 'populations'> & {
                createdBy: Pick<UsersInfo, 'nameOrEmail'>;
            }
        >;
        positions: Pick<PositionsConnection, 'totalCount'> & { nodes: Array<Pick<Position, 'id'>> };
        volunteersRegistrations: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<Pick<VolunteersRegistration, 'userInfoId'>>;
        };
        assignedVolunteersRegistrations: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<Pick<VolunteersRegistration, 'userInfoId'>>;
        };
        volunteersSegments: Array<Pick<Segment, 'id'>>;
        positionsSegments: Array<Pick<Segment, 'id'>>;
    };
};

export type DocumentsAssignedUsersQueryVariables = {
    eventId: Scalars['EventId'];
};

export type DocumentsAssignedUsersQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<{ userInfo: Pick<UsersInfo, 'id'> & { name: UsersInfo['nameOrEmail'] } }>;
        };
    };
};

export type DocumentsPositionsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type DocumentsPositionsQuery = {
    event: { positions: { nodes: Array<Pick<Position, 'id' | 'name'>> } };
};

export type EventDocumentUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    id: Scalars['EventsDocumentId'];
    options: Scalars['JSON'];
};

export type EventDocumentUpdateMutation = { eventDocumentUpdate: Pick<EventsDocument, 'id'> };

export type PositionPlanningDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    date?: Maybe<Scalars['Date']>;
};

export type PositionPlanningDocumentQuery = {
    event: DocumentPositionPlanningFragment & EventPositionsExportFragment;
};

export type PositionSheetDocumentQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionSheetDocumentQuery = {
    event: EventPositionsExportFragment;
} & DocumentPositionSheetFragment;

export type PlanningDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
    date?: Maybe<Scalars['Date']>;
};

export type PlanningDocumentQuery = {
    event: DocumentPositionsPlanningFragment & EventPositionsExportFragment;
};

export type PositionsVolunteersPlanningDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
};

export type PositionsVolunteersPlanningDocumentQuery = {
    event: DocumentPositionsVolunteersPlanningFragment & EventPositionsExportFragment;
};

export type UserAssignmentsDocumentQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserAssignmentsDocumentQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> & EventVolunteersExportFragment;
} & DocumentUserAssignmentsFragment;

export type UserBadgeDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserBadgeDocumentQuery = {
    event: DocumentUserBadgeFragment & EventVolunteersExportFragment;
};

export type UserCertificateDocumentQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserCertificateDocumentQuery = {
    organization: OrganizationCertificateDefaultFragment;
    event: DocumentUserCertificateFragment & EventVolunteersExportFragment;
};

export type UserCustomBadgeDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    customBadge: CustomBadge;
};

export type UserCustomBadgeDocumentQuery = {
    event: DocumentUserCustomBadgeFragment & EventVolunteersExportFragment;
};

export type UserCustomDocumentExportQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    customDocumentId: Scalars['CustomDocumentId'];
};

export type UserCustomDocumentExportQuery = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: Pick<Event, 'id' | 'name' | 'organizationId' | 'startAt' | 'endAt'> & {
        customDocument?: Maybe<Pick<CustomDocument, 'name' | 'slug' | 'configuration'>>;
        volunteerRegistration: CustomBadgeVolunteerRegistrationFragment;
    } & EventVolunteersExportFragment;
};

export type UserPlanningDaysDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPlanningDaysDocumentQuery = {
    event: DocumentUserPlanningDaysFragment & EventVolunteersExportFragment;
};

export type UserPlanningDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPlanningDocumentQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> &
        DocumentUserPlanningFragment &
        EventVolunteersExportFragment;
};

export type UserPlanningListDocumentQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPlanningListDocumentQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> &
        DocumentUserPlanningListFragment &
        EventVolunteersExportFragment;
};

export type UsersListingDocumentQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
};

export type UsersListingDocumentQuery = {
    event: EventVolunteersExportFragment;
} & DocumentUsersListingFragment;

export type EmailTemplateQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId?: Maybe<Scalars['FormId']>;
    source?: Maybe<LoginCodeSource>;
    emailType: EmailType;
};

export type EmailTemplateQuery = {
    organization: {
        emailsSenders: Array<Pick<EmailsSender, 'id' | 'name'>>;
        emailTemplate?: Maybe<
            Pick<
                EmailsTemplate,
                'emailType' | 'useDefault' | 'subject' | 'content' | 'emailSenderId' | 'attachments'
            >
        >;
    };
};

export type EmailTemplateUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId?: Maybe<Scalars['FormId']>;
    source?: Maybe<LoginCodeSource>;
    emailTemplate: EmailsTemplateInput;
};

export type EmailTemplateUpdateMutation = { emailTemplateUpdate: Pick<EmailsTemplate, 'id'> };

export type EmailsTemplatesQueryVariables = {
    eventId: Scalars['EventId'];
};

export type EmailsTemplatesQuery = {
    event: { emailSender?: Maybe<Pick<EmailsSender, 'domainStatus'>> };
};

export type AllEventsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type AllEventsQuery = {
    organization: { events: { nodes: Array<Pick<Event, 'id' | 'name'>> } };
};

export type CreateEventMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    event: EventInput;
};

export type CreateEventMutation = { eventCreate: Pick<Event, 'id'> };

export type EventCreateInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type EventCreateInfosQuery = { organization: CreateUpdateEventInfosFragment };

export type EventOrganizationCreateMutationVariables = {
    eventOrganization: EventOrganizationInput;
};

export type EventOrganizationCreateMutation = {
    eventOrganizationCreate: Pick<Event, 'id' | 'organizationId'>;
};

export type EventUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    event: EventInput;
};

export type EventUpdateMutation = { eventUpdate: Pick<Event, 'id'> };

export type EventUpdateInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type EventUpdateInfosQuery = {
    organization: CreateUpdateEventInfosFragment;
    event: Pick<
        Event,
        | 'name'
        | 'description'
        | 'languages'
        | 'startAt'
        | 'endAt'
        | 'timeZone'
        | 'country'
        | 'layer'
        | 'latitude'
        | 'longitude'
        | 'emailSenderId'
        | 'dayStartTime'
        | 'tagsIds'
        | 'fields'
    >;
};

export type EventDuplicateMutationVariables = {
    eventId: Scalars['EventId'];
    options: EventDuplicateInput;
};

export type EventDuplicateMutation = { eventDuplicate: Pick<Event, 'id'> };

export type EventsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<EventsSort>;
};

export type EventsQuery = {
    data: {
        rows: Pick<EventsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<EventsEventFragment>;
        };
    };
};

export type EventsMassArchiveMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    selecteds: MassActionSelectedsInput;
};

export type EventsMassArchiveMutation = Pick<Mutation, 'eventsMassArchive'>;

export type EventsSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type EventsSegmentsQuery = {
    organization: {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type EventsEventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    id: Scalars['EventId'];
};

export type EventsEventQuery = { data: { row: EventsEventFragment } };

export type EventsMobileQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type EventsMobileQuery = { organization: { events: { nodes: Array<Pick<Event, 'id'>> } } };

export type EventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type EventQuery = {
    event: Pick<Event, 'id' | 'organizationId' | 'name' | 'languages'> & {
        accreditationsStats: Pick<AccreditationsStats, 'numberOfWaitingAccreditation'>;
        assignmentsStats: Pick<
            AssignmentsStats,
            'numberOfWaitingAssignment' | 'numberOfPreAssigned'
        >;
    };
    user: {
        roles: Array<Pick<Role, 'id' | 'positionPermissions' | 'type'>>;
        usersPositionsCategories: Array<
            Pick<UsersPositionsCategory, 'positionCategoryId'> & {
                role: Pick<Role, 'id' | 'editPosition' | 'type'>;
            }
        >;
        usersPositions: Array<
            Pick<UsersPosition, 'positionId'> & { role: Pick<Role, 'id' | 'editPosition' | 'type'> }
        >;
    };
};

export type FilesImportMutationVariables = {
    eventId?: Maybe<Scalars['EventId']>;
    organizationId: Scalars['OrganizationId'];
    input: FilesImportInput;
};

export type FilesImportMutation = { jobId: Mutation['filesImport'] };

export type FilesImportCustomFieldsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type FilesImportCustomFieldsQuery = {
    organization: {
        customFileFields: {
            nodes: Array<
                Pick<
                    CustomField,
                    | 'id'
                    | 'name'
                    | 'slug'
                    | 'customFieldCategoryId'
                    | 'customFieldCategoryName'
                    | 'fieldType'
                    | 'fieldProperty'
                    | 'canSelectMultiple'
                > & { values: Array<Pick<CustomFieldsValue, 'id' | 'value'>> }
            >;
        };
    };
};

export type FormCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    form: FormInput;
};

export type FormCreateMutation = { formCreate: Pick<Form, 'id'> };

export type FormDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
};

export type FormDeleteMutation = { formDelete: Pick<Form, 'id'> };

export type FormDuplicateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    newEventId?: Maybe<Scalars['EventId']>;
};

export type FormDuplicateMutation = { formDuplicate: Pick<Form, 'id'> };

export type FormsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    level: OrganizationEventLevel;
    eventId?: Maybe<Scalars['EventId']>;
};

export type FormsQuery = { organization: { forms: Array<FormListFragment> } };

export type FormQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormQuery = { organization: { form: Pick<Form, 'name'> } };

export type FormElementsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormElementsQuery = {
    organization: {
        customFields: { nodes: Array<FormCustomFieldFragment> };
        form: Pick<Form, 'name'> & { elements: Array<FormElementsElementFragment> };
    };
};

export type FormElementsUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    elements: Array<FormsElementInput>;
};

export type FormElementsUpdateMutation = { formElementsUpdate: Pick<Form, 'id'> };

export type FormParametersAccreditationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormParametersAccreditationsQuery = {
    organization: {
        form: Pick<
            Form,
            | 'name'
            | 'slotDisplay'
            | 'accreditationDisplay'
            | 'hiddenAccreditationsCategoriesIds'
            | 'hiddenAccreditationsIds'
            | 'hiddenAccreditationsSlotsIds'
            | 'displayedAccreditationsCategoriesIds'
            | 'displayedAccreditationsIds'
            | 'displayedAccreditationsSlotsIds'
            | 'showFullAccreditation'
            | 'showAccreditationDescription'
            | 'accreditationTitle'
            | 'accreditationSubtitle'
            | 'accreditationFilter'
        >;
    };
};

export type FormParametersAccreditationsUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersAccreditationsInput;
};

export type FormParametersAccreditationsUpdateMutation = {
    formParametersAccreditationsUpdate: Pick<Form, 'id'>;
};

export type FormParametersAccreditationsHiddenDisplayedQueryVariables = {
    eventId: Scalars['EventId'];
};

export type FormParametersAccreditationsHiddenDisplayedQuery = {
    event: {
        accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>>;
        accreditations: {
            nodes: Array<
                Pick<Accreditation, 'id' | 'name' | 'hiddenSlotId'> & {
                    slots: Array<Pick<AccreditationsSlot, 'id' | 'name' | 'date'>>;
                }
            >;
        };
    };
};

export type FormParametersAvailabilitiesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
};

export type FormParametersAvailabilitiesQuery = {
    organization: {
        form: Pick<Form, 'name'> & {
            customSlotsPeriods: Array<FormRegisterCustomSlotPeriodFragment>;
        } & FormSlotsOptionsFragment;
    };
    event: Pick<Event, 'startAt' | 'endAt'>;
};

export type FormParametersAvailabilitiesUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersAvailabilitiesInput;
};

export type FormParametersAvailabilitiesUpdateMutation = {
    formParametersAvailabilitiesUpdate: Pick<Form, 'id'>;
};

export type FormParametersBroadcastEventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
};

export type FormParametersBroadcastEventQuery = {
    organization: {
        form: Pick<Form, 'name' | 'noAccessMessage' | 'noAccessTitle'> & {
            segments: Array<Pick<FormsSegment, 'segmentId'>>;
        };
        segments: Array<Pick<Segment, 'id' | 'name'>>;
    };
    event: { segments: Array<Pick<Segment, 'id' | 'name'>> };
};

export type FormParametersBroadcastOrganizationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormParametersBroadcastOrganizationQuery = {
    organization: {
        segments: Array<Pick<Segment, 'id' | 'name'>>;
        form: Pick<Form, 'name' | 'noAccessMessage' | 'noAccessTitle'> & {
            segments: Array<Pick<FormsSegment, 'segmentId'>>;
        };
    };
};

export type FormParametersBroadcastUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersBroadcastInput;
};

export type FormParametersBroadcastUpdateMutation = {
    formParametersBroadcastUpdate: Pick<Form, 'id'>;
};

export type FormParametersDesignQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormParametersDesignQuery = {
    organization: {
        form: Pick<Form, 'name'> & {
            design?: Maybe<
                Pick<Design, 'colors'> & { banner?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>> }
            >;
        };
    };
};

export type FormParametersDesignUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    design: DesignInput;
};

export type FormParametersDesignUpdateMutation = { formParametersDesignUpdate: Pick<Form, 'id'> };

export type FormParametersGeneralQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormParametersGeneralQuery = {
    organization: {
        customFields: { nodes: Array<MassEditCustomFieldFragment> };
        form: Pick<
            Form,
            | 'name'
            | 'allowMultiProfiles'
            | 'setAssignmentStateToWaiting'
            | 'setAccreditationStateToWaiting'
            | 'description'
            | 'successMessage'
            | 'areTeamsAllowed'
            | 'maxTeamMembers'
            | 'teamMemberFillFullForm'
            | 'emailTitle'
            | 'informationTitle'
            | 'registerButtonText'
            | 'isEditableInMemberSpace'
        > & {
            customFields: Array<Pick<FormsCustomField, 'addValues' | 'customFieldId' | 'value'>>;
        };
    };
};

export type FormParametersGeneralUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersGeneralInput;
};

export type FormParametersGeneralUpdateMutation = { formParametersGeneralUpdate: Pick<Form, 'id'> };

export type FormParametersMissionsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormParametersMissionsQuery = {
    organization: {
        form: Pick<
            Form,
            | 'name'
            | 'slotDisplay'
            | 'positionDisplay'
            | 'positionFilter'
            | 'positionRanking'
            | 'positionLimit'
            | 'hiddenPositionsCategoriesIds'
            | 'hiddenPositionsIds'
            | 'hiddenPositionsSlotsIds'
            | 'displayedPositionsCategoriesIds'
            | 'displayedPositionsIds'
            | 'displayedPositionsSlotsIds'
            | 'showFullPosition'
            | 'showPositionCategoryDescription'
            | 'showPositionDescription'
            | 'positionTitle'
            | 'positionSubtitle'
            | 'willAutoAssign'
        >;
    };
};

export type FormParametersMissionsUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersMissionsInput;
};

export type FormParametersMissionsUpdateMutation = {
    formParametersMissionsUpdate: Pick<Form, 'id'>;
};

export type FormParametersMissionsHiddenDisplayedQueryVariables = {
    eventId: Scalars['EventId'];
};

export type FormParametersMissionsHiddenDisplayedQuery = {
    event: {
        positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
        positions: {
            nodes: Array<
                Pick<Position, 'id' | 'name'> & {
                    slots: Array<Pick<PositionsSlot, 'id' | 'name' | 'range'>>;
                }
            >;
        };
    };
};

export type FormParametersStateQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
};

export type FormParametersStateQuery = {
    organization: {
        form: Pick<Form, 'name' | 'state' | 'closedTitle' | 'closedMessage' | 'closedAt'>;
    };
};

export type FormParametersStateUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    form: FormParametersStateInput;
};

export type FormParametersStateUpdateMutation = { formParametersStateUpdate: Pick<Form, 'id'> };

export type HomeOrganizationsQueryVariables = {};

export type HomeOrganizationsQuery = { user: { roles: Array<Pick<Role, 'organizationId'>> } };

export type CollaboratorsVolunteersQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
};

export type CollaboratorsVolunteersQuery = {
    event: {
        collaborators: Array<{ userInfo: LeaderUserInfoFragment }>;
        volunteersRegistrations: { nodes: Array<{ userInfo: LeaderUserInfoFragment }> };
    };
};

export type DelegationsImportResultMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    jobId: Scalars['UUID'];
};

export type DelegationsImportResultMutation = {
    delegationsImportResult: Pick<DelegationsImportResult, 'state'> & {
        event?: Maybe<Pick<DelegationsImportEvent, 'name'>>;
    };
};

export type EventDocumentsExportResultMutationVariables = {
    eventId: Scalars['EventId'];
    jobId: Scalars['UUID'];
};

export type EventDocumentsExportResultMutation = {
    eventDocumentsExportResult: Pick<ExportResult, 'state' | 'url'>;
};

export type ExportResultMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    jobId: Scalars['UUID'];
};

export type ExportResultMutation = { exportResult: Pick<ExportResult, 'state' | 'url'> };

export type FilesImportResultMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    jobId: Scalars['UUID'];
};

export type FilesImportResultMutation = {
    filesImportResult: Pick<FilesImportResult, 'state'> & {
        event?: Maybe<Pick<FilesImportEvent, 'name'>>;
    };
};

export type PositionsImportResultMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    jobId: Scalars['UUID'];
};

export type PositionsImportResultMutation = {
    positionsImportResult: Pick<PositionsImportResult, 'state'> & {
        event?: Maybe<Pick<PositionsImportEvent, 'name'>>;
    };
};

export type VolunteersImportResultMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    jobId: Scalars['UUID'];
};

export type VolunteersImportResultMutation = {
    volunteersImportResult: Pick<VolunteersImportResult, 'state'> & {
        event?: Maybe<Pick<VolunteersImportEvent, 'name'>>;
    };
};

export type EventKeyQueryVariables = {
    eventId: Scalars['EventId'];
};

export type EventKeyQuery = { event: Pick<Event, 'key'> };

export type WeezeventRulesQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
};

export type WeezeventRulesQuery = {
    event: {
        weezeventConnectionInfos?: Maybe<
            Pick<
                WeezeventConnectionInfo,
                'event' | 'idEvent' | 'ticket' | 'synchronizationState' | 'updatedAt'
            >
        >;
        weezeventRules: Pick<WeezeventRulesConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<
                Pick<WeezeventRule, 'id' | 'name' | 'value' | 'idQuestion'> & {
                    field?: Maybe<
                        Pick<WeezeventField, 'name'> & {
                            choices?: Maybe<Array<Pick<WeezeventSelectChoice, 'id' | 'label'>>>;
                        }
                    >;
                    segment: Pick<Segment, 'name'>;
                }
            >;
        };
    };
};

export type EventWeezeventUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    weezevent: EventWeezeventInput;
};

export type EventWeezeventUpdateMutation = { eventWeezeventUpdate: Pick<Event, 'id'> };

export type WeezeventV2InfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type WeezeventV2InfosQuery = {
    organization: { customFields: { nodes: Array<Pick<CustomField, 'slug' | 'name'>> } };
    event: Pick<Event, 'weezevent'> & {
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name'> & { segments: Array<Pick<Segment, 'id' | 'name'>> }
        >;
    };
};

export type OrganizationCreateMutationVariables = {
    organization: OrganizationInput;
};

export type OrganizationCreateMutation = { organizationCreate: Pick<Organization, 'id'> };

export type OrganizationsQueryVariables = {};

export type OrganizationsQuery = {
    organizations: Array<Pick<Organization, 'id' | 'name'>>;
    user: { roles: Array<Pick<Role, 'organizationId' | 'type'>> };
};

export type OrganizationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationQuery = {
    organization: Pick<
        Organization,
        | 'id'
        | 'name'
        | 'periodEndAt'
        | 'features'
        | 'customBadges'
        | 'isBlocked'
        | 'key'
        | 'weezeventOrganizationId'
    > & {
        invoices: Array<Pick<Invoice, 'invoiceNumber' | 'url' | 'insertedAt'>>;
        events: { nodes: Array<Pick<Event, 'id' | 'name' | 'state'>> };
    };
    user: Pick<User, 'email' | 'language' | 'termsAcceptedAt'> & {
        roles: Array<Pick<Role, 'type'>>;
        userInfo: Pick<UsersInfo, 'name'> & { firstName: UsersInfo['fieldValue'] } & {
            picture?: Maybe<Pick<Document, 'url'>>;
        };
    };
};

export type UserInfoQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type UserInfoQuery = {
    user: {
        userInfo: Pick<UsersInfo, 'id'> & {
            firstName: UsersInfo['fieldValue'];
            lastName: UsersInfo['fieldValue'];
            phone: UsersInfo['fieldValue'];
        };
    };
};

export type UserInfoAndTermsUpdateMutationVariables = {
    userInfoId: Scalars['UsersInfoId'];
    userInfoAndTermsInput: UserInfoAndTermsInput;
};

export type UserInfoAndTermsUpdateMutation = { userInfoAndTermsUpdate: Pick<User, 'id'> };

export type ActivitiesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    operations?: Maybe<Array<Scalars['String']>>;
    eventsIds?: Maybe<Array<Scalars['EventId']>>;
    date?: Maybe<Scalars['Date']>;
    offset?: Maybe<Scalars['Int']>;
};

export type ActivitiesQuery = {
    organization: {
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
        activities: Pick<ActivitiesConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<Pick<Activity, 'date' | 'icon' | 'text'>>;
        };
    };
};

export type OrganizationCategoriesAccreditationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['EventId']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationCategoriesAccreditationsQuery = {
    organization: {
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
        accreditationsCategories: Pick<
            AccreditationsCategoriesConnection,
            'numberOfPages' | 'totalCount'
        > & {
            nodes: Array<
                Pick<AccreditationsCategory, 'id' | 'eventId' | 'name' | 'numberOfAccreditations'>
            >;
        };
    };
};

export type OrganizationCategoriesCustomFieldsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationCategoriesCustomFieldsQuery = {
    organization: {
        customFieldsCategories: Pick<
            CustomFieldsCategoriesConnection,
            'numberOfPages' | 'totalCount'
        > & { nodes: Array<Pick<CustomFieldsCategory, 'id' | 'name' | 'numberOfCustomFields'>> };
    };
};

export type OrganizationCategoriesDelegationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['EventId']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationCategoriesDelegationsQuery = {
    organization: {
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
        delegationsCategories: Pick<
            DelegationsCategoriesConnection,
            'numberOfPages' | 'totalCount'
        > & {
            nodes: Array<
                Pick<
                    DelegationsCategory,
                    'id' | 'organizationId' | 'eventId' | 'name' | 'numberOfDelegations'
                >
            >;
        };
    };
};

export type OrganizationCategoriesPositionsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['EventId']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationCategoriesPositionsQuery = {
    organization: {
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
        positionsCategories: Pick<PositionsCategoriesConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<Pick<PositionsCategory, 'id' | 'eventId' | 'name' | 'numberOfPositions'>>;
        };
    };
};

export type OrganizationDelegationSpaceSettingsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationDelegationSpaceSettingsQuery = {
    organization: Pick<
        Organization,
        'customBadges' | 'allowDelegationSpaceDownload' | 'delegationsSpaceCustomBadges'
    > & {
        customDocuments: Array<
            Pick<CustomDocument, 'id' | 'slug' | 'name'> & { event: Pick<Event, 'id' | 'name'> }
        >;
    };
};

export type OrganizationDelegationSpaceSettingsUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationSpaceSettings: DelegationSpaceSettingsInput;
};

export type OrganizationDelegationSpaceSettingsUpdateMutation = {
    organizationDelegationSpaceSettingsUpdate: Pick<Organization, 'id'>;
};

export type OrganizationDelegationsSpaceDesignQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationDelegationsSpaceDesignQuery = {
    organization: {
        delegationsSpaceDesign?: Maybe<
            Pick<Design, 'colors'> & {
                banner?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
                logo?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
            }
        >;
    };
};

export type OrganizationDelegationsSpaceDesignUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationsSpaceDesign: DesignInput;
};

export type OrganizationDelegationsSpaceDesignUpdateMutation = {
    organizationDelegationsSpaceDesignUpdate: Pick<Organization, 'id'>;
};

export type DomainDkimVerificationRetryMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    domain: Scalars['String'];
};

export type DomainDkimVerificationRetryMutation = Pick<Mutation, 'domainDkimVerificationRetry'>;

export type OrganizationDomainsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationDomainsQuery = {
    organization: {
        domains: Array<
            Pick<Domain, 'name' | 'status'> & {
                dnsEntries: Array<Pick<DnsEntry, 'name' | 'type' | 'value'>>;
            }
        >;
    };
};

export type EmailSenderCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    emailSender: EmailsSenderInput;
};

export type EmailSenderCreateMutation = { emailSenderCreate: Pick<EmailsSender, 'id'> };

export type EmailSenderToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type EmailSenderToEditQuery = {
    organization: {
        emailSender?: Maybe<
            Pick<
                EmailsSender,
                | 'id'
                | 'name'
                | 'signature'
                | 'fromEmail'
                | 'fromName'
                | 'replyTo'
                | 'eventsIds'
                | 'isOrganizationDefault'
                | 'isValidToSendEmail'
            > & {
                header?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
                picture?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
                footer?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
            }
        >;
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
    };
};

export type EmailSenderUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
    emailSender: EmailsSenderInput;
};

export type EmailSenderUpdateMutation = { emailSenderUpdate: Pick<EmailsSender, 'id'> };

export type EmailSenderDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type EmailSenderDeleteMutation = { emailSenderDelete: Pick<EmailsSender, 'id'> };

export type EmailSenderDuplicateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type EmailSenderDuplicateMutation = { emailSenderDuplicate: Pick<EmailsSender, 'id'> };

export type EmailSenderVerificationResendMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type EmailSenderVerificationResendMutation = Pick<Mutation, 'emailSenderVerificationResend'>;

export type EmailsSendersQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type EmailsSendersQuery = {
    organization: {
        emailsSenders: Array<
            Pick<EmailsSender, 'id' | 'name' | 'from' | 'fromEmail' | 'isValidToSendEmail'>
        >;
    };
};

export type EmailSenderPreviewQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    emailSenderId: Scalars['EmailsSenderId'];
};

export type EmailSenderPreviewQuery = {
    organization: { emailSender?: Maybe<Pick<EmailsSender, 'name' | 'from' | 'preview'>> };
};

export type OrganizationParametersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationParametersInfosQuery = { organization: OrganizationAdminInfosFragment };

export type OrganizationUpdateInfosMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    organization: OrganizationInfosInput;
};

export type OrganizationUpdateInfosMutation = { organizationUpdateInfos: Pick<Organization, 'id'> };

export type InvoicesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type InvoicesQuery = {
    organization: {
        invoices: Array<
            Pick<
                Invoice,
                | 'id'
                | 'invoiceNumber'
                | 'amountCents'
                | 'smsAmountCents'
                | 'paidAt'
                | 'url'
                | 'insertedAt'
                | 'isRefunded'
            >
        >;
    };
};

export type OrganizationMembersSpaceDesignQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationMembersSpaceDesignQuery = {
    organization: {
        membersSpaceDesign?: Maybe<
            Pick<Design, 'colors'> & {
                banner?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
                logo?: Maybe<Pick<Document, 'acl' | 'name' | 'key'>>;
            }
        >;
    };
};

export type OrganizationMembersSpaceDesignUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    membersSpaceDesign: DesignInput;
};

export type OrganizationMembersSpaceDesignUpdateMutation = {
    organizationMembersSpaceDesignUpdate: Pick<Organization, 'id'>;
};

export type OrganizationMembersSpaceSettingsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationMembersSpaceSettingsQuery = {
    organization: Pick<
        Organization,
        'hideAssignmentsUntilConfirmationEmailSent' | 'hideAccreditationsUntilConfirmationEmailSent'
    >;
};

export type OrganizationMembersSpaceSettingsUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    membersSpaceSettings: MembersSpaceSettingsInput;
};

export type OrganizationMembersSpaceSettingsUpdateMutation = {
    organizationMembersSpaceSettingsUpdate: Pick<Organization, 'id'>;
};

export type UserDeleteMutationVariables = {};

export type UserDeleteMutation = { userDelete: Pick<User, 'id'> };

export type SkidataConnectionInfoSeaonEventAddMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type SkidataConnectionInfoSeaonEventAddMutation = {
    skidataConnectionInfoSeaonEventAdd: Pick<SkidataConnectionInfo, 'organizationId'>;
};

export type SkidataConnectionInfoSeaonEventDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type SkidataConnectionInfoSeaonEventDeleteMutation = {
    skidataConnectionInfoSeaonEventDelete: Pick<SkidataConnectionInfo, 'organizationId'>;
};

export type SkidataConnectionInfoSynchronizedEventAddMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type SkidataConnectionInfoSynchronizedEventAddMutation = {
    skidataConnectionInfoSynchronizedEventAdd: Pick<SkidataConnectionInfo, 'organizationId'>;
};

export type SkidataConnectionInfoSynchronizedEventDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type SkidataConnectionInfoSynchronizedEventDeleteMutation = {
    skidataConnectionInfoSynchronizedEventDelete: Pick<SkidataConnectionInfo, 'organizationId'>;
};

export type SkidataConnectionInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type SkidataConnectionInfosQuery = {
    organization: {
        events: { nodes: Array<Pick<Event, 'id' | 'name' | 'startAt' | 'endAt'>> };
        skidataConnectionInfos?: Maybe<
            Pick<
                SkidataConnectionInfo,
                | 'url'
                | 'issuer'
                | 'receiver'
                | 'seasonEventsIds'
                | 'synchronizedEventsIds'
                | 'place'
            >
        >;
    };
};

export type SkidataConnectionInfosDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type SkidataConnectionInfosDeleteMutation = {
    skidataConnectionInfosDelete: Pick<SkidataConnectionInfo, 'organizationId'>;
};

export type SkidataConnectionInfosUpsertMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    connectionInfos: SkidataConnectionInfoInput;
};

export type SkidataConnectionInfosUpsertMutation = {
    skidataConnectionInfosUpsert: Pick<SkidataConnectionInfo, 'organizationId'>;
};

export type SkidataSynchronizeMutationVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type SkidataSynchronizeMutation = Pick<Mutation, 'skidataSynchronize'>;

export type OrganizationTagsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationTagsQuery = {
    organization: {
        tags: Pick<TagsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<Pick<Tag, 'id' | 'name' | 'numberOfTimesUsed'>>;
        };
    };
};

export type TagCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    tag: TagInput;
};

export type TagCreateMutation = { tagCreate: Pick<Tag, 'id' | 'name'> };

export type TagDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    tagId: Scalars['TagId'];
};

export type TagDeleteMutation = { tagDelete: Pick<Tag, 'id'> };

export type TagUpdateNameMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    tagId: Scalars['TagId'];
    name: Scalars['String'];
};

export type TagUpdateNameMutation = { tagUpdateName: Pick<Tag, 'id'> };

export type EventAdminsLeadersQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    onlyAdmin?: Maybe<Scalars['Boolean']>;
    offset?: Maybe<Scalars['Int']>;
};

export type EventAdminsLeadersQuery = {
    organization: { roles: Array<Pick<Role, 'id' | 'type' | 'name'>> };
    event: {
        adminsLeaders: Pick<EventAdminsLeadersConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<
                Pick<EventAdminLeader, 'id' | 'userId' | 'isAdmin' | 'roleId'> & {
                    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    };
                    positionCategory?: Maybe<Pick<PositionsCategory, 'id' | 'name'>>;
                    position?: Maybe<Pick<Position, 'id' | 'name'>>;
                    delegation?: Maybe<Pick<Delegation, 'id' | 'name'>>;
                }
            >;
        };
    };
};

export type EventInviteMutationVariables = {
    eventId: Scalars['EventId'];
    email: Scalars['UserEmail'];
};

export type EventInviteMutation = { eventInvite: Pick<User, 'id'> };

export type OrganizationInviteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    email: Scalars['UserEmail'];
};

export type OrganizationInviteMutation = { organizationInvite: Pick<User, 'id'> };

export type TeamQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type TeamQuery = {
    organization: Pick<Organization, 'name'> & {
        usersOrganizations: Array<
            Pick<UsersOrganization, 'sendNotifications'> & {
                user: Pick<User, 'id' | 'email'> & {
                    userInfo: Pick<UsersInfo, 'id' | 'name'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    };
                };
            }
        >;
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
    };
};

export type UserEventDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    userId: Scalars['UserId'];
};

export type UserEventDeleteMutation = Pick<Mutation, 'userEventDelete'>;

export type UserEventUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    userId: Scalars['UserId'];
    roleId: Scalars['RoleId'];
};

export type UserEventUpdateMutation = Pick<Mutation, 'userEventUpdate'>;

export type UserLeaderDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserLeaderDeleteMutation = { userLeaderDelete: Pick<Event, 'id'> };

export type UserOrganizationDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    userId: Scalars['UserId'];
};

export type UserOrganizationDeleteMutation = { userOrganizationDelete: Pick<Organization, 'id'> };

export type UserOrganizationUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    userId: Scalars['UserId'];
    sendNotifications: Scalars['Boolean'];
};

export type UserOrganizationUpdateMutation = { userOrganizationUpdate: Pick<Organization, 'id'> };

export type UserPositionCategoryUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    roleId: Scalars['RoleId'];
};

export type UserPositionCategoryUpdateMutation = {
    userPositionCategoryUpdate?: Maybe<Pick<UsersPositionsCategory, 'positionCategoryId'>>;
};

export type UserPositionUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    positionId: Scalars['PositionId'];
    roleId: Scalars['RoleId'];
};

export type UserPositionUpdateMutation = {
    userPositionUpdate?: Maybe<Pick<UsersPosition, 'positionCategoryId'>>;
};

export type OrganizationTranslationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    content?: Maybe<Scalars['String']>;
    status?: Maybe<TranslationStatus>;
    eventsIds?: Maybe<Array<Scalars['EventId']>>;
    modules?: Maybe<Array<TranslationModule>>;
    offset?: Maybe<Scalars['Int']>;
};

export type OrganizationTranslationsQuery = {
    organization: {
        translations: Pick<TranslationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<TranslationFragment>;
        };
    };
};

export type OrganizationTranslationsStatsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationTranslationsStatsQuery = {
    organization: Pick<Organization, 'languages'> & {
        events: { nodes: Array<Pick<Event, 'id' | 'name'>> };
        translations: Pick<TranslationsConnection, 'totalCount'>;
        translated: Pick<TranslationsConnection, 'totalCount'>;
    };
};

export type TranslationUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    translationId: Scalars['TranslationId'];
    translation: TranslationInput;
};

export type TranslationUpdateMutation = { translationUpdate: Pick<Translation, 'id'> };

export type PositionCreateMutationVariables = {
    eventId: Scalars['EventId'];
    position: PositionInput;
};

export type PositionCreateMutation = { positionCreate: Pick<Position, 'id'> };

export type PositionEventInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type PositionEventInfosQuery = {
    organization: {
        positionCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
    } & CreatePositionCustomFieldsFragment &
        CreatePositionTagsFragment;
    event: CreatePositionEventFragment;
};

export type PositionToEditQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionToEditQuery = {
    organization: {
        positionCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
    } & CreatePositionCustomFieldsFragment &
        CreatePositionTagsFragment;
    event: {
        position: Pick<
            Position,
            | 'name'
            | 'description'
            | 'acronym'
            | 'color'
            | 'icon'
            | 'privateNote'
            | 'address'
            | 'latitude'
            | 'longitude'
            | 'layer'
            | 'zoom'
            | 'tracesIds'
            | 'tagsIds'
            | 'fields'
            | 'conditionsOperator'
        > & {
            category: Pick<PositionsCategory, 'id'>;
            slots: Array<
                Pick<
                    PositionsSlot,
                    | 'id'
                    | 'name'
                    | 'startDate'
                    | 'startTime'
                    | 'endDate'
                    | 'endTime'
                    | 'resources'
                    | 'assignedResources'
                >
            >;
            positionsCustomFields: Array<
                Pick<PositionsCustomField, 'customFieldId' | 'conditionValue'>
            >;
        };
    } & CreatePositionEventFragment;
};

export type PositionUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    position: PositionInput;
};

export type PositionUpdateMutation = { positionUpdate: Pick<Position, 'id'> };

export type PositionsImportMutationVariables = {
    eventId: Scalars['EventId'];
    organizationId: Scalars['OrganizationId'];
    input: PositionsImportInput;
};

export type PositionsImportMutation = { jobId: Mutation['positionsImport'] };

export type KmlEventInfosQueryVariables = {
    eventId: Scalars['EventId'];
};

export type KmlEventInfosQuery = {
    event: Pick<Event, 'layer' | 'startAt'> & {
        positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
    };
};

export type PositionsCreateMutationVariables = {
    eventId: Scalars['EventId'];
    positions: Array<PositionInput>;
};

export type PositionsCreateMutation = { positionsCreate: Array<Pick<Position, 'id'>> };

export type PositionsCategoriesQueryVariables = {
    eventId: Scalars['EventId'];
};

export type PositionsCategoriesQuery = {
    event: { positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>> };
};

export type PositionsMassDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    selecteds: MassActionSelectedsInput;
};

export type PositionsMassDeleteMutation = Pick<Mutation, 'positionsMassDelete'>;

export type PositionsPositionQueryVariables = {
    eventId: Scalars['EventId'];
    id: Scalars['PositionId'];
    includeCheckedInRate: Scalars['Boolean'];
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeLeaders: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
};

export type PositionsPositionQuery = { data: { row: PositionsPositionFragment } };

export type EventLayerUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    layer: MapLayer;
};

export type EventLayerUpdateMutation = { eventLayerUpdate: Pick<Event, 'id'> };

export type TraceCreateMutationVariables = {
    eventId: Scalars['EventId'];
    trace: TraceInput;
};

export type TraceCreateMutation = { traceCreate: Pick<Trace, 'id'> };

export type TraceDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    traceId: Scalars['TraceId'];
};

export type TraceDeleteMutation = { traceDelete: Pick<Trace, 'id'> };

export type TraceUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    traceId: Scalars['TraceId'];
    trace: TraceInput;
};

export type TraceUpdateMutation = { traceUpdate: Pick<Trace, 'id'> };

export type TracesQueryVariables = {
    eventId: Scalars['EventId'];
};

export type TracesQuery = {
    event: { traces: Array<Pick<Trace, 'id' | 'name' | 'color' | 'points'>> };
};

export type PositionSlotCreateMutationVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    positionSlot: PositionsSlotInput;
};

export type PositionSlotCreateMutation = { positionSlotCreate: Pick<PositionsSlot, 'id'> };

export type PositionsPlanningQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type PositionsPlanningQuery = {
    data: {
        rows: Pick<PositionsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<PositionPlanningFragment>;
        };
    };
};

export type PositionsPlanningPositionQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionsPlanningPositionQuery = { data: { row: PositionPlanningFragment } };

export type PositionCategoryLeaderDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type PositionCategoryLeaderDeleteMutation = {
    positionCategoryLeaderDelete: Pick<PositionsCategory, 'id'>;
};

export type PositionsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    hasCoordinates?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<PositionsSort>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    includeCheckedInRate: Scalars['Boolean'];
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeLeaders: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
    includeTraces: Scalars['Boolean'];
};

export type PositionsQuery = {
    data: Pick<Event, 'layer' | 'latitude' | 'longitude'> & {
        rows: Pick<PositionsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<PositionsPositionFragment>;
        };
        traces: Array<TraceFragment>;
    };
};

export type PositionsHeaderQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
};

export type PositionsHeaderQuery = {
    event: {
        segment: Pick<Segment, 'count'> & {
            links: Pick<
                SegmentDocumentLink,
                'positionsPlanningLink' | 'positionsVolunteersPlanningLink'
            >;
            stats?: Maybe<Pick<PositionsSegmentStats, 'assignedResources' | 'resources'>>;
        };
    };
};

export type PositionsSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type PositionsSegmentsQuery = {
    organization: { customFields: { nodes: Array<SegmentCustomFieldFragment> } };
    event: Pick<Event, 'organizationId' | 'startAt' | 'endAt' | 'numberOfPositions'> & {
        positionsCategories: Array<
            Pick<PositionsCategory, 'id' | 'name'> & { leaders: Array<LeaderInfosFragment> }
        >;
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type PositionMapQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionMapQuery = {
    eventPublic: {
        position: Pick<PositionPublic, 'layer' | 'latitude' | 'longitude' | 'zoom'> & {
            traces: Array<TraceFragment>;
        };
    };
};

export type MassConditionsCustomFieldsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type MassConditionsCustomFieldsQuery = { organization: CreatePositionCustomFieldsFragment };

export type PositionsMassConditionsMutationVariables = {
    eventId: Scalars['EventId'];
    massConditions: MassConditionsInput;
};

export type PositionsMassConditionsMutation = Pick<Mutation, 'positionsMassConditions'>;

export type PositionsMobileQueryVariables = {
    eventId: Scalars['EventId'];
    predicates?: Maybe<Scalars['JSON']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
};

export type PositionsMobileQuery = {
    event: {
        positions: Pick<PositionsConnection, 'totalCount'> & {
            nodes: Array<
                Pick<
                    Position,
                    'id' | 'name' | 'range' | 'resources' | 'assignedResources' | 'isVisible'
                > & {
                    category: Pick<PositionsCategory, 'id' | 'name'> & {
                        leaders: Array<LeaderInfosFragment>;
                    };
                    slots: Array<Pick<PositionsSlot, 'id'>>;
                }
            >;
            pageInfo: FullPageInfoFragment;
        };
    };
};

export type PositionsSegmentsMobileQueryVariables = {
    eventId: Scalars['EventId'];
};

export type PositionsSegmentsMobileQuery = {
    event: { segmentsFolders: Array<SegmentsFoldersSegmentsFragment> };
};

export type RecentPositionsSearchesMobileQueryVariables = {
    eventId: Scalars['EventId'];
};

export type RecentPositionsSearchesMobileQuery = {
    event: Pick<Event, 'recentPositionsRegistrationsSearches'>;
};

export type PositionSlotMembersMobileQueryVariables = {
    eventId: Scalars['EventId'];
    positionSlotId: Scalars['PositionsSlotId'];
    predicates: Scalars['JSON'];
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type PositionSlotMembersMobileQuery = {
    event: {
        positionSlot: Pick<PositionsSlot, 'name' | 'positionCategoryId' | 'range'>;
        volunteersRegistrations: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<
                Pick<VolunteersRegistration, 'id'> & {
                    positionSlotUserInfo?: Maybe<Pick<PositionsSlotsUsersInfo, 'id' | 'state'>>;
                    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    };
                }
            >;
            pageInfo: FullPageInfoFragment;
        };
    };
};

export type PositionSlotsMobileQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type PositionSlotsMobileQuery = {
    event: {
        positionsSlots: Pick<PositionsSlotsConnection, 'totalCount'> & {
            nodes: Array<PositionPositionSlotFragment>;
            pageInfo: FullPageInfoFragment;
        };
    };
};

export type PositionDashboardQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionDashboardQuery = {
    organization: {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        roles: Array<Pick<Role, 'id' | 'name' | 'type'>>;
    };
    event: {
        position: Pick<
            Position,
            | 'id'
            | 'positionCategoryId'
            | 'positionCategoryName'
            | 'name'
            | 'description'
            | 'privateNote'
            | 'color'
            | 'icon'
            | 'resources'
            | 'assignedResources'
            | 'preassignedResources'
            | 'address'
            | 'latitude'
            | 'longitude'
            | 'zoom'
            | 'layer'
            | 'isVisible'
            | 'range'
            | 'fields'
        > & {
            links: Pick<PositionDocumentLink, 'positionPlanningLink' | 'positionSheetLink'>;
            traces: Array<TraceFragment>;
            customFields: Array<
                Pick<PositionsCustomField, 'conditionValue'> & {
                    customField: Pick<CustomField, 'name' | 'fieldType'> & {
                        values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
                    };
                }
            >;
            leaders: Array<LeaderInfosFragment>;
            tags: Array<Pick<Tag, 'id' | 'name'>>;
        };
    };
};

export type PositionQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionQuery = {
    event: {
        position: Pick<
            Position,
            | 'id'
            | 'name'
            | 'acronym'
            | 'positionCategoryId'
            | 'assignedResources'
            | 'preassignedResources'
        > & { links: Pick<PositionDocumentLink, 'positionPlanningLink' | 'positionSheetLink'> };
    };
};

export type PositionLeaderDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type PositionLeaderDeleteMutation = { positionLeaderDelete: Pick<Position, 'id'> };

export type PositionLeadersAsignMutationVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    leaders: Array<LeaderInput>;
};

export type PositionLeadersAsignMutation = { positionLeadersAssign: Pick<Position, 'id'> };

export type PositionSlotDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    positionSlotId: Scalars['PositionsSlotId'];
};

export type PositionSlotDeleteMutation = { positionSlotDelete: Pick<PositionsSlot, 'id'> };

export type PositionSlotUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    positionSlotId: Scalars['PositionsSlotId'];
    positionSlot: PositionsSlotInput;
};

export type PositionSlotUpdateMutation = { positionSlotUpdate: Pick<PositionsSlot, 'id'> };

export type PositionSlotVolunteersQueryVariables = {
    eventId: Scalars['EventId'];
    positionSlotId: Scalars['PositionsSlotId'];
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<VolunteersRegistrationsSort>;
};

export type PositionSlotVolunteersQuery = {
    event: {
        positionSlot: Pick<
            PositionsSlot,
            | 'id'
            | 'positionId'
            | 'positionCategoryId'
            | 'nameOrRange'
            | 'assignedResources'
            | 'resources'
        >;
        volunteersRegistrations: Pick<
            VolunteersRegistrationsConnection,
            'numberOfPages' | 'totalCount'
        > & {
            nodes: Array<
                Pick<VolunteersRegistration, 'id'> & {
                    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    };
                    positionSlotUserInfo?: Maybe<
                        Pick<
                            PositionsSlotsUsersInfo,
                            'id' | 'checkInAt' | 'checkOutAt' | 'state' | 'timeDifferenceMinutes'
                        >
                    >;
                }
            >;
        };
    };
};

export type PositionSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    name?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['Date']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<PositionsSlotsSort>;
};

export type PositionSlotsQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> & {
        positionsSlots: Pick<PositionsSlotsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<PositionPositionSlotFragment>;
        };
    };
};

export type PositionCategoryCreateMutationVariables = {
    eventId: Scalars['EventId'];
    positionCategory: PositionsCategoryInput;
};

export type PositionCategoryCreateMutation = {
    positionCategoryCreate: Pick<PositionsCategory, 'id' | 'name'>;
};

export type PositionCategoryUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    positionCategory: PositionsCategoryInput;
};

export type PositionCategoryUpdateMutation = {
    positionCategoryUpdate: Pick<PositionsCategory, 'id' | 'name'>;
};

export type PositionCategoryDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
};

export type PositionCategoryDeleteMutation = {
    positionCategoryDelete: Pick<PositionsCategory, 'id'>;
};

export type PositionCategoryQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
};

export type PositionCategoryQuery = {
    organization: { roles: Array<Pick<Role, 'id' | 'name' | 'type'>> };
    event: {
        positionCategory: Pick<
            PositionsCategory,
            | 'id'
            | 'name'
            | 'description'
            | 'assignedResources'
            | 'preassignedResources'
            | 'numberOfPositions'
            | 'resources'
            | 'range'
        > & { leaders: Array<LeaderInfosFragment> };
    };
};

export type PositionCategoryDescriptionUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    description: Scalars['String'];
};

export type PositionCategoryDescriptionUpdateMutation = {
    positionCategoryDescriptionUpdate: Pick<PositionsCategory, 'id'>;
};

export type PositionCategoryDuplicateMutationVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
};

export type PositionCategoryDuplicateMutation = {
    positionCategoryDuplicate: Pick<PositionsCategory, 'id'>;
};

export type PositionCategoryPositionsQueryVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    name?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['Date']>;
    offset?: Maybe<Scalars['Int']>;
};

export type PositionCategoryPositionsQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> & {
        positions: Pick<PositionsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<PositionCategoryPositionFragment>;
        };
    };
};

export type PositionsCategoryLeadersAssignMutationVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
    leaders: Array<LeaderInput>;
};

export type PositionsCategoryLeadersAssignMutation = {
    positionsCategoryLeadersAssign: Pick<PositionsCategory, 'id'>;
};

export type AvailableVolunteersRegistrationsQueryVariables = {
    eventId: Scalars['EventId'];
    positionSlotId: Scalars['PositionsSlotId'];
    filterAvailabilities: Scalars['Boolean'];
    filterPositions: Scalars['Boolean'];
    applyConditions: Scalars['Boolean'];
    sort?: Maybe<VolunteersRegistrationsSort>;
};

export type AvailableVolunteersRegistrationsQuery = {
    event: {
        positionSlot: Pick<PositionsSlot, 'positionName' | 'positionCategoryId' | 'nameOrRange'> & {
            availableVolunteersRegistrations: Array<
                Pick<
                    VolunteersRegistration,
                    | 'id'
                    | 'positionsCategoriesIds'
                    | 'positionsSlotsIds'
                    | 'positionsIds'
                    | 'numberOfAssignments'
                    | 'isAssignedTo'
                    | 'insertedAt'
                > & {
                    userInfo: Pick<UsersInfo, 'id' | 'email' | 'name'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    };
                }
            >;
        };
    };
};

export type PositionsPositionSlotQueryVariables = {
    eventId: Scalars['EventId'];
    id: Scalars['PositionsSlotId'];
    includeCheckedInRate: Scalars['Boolean'];
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
};

export type PositionsPositionSlotQuery = { event: { positionSlot: PositionsPositionSlotFragment } };

export type PositionsSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    predicates?: Maybe<Scalars['JSON']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<PositionsSlotsSort>;
    includeCheckedInRate: Scalars['Boolean'];
    includeConditions: Scalars['Boolean'];
    includeCreatedBy: Scalars['Boolean'];
    includeTags: Scalars['Boolean'];
};

export type PositionsSlotsQuery = {
    event: Pick<Event, 'startAt' | 'endAt'> & {
        positionsSlots: Pick<PositionsSlotsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<PositionsPositionSlotFragment>;
        };
    };
};

export type UserFieldUpdateQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    slug: Scalars['CustomFieldSlug'];
};

export type UserFieldUpdateQuery = {
    organization: { customField: Pick<CustomField, 'name' | 'description'> };
};

export type UserInfoMobileQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserInfoMobileQuery = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: {
        formsCustomsFields: Array<CustomFieldWithConditionFragment>;
        volunteerRegistration: Pick<
            VolunteersRegistration,
            'id' | 'state' | 'volunteerMinutes' | 'insertedAt'
        > & {
            slots: Array<
                Pick<FormsUsersInfosSlot, 'range'> | Pick<VolunteersRegistrationsSlot, 'range'>
            >;
            positionsSlots: Array<
                Pick<PositionsSlot, 'nameOrRange' | 'range'> & {
                    position: Pick<Position, 'id' | 'name'>;
                }
            >;
            positions: Array<Pick<Position, 'id' | 'name' | 'range'>>;
            positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name' | 'range'>>;
            userInfo: UserInfoOverlayFragment;
            positionsSlotsUsersInfos: Array<UserPositionSlotUserInfoFragment>;
            preassignPositions: Array<Pick<Position, 'id' | 'name' | 'range'>>;
            preassignPositionsCategories: Array<Pick<PositionsCategory, 'id' | 'name' | 'range'>>;
        };
    };
};

export type UserProfileAccreditationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserProfileAccreditationsQuery = {
    organization: {
        userInfo: {
            formsUsersInfos: Array<{
                accreditationsSlots: Array<
                    Pick<AccreditationsSlot, 'name' | 'date'> & {
                        accreditation: Pick<Accreditation, 'id' | 'name'>;
                    }
                >;
                form: Pick<Form, 'id' | 'name'>;
            }>;
        };
    };
    event: Pick<
        Event,
        'startAt' | 'endAt' | 'hasFormWithAccreditationDisplay' | 'isWeezeventConnected'
    > & {
        volunteerRegistration: {
            accreditationsUsersInfos: Array<UserPanelAccreditationSlotUserInfoFragment>;
        };
    };
};

export type UserInfoAdminDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserInfoAdminDeleteMutation = { userInfoAdminDelete: Pick<UsersInfo, 'id'> };

export type UserOverlayActionsEventQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserOverlayActionsEventQuery = {
    event: {
        customDocuments: Array<CustomDocumentPopulationFragment>;
        volunteerRegistration: Pick<VolunteersRegistration, 'accreditationState' | 'state'> & {
            delegation?: Maybe<Pick<Delegation, 'id' | 'name'>>;
            userInfo: {
                links?: Maybe<
                    Pick<
                        UserInfoDocumentLink,
                        | 'userAssignmentsLink'
                        | 'userBadgeLink'
                        | 'userCertificateLink'
                        | 'userCustomBadgeLinks'
                        | 'userCustomDocumentLinks'
                        | 'userPlanningLink'
                        | 'userPlanningDaysLink'
                        | 'userPlanningListLink'
                    >
                >;
            } & UserInfoOverlayFragment;
        };
    };
};

export type UserOverlayActionsOrganizationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserOverlayActionsOrganizationQuery = {
    organization: { userInfo: UserInfoOverlayFragment };
};

export type UserHistoryCampaignsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserHistoryCampaignsQuery = {
    organization: {
        userInfo: {
            campaigns: {
                nodes: Array<
                    Pick<
                        Campaign,
                        'id' | 'organizationId' | 'eventId' | 'name' | 'campaignType' | 'sentAt'
                    > & {
                        event?: Maybe<Pick<Event, 'name'>>;
                        emails: { nodes: Array<Pick<Email, 'state'>> };
                        textMessages: { nodes: Array<Pick<TextMessage, 'state'>> };
                    }
                >;
            };
        };
    };
};

export type UserHistoryEventsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserHistoryEventsQuery = {
    organization: {
        userInfo: {
            volunteersRegistrations: Array<
                Pick<VolunteersRegistration, 'id' | 'insertedAt'> & {
                    delegation?: Maybe<Pick<Delegation, 'name'>>;
                    event: Pick<Event, 'id' | 'name'>;
                    accreditationsUsersInfos: Array<{
                        accreditation: Pick<
                            Accreditation,
                            'id' | 'acronym' | 'color' | 'icon' | 'name'
                        >;
                    }>;
                    positionsSlotsUsersInfos: Array<{
                        position: Pick<
                            PositionPublic,
                            'id' | 'acronym' | 'color' | 'icon' | 'name'
                        >;
                    }>;
                }
            >;
        };
    };
};

export type UserHistoryFormsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserHistoryFormsQuery = {
    organization: {
        userInfo: {
            formsUsersInfos: Array<
                Pick<FormsUsersInfo, 'id' | 'insertedAt'> & {
                    form: Pick<Form, 'name'>;
                    event?: Maybe<Pick<Event, 'name'>>;
                }
            >;
        };
    };
};

export type EventProfilePrivateFieldsUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    profilePrivateFields: Array<Scalars['CustomFieldSlug']>;
};

export type EventProfilePrivateFieldsUpdateMutation = {
    eventProfilePrivateFieldsUpdate: Pick<Event, 'id'>;
};

export type OrganizationProfilePrivateFieldsUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    profilePrivateFields: Array<Scalars['CustomFieldSlug']>;
};

export type OrganizationProfilePrivateFieldsUpdateMutation = {
    organizationProfilePrivateFieldsUpdate: Pick<Organization, 'id'>;
};

export type UserInformationsEventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserInformationsEventQuery = {
    organization: Pick<Organization, 'weezeventOrganizationId'> & {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        userInfo: {
            formsUsersInfos: Array<FormUserInfoUserProfileFragment>;
            user: {
                usersInfos: Array<
                    Pick<UsersInfo, 'id' | 'name' | 'email'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                        delegations: Array<Pick<Delegation, 'id' | 'name'>>;
                    }
                >;
            };
        } & UserInfoOverlayFragment;
    };
    event: Pick<Event, 'profilePrivateFields' | 'weezevent'> & {
        forms: Array<FormUserOverlayFragment>;
        volunteerRegistration: Pick<
            VolunteersRegistration,
            'insertedAt' | 'shortTag' | 'ticketId' | 'weezeventParticipantId'
        > & { delegation?: Maybe<DelegationUserProfileFragment> };
    };
};

export type UserInformationsOrganizationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserInformationsOrganizationQuery = {
    organization: Pick<Organization, 'profilePrivateFields'> & {
        customFields: {
            nodes: Array<
                { category: Pick<CustomFieldsCategory, 'name'> } & CustomFieldWithConditionFragment
            >;
        };
        forms: Array<FormUserOverlayFragment>;
        userInfo: Pick<UsersInfo, 'insertedAt'> & {
            delegations: Array<DelegationUserProfileFragment>;
            formsUsersInfos: Array<FormUserInfoUserProfileFragment>;
            user: {
                usersInfos: Array<
                    Pick<UsersInfo, 'id' | 'name' | 'email'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    }
                >;
            };
        } & UserInfoOverlayFragment;
    };
};

export type UserPositionsAvailablePositionsSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPositionsAvailablePositionsSlotsQuery = {
    event: {
        volunteerRegistration: {
            availablePositionsSlots: Array<
                Pick<PositionsSlot, 'id' | 'name' | 'range'> & { position: Pick<Position, 'name'> }
            >;
        };
    };
};

export type UserOverlayQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
};

export type UserOverlayQuery = {
    organization: {
        userInfo: Pick<UsersInfo, 'numberOfComments'> & {
            volunteerRegistration?: Maybe<
                Pick<VolunteersRegistration, 'state' | 'accreditationState'>
            >;
        } & UserInfoOverlayFragment;
    };
};

export type CommentCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    comment: CommentInput;
};

export type CommentCreateMutation = { commentCreate: Pick<Comment, 'id'> };

export type CommentDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    commentId: Scalars['CommentId'];
};

export type CommentDeleteMutation = { commentDelete: Pick<Comment, 'id'> };

export type CommentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type CommentsQuery = { organization: { userInfo: { comments: Array<CommentFragment> } } };

export type UpdateUserInfosEventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UpdateUserInfosEventQuery = {
    organization: {
        customFields: {
            nodes: Array<
                { category: Pick<CustomFieldsCategory, 'name'> } & CustomFieldWithConditionFragment
            >;
        };
        userInfo: Pick<
            UsersInfo,
            'id' | 'isEditable' | 'userId' | 'email' | 'nameOrEmail' | 'fields'
        > & { formsUsersInfos: Array<Pick<FormsUsersInfo, 'formId' | 'insertedAt' | 'teamCode'>> };
    };
    event: Pick<Event, 'country'> & { forms: Array<UpdateUserFormFragment> };
};

export type UpdateUserInfosOrganizationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UpdateUserInfosOrganizationQuery = {
    organization: Pick<Organization, 'country'> & {
        customFields: {
            nodes: Array<
                { category: Pick<CustomFieldsCategory, 'name'> } & CustomFieldWithConditionFragment
            >;
        };
        forms: Array<UpdateUserFormFragment>;
        userInfo: Pick<UsersInfo, 'id' | 'userId' | 'email' | 'nameOrEmail' | 'fields'> & {
            formsUsersInfos: Array<Pick<FormsUsersInfo, 'formId' | 'insertedAt' | 'teamCode'>>;
        };
    };
};

export type OrganizationUsersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    excludedEventId?: Maybe<Scalars['EventId']>;
    excludedDelegationId?: Maybe<Scalars['DelegationId']>;
    name?: Maybe<Scalars['String']>;
};

export type OrganizationUsersInfosQuery = {
    organization: { usersInfos: { nodes: Array<ExistingVolunteerFragment> } };
};

export type UserInfoAdminCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    email: Scalars['UserEmail'];
    sendVolunteerRegistrationEmail: Scalars['Boolean'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type UserInfoAdminCreateMutation = { userInfoAdminCreate: Pick<UsersInfo, 'id'> };

export type VolunteersRegistrationsAdminAddMutationVariables = {
    eventId: Scalars['EventId'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
    sendVolunteerRegistrationEmail: Scalars['Boolean'];
};

export type VolunteersRegistrationsAdminAddMutation = {
    volunteersRegistrationsAdminAdd: Array<Pick<VolunteersRegistration, 'id'>>;
};

export type UsersInfosExportMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    segmentId: Scalars['SegmentId'];
    format: ExportFormat;
    includeFiles: Scalars['Boolean'];
    columns: Array<Scalars['String']>;
};

export type UsersInfosExportMutation = { jobId: Mutation['usersInfosExport'] };

export type VolunteersExportMutationVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
    format: ExportFormat;
    includeFiles: Scalars['Boolean'];
    columns: Array<Scalars['String']>;
};

export type VolunteersExportMutation = { jobId: Mutation['volunteersExport'] };

export type EventVolunteersImportInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    variety: CustomFieldVariety;
    eventId: Scalars['EventId'];
};

export type EventVolunteersImportInfosQuery = {
    organization: { customFields: { nodes: Array<CustomFieldImportFragment> } };
    event: { accreditations: { nodes: Array<AccreditationImportFragment> } };
};

export type OrganizationImportInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    variety?: Maybe<CustomFieldVariety>;
};

export type OrganizationImportInfosQuery = {
    organization: { customFields: { nodes: Array<CustomFieldImportFragment> } };
};

export type VolunteersImportMutationVariables = {
    eventId?: Maybe<Scalars['EventId']>;
    organizationId: Scalars['OrganizationId'];
    input: VolunteersImportInput;
};

export type VolunteersImportMutation = { jobId: Mutation['volunteersImport'] };

export type VolunteersAccreditationsMatrixQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersAccreditationsMatrixQuery = {
    event: {
        accreditations: {
            nodes: Array<
                Pick<Accreditation, 'id' | 'acronym' | 'name'> & {
                    accreditationCategory: Pick<AccreditationsCategory, 'name'>;
                    slots: Array<Pick<AccreditationsSlot, 'id' | 'name' | 'date'>>;
                }
            >;
        };
    };
};

export type VolunteersPlanningQueryVariables = {
    eventId: Scalars['EventId'];
    predicates: Scalars['JSON'];
    name?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type VolunteersPlanningQuery = {
    data: {
        rows: Pick<VolunteersRegistrationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<DocumentUsersPlanningFragment>;
        };
    };
};

export type VolunteerActionsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteerActionsQuery = {
    event: Pick<Event, 'hasDelegations' | 'hasForms'> & {
        weezeventConnectionInfos?: Maybe<Pick<WeezeventConnectionInfo, 'idEvent'>>;
    };
};

export type VolunteersHeaderQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
};

export type VolunteersHeaderQuery = {
    event: { segment: { links: Pick<SegmentDocumentLink, 'usersListingLink'> } };
};

export type MassAssignSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    positionName?: Maybe<Scalars['String']>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    excludeFull?: Maybe<Scalars['Boolean']>;
    filterAvailabilities?: Maybe<Scalars['Boolean']>;
    filterWished?: Maybe<Scalars['Boolean']>;
    selecteds?: Maybe<MassActionSelectedsInput>;
};

export type MassAssignSlotsQuery = {
    event: {
        segments: Array<Pick<Segment, 'id' | 'name'>>;
        positionsSlots: {
            nodes: Array<
                Pick<PositionsSlot, 'id' | 'name' | 'range' | 'assignedResources' | 'resources'> & {
                    position: Pick<Position, 'id' | 'positionCategoryId' | 'acronym' | 'name'>;
                    positionCategory: Pick<PositionsCategory, 'id' | 'name'>;
                }
            >;
        };
    };
};

export type VolunteersRegistrationsMassPreAssignMutationVariables = {
    eventId: Scalars['EventId'];
    massPreAssign: MassPreAssignInput;
};

export type VolunteersRegistrationsMassPreAssignMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassPreAssign'
>;

export type MassCampaignSendEventCampaignsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type MassCampaignSendEventCampaignsQuery = {
    event: { campaigns: { nodes: Array<Pick<Campaign, 'id' | 'name'>> } };
};

export type MassCampaignSendOrganizationCampaignsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type MassCampaignSendOrganizationCampaignsQuery = {
    organization: { campaigns: { nodes: Array<Pick<Campaign, 'id' | 'name'>> } };
};

export type UsersInfosMassCampaignSendMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    campaignId: Scalars['CampaignId'];
    selecteds: MassActionSelectedsInput;
};

export type UsersInfosMassCampaignSendMutation = Pick<Mutation, 'usersInfosMassCampaignSend'>;

export type VolunteersRegistrationsMassCampaignSendMutationVariables = {
    eventId: Scalars['EventId'];
    campaignId: Scalars['CampaignId'];
    selecteds: MassActionSelectedsInput;
};

export type VolunteersRegistrationsMassCampaignSendMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassCampaignSend'
>;

export type MassDelegationAddOrganizationDelegationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type MassDelegationAddOrganizationDelegationsQuery = {
    organization: { delegations: { nodes: Array<Pick<Delegation, 'id' | 'name'>> } };
};

export type UsersInfosMassDelegationAddMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
    selecteds: MassActionSelectedsInput;
};

export type UsersInfosMassDelegationAddMutation = Pick<Mutation, 'usersInfosMassDelegationAdd'>;

export type VolunteersRegistrationsMassDelegationAddMutationVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    selecteds: MassActionSelectedsInput;
};

export type VolunteersRegistrationsMassDelegationAddMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassDelegationAdd'
>;

export type UsersInfosMassFormAddMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type UsersInfosMassFormAddMutation = Pick<Mutation, 'usersInfosMassFormAdd'>;

export type VolunteersRegistrationsMassFormAddMutationVariables = {
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type VolunteersRegistrationsMassFormAddMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassFormAdd'
>;

export type UsersInfosMassFormRemoveMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type UsersInfosMassFormRemoveMutation = Pick<Mutation, 'usersInfosMassFormRemove'>;

export type VolunteersRegistrationsMassFormRemoveMutationVariables = {
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
    selecteds: MassActionSelectedsInput;
};

export type VolunteersRegistrationsMassFormRemoveMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassFormRemove'
>;

export type MassPreAssignCategoriesQueryVariables = {
    eventId: Scalars['EventId'];
};

export type MassPreAssignCategoriesQuery = {
    event: {
        positionsCategories: Array<
            Pick<PositionsCategory, 'id' | 'name' | 'assignedResources' | 'resources'>
        >;
    };
};

export type MassPreAssignPositionsQueryVariables = {
    eventId: Scalars['EventId'];
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<PositionsSort>;
};

export type MassPreAssignPositionsQuery = {
    event: {
        positions: Pick<PositionsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<
                Pick<
                    Position,
                    'id' | 'name' | 'positionCategoryName' | 'assignedResources' | 'resources'
                >
            >;
        };
    };
};

export type VolunteersMobileQueryVariables = {
    eventId: Scalars['EventId'];
    predicates: Scalars['JSON'];
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
};

export type VolunteersMobileQuery = {
    event: {
        volunteersRegistrations: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<
                Pick<VolunteersRegistration, 'id' | 'state'> & {
                    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    };
                }
            >;
            pageInfo: FullPageInfoFragment;
        };
    };
};

export type VolunteersSegmentsMobileQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsMobileQuery = {
    event: { segmentsFolders: Array<SegmentsFoldersSegmentsFragment> };
};

export type RecentVolunteersSearchesMobileQueryVariables = {
    eventId: Scalars['EventId'];
};

export type RecentVolunteersSearchesMobileQuery = {
    event: Pick<Event, 'recentVolunteersRegistrationsSearches'>;
};

export type EmailCampaignQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    campaignId: Scalars['CampaignId'];
};

export type EmailCampaignQuery = {
    organization: Pick<Organization, 'id' | 'name' | 'exportDatetimeFormat'> & {
        campaign: {
            emailSender?: Maybe<EmailSenderEmailRendererFragment>;
            event?: Maybe<EventEmailRendererFragment>;
        } & CampaignEmailRendererFragment;
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        userInfo: {
            volunteerRegistration?: Maybe<VolunteerRegistrationEmailRendererFragment>;
        } & UserInfoEmailRendererFragment;
    };
};

export type EmailDelegationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
};

export type EmailDelegationQuery = {
    organization: { delegation: Pick<Delegation, 'id' | 'formId' | 'name'> };
};

export type EmailEventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    emailType?: Maybe<EmailType>;
    formId?: Maybe<Scalars['FormId']>;
    loadVolunteerRegistration: Scalars['Boolean'];
};

export type EmailEventQuery = {
    organization: {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        emailTemplate?: Maybe<
            {
                emailSender?: Maybe<EmailSenderEmailRendererFragment>;
            } & EmailTemplateEmailRendererFragment
        >;
        userInfo: UserInfoEmailRendererFragment;
    } & OrganizationEmailRendererFragment;
    event: {
        emailSender?: Maybe<EmailSenderEmailRendererFragment>;
        volunteerRegistration: VolunteerRegistrationEmailRendererFragment;
    } & EventEmailRendererFragment;
};

export type EmailOrganizationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    emailType?: Maybe<EmailType>;
    source?: Maybe<LoginCodeSource>;
    formId?: Maybe<Scalars['FormId']>;
    userInfoId: Scalars['UsersInfoId'];
};

export type EmailOrganizationQuery = {
    organization: {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        emailSender?: Maybe<EmailSenderEmailRendererFragment>;
        emailTemplate?: Maybe<
            {
                emailSender?: Maybe<EmailSenderEmailRendererFragment>;
            } & EmailTemplateEmailRendererFragment
        >;
        userInfo: UserInfoEmailRendererFragment;
    } & OrganizationEmailRendererFragment;
};

export type EmailPositionQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type EmailPositionQuery = { event: { position: Pick<Position, 'name'> } };

export type EmailPositionCategoryQueryVariables = {
    eventId: Scalars['EventId'];
    positionCategoryId: Scalars['PositionsCategoryId'];
};

export type EmailPositionCategoryQuery = {
    event: { positionCategory: Pick<PositionsCategory, 'name'> };
};

export type FormInviteQueryVariables = {
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
};

export type FormInviteQuery = {
    event: {
        team?: Maybe<
            Pick<Team, 'leaderUserInfoId'> & {
                positionsCategories: Array<Pick<PositionsCategoryPublic, 'name'>>;
                positions: Array<Pick<PositionPublic, 'name'>>;
                slots: Array<
                    Pick<FormsUsersInfosSlot, 'range'> | Pick<VolunteersRegistrationsSlot, 'range'>
                >;
                accreditationsSlots: Array<
                    Pick<AccreditationsSlotPublic, 'name' | 'date'> & {
                        accreditation: Pick<AccreditationPublic, 'name'>;
                    }
                >;
            }
        >;
    };
};

export type FormSubmitQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
    formId: Scalars['FormId'];
};

export type FormSubmitQuery = { organization: { userInfo: { formUserInfo: FormEmailFragment } } };

export type NewMemberQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    formId: Scalars['FormId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type NewMemberQuery = {
    organization: { form: Pick<Form, 'name'>; userInfo: Pick<UsersInfo, 'name'> };
};

export type NewVolunteerQueryVariables = {
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
};

export type NewVolunteerQuery = {
    event: { volunteerRegistration: { userInfo: Pick<UsersInfo, 'name'> } };
};

export type UserAccreditationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserAccreditationsQuery = {
    organization: { userInfo: { formsUsersInfos: Array<WishesEmailFragment> } };
    event: {
        volunteerRegistration: Pick<VolunteersRegistration, 'id'> & {
            accreditationsUsersInfos: Array<AccreditationEmailFragment>;
        };
    };
};

export type EventNameQueryVariables = {
    eventId: Scalars['EventId'];
};

export type EventNameQuery = { event: Pick<Event, 'name'> };

export type WeezeventInfosQueryVariables = {
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
};

export type WeezeventInfosQuery = {
    event: Pick<Event, 'organizationId'> & {
        volunteerRegistration?: Maybe<{
            delegation?: Maybe<Pick<Delegation, 'name'>>;
            userInfo: Pick<UsersInfo, 'id' | 'email' | 'fields'> & {
                firstName: UsersInfo['fieldValue'];
                lastName: UsersInfo['fieldValue'];
                population: UsersInfo['fieldValue'];
                sousPopulationVendeeGlobe: UsersInfo['fieldValue'];
            } & { picture?: Maybe<Pick<Document, 'url'>> };
            positionsSlotsUsersInfos: Array<{
                positionCategory: Pick<PositionsCategory, 'name'>;
                position: Pick<PositionPublic, 'id' | 'name'>;
            }>;
            weezeventParticipantInfo?: Maybe<
                Pick<WeezeventParticipantInfo, 'idBarcode' | 'idParticipant'>
            >;
        }>;
        weezeventConnectionInfos?: Maybe<
            Pick<
                WeezeventConnectionInfo,
                'apiKey' | 'accessToken' | 'idEvent' | 'idTicket' | 'synchronizationDirection'
            >
        >;
        weezeventRules: {
            nodes: Array<
                Pick<WeezeventRule, 'idQuestion' | 'value'> & {
                    segment: Pick<Segment, 'usersInfosIds'>;
                }
            >;
        };
    };
};

export type WeezeventV2CreateChecklistQueryVariables = {
    eventId: Scalars['EventId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
};

export type WeezeventV2CreateChecklistQuery = {
    event: Pick<Event, 'name'> & {
        accreditationSlot: Pick<
            AccreditationsSlot,
            'id' | 'name' | 'date' | 'isHidden' | 'weezeventChecklistId'
        > & {
            accreditation: Pick<
                Accreditation,
                'id' | 'name' | 'hasSlots' | 'color' | 'weezeventIsSynchronized'
            >;
        };
    };
};

export type WeezeventV2CreateEventQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type WeezeventV2CreateEventQuery = {
    organization: { customFields: { nodes: Array<Pick<CustomField, 'slug' | 'name'>> } };
    event: {
        accreditationsSlots: {
            nodes: Array<
                Pick<AccreditationsSlot, 'id' | 'name' | 'date'> & {
                    accreditation: Pick<Accreditation, 'name'>;
                }
            >;
        };
        segments: Array<Pick<Segment, 'id' | 'name'>>;
    };
};

export type WeezeventV2CreateMemberQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
};

export type WeezeventV2CreateMemberQuery = {
    organization: {
        customFields: {
            nodes: Array<Pick<CustomField, 'fieldType' | 'slug' | 'name' | 'canSelectMultiple'>>;
        };
    };
    event: {
        accreditationsSlots: {
            nodes: Array<
                Pick<AccreditationsSlot, 'id' | 'name' | 'date'> & {
                    accreditation: Pick<Accreditation, 'name'>;
                }
            >;
        };
        volunteerRegistration: VolunteerRegistrationWeezeventFragment;
    };
};

export type DocumentPositionsPlanningQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
    date?: Maybe<Scalars['Date']>;
};

export type DocumentPositionsPlanningQuery = { event: DocumentPositionsPlanningFragment };

export type PositionPlanningQueryVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
    date?: Maybe<Scalars['Date']>;
};

export type PositionPlanningQuery = { event: DocumentPositionPlanningFragment };

export type PositionSheetQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionSheetQuery = DocumentPositionSheetFragment;

export type PositionsVolunteersPlanningQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
};

export type PositionsVolunteersPlanningQuery = {
    event: DocumentPositionsVolunteersPlanningFragment;
};

export type UserAssignmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserAssignmentsQuery = {
    organization: { userInfo: { formsUsersInfos: Array<WishesEmailFragment> } };
} & DocumentUserAssignmentsFragment;

export type UserBadgeQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserBadgeQuery = { event: DocumentUserBadgeFragment };

export type UserCertificateQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserCertificateQuery = { event: DocumentUserCertificateFragment };

export type UserCustomBadgeQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    customBadge: CustomBadge;
};

export type UserCustomBadgeQuery = { event: DocumentUserCustomBadgeFragment };

export type UserCustomBadgesQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
    customBadge: CustomBadge;
};

export type UserCustomBadgesQuery = { event: DocumentUserCustomBadgesFragment };

export type UserCustomDocumentQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    customDocumentSlug: Scalars['String'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserCustomDocumentQuery = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: Pick<Event, 'id' | 'name' | 'organizationId' | 'startAt' | 'endAt'> & {
        customDocuments: Array<
            Pick<CustomDocument, 'id' | 'name' | 'populations' | 'configuration'>
        >;
        volunteerRegistration: CustomBadgeVolunteerRegistrationFragment;
    };
};

export type UserPlanningQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPlanningQuery = { event: DocumentUserPlanningFragment };

export type UserPlanningDaysQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPlanningDaysQuery = { event: DocumentUserPlanningDaysFragment };

export type UserPlanningListQueryVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPlanningListQuery = { event: DocumentUserPlanningListFragment };

export type UsersListingQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
};

export type UsersListingQuery = DocumentUsersListingFragment;

export type ExportPositionsMapQueryVariables = {
    eventId: Scalars['EventId'];
    segmentId: Scalars['SegmentId'];
};

export type ExportPositionsMapQuery = {
    event: Pick<Event, 'layer'> & {
        positions: { nodes: Array<PositionPositionsMapFragment> };
        traces: Array<TracePositionsMapFragment>;
    };
};

export type ExportAccreditationsMatrixInfosQueryVariables = {
    eventId: Scalars['EventId'];
};

export type ExportAccreditationsMatrixInfosQuery = {
    event: Pick<Event, 'name'> & {
        accreditations: {
            nodes: Array<
                Pick<Accreditation, 'id' | 'name' | 'hasSlots'> & {
                    hiddenSlot: Pick<AccreditationsSlot, 'id' | 'name' | 'date'>;
                    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
                    slots: Array<Pick<AccreditationsSlot, 'id' | 'name' | 'date'>>;
                }
            >;
        };
    };
};

export type ExportAccreditationsMatrixMembersQueryVariables = {
    eventId: Scalars['EventId'];
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
};

export type ExportAccreditationsMatrixMembersQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<{
                accreditationsUsersInfos: Array<
                    Pick<AccreditationsUsersInfo, 'accreditationSlotId'>
                >;
                userInfo: Pick<UsersInfo, 'id' | 'email'> & {
                    firstName: UsersInfo['fieldValue'];
                    lastName: UsersInfo['fieldValue'];
                };
            }>;
            pageInfo: Pick<PageInfo, 'hasNextPage'>;
        };
    };
};

export type ExportAccreditationsMembersQueryVariables = {
    eventId: Scalars['EventId'];
    states?: Maybe<Array<AccreditationState>>;
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
};

export type ExportAccreditationsMembersQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<
                Pick<VolunteersRegistration, 'accreditationState' | 'insertedAt'> & {
                    accreditationsUsersInfos: Array<{
                        accreditationSlot: Pick<AccreditationsSlot, 'name' | 'date'>;
                        accreditation: Pick<Accreditation, 'name' | 'acronym'>;
                        accreditationCategory: Pick<AccreditationsCategory, 'name'>;
                    }>;
                    userInfo: Pick<UsersInfo, 'id' | 'email' | 'fields'>;
                }
            >;
            pageInfo: Pick<PageInfo, 'hasNextPage'>;
        };
    };
};

export type ExportAssignmentsInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type ExportAssignmentsInfosQuery = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: Pick<Event, 'name'> & { formsCustomsFields: Array<CustomFieldWithConditionFragment> };
};

export type ExportAssignmentsMembersQueryVariables = {
    eventId: Scalars['EventId'];
    states?: Maybe<Array<VolunteerRegistrationState>>;
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
};

export type ExportAssignmentsMembersQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<
                Pick<VolunteersRegistration, 'state' | 'insertedAt'> & {
                    positionsSlotsUsersInfos: Array<
                        Pick<
                            PositionsSlotsUsersInfo,
                            'checkInAt' | 'checkOutAt' | 'timeDifferenceMinutes' | 'state'
                        > & {
                            positionSlot: Pick<PositionsSlotPublic, 'name' | 'range'>;
                            position: Pick<PositionPublic, 'name' | 'address'> & {
                                category: Pick<PositionsCategoryPublic, 'name'>;
                            };
                        }
                    >;
                    userInfo: Pick<UsersInfo, 'id' | 'email' | 'fields'>;
                }
            >;
            pageInfo: Pick<PageInfo, 'hasNextPage'>;
        };
    };
};

export type ExportInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type ExportInfosQuery = {
    organization: Pick<
        Organization,
        'exportDateFormat' | 'exportDatetimeFormat' | 'exportTimeFormat'
    > & { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: Pick<Event, 'name' | 'timeZone'> & { forms: Array<Pick<Form, 'id' | 'name'>> };
};

export type ExportPositionsQueryVariables = {
    eventId: Scalars['EventId'];
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
};

export type ExportPositionsQuery = {
    event: {
        positionsSlots: {
            nodes: Array<
                Pick<PositionsSlot, 'name' | 'range' | 'resources' | 'assignedResources'> & {
                    position: Pick<
                        Position,
                        | 'name'
                        | 'positionCategoryName'
                        | 'color'
                        | 'address'
                        | 'latitude'
                        | 'longitude'
                        | 'fields'
                    > & { tags: Array<Pick<Tag, 'name'>> };
                    leaders: Array<{
                        userInfo: Pick<UsersInfo, 'name'> & { user: Pick<User, 'email'> };
                    }>;
                }
            >;
            pageInfo: Pick<PageInfo, 'hasNextPage'>;
        };
    };
};

export type ExportPositionsInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type ExportPositionsInfosQuery = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: Pick<Event, 'name' | 'timeZone'>;
};

export type ExportUsersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    predicates: Scalars['JSON'];
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
};

export type ExportUsersInfosQuery = {
    organization: {
        usersInfos: {
            nodes: Array<
                Pick<
                    UsersInfo,
                    | 'id'
                    | 'organizationId'
                    | 'email'
                    | 'name'
                    | 'fields'
                    | 'eventsNames'
                    | 'numberOfEvents'
                    | 'insertedAt'
                > & { delegations: Array<Pick<Delegation, 'name'>> }
            >;
            pageInfo: Pick<PageInfo, 'hasNextPage'>;
        };
    };
};

export type ExportVolunteersQueryVariables = {
    eventId: Scalars['EventId'];
    predicates: Scalars['JSON'];
    limit: Scalars['Int'];
    offset?: Maybe<Scalars['Int']>;
    includeAccreditations: Scalars['Boolean'];
    includeAccreditationsMatrix: Scalars['Boolean'];
    includeAvailability: Scalars['Boolean'];
    includeCanRegistrationStateUpdate: Scalars['Boolean'];
    includeComments: Scalars['Boolean'];
    includeDelegation: Scalars['Boolean'];
    includeForms: Scalars['Boolean'];
    includeIsEditable: Scalars['Boolean'];
    includeNumberOfAccreditations: Scalars['Boolean'];
    includeNumberOfAssignments: Scalars['Boolean'];
    includeNumberOfAssignmentsDays: Scalars['Boolean'];
    includeNumberOfAvailableDays: Scalars['Boolean'];
    includePositions: Scalars['Boolean'];
    includeTeam: Scalars['Boolean'];
    includeVolunteerMinutes: Scalars['Boolean'];
    includeWishedPositions: Scalars['Boolean'];
    includePreassignedPositions: Scalars['Boolean'];
    includeWeezevent: Scalars['Boolean'];
};

export type ExportVolunteersQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<VolunteerRegistrationFragment>;
            pageInfo: Pick<PageInfo, 'hasNextPage'>;
        };
    };
};

export type OrganizationExportInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type OrganizationExportInfosQuery = {
    organization: Pick<
        Organization,
        'name' | 'exportDateFormat' | 'exportDatetimeFormat' | 'exportTimeFormat'
    > & { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
};

export type OrganizationCustomFieldsBackQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    variety: CustomFieldVariety;
    eventId?: Maybe<Scalars['EventId']>;
};

export type OrganizationCustomFieldsBackQuery = {
    organization: {
        customFields: { nodes: Array<CustomFieldImportFragment> };
        event?: Maybe<{ accreditationsSlots: { nodes: Array<Pick<AccreditationsSlot, 'id'>> } }>;
    };
};

export type MontreuxMembersQueryVariables = {
    eventId: Scalars['EventId'];
    predicates?: Maybe<Scalars['JSON']>;
};

export type MontreuxMembersQuery = {
    event: {
        volunteersRegistrations: {
            nodes: Array<{
                userInfo: Pick<UsersInfo, 'id' | 'fields' | 'email' | 'updatedAt' | 'deletedAt'>;
            }>;
        };
    };
};

export type ZapierCustomFieldsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type ZapierCustomFieldsQuery = {
    organization: {
        customFields: {
            nodes: Array<
                Pick<CustomField, 'canSelectMultiple' | 'fieldType' | 'name' | 'slug'> & {
                    values: Array<Pick<CustomFieldsValue, 'value'>>;
                }
            >;
        };
    };
};

export type ZapierMemberQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type ZapierMemberQuery = { organization: { userInfo: ZapierMemberFragment } };

export type ZapierMembersQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    ids: Array<Scalars['UsersInfoId']>;
    predicates: Scalars['JSON'];
};

export type ZapierMembersQuery = {
    organization: { usersInfos: { nodes: Array<ZapierMemberFragment> } };
};

export type ZapierRegistrationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    ids: Array<Scalars['VolunteersRegistrationId']>;
};

export type ZapierRegistrationsQuery = {
    organization: {
        volunteersRegistrations: {
            nodes: Array<
                Pick<VolunteersRegistration, 'id' | 'state' | 'updatedAt'> & {
                    event: Pick<Event, 'id' | 'name'>;
                    userInfo: Pick<UsersInfo, 'id' | 'fields' | 'email'>;
                }
            >;
        };
    };
};

export type TextMessageInfoQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type TextMessageInfoQuery = {
    organization: Pick<Organization, 'id' | 'name' | 'exportDatetimeFormat'> & {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        userInfo: UserInfoEmailRendererFragment;
    };
    event: Pick<Event, 'id' | 'organizationId' | 'name' | 'startAt' | 'endAt'> & {
        volunteerRegistration: VolunteerRegistrationEmailRendererFragment;
    };
};

export type TextMessageOrganizationInfoQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
    eventId?: Maybe<Scalars['EventId']>;
};

export type TextMessageOrganizationInfoQuery = {
    organization: Pick<Organization, 'id' | 'name' | 'exportDatetimeFormat'> & {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        userInfo: UserInfoEmailRendererFragment;
    };
};

export type AccreditationChartFragment = Pick<Accreditation, 'id' | 'name' | 'hasSlots'> & {
    hiddenSlot: Pick<
        AccreditationsSlot,
        | 'date'
        | 'name'
        | 'assignedResources'
        | 'wishedResources'
        | 'scannedResources'
        | 'maxResources'
    >;
    slots: Array<
        Pick<
            AccreditationsSlot,
            | 'date'
            | 'name'
            | 'assignedResources'
            | 'wishedResources'
            | 'scannedResources'
            | 'maxResources'
        >
    >;
};

export type AccreditationEmailFragment = {
    accreditation: Pick<
        Accreditation,
        'id' | 'eventId' | 'name' | 'description' | 'address' | 'latitude' | 'longitude'
    >;
    accreditationSlot: Pick<AccreditationsSlot, 'name' | 'date'>;
};

export type AccreditationImportFragment = Pick<Accreditation, 'id' | 'name'> & {
    slots: Array<Pick<AccreditationsSlot, 'id' | 'name' | 'date'>>;
};

export type AccreditationsAccreditationFragment = Pick<
    Accreditation,
    | 'id'
    | 'name'
    | 'address'
    | 'latitude'
    | 'longitude'
    | 'acronym'
    | 'icon'
    | 'color'
    | 'fillingRate'
    | 'maxResources'
    | 'assignedResources'
    | 'willAutoAccredit'
    | 'weezeventIsSynchronized'
    | 'fields'
    | 'insertedAt'
> & {
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
    accreditationsCustomFields: Array<AccreditationsAccreditationCustomFieldFragment>;
    createdBy?: Maybe<Pick<UsersInfo, 'email' | 'name'>>;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
};

export type AccreditationsAccreditationCustomFieldFragment = Pick<
    AccreditationsCustomField,
    'conditionValue'
> & {
    customField: Pick<CustomField, 'name' | 'fieldType'> & {
        values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
    };
};

export type AccreditationsAccreditationSlotFragment = Pick<
    AccreditationsSlot,
    | 'id'
    | 'name'
    | 'date'
    | 'maxResources'
    | 'assignedResources'
    | 'fillingRate'
    | 'isHidden'
    | 'insertedAt'
    | 'weezeventChecklistId'
> & {
    accreditation: Pick<
        Accreditation,
        | 'id'
        | 'name'
        | 'address'
        | 'latitude'
        | 'longitude'
        | 'acronym'
        | 'icon'
        | 'color'
        | 'fields'
    > & {
        accreditationsCustomFields: Array<AccreditationsAccreditationCustomFieldFragment>;
        createdBy?: Maybe<Pick<UsersInfo, 'email' | 'name'>>;
        tags: Array<Pick<Tag, 'id' | 'name'>>;
    };
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
};

export type AccreditationsSlotFragment = Pick<
    AccreditationsSlot,
    | 'id'
    | 'accreditationCategoryId'
    | 'accreditationId'
    | 'eventId'
    | 'name'
    | 'date'
    | 'assignedResources'
    | 'wishedResources'
    | 'maxResources'
> & {
    accreditation: Pick<
        Accreditation,
        | 'id'
        | 'accreditationCategoryId'
        | 'eventId'
        | 'acronym'
        | 'name'
        | 'color'
        | 'icon'
        | 'hasSlots'
        | 'accreditationDisplay'
        | 'hiddenSlotId'
    >;
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
};

export type AssigmentEmailFragment = {
    position: Pick<
        PositionPublic,
        | 'id'
        | 'eventId'
        | 'name'
        | 'description'
        | 'address'
        | 'latitude'
        | 'longitude'
        | 'zoom'
        | 'range'
        | 'layer'
        | 'fields'
    > & {
        leaders: Array<LeaderAssignmentFragment>;
        category: Pick<PositionsCategoryPublic, 'name'> & {
            leaders: Array<LeaderAssignmentFragment>;
        };
        tags: Array<Pick<Tag, 'name'>>;
    };
    positionSlot: Pick<PositionsSlotPublic, 'name' | 'range'>;
};

export type CampaignEditFragment = Pick<
    Campaign,
    | 'name'
    | 'state'
    | 'campaignType'
    | 'sender'
    | 'text'
    | 'subject'
    | 'html'
    | 'replyTo'
    | 'sendToEveryone'
    | 'states'
    | 'positionsCategoriesIds'
    | 'positionsIds'
    | 'positionsSlotsIds'
    | 'tagsIds'
    | 'accreditationsCategoriesIds'
    | 'accreditationsIds'
    | 'accreditationsSlotsIds'
    | 'usersInfosIds'
    | 'segmentsIds'
    | 'scheduledAt'
    | 'emailSenderId'
    | 'attachments'
> & { documents: Array<Pick<Document, 'acl' | 'key' | 'name' | 'size'>> };

export type CampaignEmailRendererFragment = Pick<
    Campaign,
    'subject' | 'text' | 'html' | 'replyTo' | 'attachments'
> & { documents: Array<Pick<Document, 'name' | 'key'>> };

export type CampaignListFragment = Pick<
    Campaign,
    | 'id'
    | 'name'
    | 'campaignType'
    | 'state'
    | 'numberOfRecipients'
    | 'cost'
    | 'sentAt'
    | 'scheduledAt'
> & { createdBy?: Maybe<Pick<UsersInfo, 'nameOrEmail'>> };

export type CampaignUserInfoFragment = Pick<UsersInfo, 'id' | 'name' | 'email'> & {
    phone: UsersInfo['fieldValue'];
} & { picture?: Maybe<Pick<Document, 'url'>> };

export type CommentFragment = Pick<Comment, 'id' | 'content' | 'insertedAt'> & {
    event?: Maybe<Pick<Event, 'name'>>;
    writtenBy: Pick<User, 'email'> & {
        userInfo: Pick<UsersInfo, 'name' | 'nameOrEmail'> & {
            picture?: Maybe<Pick<Document, 'url'>>;
        };
    };
};

export type CreateAccreditationEventFragment = Pick<
    Event,
    'startAt' | 'endAt' | 'layer' | 'isWeezeventConnected'
> & { accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>> };

export type CreateCampaignEmailSenderFragment = Pick<
    EmailsSender,
    'id' | 'name' | 'isValidToSendEmail'
>;

export type CreateCustomFieldInfosFragment = {
    customFields: {
        nodes: Array<
            Pick<CustomField, 'id' | 'name' | 'fieldProperty' | 'fieldType'> & {
                values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
            }
        >;
    };
    customFieldsCategories: { nodes: Array<Pick<CustomFieldsCategory, 'id' | 'name'>> };
};

export type CreateDelegationEventFragment = Pick<Event, 'startAt' | 'endAt'> & {
    forms: Array<CreateDelegationFormFragment>;
} & EventAccreditationsFragment;

export type CreateDelegationFormFragment = Pick<Form, 'id' | 'name'>;

export type CreatePositionCustomFieldsFragment = {
    customFields: { nodes: Array<PositionConditionCustomFieldFragment> };
};

export type CreatePositionEventFragment = Pick<
    Event,
    'startAt' | 'endAt' | 'layer' | 'latitude' | 'longitude'
> & {
    positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
    traces: Array<TraceFragment>;
};

export type CreatePositionTagsFragment = { tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> } };

export type CreateUpdateEventInfosFragment = Pick<Organization, 'name'> & {
    eventCustomFields: { nodes: Array<CustomFieldWithConditionFragment> };
    emailsSenders: Array<Pick<EmailsSender, 'id' | 'name'>>;
    tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> };
};

export type CreatedByFragment = Pick<UsersInfo, 'name' | 'email'> & {
    picture?: Maybe<Pick<Document, 'url'>>;
};

export type CustomBadgeVolunteerRegistrationFragment = Pick<
    VolunteersRegistration,
    'state' | 'isLeader' | 'utid' | 'ticketId'
> & {
    userInfo: Pick<
        UsersInfo,
        'id' | 'email' | 'name' | 'nameOrEmail' | 'fields' | 'registrationDate'
    > & { firstName: UsersInfo['fieldValue']; lastName: UsersInfo['fieldValue'] } & {
        links?: Maybe<
            Pick<
                UserInfoDocumentLink,
                | 'userAssignmentsLink'
                | 'userBadgeLink'
                | 'userCertificateLink'
                | 'userCustomBadgeLinks'
                | 'userCustomDocumentLinks'
                | 'userPlanningLink'
                | 'userPlanningDaysLink'
                | 'userPlanningListLink'
            >
        >;
        picture?: Maybe<Pick<Document, 'url'>>;
        formsUsersInfos: Array<
            Pick<FormsUsersInfo, 'formId'> & { slots: Array<Pick<FormsUsersInfosSlot, 'range'>> }
        >;
    };
    delegation?: Maybe<
        Pick<Delegation, 'name'> & {
            leaders: Array<
                Pick<UsersInfo, 'name'> & {
                    firstName: UsersInfo['fieldValue'];
                    lastName: UsersInfo['fieldValue'];
                    phone: UsersInfo['fieldValue'];
                }
            >;
        }
    >;
    accreditationsUsersInfos: Array<{
        accreditation: Pick<Accreditation, 'id' | 'name' | 'acronym' | 'color' | 'icon'>;
        accreditationSlot: Pick<AccreditationsSlot, 'id' | 'name' | 'date'>;
        accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
    }>;
    positionsSlotsUsersInfos: Array<
        Pick<PositionsSlotsUsersInfo, 'id'> & {
            position: Pick<
                PositionPublic,
                | 'id'
                | 'eventId'
                | 'name'
                | 'description'
                | 'address'
                | 'latitude'
                | 'longitude'
                | 'fields'
                | 'acronym'
            > & {
                tags: Array<Pick<Tag, 'name'>>;
                leaders: Array<{
                    userInfo: Pick<UsersInfo, 'id' | 'name'> & {
                        firstName: UsersInfo['fieldValue'];
                        lastName: UsersInfo['fieldValue'];
                        phone: UsersInfo['fieldValue'];
                    };
                }>;
            };
            positionCategory: Pick<PositionsCategory, 'id' | 'name'> & {
                leaders: Array<{
                    userInfo: Pick<UsersInfo, 'id' | 'name'> & {
                        firstName: UsersInfo['fieldValue'];
                        lastName: UsersInfo['fieldValue'];
                        phone: UsersInfo['fieldValue'];
                    };
                }>;
            };
            positionSlot: Pick<PositionsSlotPublic, 'id' | 'name' | 'range'>;
        }
    >;
    weezeventParticipantInfo?: Maybe<
        Pick<WeezeventParticipantInfo, 'idBarcode' | 'identificationNumber'>
    >;
};

export type CustomDocumentPopulationFragment = Pick<
    CustomDocument,
    'slug' | 'name' | 'populationsIds'
>;

export type CustomFieldFragment = Pick<
    CustomField,
    | 'id'
    | 'slug'
    | 'name'
    | 'label'
    | 'variety'
    | 'fieldProperty'
    | 'fieldType'
    | 'isPrivate'
    | 'hasCondition'
    | 'conditionCustomFieldId'
    | 'dependencies'
    | 'numberOfTimesUsed'
    | 'canDelete'
    | 'isSelectV2'
    | 'insertedAt'
> & {
    category: Pick<CustomFieldsCategory, 'id' | 'name'>;
    conditionCustomField?: Maybe<Pick<CustomField, 'name'>>;
    createdBy?: Maybe<
        Pick<UsersInfo, 'id' | 'name' | 'email'> & { picture?: Maybe<Pick<Document, 'url'>> }
    >;
    values: Array<Pick<CustomFieldsValue, 'value'>>;
};

export type CustomFieldImportFragment = Pick<
    CustomField,
    | 'id'
    | 'name'
    | 'slug'
    | 'customFieldCategoryId'
    | 'customFieldCategoryName'
    | 'fieldType'
    | 'fieldProperty'
    | 'canSelectMultiple'
> & { values: Array<Pick<CustomFieldsValue, 'id' | 'value'>> };

export type CustomFieldWithConditionFragment = Pick<
    CustomField,
    | 'id'
    | 'name'
    | 'label'
    | 'description'
    | 'fieldType'
    | 'fieldProperty'
    | 'isPrivate'
    | 'hasCondition'
    | 'conditionValue'
    | 'canSelectMultiple'
    | 'slug'
    | 'isSelectV2'
> & {
    conditionCustomField?: Maybe<
        Pick<CustomField, 'id' | 'fieldType' | 'fieldProperty' | 'canSelectMultiple' | 'slug'>
    >;
    document?: Maybe<Pick<Document, 'url' | 'name'>>;
    values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
};

export type DelegationAccreditationSlotFragment = Pick<
    DelegationAccreditationSlot,
    'accreditationCategoryId' | 'accreditationId' | 'accreditationSlotId' | 'isVisible'
> & { accreditationSlot: Pick<AccreditationsSlot, 'date'> };

export type DelegationAccreditationSlotStatsFragment = Pick<
    DelegationAccreditationSlot,
    'assignedResources' | 'maxResources' | 'scannedResources' | 'wishedResources'
>;

export type DelegationAccreditationsSlotsFragment = {
    accreditationsSlots: Array<
        Pick<
            DelegationAccreditationSlot,
            | 'accreditationId'
            | 'accreditationSlotId'
            | 'maxResources'
            | 'assignedResources'
            | 'isDefault'
            | 'isVisible'
        > & {
            accreditationSlot: Pick<
                AccreditationsSlot,
                'id' | 'name' | 'date' | 'isHidden' | 'accreditationCategoryId'
            > & { accreditation: Pick<Accreditation, 'id' | 'name'> };
        }
    >;
};

export type DelegationUserProfileFragment = Pick<Delegation, 'id' | 'name'> & {
    event?: Maybe<Pick<Event, 'id' | 'name'>>;
};

export type DelegationsDelegationFragment = Pick<
    Delegation,
    | 'id'
    | 'name'
    | 'state'
    | 'deadlineDate'
    | 'numberOfResources'
    | 'maxResources'
    | 'fillingRate'
    | 'showInDelegationSpace'
    | 'fields'
    | 'canLeadersAddMembers'
    | 'canLeadersEditMembers'
    | 'canLeadersEditCustomFields'
    | 'lockedAt'
    | 'insertedAt'
> & {
    delegationCategory: Pick<DelegationsCategory, 'id' | 'name'>;
    createdBy: Pick<UsersInfo, 'email' | 'name'>;
    form: Pick<Form, 'id' | 'name'>;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    leaders: Array<Pick<UsersInfo, 'nameOrEmail'>>;
};

export type DocumentEmailPreviewFragment = Pick<
    Document,
    'id' | 'acl' | 'key' | 'name' | 'size' | 'url'
>;

export type DocumentPositionPlanningFragment = {
    position: Pick<Position, 'name' | 'range'> & {
        slots: Array<Pick<PositionsSlot, 'id' | 'range'>>;
    };
    volunteersRegistrations: {
        nodes: Array<
            Pick<VolunteersRegistration, 'id'> & {
                userInfo: Pick<UsersInfo, 'email' | 'name'> & {
                    picture?: Maybe<Pick<Document, 'url'>>;
                };
                slots: Array<
                    Pick<FormsUsersInfosSlot, 'range'> | Pick<VolunteersRegistrationsSlot, 'range'>
                >;
                positionsSlotsUsersInfos: Array<
                    Pick<PositionsSlotsUsersInfo, 'positionSlotId'> & {
                        positionSlot: Pick<PositionsSlotPublic, 'range'>;
                    }
                >;
            }
        >;
    };
};

export type DocumentPositionSheetFragment = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: {
        document: Pick<EventsDocument, 'id' | 'options'>;
        position: {
            slots: Array<Pick<PositionsSlot, 'id' | 'name' | 'range'>>;
        } & PositionPdfInfosFragment;
        volunteersRegistrations: {
            nodes: Array<
                {
                    positionsSlotsUsersInfos: Array<
                        Pick<PositionsSlotsUsersInfo, 'positionSlotId'>
                    >;
                } & VolunteerPdfFragment
            >;
        };
    };
};

export type DocumentPositionsPlanningFragment = Pick<Event, 'name'> & {
    positionsSlots: {
        nodes: Array<
            Pick<PositionsSlot, 'id' | 'positionId' | 'name' | 'range'> & {
                position: Pick<Position, 'id' | 'name' | 'color'>;
            }
        >;
    };
    segment: Pick<Segment, 'name'>;
};

export type DocumentPositionsVolunteersPlanningFragment = Pick<Event, 'name'> & {
    positionsSlots: {
        nodes: Array<
            Pick<PositionsSlot, 'id' | 'range'> & { position: Pick<Position, 'id' | 'name'> }
        >;
    };
    segment: Pick<Segment, 'name'>;
    volunteersRegistrations: {
        nodes: Array<
            Pick<VolunteersRegistration, 'id'> & {
                userInfo: Pick<UsersInfo, 'email' | 'name'> & {
                    picture?: Maybe<Pick<Document, 'url'>>;
                };
                positionsSlotsUsersInfos: Array<Pick<PositionsSlotsUsersInfo, 'positionSlotId'>>;
            }
        >;
    };
};

export type DocumentUserAssignmentsFragment = {
    organization: Pick<Organization, 'id'> & {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        userInfo: Pick<UsersInfo, 'name' | 'nameOrEmail' | 'email'> & {
            language: UsersInfo['fieldValue'];
        } & {
            links?: Maybe<Pick<UserInfoDocumentLink, 'userAssignmentsLink'>>;
            picture?: Maybe<Pick<Document, 'url'>>;
        };
    };
    event: Pick<Event, 'id' | 'name'> & {
        document: Pick<EventsDocument, 'id' | 'options'>;
        volunteerRegistration: Pick<VolunteersRegistration, 'id' | 'ticketId'> & {
            preassignPositions: Array<Pick<Position, 'name'>>;
            preassignPositionsCategories: Array<Pick<PositionsCategory, 'name'>>;
            positionsSlotsUsersInfos: Array<AssigmentEmailFragment>;
        };
    };
};

export type DocumentUserBadgeFragment = {
    document: Pick<EventsDocument, 'id' | 'options'>;
    volunteerRegistration: {
        userInfo: Pick<UsersInfo, 'name' | 'email' | 'nameOrEmail'> & {
            links?: Maybe<Pick<UserInfoDocumentLink, 'userBadgeLink'>>;
            picture?: Maybe<Pick<Document, 'url'>>;
        };
        positionsSlotsUsersInfos: Array<{
            position: Pick<PositionPublic, 'name'>;
            positionSlot: Pick<PositionsSlotPublic, 'range'>;
        }>;
    };
};

export type DocumentUserCertificateFragment = {
    document: Pick<EventsDocument, 'id' | 'options'>;
    volunteerRegistration: {
        userInfo: Pick<UsersInfo, 'nameOrEmail' | 'name'> & {
            dateOfBirth: UsersInfo['fieldValue'];
            street: UsersInfo['fieldValue'];
            city: UsersInfo['fieldValue'];
            postalCode: UsersInfo['fieldValue'];
        } & { links?: Maybe<Pick<UserInfoDocumentLink, 'userCertificateLink'>> };
        positionsSlotsUsersInfos: Array<{
            position: Pick<PositionPublic, 'name'>;
            positionSlot: Pick<PositionsSlotPublic, 'range'>;
        }>;
    };
};

export type DocumentUserCustomBadgeFragment = Pick<
    Event,
    'id' | 'name' | 'startAt' | 'weezevent'
> & {
    tags: Array<Pick<Tag, 'name'>>;
    document: Pick<EventsDocument, 'id' | 'options'>;
    volunteerRegistration: CustomBadgeVolunteerRegistrationFragment;
};

export type DocumentUserCustomBadgesFragment = Pick<
    Event,
    'id' | 'name' | 'startAt' | 'weezevent'
> & {
    tags: Array<Pick<Tag, 'name'>>;
    document: Pick<EventsDocument, 'id' | 'options'>;
    volunteersRegistrations: { nodes: Array<CustomBadgeVolunteerRegistrationFragment> };
};

export type DocumentUserPlanningFragment = Pick<Event, 'startAt' | 'endAt' | 'dayStartTime'> & {
    volunteerRegistration: {
        userInfo: Pick<UsersInfo, 'nameOrEmail'> & { language: UsersInfo['fieldValue'] } & {
            links?: Maybe<Pick<UserInfoDocumentLink, 'userPlanningLink'>>;
        };
        positionsSlotsUsersInfos: Array<{
            positionSlot: Pick<PositionsSlotPublic, 'name' | 'range'>;
            position: Pick<PositionPublic, 'name'>;
        }>;
    };
};

export type DocumentUserPlanningDaysFragment = {
    document: Pick<EventsDocument, 'id' | 'options'>;
    volunteerRegistration: {
        userInfo: Pick<UsersInfo, 'nameOrEmail'> & {
            links?: Maybe<Pick<UserInfoDocumentLink, 'userPlanningDaysLink'>>;
        };
        positionsSlotsUsersInfos: Array<{
            positionSlot: Pick<PositionsSlotPublic, 'range'>;
            position: Pick<PositionPublic, 'id' | 'organizationId' | 'name' | 'address'>;
        }>;
    };
};

export type DocumentUserPlanningListFragment = {
    document: Pick<EventsDocument, 'id' | 'options'>;
    volunteerRegistration: {
        userInfo: Pick<UsersInfo, 'nameOrEmail'> & { language: UsersInfo['fieldValue'] } & {
            links?: Maybe<Pick<UserInfoDocumentLink, 'userPlanningListLink'>>;
        };
        positionsSlotsUsersInfos: Array<{
            positionSlot: Pick<PositionsSlotPublic, 'name' | 'range'>;
            position: Pick<PositionPublic, 'name'>;
        }>;
    };
};

export type DocumentUsersListingFragment = {
    organization: { customFields: { nodes: Array<CustomFieldWithConditionFragment> } };
    event: {
        document: Pick<EventsDocument, 'id' | 'options'>;
        segment: Pick<Segment, 'name'>;
        volunteersRegistrations: { nodes: Array<VolunteerPdfFragment> };
    };
};

export type DocumentUsersPlanningFragment = Pick<VolunteersRegistration, 'id' | 'state'> & {
    userInfo: Pick<UsersInfo, 'id' | 'email' | 'name'> & { picture?: Maybe<Pick<Document, 'url'>> };
    positionsSlotsUsersInfos: Array<{
        positionCategory: Pick<PositionsCategory, 'name'>;
        positionSlot: Pick<PositionsSlotPublic, 'id' | 'name' | 'range'>;
        position: Pick<PositionPublic, 'id' | 'name' | 'color' | 'icon'>;
    }>;
    slots: Array<Pick<FormsUsersInfosSlot, 'range'> | Pick<VolunteersRegistrationsSlot, 'range'>>;
};

type EditVolunteerRegistrationSlot_FormsUsersInfosSlot_Fragment = Pick<
    FormsUsersInfosSlot,
    'startDate' | 'startTime' | 'endDate' | 'endTime'
>;

type EditVolunteerRegistrationSlot_VolunteersRegistrationsSlot_Fragment = Pick<
    VolunteersRegistrationsSlot,
    'startDate' | 'startTime' | 'endDate' | 'endTime'
>;

export type EditVolunteerRegistrationSlotFragment =
    | EditVolunteerRegistrationSlot_FormsUsersInfosSlot_Fragment
    | EditVolunteerRegistrationSlot_VolunteersRegistrationsSlot_Fragment;

export type EmailSenderEmailRendererFragment = Pick<
    EmailsSender,
    'fromName' | 'fromEmail' | 'replyTo' | 'signature'
> & {
    header?: Maybe<Pick<Document, 'key'>>;
    picture?: Maybe<Pick<Document, 'key'>>;
    footer?: Maybe<Pick<Document, 'key'>>;
};

export type EmailTemplateEmailRendererFragment = Pick<
    EmailsTemplate,
    'useDefault' | 'subject' | 'content' | 'attachments'
>;

export type EmailsRecipientsFragment = Pick<EmailsConnection, 'numberOfPages' | 'totalCount'> & {
    nodes: Array<
        Pick<Email, 'id' | 'state' | 'sentAt' | 'infos'> & { userInfo: CampaignUserInfoFragment }
    >;
};

export type EventAccreditationsFragment = {
    accreditationsCategories: Array<
        Pick<AccreditationsCategory, 'id' | 'name'> & {
            accreditations: Array<
                Pick<
                    Accreditation,
                    | 'id'
                    | 'accreditationCategoryId'
                    | 'name'
                    | 'accreditationCategoryName'
                    | 'accreditationDisplay'
                    | 'hasSlots'
                    | 'hiddenSlotId'
                > & {
                    slots: Array<
                        Pick<AccreditationsSlot, 'id' | 'accreditationId' | 'name' | 'date'>
                    >;
                }
            >;
        }
    >;
};

export type EventEmailRendererFragment = Pick<
    Event,
    | 'id'
    | 'organizationId'
    | 'name'
    | 'languages'
    | 'volunteerSpaceLink'
    | 'timeZone'
    | 'startAt'
    | 'endAt'
>;

export type EventPageFragment = Pick<EventPublic, 'name' | 'parameters'> & {
    background?: Maybe<Pick<Document, 'url'>>;
};

export type EventPositionsExportFragment = Pick<Event, 'languages'> & {
    segmentsFolders: Array<
        Pick<SegmentsFolder, 'id' | 'name'> & { segments: Array<Pick<Segment, 'id' | 'name'>> }
    >;
};

type EventRegisterSlotsPositions_Event_Fragment = Pick<
    Event,
    | 'startAt'
    | 'endAt'
    | 'positionDisplay'
    | 'slotDisplay'
    | 'positionFilter'
    | 'daysDisplay'
    | 'ranges'
    | 'country'
> & {
    customSlots: Array<Pick<CustomSlot, 'name' | 'startTime' | 'endTime'>>;
    positionsCategories: Array<
        Pick<PositionsCategory, 'id' | 'name' | 'description'> & {
            positions: Array<
                Pick<
                    Position,
                    'id' | 'name' | 'description' | 'isVisible' | 'conditionsOperator'
                > & {
                    slots: Array<
                        Pick<PositionsSlot, 'id' | 'name' | 'nameOrRange' | 'range' | 'isFull'>
                    >;
                    conditionsCustomFields: Array<
                        Pick<PositionsCustomField, 'conditionValue'> & {
                            customField: Pick<
                                CustomField,
                                'slug' | 'fieldType' | 'canSelectMultiple'
                            >;
                        }
                    >;
                }
            >;
        }
    >;
};

type EventRegisterSlotsPositions_EventPublic_Fragment = Pick<
    EventPublic,
    | 'startAt'
    | 'endAt'
    | 'positionDisplay'
    | 'slotDisplay'
    | 'positionFilter'
    | 'daysDisplay'
    | 'ranges'
    | 'country'
> & {
    customSlots: Array<Pick<CustomSlot, 'name' | 'startTime' | 'endTime'>>;
    positionsCategories: Array<
        Pick<PositionsCategoryPublic, 'id' | 'name' | 'description'> & {
            positions: Array<
                Pick<
                    PositionPublic,
                    'id' | 'name' | 'description' | 'isVisible' | 'conditionsOperator'
                > & {
                    slots: Array<
                        Pick<
                            PositionsSlotPublic,
                            'id' | 'name' | 'nameOrRange' | 'range' | 'isFull'
                        >
                    >;
                    conditionsCustomFields: Array<
                        Pick<PositionsCustomField, 'conditionValue'> & {
                            customField: Pick<
                                CustomField,
                                'slug' | 'fieldType' | 'canSelectMultiple'
                            >;
                        }
                    >;
                }
            >;
        }
    >;
};

export type EventRegisterSlotsPositionsFragment =
    | EventRegisterSlotsPositions_Event_Fragment
    | EventRegisterSlotsPositions_EventPublic_Fragment;

export type EventVolunteersExportFragment = Pick<Event, 'languages'> & {
    segmentsFolders: Array<
        Pick<SegmentsFolder, 'id' | 'name'> & { segments: Array<Pick<Segment, 'id' | 'name'>> }
    >;
};

export type EventsEventFragment = Pick<
    Event,
    'id' | 'name' | 'state' | 'startAt' | 'endAt' | 'fields'
> & { tags: Array<Pick<Tag, 'id' | 'name'>> };

export type ExistingVolunteerFragment = Pick<UsersInfo, 'id' | 'nameOrEmail' | 'name' | 'email'> & {
    picture?: Maybe<Pick<Document, 'url'>>;
};

export type FormAccreditationsOptionsFragment = Pick<
    Form,
    | 'accreditationDisplay'
    | 'accreditationFilter'
    | 'showFullAccreditation'
    | 'hiddenAccreditationsCategoriesIds'
    | 'hiddenAccreditationsIds'
    | 'hiddenAccreditationsSlotsIds'
    | 'displayedAccreditationsCategoriesIds'
    | 'displayedAccreditationsIds'
    | 'displayedAccreditationsSlotsIds'
    | 'showAccreditationDescription'
    | 'slotDisplay'
>;

export type FormCustomFieldFragment = Pick<
    CustomField,
    'id' | 'name' | 'label' | 'fieldType' | 'customFieldCategoryName'
> & { conditionCustomField?: Maybe<Pick<CustomField, 'id' | 'name'>> };

export type FormElementDisplayFragment = Pick<
    FormsElement,
    'elementType' | 'isMandatory' | 'section' | 'text'
> & { customField?: Maybe<CustomFieldWithConditionFragment> };

export type FormElementsElementFragment = Pick<
    FormsElement,
    'id' | 'elementType' | 'customFieldId' | 'section' | 'text' | 'isMandatory'
>;

export type FormEmailFragment = Pick<FormsUsersInfo, 'teamCode'> & {
    form: Pick<Form, 'id' | 'areTeamsAllowed'>;
} & WishesEmailFragment;

export type FormListFragment = Pick<Form, 'id' | 'name' | 'numberOfCustomFields'>;

export type FormMemberRegistrationFragment = Pick<
    Form,
    'id' | 'name' | 'isEditableInMemberSpace'
> & {
    elements: Array<Pick<FormsElement, 'id'> & FormElementDisplayFragment>;
    customSlotsPeriods: Array<FormRegisterCustomSlotPeriodFragment>;
} & FormSlotsOptionsFragment &
    FormMissionsOptionsFragment &
    FormAccreditationsOptionsFragment;

export type FormMissionsOptionsFragment = Pick<
    Form,
    | 'positionDisplay'
    | 'positionFilter'
    | 'positionRanking'
    | 'positionLimit'
    | 'showFullPosition'
    | 'hiddenPositionsCategoriesIds'
    | 'hiddenPositionsIds'
    | 'hiddenPositionsSlotsIds'
    | 'displayedPositionsCategoriesIds'
    | 'displayedPositionsIds'
    | 'displayedPositionsSlotsIds'
    | 'showPositionCategoryDescription'
    | 'showPositionDescription'
    | 'slotDisplay'
    | 'willAutoAssign'
>;

type FormRegisterAccreditation_Accreditation_Fragment = Pick<
    Accreditation,
    | 'id'
    | 'name'
    | 'description'
    | 'hiddenSlotId'
    | 'hasSlots'
    | 'accreditationDisplay'
    | 'conditionsOperator'
> & {
    slots: Array<FormRegisterAccreditationSlot_AccreditationsSlot_Fragment>;
    conditionsCustomFields: Array<
        Pick<AccreditationsCustomField, 'conditionValue'> & {
            customField: Pick<CustomField, 'slug' | 'fieldType' | 'canSelectMultiple'>;
        }
    >;
};

type FormRegisterAccreditation_AccreditationPublic_Fragment = Pick<
    AccreditationPublic,
    | 'id'
    | 'name'
    | 'description'
    | 'hiddenSlotId'
    | 'hasSlots'
    | 'accreditationDisplay'
    | 'conditionsOperator'
> & {
    slots: Array<FormRegisterAccreditationSlot_AccreditationsSlotPublic_Fragment>;
    conditionsCustomFields: Array<
        Pick<AccreditationsCustomField, 'conditionValue'> & {
            customField: Pick<CustomField, 'slug' | 'fieldType' | 'canSelectMultiple'>;
        }
    >;
};

export type FormRegisterAccreditationFragment =
    | FormRegisterAccreditation_Accreditation_Fragment
    | FormRegisterAccreditation_AccreditationPublic_Fragment;

type FormRegisterAccreditationCategory_AccreditationsCategory_Fragment = Pick<
    AccreditationsCategory,
    'id' | 'name'
> & { accreditations: Array<FormRegisterAccreditation_Accreditation_Fragment> };

type FormRegisterAccreditationCategory_AccreditationsCategoryPublic_Fragment = Pick<
    AccreditationsCategoryPublic,
    'id' | 'name'
> & { accreditations: Array<FormRegisterAccreditation_AccreditationPublic_Fragment> };

export type FormRegisterAccreditationCategoryFragment =
    | FormRegisterAccreditationCategory_AccreditationsCategory_Fragment
    | FormRegisterAccreditationCategory_AccreditationsCategoryPublic_Fragment;

type FormRegisterAccreditationSlot_AccreditationsSlot_Fragment = Pick<
    AccreditationsSlot,
    'id' | 'name' | 'date' | 'isFull'
>;

type FormRegisterAccreditationSlot_AccreditationsSlotPublic_Fragment = Pick<
    AccreditationsSlotPublic,
    'id' | 'name' | 'date' | 'isFull'
>;

export type FormRegisterAccreditationSlotFragment =
    | FormRegisterAccreditationSlot_AccreditationsSlot_Fragment
    | FormRegisterAccreditationSlot_AccreditationsSlotPublic_Fragment;

type FormRegisterCategory_PositionsCategory_Fragment = Pick<
    PositionsCategory,
    'id' | 'name' | 'description'
> & { positions: Array<FormRegisterPosition_Position_Fragment> };

type FormRegisterCategory_PositionsCategoryPublic_Fragment = Pick<
    PositionsCategoryPublic,
    'id' | 'name' | 'description'
> & { positions: Array<FormRegisterPosition_PositionPublic_Fragment> };

export type FormRegisterCategoryFragment =
    | FormRegisterCategory_PositionsCategory_Fragment
    | FormRegisterCategory_PositionsCategoryPublic_Fragment;

export type FormRegisterCustomSlotFragment = Pick<CustomSlot, 'name' | 'startTime' | 'endTime'>;

export type FormRegisterCustomSlotPeriodFragment = Pick<
    CustomSlotsPeriod,
    'name' | 'startDate' | 'endDate' | 'hideNameDates' | 'displayAsCalendar'
> & { customSlots: Array<FormRegisterCustomSlotFragment> };

type FormRegisterPosition_Position_Fragment = Pick<
    Position,
    'id' | 'name' | 'description' | 'isVisible' | 'conditionsOperator'
> & {
    slots: Array<FormRegisterSlot_PositionsSlot_Fragment>;
    conditionsCustomFields: Array<
        Pick<PositionsCustomField, 'conditionValue'> & {
            customField: Pick<CustomField, 'slug' | 'fieldType' | 'canSelectMultiple'>;
        }
    >;
};

type FormRegisterPosition_PositionPublic_Fragment = Pick<
    PositionPublic,
    'id' | 'name' | 'description' | 'isVisible' | 'conditionsOperator'
> & {
    slots: Array<FormRegisterSlot_PositionsSlotPublic_Fragment>;
    conditionsCustomFields: Array<
        Pick<PositionsCustomField, 'conditionValue'> & {
            customField: Pick<CustomField, 'slug' | 'fieldType' | 'canSelectMultiple'>;
        }
    >;
};

export type FormRegisterPositionFragment =
    | FormRegisterPosition_Position_Fragment
    | FormRegisterPosition_PositionPublic_Fragment;

type FormRegisterSlot_PositionsSlot_Fragment = Pick<
    PositionsSlot,
    'id' | 'name' | 'range' | 'nameOrRange' | 'isFull'
>;

type FormRegisterSlot_PositionsSlotPublic_Fragment = Pick<
    PositionsSlotPublic,
    'id' | 'name' | 'range' | 'nameOrRange' | 'isFull'
>;

export type FormRegisterSlotFragment =
    | FormRegisterSlot_PositionsSlot_Fragment
    | FormRegisterSlot_PositionsSlotPublic_Fragment;

export type FormSlotsOptionsFragment = Pick<
    Form,
    'slotDisplay' | 'daysDisplay' | 'slotTitle' | 'slotSubtitle'
>;

export type FormUserInfoUserProfileFragment = Pick<
    FormsUsersInfo,
    'organizationId' | 'eventId' | 'formId' | 'insertedAt' | 'teamCode'
> & { teamMembers: Array<UserInfoTeamMemberFragment> };

export type FormUserInfoWishedFragment = {
    formUserInfo: Pick<
        FormsUsersInfo,
        | 'insertedAt'
        | 'formId'
        | 'positionsCategoriesIds'
        | 'positionsIds'
        | 'positionsSlotsIds'
        | 'accreditationsSlotsIds'
    > & {
        form: FormMemberRegistrationFragment;
        accreditationsSlots: Array<
            Pick<AccreditationsSlot, 'name' | 'date'> & {
                accreditation: Pick<Accreditation, 'id' | 'name'>;
            }
        >;
        positionsSlots: Array<
            Pick<PositionsSlot, 'nameOrRange'> & { position: Pick<Position, 'id' | 'name'> }
        >;
        positions: Array<Pick<Position, 'id' | 'name' | 'range'>>;
        positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name' | 'range'>>;
        slots: Array<Pick<FormsUsersInfosSlot, 'startDate' | 'startTime' | 'endDate' | 'endTime'>>;
    };
};

export type FormUserOverlayFragment = Pick<Form, 'id' | 'name'> & {
    elements: Array<
        Pick<FormsElement, 'id' | 'elementType' | 'section'> & {
            customField?: Maybe<CustomFieldWithConditionFragment>;
        }
    >;
};

export type FormsUsersInfosWishedFragment = {
    formsUsersInfos: Array<
        Pick<
            FormsUsersInfo,
            | 'insertedAt'
            | 'formId'
            | 'eventId'
            | 'positionsCategoriesIds'
            | 'positionsIds'
            | 'positionsSlotsIds'
            | 'accreditationsSlotsIds'
        > & {
            form: FormMemberRegistrationFragment;
            accreditationsSlots: Array<
                Pick<AccreditationsSlot, 'name' | 'date'> & {
                    accreditation: Pick<Accreditation, 'id' | 'name'>;
                }
            >;
            positionsSlots: Array<
                Pick<PositionsSlot, 'nameOrRange'> & { position: Pick<Position, 'id' | 'name'> }
            >;
            positions: Array<Pick<Position, 'id' | 'name' | 'range'>>;
            positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name' | 'range'>>;
            slots: Array<
                Pick<
                    FormsUsersInfosSlot,
                    'startDate' | 'startTime' | 'endDate' | 'endTime' | 'range'
                >
            >;
        }
    >;
};

export type FullPageInfoFragment = Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>;

export type LeaderAssignmentFragment = {
    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & { phone: UsersInfo['fieldValue'] };
};

export type LeaderInfosFragment = Pick<Leader, 'canEdit'> & {
    role: Pick<Role, 'id' | 'name'>;
    userInfo: LeaderUserInfoFragment;
};

export type LeaderUserInfoFragment = Pick<
    UsersInfo,
    'id' | 'userId' | 'email' | 'name' | 'nameOrEmail'
> & { picture?: Maybe<Pick<Document, 'url'>> };

export type MassEditCustomFieldFragment = Pick<
    CustomField,
    'id' | 'name' | 'slug' | 'fieldType' | 'fieldProperty' | 'canSelectMultiple'
> & { values: Array<Pick<CustomFieldsValue, 'id' | 'value'>> };

export type MemberAccreditationFragment = Pick<
    AccreditationsUsersInfo,
    'isConfirmationEmailSent'
> & {
    accreditationSlot: Pick<AccreditationsSlot, 'name' | 'date'>;
    accreditation: Pick<Accreditation, 'name' | 'description' | 'acronym' | 'hasSlots'>;
    accreditationCategory: Pick<AccreditationsCategory, 'name'>;
};

export type MemberAssignmentFragment = Pick<PositionsSlotsUsersInfo, 'isConfirmationEmailSent'> & {
    position: Pick<
        PositionPublic,
        'name' | 'description' | 'address' | 'latitude' | 'longitude' | 'zoom' | 'layer'
    > & {
        leaders: Array<LeaderAssignmentFragment>;
        category: Pick<PositionsCategoryPublic, 'name'> & {
            leaders: Array<LeaderAssignmentFragment>;
        };
        traces: Array<TraceFragment>;
    };
    positionSlot: Pick<PositionsSlotPublic, 'range'>;
};

export type MemberRegistrationUserInfoFragment = Pick<
    UsersInfo,
    'id' | 'userId' | 'name' | 'email' | 'nameOrEmail' | 'fields'
> & { picture?: Maybe<Pick<Document, 'url'>> };

export type OrganizationAdminInfosFragment = Pick<
    Organization,
    | 'name'
    | 'adminName'
    | 'adminEmail'
    | 'street'
    | 'postalCode'
    | 'city'
    | 'country'
    | 'timeZone'
    | 'emailSenderId'
    | 'exportDateFormat'
    | 'exportDatetimeFormat'
    | 'exportTimeFormat'
> & { emailsSenders: Array<Pick<EmailsSender, 'id' | 'name'>> };

export type OrganizationCertificateDefaultFragment = Pick<
    Organization,
    'name' | 'adminName' | 'street' | 'city' | 'postalCode' | 'country'
>;

export type OrganizationCustomFieldsFragment = {
    customFields: { nodes: Array<SegmentCustomFieldFragment> };
};

type OrganizationDelegationsSpace_Organization_Fragment = Pick<
    Organization,
    'name' | 'allowDelegationSpaceDownload' | 'delegationsSpaceCustomBadges'
> & {
    delegationsSpaceDesign?: Maybe<
        Pick<Design, 'colors'> & {
            logo?: Maybe<Pick<Document, 'url'>>;
            banner?: Maybe<Pick<Document, 'url'>>;
        }
    >;
};

type OrganizationDelegationsSpace_OrganizationPublic_Fragment = Pick<
    OrganizationPublic,
    'name' | 'allowDelegationSpaceDownload' | 'delegationsSpaceCustomBadges'
> & {
    delegationsSpaceDesign?: Maybe<
        Pick<Design, 'colors'> & {
            logo?: Maybe<Pick<Document, 'url'>>;
            banner?: Maybe<Pick<Document, 'url'>>;
        }
    >;
};

export type OrganizationDelegationsSpaceFragment =
    | OrganizationDelegationsSpace_Organization_Fragment
    | OrganizationDelegationsSpace_OrganizationPublic_Fragment;

export type OrganizationEmailRendererFragment = Pick<
    Organization,
    'id' | 'name' | 'exportDatetimeFormat'
>;

export type OrganizationMembersSpaceFragment = Pick<
    Organization,
    | 'hideAssignmentsUntilConfirmationEmailSent'
    | 'hideAccreditationsUntilConfirmationEmailSent'
    | 'name'
> & {
    membersSpaceDesign?: Maybe<
        Pick<Design, 'colors'> & {
            logo?: Maybe<Pick<Document, 'url'>>;
            banner?: Maybe<Pick<Document, 'url'>>;
        }
    >;
};

export type PositionCategoryPositionFragment = Pick<
    Position,
    'id' | 'assignedResources' | 'name' | 'range' | 'resources' | 'numberOfSlots'
>;

export type PositionConditionCustomFieldFragment = Pick<
    CustomField,
    'id' | 'name' | 'slug' | 'fieldType'
> & { values: Array<Pick<CustomFieldsValue, 'id' | 'value'>> };

export type PositionPdfInfosFragment = Pick<
    Position,
    | 'id'
    | 'eventId'
    | 'name'
    | 'description'
    | 'range'
    | 'address'
    | 'latitude'
    | 'longitude'
    | 'zoom'
    | 'layer'
    | 'numberOfSlots'
    | 'assignedResources'
    | 'acronym'
> & {
    leaders: Array<LeaderAssignmentFragment>;
    category: Pick<PositionsCategory, 'name'> & { leaders: Array<LeaderAssignmentFragment> };
};

export type PositionPlanningFragment = Pick<Position, 'id' | 'name' | 'color' | 'icon'> & {
    category: Pick<PositionsCategory, 'name'>;
    slots: Array<
        Pick<
            PositionsSlot,
            'id' | 'name' | 'range' | 'fillingRate' | 'assignedResources' | 'resources'
        >
    >;
};

export type PositionPositionSlotFragment = Pick<
    PositionsSlot,
    | 'id'
    | 'positionCategoryId'
    | 'positionId'
    | 'assignedResources'
    | 'name'
    | 'range'
    | 'nameOrRange'
    | 'resources'
    | 'startDate'
    | 'startTime'
    | 'endDate'
    | 'endTime'
>;

export type PositionPositionsCustomFieldsFragment = Pick<Position, 'conditionsOperator'> & {
    conditionsCustomFields: Array<
        Pick<PositionsCustomField, 'conditionValue'> & {
            customField: Pick<CustomField, 'slug' | 'fieldType' | 'canSelectMultiple'>;
        }
    >;
};

export type PositionPositionsMapFragment = Pick<
    Position,
    'acronym' | 'color' | 'icon' | 'latitude' | 'longitude'
>;

export type PositionsPositionFragment = Pick<
    Position,
    | 'id'
    | 'name'
    | 'range'
    | 'resources'
    | 'assignedResources'
    | 'address'
    | 'latitude'
    | 'longitude'
    | 'acronym'
    | 'color'
    | 'icon'
    | 'isVisible'
    | 'state'
    | 'fillingRate'
    | 'numberOfSlots'
    | 'fields'
    | 'insertedAt'
    | 'checkedInRate'
> & {
    category: Pick<PositionsCategory, 'id' | 'name'> & {
        leaders: Array<{ userInfo: Pick<UsersInfo, 'id' | 'name'> }>;
    };
    createdBy?: Maybe<Pick<UsersInfo, 'email' | 'name'>>;
    leaders: Array<{ userInfo: Pick<UsersInfo, 'id' | 'name'> }>;
    positionsCustomFields: Array<PositionsPositionCustomFieldFragment>;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
};

export type PositionsPositionCustomFieldFragment = Pick<PositionsCustomField, 'conditionValue'> & {
    customField: Pick<CustomField, 'name' | 'fieldType'> & {
        values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
    };
};

export type PositionsPositionSlotFragment = Pick<
    PositionsSlot,
    | 'id'
    | 'name'
    | 'startDate'
    | 'startTime'
    | 'endDate'
    | 'endTime'
    | 'range'
    | 'assignedResources'
    | 'resources'
    | 'fillingRate'
    | 'state'
    | 'insertedAt'
    | 'checkedInRate'
> & {
    position: Pick<
        Position,
        | 'id'
        | 'name'
        | 'fields'
        | 'acronym'
        | 'color'
        | 'icon'
        | 'address'
        | 'latitude'
        | 'longitude'
    > & {
        createdBy?: Maybe<Pick<UsersInfo, 'email' | 'name'>>;
        positionsCustomFields: Array<PositionsPositionCustomFieldFragment>;
        tags: Array<Pick<Tag, 'id' | 'name'>>;
    };
    positionCategory: Pick<PositionsCategory, 'id' | 'name'>;
};

export type SegmentCampaignFragment = Pick<Campaign, 'id' | 'name' | 'campaignType'>;

export type SegmentCustomFieldFragment = Pick<
    CustomField,
    | 'id'
    | 'slug'
    | 'name'
    | 'label'
    | 'fieldType'
    | 'fieldProperty'
    | 'canSelectMultiple'
    | 'customFieldCategoryId'
    | 'customFieldCategoryName'
    | 'isSelectV2'
> & { values: Array<Pick<CustomFieldsValue, 'id' | 'value'>> };

export type SegmentEventFragment = Pick<Event, 'id' | 'name'>;

export type SegmentFormFragment = Pick<Form, 'id' | 'name'>;

export type SegmentsFoldersSegmentsFragment = Pick<SegmentsFolder, 'id' | 'name'> & {
    segments: Array<
        Pick<
            Segment,
            | 'id'
            | 'segmentFolderId'
            | 'name'
            | 'predicates'
            | 'columns'
            | 'columnsExport'
            | 'columnsSearch'
            | 'sort'
            | 'count'
            | 'rendering'
        >
    >;
};

export type TraceFragment = Pick<Trace, 'id' | 'name' | 'color' | 'points'>;

export type TracePositionsMapFragment = Pick<Trace, 'id' | 'color' | 'points'>;

export type TranslationFragment = Pick<
    Translation,
    | 'id'
    | 'translationType'
    | 'module'
    | 'fr'
    | 'en'
    | 'es'
    | 'de'
    | 'nl'
    | 'isFrTranslated'
    | 'isEnTranslated'
    | 'isEsTranslated'
    | 'isDeTranslated'
    | 'isNlTranslated'
    | 'frString'
    | 'enString'
    | 'esString'
    | 'deString'
    | 'nlString'
>;

export type UpdateUserFormFragment = Pick<
    Form,
    'id' | 'name' | 'areTeamsAllowed' | 'delegationsIds'
> & { elements: Array<FormElementDisplayFragment> };

export type UserInfoCommunityFragment = Pick<
    UsersInfo,
    | 'id'
    | 'name'
    | 'email'
    | 'nameOrEmail'
    | 'fields'
    | 'numberOfEvents'
    | 'eventsNames'
    | 'insertedAt'
> & { phone: UsersInfo['fieldValue'] } & {
    picture?: Maybe<Pick<Document, 'url'>>;
    delegations: Array<Pick<Delegation, 'id' | 'name'>>;
    formsUsersInfos: Array<
        Pick<FormsUsersInfo, 'id' | 'insertedAt'> & { form: Pick<Form, 'id' | 'name'> }
    >;
};

export type UserInfoEmailRendererFragment = Pick<
    UsersInfo,
    'id' | 'email' | 'fields' | 'registrationDate'
> & {
    links?: Maybe<
        Pick<
            UserInfoDocumentLink,
            | 'userAssignmentsLink'
            | 'userBadgeLink'
            | 'userCertificateLink'
            | 'userCustomBadgeLinks'
            | 'userCustomDocumentLinks'
            | 'userPlanningLink'
            | 'userPlanningDaysLink'
            | 'userPlanningListLink'
        >
    >;
    user: Pick<User, 'id' | 'language'>;
};

export type UserInfoFormFragment = Pick<
    UsersInfo,
    'id' | 'email' | 'nameOrEmail' | 'fields' | 'registrationDate' | 'canAccess'
> & {
    links?: Maybe<
        Pick<
            UserInfoDocumentLink,
            | 'userAssignmentsLink'
            | 'userBadgeLink'
            | 'userCertificateLink'
            | 'userCustomBadgeLinks'
            | 'userCustomDocumentLinks'
            | 'userPlanningLink'
            | 'userPlanningDaysLink'
            | 'userPlanningListLink'
        >
    >;
};

export type UserInfoOverlayFragment = Pick<
    UsersInfo,
    'id' | 'name' | 'email' | 'nameOrEmail' | 'fields'
> & { phone: UsersInfo['fieldValue']; population: UsersInfo['fieldValue'] } & {
    picture?: Maybe<Pick<Document, 'url'>>;
};

export type UserInfoTeamMemberFragment = Pick<UsersInfo, 'id' | 'email' | 'name'> & {
    picture?: Maybe<Pick<Document, 'url'>>;
};

export type UserPanelAccreditationSlotUserInfoFragment = Pick<
    AccreditationsUsersInfo,
    'id' | 'isAutoAccredit' | 'scannedAt'
> & {
    accreditation: Pick<Accreditation, 'id' | 'name' | 'color' | 'icon'>;
    accreditationSlot: Pick<AccreditationsSlot, 'id' | 'name' | 'date'>;
};

export type UserPositionSlotUserInfoFragment = Pick<
    PositionsSlotsUsersInfo,
    | 'checkInAt'
    | 'checkOutAt'
    | 'eventId'
    | 'id'
    | 'insertedAt'
    | 'organizationId'
    | 'positionCategoryId'
    | 'state'
    | 'timeDifferenceMinutes'
    | 'userInfoId'
> & {
    position: Pick<PositionPublic, 'id' | 'acronym' | 'color' | 'icon' | 'name'>;
    positionSlot: Pick<PositionsSlotPublic, 'id' | 'name' | 'range'>;
    positionCategory: Pick<PositionsCategory, 'id' | 'name'>;
};

export type VolunteerInfosFragment = {
    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & { phone: UsersInfo['fieldValue'] } & {
        picture?: Maybe<Pick<Document, 'url'>>;
    };
};

export type VolunteerPdfFragment = Pick<VolunteersRegistration, 'teamCode'> & {
    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email' | 'fields'> & {
        picture?: Maybe<Pick<Document, 'url'>>;
    };
};

export type VolunteerRegistrationFragment = Pick<
    VolunteersRegistration,
    | 'id'
    | 'ticketId'
    | 'state'
    | 'accreditationState'
    | 'insertedAt'
    | 'canRegistrationStateUpdate'
    | 'numberOfAssignments'
    | 'numberOfAssignmentsDays'
    | 'numberOfAccreditations'
    | 'numberOfAvailableDays'
    | 'volunteerMinutes'
    | 'teamCode'
    | 'shortTag'
    | 'weezeventParticipantId'
> & {
    delegation?: Maybe<Pick<Delegation, 'id' | 'name'> & { form: Pick<Form, 'id' | 'name'> }>;
    userInfo: Pick<
        UsersInfo,
        | 'id'
        | 'userId'
        | 'organizationId'
        | 'name'
        | 'email'
        | 'nameOrEmail'
        | 'fields'
        | 'isEditable'
        | 'insertedAt'
    > & {
        links?: Maybe<
            Pick<
                UserInfoDocumentLink,
                | 'userAssignmentsLink'
                | 'userBadgeLink'
                | 'userCertificateLink'
                | 'userCustomBadgeLinks'
                | 'userCustomDocumentLinks'
                | 'userPlanningLink'
                | 'userPlanningDaysLink'
                | 'userPlanningListLink'
            >
        >;
        picture?: Maybe<Pick<Document, 'url'>>;
        comments: Array<Pick<Comment, 'content'>>;
        formsUsersInfos: Array<
            Pick<FormsUsersInfo, 'id' | 'insertedAt'> & {
                form: Pick<Form, 'id' | 'name' | 'slotDisplay'>;
                slots: Array<Pick<FormsUsersInfosSlot, 'id' | 'range'>>;
                teamMembers: Array<
                    Pick<UsersInfo, 'id' | 'email' | 'name'> & {
                        picture?: Maybe<Pick<Document, 'url'>>;
                    }
                >;
            }
        >;
    };
    accreditationsUsersInfos: Array<{ accreditationSlot: AccreditationsSlotFragment }>;
    matrixAuis: Array<Pick<AccreditationsUsersInfo, 'accreditationSlotId' | 'isAutoAccredit'>>;
    accreditationsSlots: Array<AccreditationsSlotFragment>;
    matrixWishedSlots: Array<Pick<AccreditationsSlot, 'id'>>;
    positionsSlotsUsersInfos: Array<UserPositionSlotUserInfoFragment>;
    positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
    positions: Array<Pick<Position, 'id' | 'acronym' | 'color' | 'icon' | 'name'>>;
    positionsSlots: Array<
        Pick<PositionsSlot, 'id' | 'name' | 'range'> & {
            position: Pick<Position, 'acronym' | 'color' | 'icon' | 'name'>;
        }
    >;
    preassignPositionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
    preassignPositions: Array<Pick<Position, 'id' | 'name'>>;
    weezeventParticipantInfo?: Maybe<Pick<WeezeventParticipantInfo, 'idBarcode'>>;
};

export type VolunteerRegistrationDelegationAccreditationSlotFragment = Pick<
    VolunteersRegistration,
    'id'
> & {
    accreditationsSlots: Array<Pick<AccreditationsSlot, 'id'>>;
    accreditationsUsersInfos: Array<Pick<AccreditationsUsersInfo, 'accreditationSlotId'>>;
    userInfo: Pick<UsersInfo, 'id' | 'email' | 'name'> & { picture?: Maybe<Pick<Document, 'url'>> };
};

export type VolunteerRegistrationEmailRendererFragment = Pick<
    VolunteersRegistration,
    'ticketId'
> & {
    delegation?: Maybe<
        Pick<Delegation, 'name'> & {
            leaders: Array<{
                firstName: UsersInfo['fieldValue'];
                lastName: UsersInfo['fieldValue'];
                phone: UsersInfo['fieldValue'];
            }>;
        }
    >;
    weezeventParticipantInfo?: Maybe<
        Pick<WeezeventParticipantInfo, 'idBarcode' | 'identificationNumber'>
    >;
};

export type VolunteerRegistrationWaitingAssignmentFragment = Pick<
    VolunteersRegistration,
    'insertedAt'
> & {
    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & { picture?: Maybe<Pick<Document, 'url'>> };
};

export type VolunteerRegistrationWeezeventFragment = Pick<
    VolunteersRegistration,
    'id' | 'ticketId'
> & {
    accreditationsUsersInfos: Array<
        Pick<AccreditationsUsersInfo, 'accreditationSlotId'> & {
            accreditation: Pick<Accreditation, 'name'>;
        }
    >;
    delegation?: Maybe<Pick<Delegation, 'name'>>;
    userInfo: Pick<UsersInfo, 'id' | 'email'> & {
        firstName: UsersInfo['fieldValue'];
        lastName: UsersInfo['fieldValue'];
        fieldsExpanded: UsersInfo['fields'];
    };
};

export type WishesEmailFragment = {
    positionsCategories: Array<Pick<PositionsCategory, 'name'>>;
    positions: Array<Pick<Position, 'name'>>;
    slots: Array<Pick<FormsUsersInfosSlot, 'range'>>;
    accreditationsSlots: Array<
        Pick<AccreditationsSlot, 'name' | 'date'> & { accreditation: Pick<Accreditation, 'name'> }
    >;
};

export type ZapierMemberFragment = Pick<
    UsersInfo,
    'id' | 'fields' | 'email' | 'insertedAt' | 'updatedAt' | 'deletedAt'
>;

export type AccreditAssignWaitingMembersQueryVariables = {
    eventId: Scalars['EventId'];
    states?: Maybe<Array<VolunteerRegistrationState>>;
    accreditationStates?: Maybe<Array<AccreditationState>>;
    name?: Maybe<Scalars['String']>;
    segmentId?: Maybe<Scalars['SegmentId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    direction: SortDirection;
    loadDelegations: Scalars['Boolean'];
};

export type AccreditAssignWaitingMembersQuery = {
    event: {
        delegations: { nodes: Array<Pick<Delegation, 'id' | 'name'>> };
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name'> & { segments: Array<Pick<Segment, 'id' | 'name'>> }
        >;
        volunteersRegistrations: Pick<VolunteersRegistrationsConnection, 'totalCount'> & {
            nodes: Array<VolunteerRegistrationWaitingAssignmentFragment>;
        };
    };
};

export type VolunteersRegistrationsMassAccreditMutationVariables = {
    eventId: Scalars['EventId'];
    massAccredit: MassAccreditInput;
};

export type VolunteersRegistrationsMassAccreditMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassAccredit'
>;

export type AccreditationsMassEditMutationVariables = {
    eventId: Scalars['EventId'];
    massEdit: MassEditInput;
};

export type AccreditationsMassEditMutation = Pick<Mutation, 'accreditationsMassEdit'>;

export type AccreditationsMassEditInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type AccreditationsMassEditInfosQuery = {
    organization: {
        customFields: { nodes: Array<MassEditCustomFieldFragment> };
        tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> };
    };
    event: { accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>> };
};

export type CustomFieldsMassEditMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    massEdit: MassEditInput;
};

export type CustomFieldsMassEditMutation = Pick<Mutation, 'customFieldsMassEdit'>;

export type CustomFieldsMassEditInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type CustomFieldsMassEditInfosQuery = {
    organization: {
        customFieldsCategories: { nodes: Array<Pick<CustomFieldsCategory, 'id' | 'name'>> };
    };
};

export type DelegationsMassEditMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    massEdit: MassEditInput;
};

export type DelegationsMassEditMutation = Pick<Mutation, 'delegationsMassEdit'>;

export type DelegationsMassEditInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    level: OrganizationEventLevel;
};

export type DelegationsMassEditInfosQuery = {
    organization: {
        customFields: { nodes: Array<MassEditCustomFieldFragment> };
        delegationsCategories: { nodes: Array<Pick<DelegationsCategory, 'id' | 'name'>> };
        forms: Array<Pick<Form, 'id' | 'name'>>;
        tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> };
    };
};

export type EventsMassEditMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    massEdit: MassEditInput;
};

export type EventsMassEditMutation = Pick<Mutation, 'eventsMassEdit'>;

export type PositionsMassEditMutationVariables = {
    eventId: Scalars['EventId'];
    massEdit: MassEditInput;
};

export type PositionsMassEditMutation = Pick<Mutation, 'positionsMassEdit'>;

export type PositionsMassEditInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
};

export type PositionsMassEditInfosQuery = {
    organization: {
        customFields: { nodes: Array<MassEditCustomFieldFragment> };
        tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> };
    };
    event: {
        positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>>;
        traces: Array<Pick<Trace, 'id' | 'name'>>;
    };
};

export type UsersInfosMassEditMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    massEdit: MassEditInput;
};

export type UsersInfosMassEditMutation = { usersInfosMassEdit: Array<Pick<UsersInfo, 'id'>> };

export type UsersInfosMassEditInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type UsersInfosMassEditInfosQuery = {
    organization: { customFields: { nodes: Array<MassEditCustomFieldFragment> } };
};

export type EventDelegationMembersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    privacy: CustomFieldPrivacy;
};

export type EventDelegationMembersInfosQuery = {
    organization: Pick<Organization, 'weezeventOrganizationId'> & {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
    };
    event: Pick<Event, 'startAt' | 'endAt'> & {
        customDocuments: Array<CustomDocumentPopulationFragment>;
        delegation: Pick<Delegation, 'numberOfDelegationsAccreditationsSlots'> & {
            accreditationsSlots: Array<Pick<DelegationAccreditationSlot, 'accreditationSlotId'>>;
            form: Pick<Form, 'positionDisplay' | 'slotDisplay'> & {
                elements: Array<{ customField?: Maybe<SegmentCustomFieldFragment> }>;
            };
        };
    };
};

export type OrganizationDelegationMembersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
    privacy: CustomFieldPrivacy;
};

export type OrganizationDelegationMembersInfosQuery = {
    organization: {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
        delegation: {
            form: { elements: Array<{ customField?: Maybe<SegmentCustomFieldFragment> }> };
        };
    };
};

export type DelegationAccreditationSlotMemberQueryVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type DelegationAccreditationSlotMemberQuery = {
    event: {
        delegation: { accreditationSlot: DelegationAccreditationSlotStatsFragment };
        member: VolunteerRegistrationDelegationAccreditationSlotFragment;
    };
};

export type DelegationAccreditationSlotMembersQueryVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
};

export type DelegationAccreditationSlotMembersQuery = {
    event: Pick<Event, 'isWeezeventConnected'> & {
        delegation: {
            accreditationSlot: {
                accreditationSlot: Pick<AccreditationsSlot, 'date' | 'name'>;
                accreditation: Pick<Accreditation, 'name'>;
                volunteersRegistrations: Array<VolunteerRegistrationDelegationAccreditationSlotFragment>;
            } & DelegationAccreditationSlotStatsFragment;
        };
    };
};

export type DelegationAccreditationSlotStatsQueryVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    accreditationSlotId: Scalars['AccreditationsSlotId'];
};

export type DelegationAccreditationSlotStatsQuery = {
    event: { delegation: { accreditationSlot: DelegationAccreditationSlotStatsFragment } };
};

export type DelegationAccreditationsQueryVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    onlyVisible: Scalars['Boolean'];
};

export type DelegationAccreditationsQuery = {
    event: Pick<Event, 'startAt' | 'endAt' | 'isWeezeventConnected'> & {
        delegation: Pick<Delegation, 'minAccreditationSlotDate'> & {
            accreditationsSlots: Array<
                {
                    accreditationSlot: Pick<AccreditationsSlot, 'id' | 'date' | 'name'>;
                    accreditation: Pick<Accreditation, 'name' | 'color'>;
                } & DelegationAccreditationSlotStatsFragment
            >;
        };
    };
};

export type EventDocumentsExportMutationVariables = {
    eventId: Scalars['EventId'];
    delegationId?: Maybe<Scalars['DelegationId']>;
    documentsExport: DocumentsExportInput;
};

export type EventDocumentsExportMutation = Pick<Mutation, 'eventDocumentsExport'>;

export type UserCreateMutationVariables = {};

export type UserCreateMutation = { userCreate: Pick<User, 'id' | 'email'> };

export type UserInfoDelegationDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    delegationId: Scalars['DelegationId'];
    deleteFromEvent?: Maybe<Scalars['Boolean']>;
};

export type UserInfoDelegationDeleteMutation = Pick<Mutation, 'userInfoDelegationDelete'>;

export type VolunteerRegistrationUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    email: Scalars['UserEmail'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    volunteerRegistration: VolunteersRegistrationInput;
};

export type VolunteerRegistrationUpdateMutation = {
    volunteerRegistrationUpdate: Pick<VolunteersRegistration, 'id'>;
};

export type UserPermissionQueryVariables = {
    checks: Array<PermissionCheck>;
};

export type UserPermissionQuery = { user: Pick<User, 'permission'> };

export type PositionsSlotsUsersInfosCheckInMutationVariables = {
    eventId: Scalars['EventId'];
    checkIn: CheckInInput;
};

export type PositionsSlotsUsersInfosCheckInMutation = Pick<
    Mutation,
    'positionsSlotsUsersInfosCheckIn'
>;

export type PositionSlotUserInfoQueryVariables = {
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    positionSlotId: Scalars['PositionsSlotId'];
};

export type PositionSlotUserInfoQuery = {
    event: {
        volunteerRegistration: { positionSlotUserInfo?: Maybe<UserPositionSlotUserInfoFragment> };
    };
};

export type UserPositionsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserPositionsQuery = {
    organization: { userInfo: FormsUsersInfosWishedFragment };
    event: Pick<
        Event,
        'startAt' | 'endAt' | 'hasFormWithPositionDisplay' | 'hasFormWithSlotDisplay'
    > & {
        volunteerRegistration: Pick<VolunteersRegistration, 'id' | 'volunteerMinutes'> & {
            positionsSlotsUsersInfos: Array<UserPositionSlotUserInfoFragment>;
            preassignPositions: Array<Pick<Position, 'id' | 'name' | 'range'>>;
            preassignPositionsCategories: Array<Pick<PositionsCategory, 'id' | 'name' | 'range'>>;
        };
    };
};

export type ConditionsCustomFieldsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type ConditionsCustomFieldsQuery = {
    organization: { customFields: { nodes: Array<Pick<CustomField, 'id' | 'name'>> } };
};

export type TagsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type TagsQuery = { organization: { tags: { nodes: Array<Pick<Tag, 'id' | 'name'>> } } };

export type UsersSegmentsCampaignsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type UsersSegmentsCampaignsQuery = {
    organization: { campaigns: { nodes: Array<SegmentCampaignFragment> } };
};

export type UsersSegmentsDelegationsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type UsersSegmentsDelegationsQuery = {
    organization: Pick<Organization, 'name'> & {
        delegations: {
            nodes: Array<
                Pick<Delegation, 'id' | 'name'> & { event?: Maybe<Pick<Event, 'id' | 'name'>> }
            >;
        };
    };
};

export type UsersSegmentsEventsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type UsersSegmentsEventsQuery = {
    organization: { events: { nodes: Array<SegmentEventFragment> } };
};

export type UsersSegmentsFormsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type UsersSegmentsFormsQuery = { organization: { forms: Array<SegmentFormFragment> } };

export type VolunteersSegmentsAccreditationsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsAccreditationsQuery = {
    event: {
        accreditations: {
            nodes: Array<Pick<Accreditation, 'id' | 'name' | 'accreditationCategoryName'>>;
        };
    };
};

export type VolunteersSegmentsAccreditationsCategoriesQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsAccreditationsCategoriesQuery = {
    event: { accreditationsCategories: Array<Pick<AccreditationsCategory, 'id' | 'name'>> };
};

export type VolunteersSegmentsAccreditationsSegmentsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsAccreditationsSegmentsQuery = {
    event: {
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name' | 'index'> & {
                segments: Array<Pick<Segment, 'id' | 'name' | 'index'>>;
            }
        >;
    };
};

export type VolunteersSegmentsAccreditationsSlotsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsAccreditationsSlotsQuery = {
    event: {
        accreditationsSlots: {
            nodes: Array<
                Pick<AccreditationsSlot, 'id' | 'name' | 'date'> & {
                    accreditation: Pick<Accreditation, 'name'>;
                }
            >;
        };
    };
};

export type VolunteersSegmentsCampaignsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsCampaignsQuery = {
    event: { campaigns: { nodes: Array<SegmentCampaignFragment> } };
};

export type VolunteeersSegmentsCustomSlotsPeriodsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteeersSegmentsCustomSlotsPeriodsQuery = {
    event: Pick<Event, 'startAt' | 'endAt' | 'ranges'> & {
        customSlotsPeriods: Array<
            Pick<CustomSlotsPeriod, 'name' | 'startDate' | 'endDate'> & {
                customSlots: Array<Pick<CustomSlot, 'name' | 'startTime' | 'endTime'>>;
            }
        >;
    };
};

export type VolunteersSegmentsDelegationsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsDelegationsQuery = {
    event: { delegations: { nodes: Array<Pick<Delegation, 'id' | 'name'>> } };
};

export type VolunteersSegmentsDelegationsSegmentsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsDelegationsSegmentsQuery = {
    event: {
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name' | 'index'> & {
                segments: Array<Pick<Segment, 'id' | 'name' | 'index'>>;
            }
        >;
    };
};

export type VolunteersSegmentsFormsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsFormsQuery = { event: { forms: Array<SegmentFormFragment> } };

export type VolunteersSegmentsPositionsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsPositionsQuery = {
    event: { positions: { nodes: Array<Pick<Position, 'id' | 'name' | 'positionCategoryName'>> } };
};

export type VolunteersSegmentsPositionsCategoriesQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsPositionsCategoriesQuery = {
    event: { positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name'>> };
};

export type VolunteersSegmentsPositionsSegmentsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsPositionsSegmentsQuery = {
    event: {
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name' | 'index'> & {
                segments: Array<Pick<Segment, 'id' | 'name' | 'index'>>;
            }
        >;
    };
};

export type VolunteersSegmentsPositionsSlotsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type VolunteersSegmentsPositionsSlotsQuery = {
    event: {
        positionsSlots: {
            nodes: Array<
                Pick<PositionsSlot, 'id' | 'name' | 'range'> & { position: Pick<Position, 'name'> }
            >;
        };
    };
};

export type VolunteersSegmentsUsersSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type VolunteersSegmentsUsersSegmentsQuery = {
    organization: {
        segmentsFolders: Array<
            Pick<SegmentsFolder, 'id' | 'name' | 'index'> & {
                segments: Array<Pick<Segment, 'id' | 'name' | 'index'>>;
            }
        >;
    };
};

export type SegmentCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
    segment: SegmentInput;
};

export type SegmentCreateMutation = { segmentCreate: Pick<Segment, 'id'> };

export type SegmentDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
};

export type SegmentDeleteMutation = { segmentDelete: Pick<Segment, 'id'> };

export type SegmentFolderCreateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolder: SegmentsFolderInput;
};

export type SegmentFolderCreateMutation = { segmentFolderCreate: Pick<SegmentsFolder, 'id'> };

export type SegmentFolderDeleteMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type SegmentFolderDeleteMutation = { segmentFolderDelete: Pick<SegmentsFolder, 'id'> };

export type SegmentFolderMoveDownMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type SegmentFolderMoveDownMutation = { segmentFolderMoveDown: Pick<SegmentsFolder, 'id'> };

export type SegmentFolderMoveUpMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type SegmentFolderMoveUpMutation = { segmentFolderMoveUp: Pick<SegmentsFolder, 'id'> };

export type SegmentFolderUpdateNameMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentFolderId: Scalars['SegmentsFolderId'];
    name: Scalars['String'];
};

export type SegmentFolderUpdateNameMutation = {
    segmentFolderUpdateName: Pick<SegmentsFolder, 'id'>;
};

export type SegmentMoveDownMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
};

export type SegmentMoveDownMutation = { segmentMoveDown: Pick<Segment, 'id'> };

export type SegmentMoveFolderMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
    segmentFolderId: Scalars['SegmentsFolderId'];
};

export type SegmentMoveFolderMutation = { segmentMoveFolder: Pick<Segment, 'id'> };

export type SegmentMoveUpMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
};

export type SegmentMoveUpMutation = { segmentMoveUp: Pick<Segment, 'id'> };

export type SegmentUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
    segment: SegmentInput;
};

export type SegmentUpdateMutation = { segmentUpdate: Pick<Segment, 'id'> };

export type SegmentUpdateNameMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentId: Scalars['SegmentId'];
    name: Scalars['String'];
};

export type SegmentUpdateNameMutation = { segmentUpdateName: Pick<Segment, 'id'> };

export type SegmentsFoldersReorderMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    segmentType: SegmentType;
    segmentsFolders: Array<SegmentFolderReorderInput>;
};

export type SegmentsFoldersReorderMutation = Pick<Mutation, 'segmentsFoldersReorder'>;

export type VolunteersRegistrationsMassDeleteMutationVariables = {
    eventId: Scalars['EventId'];
    selecteds: MassActionSelectedsInput;
};

export type VolunteersRegistrationsMassDeleteMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassDelete'
>;

export type UserQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    id: Scalars['UsersInfoId'];
    includeDelegations: Scalars['Boolean'];
    includeForms: Scalars['Boolean'];
};

export type UserQuery = { data: { row: UserInfoCommunityFragment } };

export type UserInfoUpdateFieldMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
    slug: Scalars['String'];
    value: Scalars['JSON'];
};

export type UserInfoUpdateFieldMutation = { userInfoUpdateField: Pick<UsersInfo, 'id'> };

export type UsersQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    predicates: Scalars['JSON'];
    name?: Maybe<Scalars['String']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<UsersInfosSort>;
    delegationCategoryId?: Maybe<Scalars['DelegationsCategoryId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    includeDelegations: Scalars['Boolean'];
    includeForms: Scalars['Boolean'];
};

export type UsersQuery = {
    data: {
        rows: Pick<UsersInfoConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<UserInfoCommunityFragment>;
        };
    };
};

export type UsersSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    privacy: CustomFieldPrivacy;
};

export type UsersSegmentsQuery = {
    organization: {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type ProfileQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type ProfileQuery = {
    user: Pick<User, 'email' | 'language'> & { userInfo: Pick<UsersInfo, 'id' | 'fields'> };
};

export type UserProfileUpdateMutationVariables = {
    userId: Scalars['UserId'];
    userInfoId: Scalars['UsersInfoId'];
    userInfo: UsersInfoProfileInput;
};

export type UserProfileUpdateMutation = { userProfileUpdate: Pick<User, 'email' | 'language'> };

export type EmailUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    email: Scalars['UserEmail'];
};

export type EmailUpdateMutation = Pick<Mutation, 'emailUpdate'>;

export type UserInfoUpdateMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userId: Scalars['UserId'];
    userInfo: UsersInfoInput;
    filledFormsIds?: Maybe<Array<Scalars['FormId']>>;
    formKeyToTeamCode?: Maybe<Scalars['JSON']>;
};

export type UserInfoUpdateMutation = { userInfoUpdate: Pick<UsersInfo, 'id'> };

export type UserInfoAccreditationsUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    userId?: Maybe<Scalars['UserId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfoAccreditations: Array<UserInfoAccreditationsInput>;
};

export type UserInfoAccreditationsUpdateMutation = Pick<Mutation, 'userInfoAccreditationsUpdate'>;

export type UserUpdateAccreditationsInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    loadDelegation: Scalars['Boolean'];
};

export type UserUpdateAccreditationsInfosQuery = {
    organization: {
        userInfo: {
            volunteerRegistration?: Maybe<{
                delegation?: Maybe<
                    Pick<Delegation, 'formId'> & {
                        accreditationsSlots: Array<DelegationAccreditationSlotFragment>;
                    }
                >;
            }>;
        } & MemberRegistrationUserInfoFragment &
            FormsUsersInfosWishedFragment;
    };
    event: {
        forms: Array<FormMemberRegistrationFragment>;
        accreditationsCategories: Array<FormRegisterAccreditationCategory_AccreditationsCategory_Fragment>;
    };
};

export type UserInfoPositionsUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    userId?: Maybe<Scalars['UserId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfoPositions: Array<UserInfoPositionsInput>;
};

export type UserInfoPositionsUpdateMutation = Pick<Mutation, 'userInfoPositionsUpdate'>;

export type UserUpdateMissionsInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserUpdateMissionsInfosQuery = {
    organization: { userInfo: MemberRegistrationUserInfoFragment & FormsUsersInfosWishedFragment };
    event: {
        forms: Array<FormMemberRegistrationFragment>;
        positionsCategories: Array<FormRegisterCategory_PositionsCategory_Fragment>;
    };
};

export type UserInfoSlotsUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    userId?: Maybe<Scalars['UserId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    userInfoId: Scalars['UsersInfoId'];
    userInfoSlots: Array<UserInfoSlotsInput>;
};

export type UserInfoSlotsUpdateMutation = Pick<Mutation, 'userInfoSlotsUpdate'>;

export type UserUpdateSlotsInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserUpdateSlotsInfosQuery = {
    organization: { userInfo: MemberRegistrationUserInfoFragment & FormsUsersInfosWishedFragment };
    event: Pick<Event, 'startAt' | 'endAt' | 'ranges'> & {
        forms: Array<FormMemberRegistrationFragment>;
    };
};

export type AwsS3PostCredentialsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    acl: Scalars['String'];
};

export type AwsS3PostCredentialsQuery = {
    awsS3PostCredentials: Pick<
        AwsS3PostCredentials,
        'endpointUrl' | 'policy' | 'amzSignature' | 'amzCredential' | 'amzDate'
    >;
};

export type AccreditationDuplicateMutationVariables = {
    eventId: Scalars['EventId'];
    accreditationId: Scalars['AccreditationId'];
};

export type AccreditationDuplicateMutation = { accreditationDuplicate: Pick<Accreditation, 'id'> };

export type CurrentUserQueryVariables = {};

export type CurrentUserQuery = {
    user: Pick<
        User,
        'id' | 'email' | 'language' | 'insertedAt' | 'hubspotToken' | 'profile' | 'termsAcceptedAt'
    >;
};

export type LoginCodeEmailCreateMutationVariables = {
    loginCodeEmail: LoginCodesEmailInput;
};

export type LoginCodeEmailCreateMutation = {
    loginCodeEmailCreate: Pick<LoginCodesEmail, 'insertedAt'>;
};

export type PositionDuplicateMutationVariables = {
    eventId: Scalars['EventId'];
    positionId: Scalars['PositionId'];
};

export type PositionDuplicateMutation = { positionDuplicate: Pick<Position, 'id'> };

export type EmailResendMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    emailType: EmailType;
};

export type EmailResendMutation = { emailResend: Pick<Email, 'id'> };

export type UserOverlaySlotsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UserOverlaySlotsQuery = {
    organization: { userInfo: FormsUsersInfosWishedFragment };
    event: Pick<Event, 'hasFormWithSlotDisplay'>;
};

export type VolunteersRegistrationsAccreditationsValidateMutationVariables = {
    eventId: Scalars['EventId'];
    delegationId?: Maybe<Scalars['DelegationId']>;
    formId: Scalars['FormId'];
    volunteerRegistrationsIds: Array<Scalars['VolunteersRegistrationId']>;
};

export type VolunteersRegistrationsAccreditationsValidateMutation = Pick<
    Mutation,
    'volunteersRegistrationsAccreditationsValidate'
>;

export type VolunteersRegistrationsMassAccreditationStateUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    massStateUpdate: MassAccreditationStateUpdateInput;
};

export type VolunteersRegistrationsMassAccreditationStateUpdateMutation = {
    volunteersRegistrationsMassAccreditationStateUpdate: Array<Pick<VolunteersRegistration, 'id'>>;
};

export type VolunteersRegistrationsMassAssignMutationVariables = {
    eventId: Scalars['EventId'];
    massAssign: MassAssignInput;
};

export type VolunteersRegistrationsMassAssignMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassAssign'
>;

export type VolunteersRegistrationsMassStateUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    massStateUpdate: MassStateUpdateInput;
};

export type VolunteersRegistrationsMassStateUpdateMutation = {
    volunteersRegistrationsMassStateUpdate: Array<Pick<VolunteersRegistration, 'id'>>;
};

export type VolunteersSegmentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    privacy: CustomFieldPrivacy;
    loadForms: Scalars['Boolean'];
    loadSegments: Scalars['Boolean'];
};

export type VolunteersSegmentsQuery = {
    organization: Pick<Organization, 'weezeventOrganizationId'> & {
        customFields: { nodes: Array<SegmentCustomFieldFragment> };
    };
    event: Pick<Event, 'startAt' | 'endAt' | 'weezevent'> & {
        forms: Array<Pick<Form, 'id' | 'name'>>;
        segmentsFolders: Array<SegmentsFoldersSegmentsFragment>;
    };
};

export type UpdateCellAccreditationsSlotsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type UpdateCellAccreditationsSlotsQuery = {
    event: {
        accreditations: {
            nodes: Array<
                Pick<Accreditation, 'name' | 'accreditationDisplay' | 'hasSlots'> & {
                    hiddenSlot: Pick<AccreditationsSlot, 'id' | 'name' | 'date'>;
                    slots: Array<Pick<AccreditationsSlot, 'id' | 'name' | 'date'>>;
                }
            >;
        };
    };
};

export type UpdateCellDelegationsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type UpdateCellDelegationsQuery = {
    event: { delegations: { nodes: Array<Pick<Delegation, 'id' | 'name'>> } };
};

export type UpdateCellPositionsSlotsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type UpdateCellPositionsSlotsQuery = {
    event: {
        positionsSlots: {
            nodes: Array<
                Pick<PositionsSlot, 'id' | 'name' | 'range'> & { position: Pick<Position, 'name'> }
            >;
        };
    };
};

export type UserInfoDelegationUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
    delegationId?: Maybe<Scalars['DelegationId']>;
};

export type UserInfoDelegationUpdateMutation = Pick<Mutation, 'userInfoDelegationUpdate'>;

export type VolunteerRegistrationUpdateFieldMutationVariables = {
    eventId: Scalars['EventId'];
    volunteerRegistrationId: Scalars['VolunteersRegistrationId'];
    slug: Scalars['String'];
    value: Scalars['JSON'];
};

export type VolunteerRegistrationUpdateFieldMutation = {
    volunteerRegistrationUpdateField: Pick<VolunteersRegistration, 'id'>;
};

export type VolunteerQueryVariables = {
    eventId: Scalars['EventId'];
    id: Scalars['VolunteersRegistrationId'];
    includeAccreditations: Scalars['Boolean'];
    includeAccreditationsMatrix: Scalars['Boolean'];
    includeAvailability: Scalars['Boolean'];
    includeCanRegistrationStateUpdate: Scalars['Boolean'];
    includeComments: Scalars['Boolean'];
    includeDelegation: Scalars['Boolean'];
    includeForms: Scalars['Boolean'];
    includeIsEditable: Scalars['Boolean'];
    includeNumberOfAccreditations: Scalars['Boolean'];
    includeNumberOfAssignments: Scalars['Boolean'];
    includeNumberOfAssignmentsDays: Scalars['Boolean'];
    includeNumberOfAvailableDays: Scalars['Boolean'];
    includePositions: Scalars['Boolean'];
    includeTeam: Scalars['Boolean'];
    includeVolunteerMinutes: Scalars['Boolean'];
    includeWishedPositions: Scalars['Boolean'];
    includePreassignedPositions: Scalars['Boolean'];
    includeWeezevent: Scalars['Boolean'];
};

export type VolunteerQuery = { data: { row: VolunteerRegistrationFragment } };

export type VolunteersQueryVariables = {
    eventId: Scalars['EventId'];
    predicates: Scalars['JSON'];
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sort?: Maybe<VolunteersRegistrationsSort>;
    name?: Maybe<Scalars['String']>;
    columnsSearch?: Maybe<Array<Scalars['String']>>;
    positionCategoryId?: Maybe<Scalars['PositionsCategoryId']>;
    positionId?: Maybe<Scalars['PositionId']>;
    preassignedPositionCategoryId?: Maybe<Scalars['PositionsCategoryId']>;
    preassignedPositionId?: Maybe<Scalars['PositionId']>;
    accreditationCategoryId?: Maybe<Scalars['AccreditationsCategoryId']>;
    accreditationId?: Maybe<Scalars['AccreditationId']>;
    delegationCategoryId?: Maybe<Scalars['DelegationsCategoryId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    includeAccreditations: Scalars['Boolean'];
    includeAccreditationsMatrix: Scalars['Boolean'];
    includeAvailability: Scalars['Boolean'];
    includeCanRegistrationStateUpdate: Scalars['Boolean'];
    includeComments: Scalars['Boolean'];
    includeDelegation: Scalars['Boolean'];
    includeForms: Scalars['Boolean'];
    includeIsEditable: Scalars['Boolean'];
    includeNumberOfAccreditations: Scalars['Boolean'];
    includeNumberOfAssignments: Scalars['Boolean'];
    includeNumberOfAssignmentsDays: Scalars['Boolean'];
    includeNumberOfAvailableDays: Scalars['Boolean'];
    includePositions: Scalars['Boolean'];
    includeTeam: Scalars['Boolean'];
    includeVolunteerMinutes: Scalars['Boolean'];
    includeWishedPositions: Scalars['Boolean'];
    includePreassignedPositions: Scalars['Boolean'];
    includeWeezevent: Scalars['Boolean'];
};

export type VolunteersQuery = {
    data: {
        rows: Pick<VolunteersRegistrationsConnection, 'numberOfPages' | 'totalCount'> & {
            nodes: Array<VolunteerRegistrationFragment>;
        };
    };
};

export type MassAccreditSlotsQueryVariables = {
    eventId: Scalars['EventId'];
    delegationId?: Maybe<Scalars['DelegationId']>;
    accreditationName?: Maybe<Scalars['String']>;
    excludeFull?: Maybe<Scalars['Boolean']>;
    excludeEmpty?: Maybe<Scalars['Boolean']>;
};

export type MassAccreditSlotsQuery = {
    event: {
        accreditationsSlots: {
            nodes: Array<
                Pick<
                    AccreditationsSlot,
                    'id' | 'name' | 'assignedResources' | 'maxResources' | 'date' | 'isFull'
                > & {
                    accreditation: Pick<
                        Accreditation,
                        | 'id'
                        | 'accreditationCategoryId'
                        | 'name'
                        | 'accreditationDisplay'
                        | 'hasSlots'
                    >;
                    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
                }
            >;
        };
    };
};

export type VolunteersRegistrationsMassWishedAccreditationsUpdateMutationVariables = {
    eventId: Scalars['EventId'];
    delegationId: Scalars['DelegationId'];
    massAccredit: MassAccreditInput;
};

export type VolunteersRegistrationsMassWishedAccreditationsUpdateMutation = Pick<
    Mutation,
    'volunteersRegistrationsMassWishedAccreditationsUpdate'
>;

export type UserExistsQueryVariables = {
    email: Scalars['UserEmail'];
};

export type UserExistsQuery = Pick<Query, 'userExists'>;

export type DelegationSpaceDashboardQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
};

export type DelegationSpaceDashboardQuery = {
    organization: Pick<Organization, 'allowDelegationSpaceDownload'> & {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        delegation: Pick<
            Delegation,
            | 'id'
            | 'name'
            | 'description'
            | 'organizationId'
            | 'eventId'
            | 'formId'
            | 'deadlineDate'
            | 'numberOfResources'
            | 'maxResources'
            | 'columns'
            | 'state'
            | 'showFormLinkInDelegationSpace'
            | 'fields'
            | 'canLeadersEditCustomFields'
        > & {
            form: Pick<Form, 'id' | 'name'>;
            leaders: Array<
                { firstName: UsersInfo['fieldValue'] } & MemberRegistrationUserInfoFragment
            >;
        };
    };
};

export type DelegationsSpaceDelegationQueryVariables = {
    delegationId: Scalars['DelegationId'];
    organizationId: Scalars['OrganizationId'];
};

export type DelegationsSpaceDelegationQuery = {
    organization: {
        delegations: {
            nodes: Array<
                Pick<Delegation, 'id' | 'name'> & { event?: Maybe<Pick<Event, 'id' | 'name'>> }
            >;
        };
        delegation: Pick<
            Delegation,
            | 'eventId'
            | 'formId'
            | 'parentId'
            | 'numberOfResources'
            | 'name'
            | 'columns'
            | 'state'
            | 'canLeadersAddMembers'
            | 'canLeadersEditMembers'
        > & {
            event?: Maybe<
                Pick<Event, 'name'> & {
                    customDocuments: Array<Pick<CustomDocument, 'slug' | 'name'>>;
                }
            >;
        };
    } & OrganizationDelegationsSpace_Organization_Fragment &
        OrganizationCustomFieldsFragment;
    user: { userInfo: { firstName: UsersInfo['fieldValue'] } & MemberRegistrationUserInfoFragment };
};

export type DelegationUpdateFieldsMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId: Scalars['DelegationId'];
    fields: Scalars['JSON'];
};

export type DelegationUpdateFieldsMutation = Pick<Mutation, 'delegationUpdateFields'>;

export type DelegationUpdateFieldsInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
};

export type DelegationUpdateFieldsInfosQuery = {
    organization: {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        delegation: Pick<Delegation, 'fields'>;
    };
};

export type DelegationsListQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type DelegationsListQuery = {
    organization: Pick<Organization, 'name'> & {
        delegations: {
            nodes: Array<
                Pick<Delegation, 'id' | 'name' | 'eventId'> & { event?: Maybe<Pick<Event, 'name'>> }
            >;
        };
    };
};

export type ParentUsersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
    name?: Maybe<Scalars['String']>;
};

export type ParentUsersInfosQuery = {
    organization: { delegation: { parentUsersInfos: Array<ExistingVolunteerFragment> } };
};

export type UsersInfosDelegationAddMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
    usersInfosIds: Array<Scalars['UsersInfoId']>;
};

export type UsersInfosDelegationAddMutation = Pick<Mutation, 'usersInfosDelegationAdd'>;

export type DelegationsSpaceCreateMemberQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    delegationId: Scalars['DelegationId'];
    formId: Scalars['FormId'];
};

export type DelegationsSpaceCreateMemberQuery = {
    organization: Pick<OrganizationPublic, 'country'> & {
        delegation: Pick<Delegation, 'eventId'> & {
            accreditationsSlots: Array<DelegationAccreditationSlotFragment>;
            form: Pick<
                Form,
                | 'id'
                | 'description'
                | 'daysDisplay'
                | 'positionTitle'
                | 'positionSubtitle'
                | 'slotTitle'
                | 'slotSubtitle'
                | 'accreditationTitle'
                | 'accreditationSubtitle'
                | 'informationTitle'
                | 'registerButtonText'
            > & {
                elements: Array<FormElementDisplayFragment>;
                customSlotsPeriods: Array<FormRegisterCustomSlotPeriodFragment>;
            } & FormMissionsOptionsFragment &
                FormAccreditationsOptionsFragment;
            event?: Maybe<
                Pick<Event, 'startAt' | 'endAt' | 'ranges' | 'country'> & {
                    accreditationsCategories: Array<FormRegisterAccreditationCategory_AccreditationsCategory_Fragment>;
                    positionsCategories: Array<FormRegisterCategory_PositionsCategory_Fragment>;
                }
            >;
        };
    } & OrganizationDelegationsSpace_OrganizationPublic_Fragment;
};

export type DelegationsSpaceUpdateMemberQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId: Scalars['DelegationId'];
    formId: Scalars['FormId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type DelegationsSpaceUpdateMemberQuery = {
    organization: Pick<Organization, 'country'> & {
        userInfo: Pick<UsersInfo, 'name' | 'userId'> & {
            picture?: Maybe<Pick<Document, 'url'>>;
            formUserInfo: Pick<
                FormsUsersInfo,
                | 'positionsCategoriesIds'
                | 'positionsIds'
                | 'positionsSlotsIds'
                | 'accreditationsSlotsIds'
            > & {
                accreditationsSlots: Array<
                    Pick<AccreditationsSlot, 'name' | 'date'> & {
                        accreditation: Pick<Accreditation, 'id' | 'name'>;
                    }
                >;
                positionsSlots: Array<
                    Pick<PositionsSlot, 'nameOrRange'> & { position: Pick<Position, 'id' | 'name'> }
                >;
                positions: Array<Pick<Position, 'id' | 'name' | 'range'>>;
                positionsCategories: Array<Pick<PositionsCategory, 'id' | 'name' | 'range'>>;
                slots: Array<
                    Pick<FormsUsersInfosSlot, 'startDate' | 'startTime' | 'endDate' | 'endTime'>
                >;
            };
        } & UserInfoFormFragment;
        delegation: Pick<Delegation, 'eventId'> & {
            accreditationsSlots: Array<DelegationAccreditationSlotFragment>;
            form: Pick<
                Form,
                | 'id'
                | 'description'
                | 'daysDisplay'
                | 'positionTitle'
                | 'positionSubtitle'
                | 'slotTitle'
                | 'slotSubtitle'
                | 'accreditationTitle'
                | 'accreditationSubtitle'
                | 'informationTitle'
                | 'registerButtonText'
            > & {
                elements: Array<FormElementDisplayFragment>;
                customSlotsPeriods: Array<FormRegisterCustomSlotPeriodFragment>;
            } & FormMissionsOptionsFragment &
                FormAccreditationsOptionsFragment;
            event?: Maybe<
                Pick<Event, 'startAt' | 'endAt' | 'ranges' | 'country'> & {
                    accreditationsCategories: Array<FormRegisterAccreditationCategory_AccreditationsCategory_Fragment>;
                    positionsCategories: Array<FormRegisterCategory_PositionsCategory_Fragment>;
                }
            >;
        };
    } & OrganizationDelegationsSpace_Organization_Fragment;
};

export type FormAccreditationsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type FormAccreditationsQuery = {
    event: {
        accreditationsCategories: Array<FormRegisterAccreditationCategory_AccreditationsCategoryPublic_Fragment>;
    };
};

export type FormInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
    teamCode?: Maybe<Scalars['TeamCode']>;
};

export type FormInfosQuery = {
    organization: Pick<OrganizationPublic, 'country'> & {
        event?: Maybe<
            Pick<EventPublic, 'id' | 'name' | 'country' | 'languages' | 'startAt' | 'endAt'> & {
                team?: Maybe<
                    Pick<
                        Team,
                        | 'teamCode'
                        | 'accreditationsSlotsIds'
                        | 'positionsCategoriesIds'
                        | 'positionsIds'
                        | 'positionsSlotsIds'
                    > & {
                        slots: Array<
                            | Pick<
                                  FormsUsersInfosSlot,
                                  'startDate' | 'startTime' | 'endDate' | 'endTime'
                              >
                            | Pick<
                                  VolunteersRegistrationsSlot,
                                  'startDate' | 'startTime' | 'endDate' | 'endTime'
                              >
                        >;
                    }
                >;
            }
        >;
        form: Pick<
            Form,
            | 'allowMultiProfiles'
            | 'description'
            | 'successMessage'
            | 'daysDisplay'
            | 'isOpen'
            | 'closedTitle'
            | 'closedMessage'
            | 'noAccessTitle'
            | 'noAccessMessage'
            | 'positionTitle'
            | 'positionSubtitle'
            | 'slotTitle'
            | 'slotSubtitle'
            | 'accreditationTitle'
            | 'accreditationSubtitle'
            | 'areTeamsAllowed'
            | 'maxTeamMembers'
            | 'teamMemberFillFullForm'
            | 'emailTitle'
            | 'informationTitle'
            | 'registerButtonText'
        > & {
            design?: Maybe<Pick<Design, 'colors'> & { banner?: Maybe<Pick<Document, 'url'>> }>;
            elements: Array<FormElementDisplayFragment>;
            segments: Array<Pick<FormsSegment, 'segmentId'>>;
        } & FormMissionsOptionsFragment &
            FormAccreditationsOptionsFragment;
    };
};

export type FormRegisterMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    delegationId?: Maybe<Scalars['DelegationId']>;
    formId: Scalars['FormId'];
    email: Scalars['UserEmail'];
    formRegister: FormRegisterInput;
};

export type FormRegisterMutation = { formRegister: Pick<FormRegisterResult, 'teamCode'> };

export type FormMissionsQueryVariables = {
    eventId: Scalars['EventId'];
};

export type FormMissionsQuery = {
    event: { positionsCategories: Array<FormRegisterCategory_PositionsCategoryPublic_Fragment> };
};

export type FormSlotsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
};

export type FormSlotsQuery = {
    organization: { form: { customSlotsPeriods: Array<FormRegisterCustomSlotPeriodFragment> } };
    event: Pick<EventPublic, 'startAt' | 'endAt' | 'ranges'>;
};

export type EmailFormInviteSendMutationVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    formId: Scalars['FormId'];
    teamCode: Scalars['TeamCode'];
    emails: Array<Scalars['UserEmail']>;
};

export type EmailFormInviteSendMutation = Pick<Mutation, 'emailFormInviteSend'>;

export type FormTeamSummaryQueryVariables = {
    eventId: Scalars['EventId'];
    teamCode: Scalars['TeamCode'];
};

export type FormTeamSummaryQuery = {
    event: {
        team?: Maybe<{
            positionsCategories: Array<Pick<PositionsCategoryPublic, 'name'>>;
            positions: Array<Pick<PositionPublic, 'name'>>;
            positionsSlots: Array<Pick<PositionsSlotPublic, 'nameOrRange'>>;
            slots: Array<
                Pick<FormsUsersInfosSlot, 'range'> | Pick<VolunteersRegistrationsSlot, 'range'>
            >;
        }>;
    };
};

export type UsersInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    formId: Scalars['FormId'];
};

export type UsersInfosQuery = {
    user: { userInfo: UserInfoFormFragment; usersInfos: Array<UserInfoFormFragment> };
};

export type MemberCampaignsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
    name?: Maybe<Scalars['String']>;
};

export type MemberCampaignsQuery = {
    organization: OrganizationMembersSpaceFragment;
    userInfo: {
        campaigns: {
            nodes: Array<Pick<Campaign, 'id' | 'name' | 'campaignType' | 'sentAt' | 'preview'>>;
        };
    };
};

export type MemberDocumentsQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
    name?: Maybe<Scalars['String']>;
};

export type MemberDocumentsQuery = {
    organization: OrganizationMembersSpaceFragment;
    userInfo: { documents: { nodes: Array<Pick<Document, 'id' | 'name' | 'size' | 'url'>> } };
};

export type MemberQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type MemberQuery = {
    organization: OrganizationMembersSpaceFragment;
    userInfo: Pick<UsersInfo, 'email' | 'name'> & { firstName: UsersInfo['fieldValue'] } & {
        picture?: Maybe<Pick<Document, 'url'>>;
        campaigns: { nodes: Array<Pick<Campaign, 'id' | 'name' | 'campaignType' | 'sentAt'>> };
        documents: { nodes: Array<Pick<Document, 'id' | 'name' | 'size'>> };
        volunteersRegistrations: Array<{
            event: Pick<Event, 'id' | 'name' | 'range' | 'startAt'>;
            positionsSlotsUsersInfos: Array<
                Pick<PositionsSlotsUsersInfo, 'id' | 'isConfirmationEmailSent'> & {
                    position: Pick<PositionPublic, 'name'>;
                    positionSlot: Pick<PositionsSlotPublic, 'range'>;
                }
            >;
            accreditationsUsersInfos: Array<
                Pick<AccreditationsUsersInfo, 'id' | 'isConfirmationEmailSent'> & {
                    accreditationSlot: Pick<AccreditationsSlot, 'name' | 'date'>;
                    accreditation: Pick<Accreditation, 'name' | 'acronym'>;
                    accreditationCategory: Pick<AccreditationsCategory, 'name'>;
                }
            >;
        }>;
    };
};

export type MemberRegistrationQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId?: Maybe<Scalars['EventId']>;
    userInfoId: Scalars['UsersInfoId'];
    isEvent: Scalars['Boolean'];
};

export type MemberRegistrationQuery = {
    organization: Pick<Organization, 'country'> & {
        customFields: { nodes: Array<CustomFieldWithConditionFragment> };
        event?: Maybe<Pick<Event, 'name' | 'country'>>;
        userInfo: {
            volunteerRegistration?: Maybe<
                Pick<
                    VolunteersRegistration,
                    'state' | 'accreditationState' | 'volunteerMinutes'
                > & {
                    positionsSlotsUsersInfos: Array<MemberAssignmentFragment>;
                    accreditationsUsersInfos: Array<MemberAccreditationFragment>;
                }
            >;
            campaigns: {
                nodes: Array<
                    Pick<Campaign, 'name'> & {
                        documents: Array<Pick<Document, 'id' | 'name' | 'size' | 'url'>>;
                    }
                >;
            };
        } & MemberRegistrationUserInfoFragment &
            FormsUsersInfosWishedFragment;
    } & OrganizationMembersSpaceFragment;
};

export type UpdateMemberInfosQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    eventId: Scalars['EventId'];
    userInfoId: Scalars['UsersInfoId'];
};

export type UpdateMemberInfosQuery = {
    organization: OrganizationMembersSpaceFragment;
    event: Pick<Event, 'country'>;
    userInfo: Pick<UsersInfo, 'id' | 'userId' | 'email' | 'nameOrEmail' | 'fields'> & {
        formsUsersInfos: Array<{ form: UpdateUserFormFragment }>;
    };
};

export type OrganizationPublicQueryVariables = {
    organizationId: Scalars['OrganizationId'];
    loadDelegationsSpace: Scalars['Boolean'];
    loadMembersSpace: Scalars['Boolean'];
};

export type OrganizationPublicQuery = {
    organization: {
        delegationsSpaceDesign?: Maybe<
            Pick<Design, 'colors'> & {
                banner?: Maybe<Pick<Document, 'url'>>;
                logo?: Maybe<Pick<Document, 'url'>>;
            }
        >;
        membersSpaceDesign?: Maybe<
            Pick<Design, 'colors'> & {
                banner?: Maybe<Pick<Document, 'url'>>;
                logo?: Maybe<Pick<Document, 'url'>>;
            }
        >;
    };
};

export type ProfilesQueryVariables = {
    organizationId: Scalars['OrganizationId'];
};

export type ProfilesQuery = {
    user: {
        usersInfos: Array<
            Pick<UsersInfo, 'id' | 'name' | 'email'> & { picture?: Maybe<Pick<Document, 'url'>> }
        >;
    };
};

export const ALL_ACCREDITATIONDISPLAY: AccreditationDisplay[] = Object.keys(
    AccreditationDisplay
) as AccreditationDisplay[];
export const ALL_ACCREDITATIONPROPERTY: AccreditationProperty[] = Object.keys(
    AccreditationProperty
) as AccreditationProperty[];
export const ALL_ACCREDITATIONSEMAILSRECIPIENTS: AccreditationsEmailsRecipients[] = Object.keys(
    AccreditationsEmailsRecipients
) as AccreditationsEmailsRecipients[];
export const ALL_ACCREDITATIONSSLOTSSORTATTRIBUTES: AccreditationsSlotsSortAttributes[] =
    Object.keys(AccreditationsSlotsSortAttributes) as AccreditationsSlotsSortAttributes[];
export const ALL_ACCREDITATIONSSORTATTRIBUTES: AccreditationsSortAttributes[] = Object.keys(
    AccreditationsSortAttributes
) as AccreditationsSortAttributes[];
export const ALL_ACCREDITATIONSTATE: AccreditationState[] = Object.keys(
    AccreditationState
) as AccreditationState[];
export const ALL_ASSIGNMENTSEMAILSRECIPIENTS: AssignmentsEmailsRecipients[] = Object.keys(
    AssignmentsEmailsRecipients
) as AssignmentsEmailsRecipients[];
export const ALL_CAMPAIGNSSORTATTRIBUTES: CampaignsSortAttributes[] = Object.keys(
    CampaignsSortAttributes
) as CampaignsSortAttributes[];
export const ALL_CAMPAIGNSTATE: CampaignState[] = Object.keys(CampaignState) as CampaignState[];
export const ALL_CAMPAIGNTYPE: CampaignType[] = Object.keys(CampaignType) as CampaignType[];
export const ALL_CHECKINSTATE: CheckInState[] = Object.keys(CheckInState) as CheckInState[];
export const ALL_CHECKINSTRATEGY: CheckInStrategy[] = Object.keys(
    CheckInStrategy
) as CheckInStrategy[];
export const ALL_CONDITIONSOPERATOR: ConditionsOperator[] = Object.keys(
    ConditionsOperator
) as ConditionsOperator[];
export const ALL_CUSTOMBADGE: CustomBadge[] = Object.keys(CustomBadge) as CustomBadge[];
export const ALL_CUSTOMFIELDPRIVACY: CustomFieldPrivacy[] = Object.keys(
    CustomFieldPrivacy
) as CustomFieldPrivacy[];
export const ALL_CUSTOMFIELDPROPERTY: CustomFieldProperty[] = Object.keys(
    CustomFieldProperty
) as CustomFieldProperty[];
export const ALL_CUSTOMFIELDSSORTATTRIBUTES: CustomFieldsSortAttributes[] = Object.keys(
    CustomFieldsSortAttributes
) as CustomFieldsSortAttributes[];
export const ALL_CUSTOMFIELDVARIETY: CustomFieldVariety[] = Object.keys(
    CustomFieldVariety
) as CustomFieldVariety[];
export const ALL_DELEGATIONPROPERTY: DelegationProperty[] = Object.keys(
    DelegationProperty
) as DelegationProperty[];
export const ALL_DELEGATIONSIMPORTSTATE: DelegationsImportState[] = Object.keys(
    DelegationsImportState
) as DelegationsImportState[];
export const ALL_DELEGATIONSSORTATTRIBUTES: DelegationsSortAttributes[] = Object.keys(
    DelegationsSortAttributes
) as DelegationsSortAttributes[];
export const ALL_DELEGATIONSTATE: DelegationState[] = Object.keys(
    DelegationState
) as DelegationState[];
export const ALL_DKIMSTATUS: DkimStatus[] = Object.keys(DkimStatus) as DkimStatus[];
export const ALL_DOCUMENTSEXPORTSTRATEGY: DocumentsExportStrategy[] = Object.keys(
    DocumentsExportStrategy
) as DocumentsExportStrategy[];
export const ALL_DOCUMENTTYPE: DocumentType[] = Object.keys(DocumentType) as DocumentType[];
export const ALL_EMAILSTATE: EmailState[] = Object.keys(EmailState) as EmailState[];
export const ALL_EMAILSTATUS: EmailStatus[] = Object.keys(EmailStatus) as EmailStatus[];
export const ALL_EMAILTYPE: EmailType[] = Object.keys(EmailType) as EmailType[];
export const ALL_EVENTPROPERTY: EventProperty[] = Object.keys(EventProperty) as EventProperty[];
export const ALL_EVENTSSORTATTRIBUTES: EventsSortAttributes[] = Object.keys(
    EventsSortAttributes
) as EventsSortAttributes[];
export const ALL_EVENTSTATE: EventState[] = Object.keys(EventState) as EventState[];
export const ALL_EXPORTFORMAT: ExportFormat[] = Object.keys(ExportFormat) as ExportFormat[];
export const ALL_EXPORTSTATE: ExportState[] = Object.keys(ExportState) as ExportState[];
export const ALL_FEATURE: Feature[] = Object.keys(Feature) as Feature[];
export const ALL_FIELDPROPERTY: FieldProperty[] = Object.keys(FieldProperty) as FieldProperty[];
export const ALL_FIELDTYPE: FieldType[] = Object.keys(FieldType) as FieldType[];
export const ALL_FILESIMPORTSTATE: FilesImportState[] = Object.keys(
    FilesImportState
) as FilesImportState[];
export const ALL_FILTERTYPE: FilterType[] = Object.keys(FilterType) as FilterType[];
export const ALL_FORMELEMENTTYPE: FormElementType[] = Object.keys(
    FormElementType
) as FormElementType[];
export const ALL_FORMPOSITIONRANKING: FormPositionRanking[] = Object.keys(
    FormPositionRanking
) as FormPositionRanking[];
export const ALL_FORMSTATE: FormState[] = Object.keys(FormState) as FormState[];
export const ALL_ICON: Icon[] = Object.keys(Icon) as Icon[];
export const ALL_INVOICESTATE: InvoiceState[] = Object.keys(InvoiceState) as InvoiceState[];
export const ALL_LANGUAGE: Language[] = Object.keys(Language) as Language[];
export const ALL_LOGINCODESOURCE: LoginCodeSource[] = Object.keys(
    LoginCodeSource
) as LoginCodeSource[];
export const ALL_MAPLAYER: MapLayer[] = Object.keys(MapLayer) as MapLayer[];
export const ALL_MASSASSIGNSTRATEGY: MassAssignStrategy[] = Object.keys(
    MassAssignStrategy
) as MassAssignStrategy[];
export const ALL_MASSPREASSIGNLEVEL: MassPreAssignLevel[] = Object.keys(
    MassPreAssignLevel
) as MassPreAssignLevel[];
export const ALL_ORGANIZATIONEVENTLEVEL: OrganizationEventLevel[] = Object.keys(
    OrganizationEventLevel
) as OrganizationEventLevel[];
export const ALL_ORGANIZATIONSSORTATTRIBUTES: OrganizationsSortAttributes[] = Object.keys(
    OrganizationsSortAttributes
) as OrganizationsSortAttributes[];
export const ALL_PDFFORMAT: PdfFormat[] = Object.keys(PdfFormat) as PdfFormat[];
export const ALL_POSITIONPROPERTY: PositionProperty[] = Object.keys(
    PositionProperty
) as PositionProperty[];
export const ALL_POSITIONSIMPORTSTATE: PositionsImportState[] = Object.keys(
    PositionsImportState
) as PositionsImportState[];
export const ALL_POSITIONSSLOTSSORTATTRIBUTES: PositionsSlotsSortAttributes[] = Object.keys(
    PositionsSlotsSortAttributes
) as PositionsSlotsSortAttributes[];
export const ALL_POSITIONSSORTATTRIBUTES: PositionsSortAttributes[] = Object.keys(
    PositionsSortAttributes
) as PositionsSortAttributes[];
export const ALL_POSITIONSTATE: PositionState[] = Object.keys(PositionState) as PositionState[];
export const ALL_REGISTERACCREDITATIONDISPLAY: RegisterAccreditationDisplay[] = Object.keys(
    RegisterAccreditationDisplay
) as RegisterAccreditationDisplay[];
export const ALL_REGISTERDAYSDISPLAY: RegisterDaysDisplay[] = Object.keys(
    RegisterDaysDisplay
) as RegisterDaysDisplay[];
export const ALL_REGISTERPOSITIONDISPLAY: RegisterPositionDisplay[] = Object.keys(
    RegisterPositionDisplay
) as RegisterPositionDisplay[];
export const ALL_REGISTERPOSITIONFILTER: RegisterPositionFilter[] = Object.keys(
    RegisterPositionFilter
) as RegisterPositionFilter[];
export const ALL_REGISTERSLOTDISPLAY: RegisterSlotDisplay[] = Object.keys(
    RegisterSlotDisplay
) as RegisterSlotDisplay[];
export const ALL_ROLETYPE: RoleType[] = Object.keys(RoleType) as RoleType[];
export const ALL_SEGMENTTYPE: SegmentType[] = Object.keys(SegmentType) as SegmentType[];
export const ALL_SEX: Sex[] = Object.keys(Sex) as Sex[];
export const ALL_SORTDIRECTION: SortDirection[] = Object.keys(SortDirection) as SortDirection[];
export const ALL_SUPPORTEDLANGUAGE: SupportedLanguage[] = Object.keys(
    SupportedLanguage
) as SupportedLanguage[];
export const ALL_SYNCHRONIZATIONSTATE: SynchronizationState[] = Object.keys(
    SynchronizationState
) as SynchronizationState[];
export const ALL_TEXTMESSAGEREPLIES: TextMessageReplies[] = Object.keys(
    TextMessageReplies
) as TextMessageReplies[];
export const ALL_TEXTMESSAGESTATE: TextMessageState[] = Object.keys(
    TextMessageState
) as TextMessageState[];
export const ALL_TRANSLATIONMODULE: TranslationModule[] = Object.keys(
    TranslationModule
) as TranslationModule[];
export const ALL_TRANSLATIONSTATUS: TranslationStatus[] = Object.keys(
    TranslationStatus
) as TranslationStatus[];
export const ALL_TRANSLATIONTYPE: TranslationType[] = Object.keys(
    TranslationType
) as TranslationType[];
export const ALL_USERPROFILE: UserProfile[] = Object.keys(UserProfile) as UserProfile[];
export const ALL_USERSINFOSSORTATTRIBUTES: UsersInfosSortAttributes[] = Object.keys(
    UsersInfosSortAttributes
) as UsersInfosSortAttributes[];
export const ALL_VOLUNTEERREGISTRATIONSTATE: VolunteerRegistrationState[] = Object.keys(
    VolunteerRegistrationState
) as VolunteerRegistrationState[];
export const ALL_VOLUNTEERSIMPORTMODE: VolunteersImportMode[] = Object.keys(
    VolunteersImportMode
) as VolunteersImportMode[];
export const ALL_VOLUNTEERSIMPORTSTATE: VolunteersImportState[] = Object.keys(
    VolunteersImportState
) as VolunteersImportState[];
export const ALL_VOLUNTEERSREGISTRATIONSSORTATTRIBUTES: VolunteersRegistrationsSortAttributes[] =
    Object.keys(VolunteersRegistrationsSortAttributes) as VolunteersRegistrationsSortAttributes[];
export const ALL_WEEZEVENTSYNCHRONIZATIONDIRECTION: WeezeventSynchronizationDirection[] =
    Object.keys(WeezeventSynchronizationDirection) as WeezeventSynchronizationDirection[];

export const DATE_TIME_FIELDS = [
    'insertedAt',
    'updatedAt',
    'scannedAt',
    'date',
    'sentAt',
    'scheduledAt',
    'deletedAt',
    'lockedAt',
    'bouncedAt',
    'complaintAt',
    'deliveredAt',
    'rejectedAt',
    'openedAt',
    'clickedAt',
    'queuedAt',
    'closedAt',
    'periodEndAt',
    'checkInAt',
    'checkOutAt',
    'validatedAt',
    'receptionDate',
    'termsAcceptedAt',
    'lastSeenAt',
    'registrationDate'
];
export const DATE_FIELDS = [
    'date',
    'startDate',
    'endDate',
    'deadlineDate',
    'minAccreditationSlotDate',
    'startAt',
    'endAt',
    'paidAt'
];
export const RANGE_FIELDS = ['range', 'ranges'];
export const DURATION_FIELDS = ['assignedDuration', 'duration'];
