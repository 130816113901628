import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { EmptyFormBox } from 'common-front/src/designSystem/components/formBox';
import { RichSelect as RichSelectComponent } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput as TextInputComponent } from 'common-front/src/designSystem/components/textInput';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { CommonEnvVars } from 'common/src/envVars';
import {
    ALL_CUSTOMBADGE,
    ALL_EVENTSTATE,
    ALL_FEATURE,
    BackofficeOrganizationQuery,
    EventsSortAttributes,
    EventState,
    SortDirection
} from 'common/src/generated/types';
import {
    BackofficeOrganizationInputService,
    IUpdateBackofficeOrganizationValues
} from 'common/src/input/backofficeOrganizationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getDisplayName } from 'common/src/vo/customBadge';
import { Sort } from 'common/src/vo/sort';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import {
    useBackofficeEventQuery,
    useBackofficeOrganizationQuery,
    useBackofficeOrganizationUpdateMutation
} from '../../../generated/graphqlHooks';
import { BackofficeEventRow } from './backofficeEventRow';

interface IUpdateBackofficeOrganizationComponentProps {
    organization: BackofficeOrganizationQuery['backoffice']['organization'];
}

const UpdateBackofficeOrganizationComponent = (
    props: IUpdateBackofficeOrganizationComponentProps
) => {
    const { history, translate } = useHeavent();
    const enumToOptions = useEnumToOptions();
    const backofficeOrganizationInput = useService(BackofficeOrganizationInputService);
    const validateService = useService(ValidateService);
    const { mutate } = useBackofficeOrganizationUpdateMutation();
    const [name, nameDebouced, setName] = useStateDebounce('');
    const [states, setStates] = React.useState<EventState[]>([]);
    const [sort, _setSort] = React.useState<Sort<EventsSortAttributes> | null>({
        direction: SortDirection.Asc,
        attribute: EventsSortAttributes.Name
    });
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading, reload } = useBackofficeEventQuery({
        organizationId: props.organization.id,
        name: isNonEmptyString(nameDebouced) ? nameDebouced : undefined,
        states: isNonEmptyArray(states) ? states : undefined,
        sort,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.backoffice?.events);
    const usersIdsOptions = React.useMemo(
        () =>
            uniqBy(
                CommonEnvVars.ADMINS.concat(props.organization.users).map((user) => ({
                    id: user.id,
                    name: user.email
                })),
                ({ id }) => id
            ),
        [props.organization]
    );
    const customBadges = React.useMemo(
        () =>
            sortBy(
                ALL_CUSTOMBADGE.map((customBadge) => ({
                    value: customBadge,
                    name: getDisplayName(customBadge)
                })),
                ({ name }) => name
            ),
        []
    );
    const setSort = (newSort: Sort<EventsSortAttributes> | null) => {
        _setSort(newSort);
        setOffset(0);
    };

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={{
                organization: backofficeOrganizationInput.organizationInputDefault(
                    props.organization
                )
            }}
            render={({ form, handleSubmit, submitting }) => (
                <FullScreenPopup
                    button={
                        <Button isLoading={submitting} textAlign="center" onClick={handleSubmit}>
                            {translate('mettre_jour_l_12499')}
                        </Button>
                    }
                    category="Backoffice"
                    fallbackClosePath={HeaventPaths.BACKOFFICE_ORGANIZATIONS}
                    title={translate('mise_jour_de_17055', props.organization.name)}
                >
                    <CenteredContainer>
                        <FormErrors />

                        <EmptyFormBox>
                            <TextInput
                                label={translate('nom_de_l_organi_49583')}
                                name="organization.name"
                            />

                            <Spacer height="4" />

                            <DateInput
                                label={translate('date_de_fin_de_17750')}
                                name="organization.periodEndAt"
                            />

                            <Spacer height="4" />

                            <RichSelect
                                isSearchVisible={true}
                                isSelectAllVisible={true}
                                label={translate('fonctionnalit_s_47714')}
                                multiple={true}
                                name="organization.features"
                            >
                                {ALL_FEATURE.map((feature) => (
                                    <option key={feature} value={feature}>
                                        {feature}
                                    </option>
                                ))}
                            </RichSelect>

                            <Spacer height="4" />

                            <RichSelect
                                isSearchVisible={true}
                                label={translate('badges_personna_27045')}
                                multiple={true}
                                name="organization.customBadges"
                            >
                                {customBadges.map(({ value, name }) => (
                                    <option key={value} value={value}>
                                        {name}
                                    </option>
                                ))}
                            </RichSelect>

                            <Spacer height="4" />

                            <RichSelect
                                isSearchVisible={true}
                                label={translate('utilisateurs_ay_27051')}
                                multiple={true}
                                name="organization.usersIds"
                            >
                                {usersIdsOptions.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </RichSelect>

                            <Spacer height="4" />

                            <Flex gap="4">
                                <TextInput
                                    label={translate('id_de_l_organis_67465')}
                                    name="organization.weezeventOrganizationId"
                                    rightIcon="xmark"
                                    shouldParseAsInt={true}
                                    onRightIconClick={() => {
                                        form.change('organization.weezeventOrganizationId', null);
                                    }}
                                />

                                <TextInput
                                    label={translate('nombre_de_membr_16400')}
                                    name="organization.membersLimit"
                                    shouldParseAsInt={true}
                                />
                            </Flex>

                            <Spacer height="4" />

                            <CheckboxText name="organization.isBlocked">
                                {translate('bloquer_l_organ_81103')}
                            </CheckboxText>
                        </EmptyFormBox>

                        <Spacer height="6" />

                        <EmptyFormBox>
                            <TableFilters
                                filters={
                                    <>
                                        <Box width={200}>
                                            <RichSelectComponent
                                                isSelectAllVisible={true}
                                                multiple={true}
                                                placeholder={translate('status_06428')}
                                                values={states}
                                                onChange={(newStates: EventState[]) => {
                                                    setStates(newStates);
                                                    setOffset(0);
                                                }}
                                            >
                                                {enumToOptions(ALL_EVENTSTATE)}
                                            </RichSelectComponent>
                                        </Box>

                                        <Box width={320}>
                                            <TextInputComponent
                                                icon="magnifying-glass"
                                                placeholder={translate('rechercher_un_40024')}
                                                value={name}
                                                onChange={(newName: string) => {
                                                    setName(newName);
                                                    setOffset(0);
                                                }}
                                            />
                                        </Box>
                                    </>
                                }
                                headerCells={
                                    <>
                                        <HeaderCellSort
                                            attribute={EventsSortAttributes.Name}
                                            setSort={setSort}
                                            sort={sort}
                                        >
                                            {translate('nom_de_l_v_nem_08711')}
                                        </HeaderCellSort>
                                        <HeaderCell>{translate('status_06428')}</HeaderCell>
                                        <HeaderCell />
                                    </>
                                }
                                numberOfPages={numberOfPages}
                                offset={offset}
                                rows={
                                    isLoading ? (
                                        <>
                                            <RowSkeleton bx={true} />
                                            <RowSkeleton bx={true} />
                                            <RowSkeleton bx={true} />
                                        </>
                                    ) : (
                                        data.backoffice.events.nodes.map((event) => (
                                            <BackofficeEventRow
                                                key={event.id}
                                                event={event}
                                                organizationId={props.organization.id}
                                                reload={reload}
                                            />
                                        ))
                                    )
                                }
                                setOffset={setOffset}
                                title={translate('liste_des_v_ne_29929')}
                                totalCount={totalCount}
                            />
                        </EmptyFormBox>

                        <Spacer height="6" />

                        <EmptyFormBox>
                            <Button
                                isLoading={submitting}
                                textAlign="center"
                                onClick={handleSubmit}
                            >
                                {translate('mettre_jour_l_12499')}
                            </Button>
                        </EmptyFormBox>
                    </CenteredContainer>
                </FullScreenPopup>
            )}
            validate={validateService.validateForForm(
                backofficeOrganizationInput.updateOrganizationSchema()
            )}
            width={1}
            onSubmit={async (values: IUpdateBackofficeOrganizationValues) => {
                await mutate({
                    id: props.organization.id,
                    organization: values.organization
                });

                history.goBack(HeaventPaths.BACKOFFICE);
            }}
        />
    );
};

export const UpdateBackofficeOrganization = () => {
    const { organizationId } = useParams();
    const { data, loader } = useBackofficeOrganizationQuery({ id: organizationId });

    return (
        loader || (
            <UpdateBackofficeOrganizationComponent organization={data.backoffice.organization} />
        )
    );
};
