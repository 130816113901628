import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormsPaths } from 'common/src/util/paths/formsPaths';
import { getFormHref } from 'common/src/util/url';
import { FormPreviewState } from 'common/src/vo/form';
import * as React from 'react';
import { useFormQuery } from '../../../generated/graphqlHooks';
import { FormContainer } from '../formContainer';

export const FormPreview = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, formId }
    } = useHeavent();
    const { data } = useFormQuery({ organizationId, formId });
    useTitle(data.organization?.form.name ?? '');
    const [previewState, setPreviesState] = React.useState<FormPreviewState>('opened');
    const link = getFormHref({
        organizationId,
        eventId,
        formId,
        query: { preview: true, previewState: previewState }
    });

    return (
        <FormContainer
            title={data.organization?.form.name ?? '-'}
            onClose={() => {
                history.push(FormsPaths.FORMS({ organizationId, eventId }));
            }}
        >
            <Flex align="center" direction="column" height={1} width={1}>
                <Flex direction="column" width="$container">
                    <Spacer height="8" />

                    <Flex align="center" gap="4">
                        <Flex css={{ flex: '1' }} direction="column">
                            <Box font="gray900 textLg semiBold">
                                {translate('aper_u_du_formu_51076')}
                            </Box>
                            <Box color="gray500">{translate('pr_visualiser_v_58073')}</Box>
                        </Flex>

                        <Box width={200}>
                            <Select
                                value={previewState}
                                onChange={(newPreviewState) => {
                                    setPreviesState(newPreviewState as FormPreviewState);
                                }}
                            >
                                <option value="opened">{translate('Opened')}</option>
                                <option value="closed">{translate('cl_tur_79063')}</option>
                                <option value="blocked">{translate('acc_s_r_fus_68875')}</option>
                            </Select>
                        </Box>
                    </Flex>
                </Flex>

                <Spacer height="5" />

                <Box css={{ flex: '1', overflow: 'hidden' }} width={1}>
                    <iframe height="100%" src={link} style={{ border: 'none' }} width="100%" />
                </Box>
            </Flex>
        </FormContainer>
    );
};
