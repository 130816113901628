import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Flex } from 'common/src/designSystem/components/flex';
import { MAX_CSV_SIZE } from 'common/src/input/csvInput';
import {
    DelegationsImportInput,
    DelegationsImportInputService
} from 'common/src/input/delegationsImportInput';
import { NumberService } from 'common/src/services/numberService';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import { ImportProcessingAlert } from '../../common/import/importProcessingAlert';
import { useDelegationsImportMutation } from '../../generated/graphqlHooks';
import { useNotificationContext } from '../../notifications/notificationContext';

const removeFileFromInput = (
    input: DelegationsImportInput & {
        csv: {
            file?: File;
        };
    }
) => {
    const csvCopy = { ...input.csv };

    delete csvCopy.file;

    return {
        ...input,
        csv: csvCopy
    };
};

export const DelegationsImport = () => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const delegationsImportInput = useService(DelegationsImportInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { mutate } = useDelegationsImportMutation();
    const { checkDelegationsImport } = useNotificationContext();

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={{ input: delegationsImportInput.default().input }}
            render={({ form, handleSubmit, submitting }) => (
                <FullScreenPopup
                    button={
                        <Button isLoading={submitting} onClick={handleSubmit}>
                            {translate('importer_la_lis_12866')}
                        </Button>
                    }
                    category={translate('import_de_d_l_g_69356')}
                    color="dark"
                    fallbackClosePath={DelegationsPaths.DELEGATIONS({ organizationId, eventId })}
                    title={translate('nouvel_import_48306')}
                >
                    <CenteredContainer ref={centeredContainerRef}>
                        {form.getState().submitSucceeded && <ImportProcessingAlert />}
                        <FormErrors />

                        <Flex direction="column" gap="5">
                            <FormBox
                                hideToggle={true}
                                initialIsOpen={true}
                                subtitle={translate(
                                    's_lectionnez_vo_125161',
                                    NumberService.bytesToMegabytes(MAX_CSV_SIZE)
                                )}
                                title={translate('votre_fichier_d_51776')}
                            >
                                <FileS3Input
                                    accept={Accept.Csv}
                                    acl="private"
                                    organizationId={organizationId}
                                    prefix="input.csv."
                                    withFileField={true}
                                />
                            </FormBox>
                        </Flex>
                    </CenteredContainer>
                </FullScreenPopup>
            )}
            validate={({ input }) =>
                validateService.validateForForm(
                    delegationsImportInput.schema({
                        delimiter: input.csv.delimiter ?? undefined,
                        quoteChar: input.csv.quoteChar ?? undefined,
                        newline: (input.csv.newline as any) ?? undefined // `any` cast needed because `newline` here might be any string
                    })
                )({ input })
            }
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async ({ input }) => {
                const inputWithoutFile = removeFileFromInput(input);

                try {
                    const { jobId } = await mutate({
                        eventId,
                        organizationId,
                        input: inputWithoutFile
                    });
                    if (centeredContainerRef.current) {
                        centeredContainerRef.current.scrollTop = 0;
                    }
                    checkDelegationsImport(organizationId, eventId, jobId);
                } catch (e) {
                    /* Already displayed in <FormErrors />. */
                }
            }}
        />
    );
};
