import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AccreditationsSlotInput, Event } from 'common/src/generated/types';
import {
    AccreditationsSlotInputService,
    ICreateAccreditationSlotValues
} from 'common/src/input/accreditationsSlotInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { pick } from 'lodash-es';
import * as React from 'react';

interface ICreateAccreditationCreateSlotModalProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    slot: AccreditationsSlotInput;

    onClose(): void;
    onSuccess(slot: AccreditationsSlotInput): Promise<void>;
}

export const CreateAccreditationCreateSlotModal = (
    props: ICreateAccreditationCreateSlotModalProps
) => {
    const translate = useTranslate();
    const accreditationSlotInput = useService(AccreditationsSlotInputService);

    return (
        <FormModal
            buttonText={translate('mettre_jour_l_76289')}
            icon="sliders"
            initialValues={{
                slot: props.slot
            }}
            schema={accreditationSlotInput.createAccreditationSlotSchema()}
            size="md"
            subtitle={translate('')}
            title={translate('mise_jour_d_u_86656')}
            onClose={props.onClose}
            onSubmit={async (values: ICreateAccreditationSlotValues) => {
                await props.onSuccess(pick(values.slot, ['id', 'name', 'date', 'maxResources']));
                props.onClose();
            }}
        >
            {({ form }) => (
                <Flex css={{ flex: '1', overflowY: 'auto' }} direction="column" width={1}>
                    <TextInput label={translate('nom_du_cr_neau_54351')} name="slot.name" />

                    <Spacer height="4" />

                    <TextInput
                        label={translate('MaxResources')}
                        name="slot.maxResources"
                        shouldParseAsInt={true}
                    />

                    <Spacer height="4" />

                    <DateInput
                        label={translate('Date')}
                        max={props.event.endAt}
                        min={props.event.startAt}
                        name="slot.date"
                        rightIcon="xmark"
                        onRightIconClick={() => {
                            form.change('slot.date', null);
                        }}
                    />
                </Flex>
            )}
        </FormModal>
    );
};
