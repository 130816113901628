import { AssignPopup } from 'common-front/src/components/assignPopup';
import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { Button } from 'common-front/src/designSystem/components/button';
import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useVolunteersRegistrationsMassAssignMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMassActions } from 'common-front/src/hooks/useMassActions';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    MassAssignStrategy,
    UsersInfoId,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { preventDefault } from 'common/src/util/links';
import { LocaleFormats } from 'common/src/util/luxon';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import { difference, noop } from 'lodash-es';
import * as React from 'react';
import { useAvailableVolunteersRegistrationsQuery } from '../../generated/graphqlHooks';
import { useLocalEventState } from '../../hooks/useLocalState';

export const PositionsSlotsAssignVolunteers = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionId, positionSlotId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [filterAvailabilities, setFilterAvailabilities] = useLocalEventState(
        'positionsSlotsAssignVolunteers.filterAvailabilities',
        true
    );
    const [filterPositions, setFilterPositions] = useLocalEventState(
        'positionsSlotsAssignVolunteers.filterPositions',
        false
    );
    const [applyConditions, setApplyConditions] = useLocalEventState(
        'positionsSlotsAssignVolunteers.applyConditions',
        true
    );
    const [sort, setSort] = React.useState<Sort<VolunteersRegistrationsSortAttributes> | null>(
        null
    );
    const { data, isLoading } = useAvailableVolunteersRegistrationsQuery({
        eventId,
        positionSlotId,
        filterAvailabilities,
        filterPositions,
        applyConditions,
        sort
    });
    const { mutate: massAssign, isLoading: isMassAssignLoading } =
        useVolunteersRegistrationsMassAssignMutation();
    const [search, setSearch] = React.useState('');
    const volunteersRegistrations = React.useMemo(() => {
        const vrs = data.event?.positionSlot.availableVolunteersRegistrations ?? [];

        if (isNonEmptyString(search)) {
            return vrs.filter(
                (vr) =>
                    vr.userInfo.name.toLowerCase().includes(search.toLowerCase()) ||
                    vr.userInfo.email.includes(search.toLowerCase())
            );
        } else {
            return vrs;
        }
    }, [data.event, search]);
    const { numberOfSelected, selectAllState, states, setStates, toggleRow, toggleSelectAll } =
        useMassActions<UsersInfoId>({});
    const numberOfAppliedFilters = React.useMemo(
        () =>
            (applyConditions ? 1 : 0) + (filterAvailabilities ? 1 : 0) + (filterPositions ? 1 : 0),
        [applyConditions, filterAvailabilities, filterPositions]
    );

    React.useEffect(() => {
        setStates(
            Object.fromEntries(
                (data.event?.positionSlot.availableVolunteersRegistrations ?? []).map((vr) => [
                    `ui${vr.userInfo.id}`,
                    { id: vr.userInfo.id, state: vr.isAssignedTo ? 'checked' : 'unchecked' }
                ])
            )
        );
    }, [data.event]);

    const onAssign = async () => {
        const initialSelectedIds = (
            data.event?.positionSlot.availableVolunteersRegistrations ?? []
        ).flatMap((vr) => (vr.isAssignedTo ? [vr.userInfo.id] : []));
        const selectedsIds = Object.values(states)
            .filter(({ state }) => state === 'checked')
            .map(({ id }) => id);
        const addedIds = difference(selectedsIds, initialSelectedIds);
        const deletedIds = difference(initialSelectedIds, selectedsIds);

        if (isNonEmptyArray(addedIds)) {
            await massAssign({
                eventId,
                massAssign: {
                    positionsSlotsIds: [positionSlotId],
                    selecteds: { ids: addedIds },
                    strategy: MassAssignStrategy.Add
                }
            });
        }

        if (isNonEmptyArray(deletedIds)) {
            await massAssign({
                eventId,
                massAssign: {
                    positionsSlotsIds: [positionSlotId],
                    selecteds: { ids: deletedIds },
                    strategy: MassAssignStrategy.Delete
                }
            });
        }
    };

    return (
        <AssignPopup
            button={
                <Button
                    isLoading={isMassAssignLoading}
                    to={PositionsPaths.POSITION({
                        organizationId,
                        eventId,
                        positionId
                    })}
                    onClick={onAssign}
                >
                    {numberOfSelected === 0
                        ? translate('ne_pas_affecter_05756')
                        : translate('affecter_1_me_46721', numberOfSelected)}
                </Button>
            }
            closePath={PositionsPaths.POSITION({
                organizationId,
                eventId,
                positionId
            })}
            popup={{
                category: translate(
                    'affectation_u_96975',
                    data.event?.positionSlot.positionName ?? ''
                ),
                title: data.event?.positionSlot.nameOrRange ?? ''
            }}
        >
            <TableFilters
                filters={
                    <Flex gap="3">
                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_un_m_05904')}
                                value={search}
                                onChange={setSearch}
                            />
                        </Box>

                        <Dropdown>
                            <Trigger>
                                <Button color="gray" leftIcon="sliders">
                                    {translate('filtres_1_41774', numberOfAppliedFilters)}
                                </Button>
                            </Trigger>

                            <Menu css={{ padding: '0' }} placement="bottom-end">
                                <Flex css={{ padding: '$4 $6' }} direction="column" width={440}>
                                    <Box font="gray800 textMd medium">
                                        {translate('liste_des_filtr_75605')}
                                    </Box>

                                    <Spacer height="4" />

                                    <Box font="gray800 textSm medium">
                                        {translate('disponibilit_s_49923')}
                                    </Box>

                                    <Spacer height="2" />

                                    <RadioText
                                        state={filterAvailabilities ? 'unchecked' : 'checked'}
                                        onClick={(state) => {
                                            setFilterAvailabilities(state !== 'checked');
                                        }}
                                    >
                                        {translate('afficher_tous_l_44338')}
                                    </RadioText>

                                    <Spacer height="3" />

                                    <RadioText
                                        state={filterAvailabilities ? 'checked' : 'unchecked'}
                                        onClick={(state) => {
                                            setFilterAvailabilities(state === 'checked');
                                        }}
                                    >
                                        {translate('filtrer_selon_l_35857')}
                                    </RadioText>

                                    <HorizontalSpacerSeparator height="4" />

                                    <Box font="gray800 textSm medium">
                                        {translate('missions_63972')}
                                    </Box>

                                    <Spacer height="2" />

                                    <RadioText
                                        state={filterPositions ? 'unchecked' : 'checked'}
                                        onClick={(state) => {
                                            setFilterPositions(state !== 'checked');
                                        }}
                                    >
                                        {translate('afficher_tous_l_44338')}
                                    </RadioText>

                                    <Spacer height="3" />

                                    <RadioText
                                        state={filterPositions ? 'checked' : 'unchecked'}
                                        onClick={(state) => {
                                            setFilterPositions(state === 'checked');
                                        }}
                                    >
                                        {translate('filtrer_selon_l_42798')}
                                    </RadioText>

                                    <HorizontalSpacerSeparator height="4" />

                                    <Box font="gray800 textSm medium">
                                        {translate('conditions_77756')}
                                    </Box>

                                    <Spacer height="2" />

                                    <RadioText
                                        state={applyConditions ? 'unchecked' : 'checked'}
                                        onClick={(state) => {
                                            setApplyConditions(state !== 'checked');
                                        }}
                                    >
                                        {translate('afficher_tous_l_44338')}
                                    </RadioText>

                                    <Spacer height="3" />

                                    <RadioText
                                        state={applyConditions ? 'checked' : 'unchecked'}
                                        onClick={(state) => {
                                            setApplyConditions(state === 'checked');
                                        }}
                                    >
                                        {translate('filtrer_selon_l_59697')}
                                    </RadioText>
                                </Flex>
                            </Menu>
                        </Dropdown>
                    </Flex>
                }
                headerCells={
                    <>
                        <HeaderCell css={{ paddingRight: 0 }} width={48}>
                            <Checkbox state={selectAllState} onClick={toggleSelectAll} />
                        </HeaderCell>
                        <HeaderCellSort
                            attribute={VolunteersRegistrationsSortAttributes.Name}
                            css={{ paddingLeft: '$2', flexGrow: 2 }}
                            setSort={setSort}
                            sort={sort}
                        >
                            {translate('nom_du_membre_69353')}
                        </HeaderCellSort>
                        <HeaderCell>{translate('nombre_d_affect_31455')}</HeaderCell>
                        <HeaderCellSort
                            attribute={VolunteersRegistrationsSortAttributes.InsertedAt}
                            setSort={setSort}
                            sort={sort}
                        >
                            {translate('date_d_inscript_31369')}
                        </HeaderCellSort>
                    </>
                }
                limit={volunteersRegistrations.length}
                numberOfPages={1}
                offset={0}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        volunteersRegistrations.map((volunteerRegistration) => (
                            <Row
                                key={volunteerRegistration.id}
                                css={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={() => {
                                    history.push(
                                        HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS_USER_INFORMATIONS(
                                            organizationId,
                                            eventId,
                                            positionId,
                                            positionSlotId,
                                            volunteerRegistration.userInfo.id
                                        )
                                    );
                                }}
                            >
                                <Cell css={{ paddingRight: 0 }} width={48}>
                                    <Checkbox
                                        state={
                                            states[`ui${volunteerRegistration.userInfo.id}`]
                                                ?.state ?? 'unchecked'
                                        }
                                        onClick={(newState, e) => {
                                            preventDefault(e);

                                            toggleRow(
                                                `ui${volunteerRegistration.userInfo.id}`,
                                                newState
                                            );
                                        }}
                                    />
                                </Cell>
                                <Cell css={{ paddingLeft: '$2', flexGrow: 2 }}>
                                    <AvatarNameEmail userInfo={volunteerRegistration.userInfo} />
                                </Cell>
                                <Cell>{volunteerRegistration.numberOfAssignments}</Cell>
                                <Cell>
                                    {dateTimeService.toLocaleString(
                                        volunteerRegistration.insertedAt.toLocal(),
                                        LocaleFormats.DateTime
                                    )}
                                </Cell>
                            </Row>
                        ))
                    )
                }
                setOffset={noop}
                title={translate('liste_des_membr_42505')}
                totalCount={volunteersRegistrations.length}
            />
        </AssignPopup>
    );
};
